import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemButton, ListItemText, Modal, Button, Box, Collapse } from "@mui/material";
import { LearnersProfile } from "../../helper/base_url";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false); // Mobile submenu state
  const [mobileAboutOpen, setMobileAboutOpen] = useState(false); // Mobile submenu state
  const location = useLocation(); // Get the current route
  const navigate = useNavigate();
  const aboutRef = useRef(null);
   const [data, setData] = useState({
      username: "",
      displayName: "",
    });
    const token = localStorage.getItem("token");
    const isActive = (path) => location.pathname === path;

    const toggleMenu = () => {
      setMenuActive(!menuActive);
    };
  
    const closeMenu = () => setMenuActive(false);
  
    const toggleAbout = () => {
      setAboutOpen(!aboutOpen);
    };
    const toggleMobileAbout = () => setMobileAboutOpen(!mobileAboutOpen); // Mobile toggle
  
    // Close submenu if clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (aboutRef.current && !aboutRef.current.contains(event.target)) {
          setAboutOpen(false);
        }
      };
  
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    const learnersLoggedIn = localStorage.getItem("token") !== null;
    const isLoggedIn = localStorage.getItem("company_token") !== null;

    // Wrap handleUserProfile with useCallback
      const handleUserProfile = useCallback(async () => {
        try {
          const response = await axios.post(
            `${LearnersProfile}`,
            {},
            {
              headers: {
                "content-type": "application/json",
                Authorization: `jwt ${token}`,
              },
            }
          );
          setData(response.data.data);
        } catch (error) {
          console.error("Error fetching user profile", error);
        }
      }, [token]); // Dependency array ensures function is stable unless token changes
    
      useEffect(() => {
        if (token) {
          handleUserProfile();
        }
      }, [token, handleUserProfile]); // Include handleUserProfile in the dependencies

      // Function to get the first letter of the username or display name
    const getFirstLetter = (name) => {
      return name ? name.charAt(0).toUpperCase() : "";
    };

  const menuItems = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "About",
      path: "#",
      submenu: [
        { label: "About Us", path: "/about-us" },
        { label: "Careers", path: "/careers" },
        //{ label: "Partnerships", path: "/partnerships" },
        // { label: "Executive Team", path: "/about/executive-team" },
      ],
    },
    {
      label: "Learners",
      path: "/learners",
    },
    {
      label: "Organizations",
      path: "/organizations",
    },
   // Show Dashboard if logged in
    ...learnersLoggedIn
    ? [
        {
          label: "Dashboard",
          onClick: (event) => {
            event.preventDefault();
            closeMenu();
            navigate("/learners/dashboardpage");
          },
        },
      ]
    : (isLoggedIn
    ? [
        {
          label: "Dashboard",
          onClick: (event) => {
            event.preventDefault();
            closeMenu();
            navigate("/organizations/dashboard");
          },
        },
      ]
    : [
        {
          label: "Sign In",
          onClick: () => {
            closeMenu();
            setOpenModel(true);
          },
        },
        {
          label: "Sign Up",
          onClick: () => {
            closeMenu();
            setOpenSignUpModal(true);
          },
        },
      ]),
  ];

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#ffff", 
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, sm: 6, md: 6 },
            boxShadow: "0px 0.5px 1px rgba(0, 0, 0, 0.2)",
          }}
        >
          {/* Brand Name */}
          <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "26px",
              sm: "30px",
              md: "40px",
              lg: "35px", // Apply 35px for 14-inch screens
            },
            fontWeight: "700",
            color: "rgb(2, 41, 100)",
            textDecoration: "none",
            "@media (min-width: 1366px) and (max-width: 1536px)": {
              fontSize: "35px",
            },
          }}
          component={Link}
          to="/"
        >
          Hue<span style={{ color: "rgb(27, 110, 244)" }}>bits</span>
        </Typography>


          {/* Responsive Menu Button #1c84e4*/}
          <IconButton
            edge="end"
            onClick={toggleMenu}
            sx={{
              color: "rgb(2, 41, 100)",
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuOutlinedIcon fontSize="large" />
          </IconButton>

          {/* Desktop Menu */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 3,
            }}
          >
            {menuItems.map((item, index) => (
              <Box key={index} sx={{ position: "relative", cursor: "pointer" }}>
              {item.submenu ? (
                    <Box
                      ref={aboutRef}
                      onMouseEnter={() => setAboutOpen(true)}
                      onMouseLeave={() => setAboutOpen(false)}
                      onClick={toggleAbout}
                    >
                      <Typography
                        sx={{
                          color: item.label === "Sign In" ? "rgb(27, 110, 244)" : "#303141",
                          cursor: "pointer",
                          fontWeight: 400,
                          fontSize: "16px",
                          padding: "6px",
                          borderRadius: "4px",
                          border: item.label === "Sign In" ? "1px solid rgb(27, 110, 244)" : "none",
                          transition: "background-color 0.3s ease",
                          "&:hover": { backgroundColor: "rgb(27, 110, 244)", color: "#fff" },
                        }}
                      >
                        {/* {item.label} <ExpandMoreIcon sx={{ fontSize: "20px", ml: 1 }} /> */}
                        {item.label}
                      </Typography>
                      {aboutOpen && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 35,
                            left: 0,
                            right: 10,
                            backgroundColor: "white",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "4px",
                            zIndex: 10,
                            width: "140px",
                            padding: "10px",
                          }}
                        >
                          {item.submenu.map((subItem, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subItem.path}
                              style={{ textDecoration: "none", color: "#303141" }}
                              onClick={() => setAboutOpen(false)}
                            >
                              <Typography
                                sx={{
                                  color: "#052659",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  transition: "background-color 0.3s ease",
                                  "&:hover": { backgroundColor: "rgb(27, 110, 244)", color: "#ffff" },
                                }}
                              >
                                {subItem.label}
                              </Typography>
                            </Link>
                          ))}
                        </Box>
                      )}
                    </Box>
                    ) : (
                    <Link
                      to={item.path}
                      onClick={item.onClick}
                      style={{ textDecoration: "none" }}
                    >
                  <Box
                  sx={{
                    color: item.label === "Sign Up" ? "#fff" : isActive(item.path) ? "#1c84e4" : "#303141", // Active color for current page
                    fontWeight: isActive(item.path) ? "400" : "400",
                    fontSize: "16px",
                    padding: "6px",
                    borderRadius: "4px",
                    border: item.label === "Sign In" ? "1px solid #1c84e4" : item.label === "Sign Up" ? "1px solid #1c84e4" : "none",
                    backgroundColor: item.label === "Sign Up" ? "#1c84e4" : "transparent",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: item.label === "Sign Up" ? "#fff" : "#1c84e4",
                      color: item.label === "Sign Up" ? "#1c84e4" : "#fff",
                    },
                  }}
                >
                    {item.label}
                </Box>
                </Link>
                )}
              </Box>
            ))}
            {learnersLoggedIn && (
            <Link to="/learners/dashboardpage" style={{ textDecoration: "none" }}>
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                backgroundColor: "#1c84e4",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "18px",
                textDecoration:"none"
              }}
            >
              {getFirstLetter(data.displayName || data.username)}
            </Box>
            </Link>
          )}
          </Box>
        </Toolbar>

        {/* Drawer for Mobile/Tablet Menu */}
        <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{
          "& .MuiDrawer-paper": {
            bgcolor: "#fff",
            width: 250,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={closeMenu} 
              sx={{ 
                color: "#052659", 
                fontWeight: 400,
                fontSize: "16px",
                padding: "8px",
                borderRadius: "4px",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1c84e4",
                  color: "#ffff",
                },
              }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item, index) => (
            <Box key={index}>
              {item.submenu ? (
                <>
                  <ListItemButton onClick={toggleMobileAbout}>
                    <ListItemText primary={item.label} 
                    sx={{
                      color: "#052659",
                      fontWeight: 400,
                      padding: "8px",
                      borderRadius: "4px",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#1c84e4",
                        color: "#ffff",
                      },
                    }}
                    />
                    <ExpandMoreIcon />
                  </ListItemButton>
                  <Collapse in={mobileAboutOpen} timeout="auto">
                    <List component="div" disablePadding>
                      {item.submenu.map((subItem, subIndex) => (
                        <ListItemButton
                          key={subIndex}
                          component={Link}
                          to={subItem.path}
                          sx={{ pl: 4 }}
                        >
                          <ListItemText primary={subItem.label} 
                          sx={{
                            color: "#052659",
                            fontWeight: 400,
                            padding: "8px",
                            borderRadius: "4px",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#1c84e4",
                              color: "#ffff",
                            },
                          }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItemButton onClick={item.onClick} component={Link} to={item.path}>
                  <ListItemText primary={item.label} 
                  sx={{
                    color: "#052659",
                    fontWeight: 400,
                    padding: "8px",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#1c84e4",
                      color: "#ffff",
                    },
                  }}
                  />
                </ListItemButton>
              )}
            </Box>
          ))}
        </List>
      </Drawer>
    </AppBar>

      {/* Modal for Sign Up Choices */}
      <Modal
      open={openSignUpModal}
      onClose={() => setOpenSignUpModal(false)}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '60%' }, // Adjust width for different screen sizes
          maxWidth: 500, // Ensure the modal doesn't exceed 500px
          backgroundColor: 'white',
          boxShadow: 24,
          borderRadius: 3,
          p: 4,
          position: 'relative',
        }}
      >
        <CloseIcon
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
            color: '#333',
          }}
          onClick={() => setOpenSignUpModal(false)}
        />
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h5" fontWeight="bold" mb={2}>
            Welcome Back
          </Typography>
          <Typography variant="body1" mb={4} color="text.secondary">
            Please choose your Sign Up type to continue
          </Typography>
          <Link to="/learners/signup" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                // background: 'linear-gradient(135deg, #5483B3, #052659)',
                backgroundColor: "rgb(27, 110, 244)",
                color: 'white',
                borderRadius: 2,
                py: 1.5,
                fontWeight: 700,
                fontSize: { xs: 14, sm: 16 }, // Adjust font size for mobile
                mb: 2,
                '&:hover': {
                  // background: 'linear-gradient(135deg, #052659, #5483B3)',
                  backgroundColor: "rgb(27, 110, 244)",
                },
              }}
            >
             Learners Sign Up
            </Button>
          </Link>
          <Link to="/organizations/signup" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#5483B3',
                color: 'rgb(27, 110, 244)',
                borderRadius: 2,
                py: 1.5,
                fontWeight: 700,
                fontSize: { xs: 14, sm: 16 }, // Adjust font size for mobile
                '&:hover': {
                  backgroundColor: '#f4faff',
                  borderColor: 'rgb(27, 110, 244)',
                },
              }}
            >
              Organizations Sign Up
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>

      {/* Modal for Log In */}
    <Modal
      open={openModel}
      onClose={() => setOpenModel(false)}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '60%' }, // Adjust width for different screen sizes
          maxWidth: 500, // Ensure the modal doesn't exceed 500px
          backgroundColor: 'white',
          boxShadow: 24,
          borderRadius: 3,
          p: 4,
          position: 'relative',
        }}
      >
        <CloseIcon
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
            color: '#333',
          }}
          onClick={() => setOpenModel(false)}
        />
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h5" fontWeight="bold" mb={2}>
            Welcome Back
          </Typography>
          <Typography variant="body1" mb={4} color="text.secondary">
            Please choose your login type to continue
          </Typography>
          <Link to="/learners/signin" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor:"rgb(27, 110, 244)",
                color: 'white',
                borderRadius: 2,
                py: 1.5,
                fontWeight: 700,
                fontSize: { xs: 14, sm: 16 }, // Adjust font size for mobile
                mb: 2,
                '&:hover': {
                  backgroundColor:"rgb(27, 110, 244)",
                },
              }}
            >
              Learners Sign In
            </Button>
          </Link>
          <Link to="/organizations/signin" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#5483B3',
                color: 'rgb(27, 110, 244)',
                borderRadius: 2,
                py: 1.5,
                fontWeight: 700,
                fontSize: { xs: 14, sm: 16 }, // Adjust font size for mobile
                '&:hover': {
                  backgroundColor: '#f4faff',
                  borderColor: 'rgb(27, 110, 244)',
                },
              }}
            >
              Organizations Sign In
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>
    </div>
  );
};

export default Header;