import React, { useState, useCallback, useEffect } from "react";
import axios from 'axios';
import avatar from '../../assets/profile.png';
import convertToBase64 from '../../helper/Convert';
import { OrganizationsCompaniesprofile } from "../../helper/base_url";
import { TextField, Button, Box, Typography, Snackbar, Alert } from "@mui/material";

const CompaniesProfile = () => {
    const [, setFile] = useState(null);
    const [data, setData] = useState({
      companyName: "",
      name: "",
      mobile: "",
      profilePic : "",
    });
    const [userId, setUserId] = useState("");
    const token = localStorage.getItem("company_token");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setFile(base64);
        setData((prevData) => ({
          ...prevData,
          profilePic: base64 
        }));
    };

    const handleUserProfile = useCallback(async (updatedData) => {
      try {
        const { data: response } = await axios.put(
          `${OrganizationsCompaniesprofile}${userId}`,
          updatedData,
          {
            headers: {
              "content-type": "application/json",
              authorization: `jwt ${localStorage.getItem('company_token')}`
            },
          });
          setData(response.data || {});
          setMessage("Profile updated successfully!");
          setError("");
      } catch {
        setError("Network error. Please try again.");
        setMessage("");
      } finally {
        setOpenSnackbar(true);
      }
    }, [userId]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const updatedData = { ...data };
      await handleUserProfile(updatedData);
    };
  
    useEffect(() => {
      if (token) {
        setUserId(userId);
        handleUserProfile(); 
      }
    }, [token, userId, handleUserProfile]);

    return (
      <Box sx={{ 
        width: { xs: "300px", sm: "600px", md: "600px" },
        padding: 2, 
        margin: '40px auto', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center' 
    }}>
        {/* Snackbar moved here for top placement */}
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={500} 
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Ensures it's displayed at the top
            sx={{marginTop:"40px"}}
        >
            {message ? (
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            ) : error ? (
                <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            ) : null}
        </Snackbar>

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <label htmlFor="profile">
                    <img 
                        src={data.profilePic ? data.profilePic : avatar} 
                        alt="Profile" 
                        style={{ width: 100, height: 100, borderRadius: '50%' }} 
                    />
                </label>
                <input onChange={onUpload} type="file" id="profile" name="profile" style={{ display: 'none' }} />
            </Box>
            <TextField 
                label="Company Name" 
                variant="outlined" 
                name="companyName" 
                value={data.companyName} 
                onChange={handleOnChange} 
                fullWidth 
            />
            <TextField 
                label="Full Name" 
                variant="outlined" 
                name="name" 
                value={data.name} 
                onChange={handleOnChange} 
                fullWidth 
            />
            <TextField 
                label="Mobile No" 
                variant="outlined" 
                name="mobile" 
                value={data.mobile} 
                onChange={handleOnChange} 
                fullWidth 
            />
            <Typography variant="body2" sx={{ mt: 2 }}>Member Since:</Typography>
            <Typography variant="body2">Total Payouts: $0.00</Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button type="submit" variant="contained" color="primary"
                    sx={{
                      margin: 0.5,
                      backgroundColor: "#043885",
                      "&:hover": {
                        backgroundColor: "#052659",
                      },
                    }}>
                      Profile Update
                    </Button>
            </Box>
        </Box>
    </Box>
    );
};

export default CompaniesProfile;
