import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Typography, CircularProgress,Container,Box,Grid,Button,LinearProgress, 
  TextField,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { OrganizationsSubmitproject } from "../../helper/base_url";

const SelectIndustry = () => {
  const [projectData, setProjectData] = useState(null);
  const [savedCategory, setSavedCategory] = useState("");
  const [savedSkills, setSavedSkills] = useState([]);
  const [savedSkills1, setSavedSkills1] = useState([]);
  const [savedProjects, setSavedProjects] = useState({});
  const [formData, setFormData] = useState(null);
  const [file, setFile] = useState(null);
  const [loading] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(90);
  const navigate = useNavigate();

  useEffect(() => {
    const storedProjectData = localStorage.getItem("ProjectData");
    if (storedProjectData) {
      setProjectData(JSON.parse(storedProjectData));
    }
    setSavedCategory(localStorage.getItem("selectedCategory") || "");
    setSavedSkills(JSON.parse(localStorage.getItem("selectedSkills")) || []);
    setSavedSkills1(JSON.parse(localStorage.getItem("selectedSkillsArray")) || []);
    setSavedProjects(JSON.parse(localStorage.getItem("projectData")) || {});
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) {
      toast.error("Please upload a project file.");
      return;
    }

    const postprojects = new FormData();
    postprojects.append("fileInput", file);
    postprojects.append("Projects", JSON.stringify({
      developmentType: formData.Development,
      developmentIn: formData.skill,
      projectTitle: projectData.title1,
      description: projectData.textarea,
      industry: savedCategory,
      skills: savedSkills.join(", "),
      programming: savedSkills1.join(", "),
      duration: savedProjects.type || "",
      budget: savedProjects.Type || null,
    }));

    try {
      const token = localStorage.getItem('company_token');
      if (!token) {
        throw new Error('User not authenticated');
      }
      const response = await axios.post(
        `${OrganizationsSubmitproject}`,
        postprojects,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "Success") {
        toast.success("Project uploaded successfully!");
        setCompletionPercentage(prevPercentage => prevPercentage + 10);
        setTimeout(() => {
          navigate("/organizations/project-confirmation");
        }, 2000);
      } else {
        toast.error("Failed to upload project.");
      }
    } catch (error) {
      //console.error("Error during project submission", error);
      toast.error("Error during project submission.");
    }
  };

  if (!projectData) {
    return <Typography variant="h6">No project data available.</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">{formData.Development}</Typography>
          {/* <Typography variant="h6" align="left">Development In <span>:</span> {formData.skill}</Typography> */}
        </Grid>

        {/* Project Information Table */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', color: '#052659' }}>Field</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: '#052659' }}>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell><strong>Development In</strong></TableCell>
                  <TableCell>{formData.skill}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Project Title </strong></TableCell>
                  <TableCell>{projectData.title1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Industry</strong></TableCell>
                  <TableCell>{savedCategory || "Not provided"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Skills</strong></TableCell>
                  <TableCell>{savedSkills.join(", ") || "Not provided"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Programmings</strong></TableCell>
                  <TableCell>{savedSkills1.join(", ") || "Not provided"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Duration</strong></TableCell>
                  <TableCell>{savedProjects.type || "Not provided"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Budget</strong></TableCell>
                  <TableCell>{savedProjects.Type || "Not provided"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Project Description</strong></TableCell>
                  <TableCell>{projectData.textarea}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* File Upload Section */}
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">Upload Project File</Typography>
          <Typography color="textSecondary">Upload files related to your project. Types Allowed: .xlsx, .xls, .doc, .docx, .ppt, .pptx, and .pdf.</Typography>
          <TextField
            fullWidth
            type="file"
            variant="outlined"
            onChange={handleFileChange}
            inputProps={{ accept: ".doc,.pdf" }}
            helperText="File Types Allowed: .xlsx, .xls, .doc, .docx, .ppt, .pptx, and .pdf. Max size: 20MB."
            required
            sx={{ marginTop: "10px" }}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
            <Button
              component={Link}
              to="/organizations/project-timeline"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              disabled={loading}
              sx={{
                margin: 0.5,
                color: "#052659",
                "& .MuiChip-deleteIcon": {
                  color: "#052659",
                },
              }}
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              endIcon={loading ? <CircularProgress size={16} /> : <FontAwesomeIcon icon={faArrowRight} />}
              disabled={loading}
              sx={{
                margin: 0.5,
                backgroundColor: "#043885",
                "&:hover": {
                  backgroundColor: "#052659",
                },
              }}
            >
              {loading ? "Submitting..." : "Project Submit"}
            </Button>
          </Box>
        </Grid>

        {/* Progress Bar */}
        <Grid item xs={12}>
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="body2" gutterBottom>Step 6 of 6</Typography>
            <LinearProgress
              variant="determinate"
              value={completionPercentage}
              sx={{
                borderRadius: 1,
                height: 10,
                backgroundColor: "#f5f5f5",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "green",
                },
              }}
            />
          </Box>
          <Typography variant="body2" align="right" sx={{ mt: 1, fontWeight: 600 }}>
            {completionPercentage}%
          </Typography>
        </Grid>

        {/* Footer */}
        <Grid item xs={12} sx={{ textAlign: "center", mt: 3, color: "text.secondary" }}>
          <Typography variant="caption"> &copy; 2024 Huebits All Rights Reserved </Typography>
          <br />
          <Typography variant="caption">Terms of Business</Typography>
        </Grid>
      </Grid>

      {/* Toast Notifications */}
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
    </Container>
  );
};

export default SelectIndustry;
