import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { LearnersProfile } from "../../../helper/base_url";

const DashboardHeader = () => {
  const [data, setData] = useState({
    fullname: "",
    username: "",
    displayName: "",
    profilePic: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  // Responsive breakpoints
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  // Wrap handleUserProfile with useCallback
  const handleUserProfile = useCallback(async () => {
    try {
      const response = await axios.post(
        `${LearnersProfile}`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `jwt ${token}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile", error);
    }
  }, [token]); // Dependency array ensures function is stable unless token changes

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token, handleUserProfile]); // Include handleUserProfile in the dependencies

  // Handlers
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  // const handleLogOut = async () => {
  //   try {
  //     await axios.post(`${Logout}`, {}, ); // Clears the auth cookie
  //     localStorage.removeItem("token"); // Remove token
  //     localStorage.removeItem("clickedPaths");
  //     navigate("/learners"); // Redirect to login page
  //   } catch (error) {
  //     error("Logout failed, please try again");
  //   }
  // };
  const handleLogOut = () => {
    localStorage.removeItem("token"); // Remove token
    localStorage.removeItem("clickedPaths");
    navigate("/learners");
  };  

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 40px",
        background: "rgb(14, 104, 249)", // Changed background color
        position: "sticky",
        top: 0,
        zIndex: 1000,
        borderBottom: "1px solid #ddd",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          marginRight: "10px",
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          sx={{
            fontWeight: "bold",
            fontSize: isMobile ? "16px" : isTablet ? "18px" : "20px",
            textAlign: "start",
            color: "#fff",
          }}
        >
          Welcome{" "}
          <span style={{ color: "#ffd700", fontWeight: "bold" }}>
            {data?.fullname || data?.username || data?.displayName}
          </span>
          <span> Keep Going</span>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#fff",
            fontSize: isMobile ? "12px" : "14px",
            textAlign: "right",
            marginTop: "4px",
          }}
        >
          Keep going—you're doing amazing!
        </Typography>
      </Box>

      {/* Profile Picture & Menu */}
      <IconButton
        onMouseEnter={handleMenuOpen} // Opens on hover
        sx={{ padding: 0 }}
      >
        <Box
          sx={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            overflow: "hidden",
            backgroundColor: "#f5f5f5",
            transition: "all 0.3s ease",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              transform: "scale(1.1)",
              backgroundColor: "#e0e0e0",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {data.profilePic ? (
            <img
              src={data.profilePic}
              alt="Profile"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          ) : (
            <PersonOutlinedIcon sx={{ color: "#4081c2", fontSize: "30px" }} />
          )}
        </Box>
      </IconButton>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          onMouseLeave: handleMenuClose, // Closes menu when moving cursor away
        }}
        sx={{
          width: "250px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/learners/dashboard/userprofile");
            handleMenuClose();
          }}
          sx={{
            padding: "12px 18px",
            fontWeight: "500",
            fontSize: "15px",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          <AccountCircleIcon sx={{ marginRight: "10px", color: "#4081c2" }} />
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogOut();
            handleMenuClose();
          }}
          sx={{
            padding: "12px 18px",
            fontWeight: "500",
            fontSize: "15px",
            color: "#d32f2f",
            "&:hover": {
              backgroundColor: "#fbe9e7",
            },
          }}
        >
          <ExitToAppIcon sx={{ marginRight: "10px" }} />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DashboardHeader;
