import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import behaves from "../../../assets/learnersSignup.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Typography, TextField, Grid, Button, IconButton, CircularProgress, useMediaQuery } from '@mui/material';
import { FaEye, FaEyeSlash, FaTwitter, FaInstagram,FaLinkedin, FaYoutube } from 'react-icons/fa';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import Footer from "../../../mainpage/Footer/learnersFooter";
import { LearnersSignup } from "../../../helper/base_url";
import ReCAPTCHA from "react-google-recaptcha";  // <-- Import this

function Signup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ name: "", email: "",username: "", password: "", confirmPassword: "", role: "student" });
  const [captchaToken, setCaptchaToken] = useState(""); // Store reCAPTCHA token
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const navigate = useNavigate();

  // Check if user is already logged in
    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate("/learners");
        }
    }, [navigate]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // reCAPTCHA callback
  const handleCaptchaChange = (token) => setCaptchaToken(token);

  const handleSubmit = async (e) => {
    e.preventDefault();
        if (!captchaToken) {
          toast.error("Please complete the reCAPTCHA.");
          return;
        }
    setLoading(true);

    const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$/;

    if (data.password.length < 8) {
        toast.error("Password should be more than 8 characters");
    } else if (!regExspecialChars.test(data.password)) {
        toast.error("Password should contain at least 1 digit, 1 special character, and 1 lower and upper case letter");
    } else if (data.password !== data.confirmPassword) {
        toast.error("Confirm password does not match");
    } else {
        try {
            const response = await axios.post(`${LearnersSignup}`, { ...data, captchaToken }, {
                headers: { "Content-Type": "application/json" },
            });
            //const { token } = response.data;
            if (response.data.error && response.data.message === "Account already exists") {
                toast.error(response.data.message);
            } else {
                const toastId = toast.loading("Submitting your registration details...");
                toast.update(toastId, {
                    render: response.data.message,
                    type: "success",
                    isLoading: false,
                });
                setData({ name: "", email: "", username: "", password: "", confirmPassword: "" });
                //localStorage.setItem('token', token);
                setTimeout(() => navigate("/learners/signin"), 500);
            }
        } catch (error) {
            toast.error(error.response?.data.message || "Network error, please try again.");
        } finally {
            setLoading(false);
        }
    }
};

  return (
    <div className="students-flex">
      <Headrepage />
      <div style={{ width: "100%", overflowX: "hidden", overflowY: "auto", height: "91vh" }}>
      <ToastContainer position="top-center" />
        <Grid container justifyContent="center" alignItems="center" style={{ padding: "40px 20px" }}>
          {!isMobile && !isTablet && (
            <Grid item xs={12} sm={8} md={6} lg={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={behaves} alt="Signup Illustration" style={{ width: "100%", maxWidth: "500px", height: "100%", marginRight:"40px", borderRadius:"10px" }} />
            </Grid>
          )}
          <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h5" align="center" gutterBottom sx={{color:"#043885"}}>
               Sign Up to your account
          </Typography>
            <form onSubmit={handleSubmit}>
            <TextField label="Name" name="name" fullWidth margin="normal" value={data.name} onChange={handleOnChange} required disabled={loading} />
            <TextField label="Email" type="email" name="email" fullWidth margin="normal" value={data.email} onChange={handleOnChange} required disabled={loading} />
            <Box position="relative">
              <TextField label="Password" type={showPassword ? "text" : "password"} name="password" fullWidth margin="normal" value={data.password} onChange={handleOnChange} required disabled={loading} />
              <IconButton onClick={() => setShowPassword(prev => !prev)} style={{ position: "absolute", right: 10, top: "35%" }}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </IconButton>
            </Box>
            <TextField label="Confirm Password" type="password" name="confirmPassword" fullWidth margin="normal" value={data.confirmPassword} onChange={handleOnChange} required disabled={loading} />
             {/* Google reCAPTCHA */}
             <div style={{margin: "20px 0px"}}>
                  <ReCAPTCHA
                    sitekey="6Le83KcqAAAAAInqCfWquiIGanUjpu2RxTMgKwuJ"  
                    onChange={handleCaptchaChange}
                  />
              </div>
              <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: 10 }} disabled={loading}>
                 {loading ? <CircularProgress size={24} /> : 'Sign Up'}
              </Button>
            </form>
             <Typography align="center" style={{ marginTop: 15 }}>
                Already have an account? <Link to="/learners/signin">Login here</Link>
             </Typography>
        </Grid>
        </Grid>
        <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
            </div>
            </div>
            </footer>
          </div>
          </div>
      </div>
  );
}

export default Signup;