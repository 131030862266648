import DataScienceFoundation from "../../../../assets/Courses/datasciencecourses/DataScienceFoundation.png";
import PythonforDataScience from "../../../../assets/Courses/datasciencecourses/PythonforDataScience.png";
import SQLforDataScience  from "../../../../assets/Courses/datasciencecourses/SQLforDataScience.png";
import StatisticsforDataScience from "../../../../assets/Courses/datasciencecourses/StatisticsforDataScience.png";
import IntroductiontoBusinessIntelligence from "../../../../assets/Courses/datasciencecourses/IntroductiontoBusinessIntelligence.png";
import MasteringTableau from "../../../../assets/Courses/datasciencecourses/MasteringTableauDataVisualizationandBusinessIntelligence.png";
import DataVisualization from "../../../../assets/Courses/datasciencecourses/DataVisualization.png";
import GoogleLookerStudio from "../../../../assets/Courses/datasciencecourses/MasteringGoogleLookerStudioDataVisualizationandBusinessIntelligence.png";
import PowerBI from "../../../../assets/Courses/datasciencecourses/MasteringPowerBIDataAnalyticsandVisualizationwithHands-OnProjects.png";
import MathematicsDataScience from "../../../../assets/Courses/datasciencecourses/MathematicsforDataScience.png";
import MachineLearningEssentials from "../../../../assets/Courses/datasciencecourses/MathematicsforDataScience.png";
import AdvancedMachineLearning from "../../../../assets/Courses/datasciencecourses/AdvancedMachineLearning.png";
import QlikSense from "../../../../assets/Courses/datasciencecourses/MasteringQlikSenseDataVisualizationandAnalytics.png";
import MachineLearningModelDeployment from "../../../../assets/Courses/datasciencecourses/MachineLearningModelDeploymentUsingFlask.png";
import TimeSeriesFoundation from "../../../../assets/Courses/datasciencecourses/TimeSeriesFoundation.png";
import DeepLearning from "../../../../assets/Courses/datasciencecourses/DeepLearning.png";
import NaturalLanguageProcessing from "../../../../assets/Courses/datasciencecourses/NaturalLanguageProcessing.png";
import ReinforcementLearning from "../../../../assets/Courses/datasciencecourses/ReinforcementLearning.png";
import AIFundamentals from "../../../../assets/Courses/datasciencecourses/ReinforcementLearning.png";
import DataEngineeringFundamentals from "../../../../assets/Courses/datasciencecourses/DataEngineeringFundamentals.png";
import AzureDataEngineering from "../../../../assets/Courses/datasciencecourses/MicrosoftAzureDataEngineering.png";
import MasteringApacheKafka from "../../../../assets/Courses/datasciencecourses/MasteringApacheKafkaHands-OnDistributedStreamingandDataPipelines.png";
import MasteringDatabricks from "../../../../assets/Courses/datasciencecourses/MasteringDatabricksEnd-to-EndDataEngineeringandAnalyticswithApacheSpark.png";
import MasteringSnowflake from "../../../../assets/Courses/datasciencecourses/MasteringSnowflakeHands-OnCloudDataWarehousingandAnalytics.png";
import GenerativeAIFundamentals from "../../../../assets/Courses/datasciencecourses/AIFundamentals.png";
import GenerativeAICourse from "../../../../assets/Courses/datasciencecourses/CompleteGenerativeAICourse.png";
import GenerativeAdversarialNetworks from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";
import CaseStudies from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";
import PromptEngineering from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";
import FineTuningLLMs from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";
import ProbabilityStatistics from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";
import ArtificialIntelligence from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";
import EngineeringMathematics from "../../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";

const BusinessIntelligence = [
  {
   coursesId: 1,
   coursesTitle: "Data Science Foundation",
    videoUrl: "67cc3b2468e13f369550b03f",
    image: DataScienceFoundation,
    completion: 0
  },
  {
   coursesId: 2,
   coursesTitle: "Python for Data Science",
    videoUrl: "67cc3d71a4b1024b47180dc9",
    image: PythonforDataScience,
    completion: 0
  },
  {
   coursesId: 3,
   coursesTitle: "SQL for Data Science",
    videoUrl: "67cc3e102d97eb150f84e3ca",
    image: SQLforDataScience,
    completion: 0
  },
  {
   coursesId: 4,
   coursesTitle: "Statistics for Data Science",
    videoUrl: "67cc3e9e68e13f369550b1c6",
    image: StatisticsforDataScience,
    completion: 0
  },
  {
   coursesId: 5,
   coursesTitle: "Introduction to Business Intelligence",
    videoUrl: "67cc3f96b4067e5a81ed473d",
    image:  IntroductiontoBusinessIntelligence,
    completion: 0
  },
  {
   coursesId: 6,
   coursesTitle: "Mastering Tableau: Data Visualization and Business Intelligence",
    videoUrl: "67cc408d137adb27bed51545", 
    image: MasteringTableau,
    completion: 0
  },
  {
   coursesId: 7,
   coursesTitle: "Data Visualization",
    videoUrl: "67cc4163c08b970ba4cd7d59",
    image: DataVisualization,
    completion: 0
  },
  {
   coursesId: 8,
   coursesTitle: "Mastering Google Looker Studio: Data Visualization and Business Intelligence",
    videoUrl: "67cc421c2754491fdbc6230c", 
    image: GoogleLookerStudio,
    completion: 0
  },
  {
   coursesId: 9,
   coursesTitle: "Mastering Power BI: Data Analytics and Visualization with Hands-Oncoursess",
    videoUrl: "67cc426093d38d2542d40703", 
    image: PowerBI,
    completion: 0
  },
  {
   coursesId: 10,
   coursesTitle: "Mastering Qlik Sense: Data Visualization and Analytics",
    videoUrl: "67cc43a50403f96395ff1d36", 
    image: QlikSense,
    completion: 0
  }
];
const MachineLearningAI = [
    {
      coursesId: 1,
      coursesTitle: "Data Science Foundation",
      videoUrl: "67cc3b2468e13f369550b03f",
      image: DataScienceFoundation,
      completion: 0
    },
    {
      coursesId: 2,
      coursesTitle: "Python for Data Science",
      videoUrl: "67cc3d71a4b1024b47180dc9",
      image: PythonforDataScience,
      completion: 0
    },
    {
      coursesId: 3,
      coursesTitle: "SQL for Data Science",
      videoUrl: "67cc3e102d97eb150f84e3ca",
      image: SQLforDataScience,
      completion: 0
    },
    {
      coursesId: 4,
      coursesTitle: "Statistics for Data Science",
      videoUrl: "67cc3e9e68e13f369550b1c6",
      image: StatisticsforDataScience,
      completion: 0
    },
    {
      coursesId: 5,
      coursesTitle: "Mathematics for Data Science",
      videoUrl: "67cc456ac4bdd4717d1b1622",
      image:  MathematicsDataScience,
      completion: 0
    },
    {
      coursesId: 6,
      coursesTitle: "Data Visualization",
      videoUrl: "67cc4163c08b970ba4cd7d59", 
      image: DataVisualization,
      completion: 0
    },
    {
      coursesId: 7,
      coursesTitle: "Machine Learning Essentials Foundations and Key Algorithms",
      videoUrl: "67cc46192d97eb150f84eb46",
      image: MachineLearningEssentials,
      completion: 0
    },
    {
      coursesId: 8,
      coursesTitle: "Advanced Machine Learning",
      videoUrl: "67ce6a18ccd9fa59a5a062e5", 
      image: AdvancedMachineLearning,
      completion: 0
    },
    {
      coursesId: 9,
      coursesTitle: "Machine Learning Model Deployment Using Flask",
      videoUrl: "67ce6abae771eb20e9c08d45", 
      image: MachineLearningModelDeployment,
      completion: 0
    },
    {
      coursesId: 10,
      coursesTitle: "Time Series Foundation",
      videoUrl: "67ce6b90e505b8439864e911", 
      image: TimeSeriesFoundation,
      completion: 0
    },
    {
        coursesId: 11,
        coursesTitle: "Deep Learning",
        videoUrl: "67ce6caf1833130b6d1e08cb", 
        image: DeepLearning,
        completion: 0
      },
      {
        coursesId: 12,
        coursesTitle: "Natural Language Processing",
        videoUrl: "67ce6d52d251915979bbfec4", 
        image: NaturalLanguageProcessing,
        completion: 0
      },
      {
        coursesId: 13,
        coursesTitle: "Reinforcement Learning",
        videoUrl: "67ce6ddadffe0f239fd47634", 
        image: ReinforcementLearning,
        completion: 0
      },
      {
        coursesId: 13,
        coursesTitle: "AI Fundamentals",
        videoUrl: "67ce6e87dffe0f239fd4768e", 
        image: AIFundamentals,
        completion: 0
      }
  ];
  const DataEngineering = [
    {
      coursesId: 1,
      coursesTitle: "Data Science Foundation",
      videoUrl: "67cc3b2468e13f369550b03f",
      image: DataScienceFoundation,
      completion: 0
    },
    {
      coursesId: 2,
      coursesTitle: "Python for Data Science",
      videoUrl: "67cc3d71a4b1024b47180dc9",
      image: PythonforDataScience,
      completion: 0
    },
    {
      coursesId: 3,
      coursesTitle: "SQL for Data Science",
      videoUrl: "67cc3e102d97eb150f84e3ca",
      image: SQLforDataScience,
      completion: 0
    },
    {
      coursesId: 4,
      coursesTitle: "Mastering Power BI: Data Analytics and Visualization with Hands-On Projects",
      videoUrl: "67cc426093d38d2542d40703",
      image: PowerBI,
      completion: 0
    },
    {
      coursesId: 5,
      coursesTitle: "Data Engineering Fundamentals",
      videoUrl: "67ce711be771eb20e9c09207",
      image:  DataEngineeringFundamentals,
      completion: 0
    },
    {
      coursesId: 6,
      coursesTitle: "Microsoft Azure Data Engineering",
      videoUrl: "67ce71817d789843d4bb9214", 
      image: AzureDataEngineering,
      completion: 0
    },
    {
      coursesId: 7,
      coursesTitle: "Mastering Apache Kafka: Hands-On Distributed Streaming and Data Pipelines",
      videoUrl: "67ce7226e505b8439864efa5",
      image: MasteringApacheKafka,
      completion: 0
    },
    {
      coursesId: 8,
      coursesTitle: "Mastering Databricks: End-to-End Data Engineering and Analytics with Apache Spark",
      videoUrl: "67ce72b97d789843d4bb94bd", 
      image: MasteringDatabricks,
      completion: 0
    },
    {
      coursesId: 9,
      coursesTitle: "Mastering Snowflake: Hands-On Cloud Data Warehousing and Analytics",
      videoUrl: "67ce7334dffe0f239fd47a34", 
      image: MasteringSnowflake,
      completion: 0
    },
  ];
  const GenerativeAI = [
    {
      coursesId: 1,
      coursesTitle: "Python for Data Science",
      videoUrl: "67cc3d71a4b1024b47180dc9",
      image: PythonforDataScience,
      completion: 0
    },
    {
      coursesId: 2,
      coursesTitle: "Deep Learning",
      videoUrl: "67ce6caf1833130b6d1e08cb",
      image: DeepLearning,
      completion: 0
    },
    {
      coursesId: 3,
      coursesTitle: "Natural Language Processing",
      videoUrl: "67ce6d52d251915979bbfec4",
      image: NaturalLanguageProcessing,
      completion: 0
    },
    {
      coursesId: 4,
      coursesTitle: "Generative AI Fundamentals",
      videoUrl: "67ce73eb30ebe304dfecf2b4",
      image: GenerativeAIFundamentals,
      completion: 0
    },
    {
      coursesId: 5,
      coursesTitle: "Complete Generative AI Course",
      videoUrl: "67ce7453e771eb20e9c09431",
      image:  GenerativeAICourse,
      completion: 0
    },
    {
      coursesId: 6,
      coursesTitle: "Generative Adversarial Networks",
      videoUrl: "67ce74b4d251915979bc0528", 
      image: GenerativeAdversarialNetworks,
      completion: 0
    },
    {
      coursesId: 7,
      coursesTitle: "Generative AI Applications and Case Studies",
      videoUrl: "67ce77d130ebe304dfecf601",
      image: CaseStudies,
      completion: 0
    },
    {
      coursesId: 8,
      coursesTitle: "Prompt Engineering",
      videoUrl: "67ce789bccd9fa59a5a070b8", 
      image: PromptEngineering,
      completion: 0
    },
    {
      coursesId: 9,
      coursesTitle: "Fine Tuning LLM’s",
      videoUrl: "67ce78d3ccd9fa59a5a070f8", 
      image: FineTuningLLMs,
      completion: 0
    },
  ];
  const CertifiedDataScientist = [
    {
      coursesId: 1,
      coursesTitle: "Data Science Foundation",
      videoUrl: "67cc3b2468e13f369550b03f",
      image: DataScienceFoundation,
      completion: 0
    },
    {
      coursesId: 2,
      coursesTitle: "Python for Data Science",
      videoUrl: "67cc3d71a4b1024b47180dc9",
      image: PythonforDataScience,
      completion: 0
    },
    {
      coursesId: 3,
      coursesTitle: "Statistics for Data Science",
      videoUrl: "67cc3e9e68e13f369550b1c6",
      image: StatisticsforDataScience,
      completion: 0
    },
    {
      coursesId: 4,
      coursesTitle: "SQL for Data Science",
      videoUrl: "67cc3e102d97eb150f84e3ca",
      image: SQLforDataScience,
      completion: 0
    },
    {
      coursesId: 5,
      coursesTitle: "Data Visualization",
      videoUrl: "67cc4163c08b970ba4cd7d59",
      image:  DataVisualization,
      completion: 0
    },
    {
      coursesId: 6,
      coursesTitle: "Machine Learning Essentials Foundations and Key Algorithms",
      videoUrl: "67cc46192d97eb150f84eb46", 
      image: MachineLearningEssentials,
      completion: 0
    },
    {
      coursesId: 7,
      coursesTitle: "Advanced Machine Learning",
      videoUrl: "67ce6a18ccd9fa59a5a062e5",
      image: AdvancedMachineLearning,
      completion: 0
    },
    {
      coursesId: 8,
      coursesTitle: "Machine Learning Model Deployment Using Flask",
      videoUrl: "67ce6abae771eb20e9c08d45", 
      image: MachineLearningModelDeployment,
      completion: 0
    },
    {
      coursesId: 9,
      coursesTitle: "Time Series Foundation",
      videoUrl: "67ce6b90e505b8439864e911", 
      image: TimeSeriesFoundation,
      completion: 0
    },
    {
        coursesId: 10,
        coursesTitle: "Deep Learning",
        videoUrl: "67ce6caf1833130b6d1e08cb", 
        image: DeepLearning,
        completion: 0
      },
      {
        coursesId: 11,
        coursesTitle: "Natural Language Processing",
        videoUrl: "67ce6d52d251915979bbfec4", 
        image: NaturalLanguageProcessing,
        completion: 0
      },
  ];
  const DataScienceArtificialIntelligence = [
    {
      coursesId: 1,
      coursesTitle: "Python for Data Science",
      videoUrl: "67cc3d71a4b1024b47180dc9",
      image: PythonforDataScience,
      completion: 0
    },
    {
      coursesId: 2,
      coursesTitle: "Probability & Statistics for Data Science",
      videoUrl: "67ce79f8c164b26b6f3a6216",
      image: ProbabilityStatistics,
      completion: 0
    },
    {
      coursesId: 3,
      coursesTitle: "Mathematics for Data Science",
      videoUrl: "67cc456ac4bdd4717d1b1622",
      image: MathematicsDataScience,
      completion: 0
    },
    {
      coursesId: 4,
      coursesTitle: "SQL for Data Science",
      videoUrl: "67cc3e102d97eb150f84e3ca",
      image: SQLforDataScience,
      completion: 0
    },
    {
      coursesId: 5,
      coursesTitle: "Machine Learning Essentials Foundations and Key Algorithms",
      videoUrl: "67cc46192d97eb150f84eb46",
      image:  MachineLearningEssentials,
      completion: 0
    },
    {
      coursesId: 6,
      coursesTitle: "Advanced Machine Learning",
      videoUrl: "67ce6a18ccd9fa59a5a062e5", 
      image: AdvancedMachineLearning,
      completion: 0
    },
    {
      coursesId: 7,
      coursesTitle: "Deep Learning",
      videoUrl: "67ce6caf1833130b6d1e08cb",
      image: DeepLearning,
      completion: 0
    },
    {
      coursesId: 8,
      coursesTitle: "Artificial Intelligence",
      videoUrl: "66e80efe3939d23d140bf34c", 
      image: ArtificialIntelligence,
      completion: 0
    },
    {
      coursesId: 9,
      coursesTitle: "Engineering Mathematics",
      videoUrl: "67ce7ae6b464d11201985261", 
      image: EngineeringMathematics,
      completion: 0
    },
  ];

export { BusinessIntelligence,DataEngineering, MachineLearningAI, GenerativeAI, CertifiedDataScientist, DataScienceArtificialIntelligence  };