import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MemoryIcon from "@mui/icons-material/Memory";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
//import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import CloseIcon from "@mui/icons-material/Close";

const SidebarComponent = () => {
  const [selected, setSelected] = useState("Learning Path");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // Sidebar visibility state
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to track current pathname

  // Detect screen size to toggle fixed or collapsible sidebar
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  useEffect(() => {
    if (isDesktop) setIsSidebarVisible(true); // Always show sidebar on desktop
    else setIsSidebarVisible(true); // Collapse sidebar on smaller screens
  }, [isDesktop]);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      localStorage.setItem("token", token);
      setSelected("Learning Path");
      navigate("/learners/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) navigate("/learners");
  }, [navigate]);

  // Use useMemo to memoize the menuItems array and avoid unnecessary re-renders
  const menuItems = useMemo(
    () => [
      { title: "Dashboard", to: "/learners/dashboard", icon: <DashboardIcon /> },
      { title: "Learning Path", to: "/learners/dashboard/internetofthings", icon: <SchoolOutlinedIcon /> },
      { title: "Courses", to: "/learners/dashboard/courses", icon: <LibraryBooksIcon /> },
      { title: "Projects", to: "/learners/dashboard/projects", icon: <AssignmentTurnedInIcon /> },
      { title: "Capstone AI Magic", to: "/learners/dashboard/capstoneaimagic", icon: <MemoryIcon /> },
      { title: "Mentor Connect", to: "/learners/dashboard/mentorconnect", icon: <CalendarTodayOutlinedIcon /> },
      { title: "Apply For Work", to: "/learners/dashboard/work", icon: <WorkOutlineOutlinedIcon /> },
      // { title: "LogOut", to: "/learners", icon: <ExitToAppOutlinedIcon /> },
    ],
    [] // Empty dependency array ensures this array is only created once
  );

  useEffect(() => {
    const currentMenu = menuItems.find(item => item.to === location.pathname);
    setSelected(currentMenu ? currentMenu.title : "");
  }, [location.pathname, menuItems]); // menuItems is now memoized, so no need for a dynamic dependency

  const Item = ({ title, to, icon, selected, setSelected }) => {
    const isActive = selected === title;

    const handleClick = () => {
      setSelected(title);  // Set the selected item to the current title
      if (title === "LogOut") {
        localStorage.clear();
        navigate("/learners");
      } else {
        navigate(to);
      }
      if (!isDesktop) setIsSidebarVisible(false); // Close sidebar on smaller screens

      // Reset the selected state if it's not the currently selected item
      if (selected !== title) {
        setSelected("");  // Clear the selected item when navigating away
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "12px 20px",
          cursor: "pointer",
          color: isActive ? "white" : "black",
          backgroundColor: isActive ? "#052659" : "transparent",
          "&:hover": {
            backgroundColor: "#052659",
            color: "white"
          },
          borderRadius: "8px",
          transition: "background-color 0.3s, color 0.3s",
        }}
        onClick={handleClick}
      >
        {React.cloneElement(icon, { sx: { marginRight: "16px", color: isActive ? "white" : "#000", "&:hover": {color: "white" }, } })}
        <Typography sx={{ fontWeight: isActive ? "bold" : "normal" }}>
          {title}
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      <Drawer
        anchor="left"
        open={isSidebarVisible}
        variant={isDesktop ? "persistent" : "temporary"} // Fixed for desktop, collapsible for smaller screens
        onClose={() => setIsSidebarVisible(false)} // Only applicable for mobile
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            paddingTop: "10px",
            backgroundColor:"#ffff",
            // backgroundColor: "#f5f5f5",
            borderRight: isDesktop ? "none" : "1px solid #ddd", // No border for desktop
            boxShadow: isDesktop ? "none" : "4px 0px 10px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            padding: "0px 0px 10px 24px",
            display: "flex",
            justifyContent: "left", // Center horizontally
            alignItems: "left",
          }}
        >
        <Typography
          variant="h1"
          sx={{
            fontSize: "36px",
            fontWeight: "700",
            color: "rgb(2, 41, 100)",
            textDecoration: "none",
          }}
          component={Link}
          to="/learners"
        >
          Hue<span style={{ color: "rgb(14, 104, 249)" }}>bits</span>
        </Typography>          {!isDesktop && (
            <IconButton
              onClick={() => setIsSidebarVisible(false)}
              sx={{
                position: "absolute", // Keeps the close button aligned
                top: "0px",
                right: "10px",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          {menuItems.map((item, index) => (
            <Item
              key={index}
              title={item.title}
              to={item.to}
              icon={item.icon}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </Box>
      </Drawer>

      {!isSidebarVisible && !isDesktop && (
        <IconButton
          onClick={() => setIsSidebarVisible(true)}
          sx={{
            position: "fixed",
            top: "0px",
            left: "0px",
            color:"#ffff",
            zIndex: 1201, // Ensures the button is on top of the content
          }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
};

export default SidebarComponent;