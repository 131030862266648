import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
//import Projects from "./projects/Iot-developer";
import Courses from "../Courses/Data-Science-Artificial-Intelligence-courses";

const Start = () => {
  // State to track which content is visible, defaulting to 'courses'
  const [showContent, setShowContent] = useState("courses");

  const handleButtonClick = (contentType) => {
    setShowContent(contentType); // Update the state based on the button clicked
  };

  return (
    <Box>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          margin: "10px 30px",
          fontSize: { xs: "20px", sm: "20px", md: "24px", lg: "28px" } // Adjust font size for different screens
        }}
      >
        GATE DA (Data Science and Artificial Intelligence)
      </Typography>

      {/* Buttons for toggling between Projects and Courses */}
      <Box sx={{ margin:"20px 30px" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleButtonClick("courses")}
          sx={{
            backgroundColor: showContent === "courses" ? "rgb(14, 104, 249)" : "white",
            color: showContent === "courses" ? "white" : "black",
            border: "1px solid rgb(14, 104, 249)",  // Corrected typo here
            '&:hover': {
              backgroundColor: showContent === "courses" ? "rgb(14, 104, 249)" : "lightgray",
            },
          }}
        >
          Courses
        </Button>
        <Button
          variant="contained"
          color="primary"
          // onClick={() => handleButtonClick("projects")}
          sx={{
            backgroundColor: showContent === "projects" ? "rgb(14, 104, 249)" : "white",
            color: showContent === "projects" ? "white" : "black",
            marginLeft: "10px",
            border: "1px solid rgb(14, 104, 249)",  // Corrected typo here
            '&:hover': {
              backgroundColor: showContent === "projects" ? "rgb(14, 104, 249)" : "lightgray",
            },
          }}
        >
          Projects
      </Button>
      </Box>
      {showContent === "courses" && <Courses />}
    </Box>
  );
};

export default Start;
