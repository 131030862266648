import React from 'react';
import { Box, Typography, List, ListItem, Link } from '@mui/material';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
//import Footerhome from "../../assets/hue1 w.png";

const Footer = () => {
  return (
    <Box component="footer" sx={{ color: 'white', py: 2, mt: 2,  padding:"10px 60px"}}>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap="40px">

          <Box>
            <Typography variant="h6" gutterBottom>
                Quick Links
            </Typography>
            <List>
               <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                  <Link href="/organizations" color="inherit" underline="none">Organizations</Link>
                </ListItem>
                <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                  <Link href="/organizations/internet-of-things" color="inherit" underline="none">Internet of Things</Link>
                </ListItem>
                <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                  <Link href="/organizations/data-science" color="inherit" underline="none">Data Science- AI/ML/DL</Link>
                </ListItem>
                <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                  <Link href="/organizations/embedded-systems" color="inherit" underline="none">Embedded Systems</Link>
                </ListItem>  
            </List>
          </Box>
         <Box>
          <Typography variant="h6" gutterBottom>
            For About
          </Typography>
          <List>
          <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
              <Link href="/about-us" color="inherit" underline="none">About Us</Link>
            </ListItem>
            <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
              <Link href="/careers" color="inherit" underline="none">Careers</Link>
            </ListItem>
            {/* <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
              <Link href="/partnerships" color="inherit" underline="none">Partnerships</Link>
            </ListItem> */}
          </List>
        </Box>
          <Box>
            <Typography variant="h6" gutterBottom>
              Legal & Accessibility
            </Typography>
            <List>
              <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                <Link href="/terms-and-conditions" color="inherit" underline="none">Terms and Conditions</Link>
              </ListItem>
              <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                <Link href="/privacy-policy" color="inherit" underline="none">Privacy Policy</Link>
              </ListItem>
            </List>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <List>
              <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                HUEBITS TECH PRIVATE LIMITED  
              </ListItem>
              <ListItem sx={{ pl: 0, fontSize: "14px", color:"#FFF" }}>
                <FaEnvelope style={{ marginRight: 8 }} /> hello@huebits.in
              </ListItem>
              <ListItem sx={{ pl: 0, fontSize: "14px", color: "#FFF" }}>
                <FaPhoneAlt style={{ marginRight: 8 }} /> +91 8919229753
              </ListItem>
            </List>
          </Box>
           {/* <Box>
            <Link href="/">
              <img className="footer-hompage-image" src={Footerhome} alt="Homepage" style={{ marginTop: 16 }} />
            </Link>
          </Box> */}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" margin="20px 0px -10px 0px">
          <Box>
          <Typography variant="body1" sx={{fontSize: "14px", textAlign:"center"}}>
          <FaMapMarkerAlt style={{ margin:"0px 8px 0px 10px" }} />  
          Regd. Office: D.No.43-7-30 Railway New Colony, Visakhapatnam, Andhra Pradesh - 530016
          </Typography>
          <Typography variant="body1" sx={{fontSize: "14px", margin:"10px 0px 0px 0px", textAlign:"center"}}>
          Branch Office: 
            #302, D.No: 47-9-7, Jetti Vigneswara Classic, Dwaraka Nagar, 3rd Line, Visakhapatnam, Andhra Pradesh 530016, India
          </Typography>
         </Box>
        </Box>
    </Box>
  );
};

export default Footer;