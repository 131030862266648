import React, { useState } from 'react';
import Apply from '../Apply-Now';
import Skillsandinterests from '../Skills-and-interests';
import AreasofInterest from '../Areas-of-Interest';
import Technical from '../Technical';
import ApplyForwork from "../Apply-For-Work";

const Applyforworks = () => {
    const [activeLink, setActiveLink] = useState('skills');

    return (
        <div>
            <div className='students-dashboard-workses'>
                <div className='students-dashboard-works'>
                    <div className='myprofilelinks'>
                        {activeLink === 'apply' && <Apply setActiveLink={setActiveLink} />}
                        {activeLink === 'skills' && <Skillsandinterests setActiveLink={setActiveLink} />}
                        {activeLink === 'areas' && <AreasofInterest setActiveLink={setActiveLink} />}
                        {activeLink === 'applywork' && <ApplyForwork setActiveLink={setActiveLink} />}
                        {activeLink === 'technical' && <Technical setActiveLink={setActiveLink} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Applyforworks;
