import React, {useRef} from 'react';
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import Footer from "../Footer/Footer";
import Headerpage from "../Headerpages/HeaderPage";
import Aboutusimages from './aboutusimages';
import Contacts from "./contacts";
import BapujiKanaparthi from "../../assets/AboutUs/images/BapujiKanaparthi.png";
//import Bapuji from "../../assets/AboutUs/images/BapujiKanaparthi.jpg";
import Samuel from "../../assets/AboutUs/images/Samuel.png";
import HemanthKumar from "../../assets/AboutUs/images/HemanthKumar.png";
import SikanderKhan from "../../assets/AboutUs/images/SikanderKhan.png";
import "./Abouts.css";

const AboutUs = () => {
   const footerSectionRef = useRef(null);
    
    const scrollToFooterSection = () => {
      footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <div className="students-flex companies-flex">
      <Headerpage />
      <div className="homepagecolores">
        <div ref={footerSectionRef} className='about-banner'>
        {/* Hero Section */}
      <div className="about-banner1">
        <h1 className="about-banner1-h1">About Us</h1>
        <p className="about-banner1-p">Smart Synergies - Smarter Futures with AI-Driven Learning & Innovation</p>
      </div>
      <div className="about-banner2">
        <h1 className="about-banner2-h1">HISTORY</h1>
        <p className="about-banner2-p">
        Founded in 2024, <span>HueBits Tech Private Limited</span> is dedicated to upskilling future tech leaders and driving business growth through IoT, AI & ML, Data Science, and embedded technologies.
        Headquartered in Visakhapatnam, India, we specialize in AI-powered training and industry-focused solutions, helping businesses adapt to evolving technology.
        <p className='about-banner2-p1'>
        With a strong emphasis on innovation and customer-centric solutions, we bridge the gap between emerging technologies and real-world applications. 
        Through strategic partnerships and continuous innovation, HueBits empowers organizations with intelligent, data-driven solutions to enhance efficiency, competitiveness, and future readiness.</p>
        </p>
      </div>
      <div className='about-banner3'>
        <h1 className='about-banner3-h1'>VISION & MISSION STATEMENT</h1>
      <div className="about-banner3-vision">
        <h2 className='about-banner3-vision-h2'>Vision</h2>
        <p className='about-banner3-vision-p'>
        To be the leading ecosystem that seamlessly integrates learning, talent development, 
        and industry problem-solving, empowering the next generation of tech professionals and 
        driving innovation in emerging technologies.
        </p>
      </div>
    <div className="about-banner3-Mission">
        <h2 className='about-banner3-Mission-h2'>Mission</h2>
        <p className='about-banner3-Mission-p'>
        To harness smart synergies between academia and industry by providing an AI-driven learning platform 
        in emerging technologies, ensuring guaranteed internships, and creating a skilled tech talent pool. 
        We strive to equip learners with industry experience while delivering innovative solutions
        to address emerging technology challenges.
        </p>
      </div>
      </div>
      <div className="about-banner4">
        <div className='about-banner4-message'>
        {/* <h1 className="about-banner4-h1">CEO MESSAGE</h1> */}
        <h1 className="about-banner4-h1">Message from our CEO</h1>
        <img src={BapujiKanaparthi} alt='BapujiKanaparthi' className='about-banner4-images'/>
        <p className="about-banner4-p">At HueBits, we are on a mission to revolutionize <strong>AI-powered learning and technology solutions</strong> that empower individuals and industries alike. 
          Our vision is to bridge the gap between innovation and real-world applications, enabling businesses and learners to thrive in an ever-evolving digital landscape.
          We believe in <strong>collaboration, creativity, and continuous growth</strong> both for our employees and our customers. As we scale new heights, our focus remains on delivering excellence, 
          fostering innovation, and making a meaningful difference.
          <p>Thank you for being part of our journey. Together, let’s build a smarter, more connected future!</p>
        </p>
      </div>
      <div className='about-banner4-image'>
      <img src={BapujiKanaparthi} alt='BapujiKanaparthi' className='about-banner4-img'/>
      </div>
      </div>
      <div className='about-banner7'>
        <h1 className='about-banner7-h1'>Executive Team</h1>
      <div className='about-banner7-cards'>
      <div className='about-banner7-cards1'>
        <div className='about-banner7-card'>
          <div className='about-banner7-card-image'>
          <img src={BapujiKanaparthi} alt="" />
          </div>
          <ul className='about-banner7-card-p'>
          <li><strong>Bapuji Kanaparthi</strong></li>
          <li>Founder & CEO</li>
          <li>Experienced CTO, Product Developer, and Trainer</li>
          <li>MTech from IIT Bombay and 10 years
           dedicated to IoT and Machine Learning Research</li>
          <li>20K+ Happy Learners and Clients</li>
          </ul>
        </div>
        <div className='about-banner7-card'>
        <div className='about-banner7-card-image'>
          <img src={Samuel} alt="" />
        </div>
          <ul className='about-banner7-card-p'>
          <li><strong>Dr. N.B. Samuel</strong></li> 
          <li>Chief Operating Officer</li> 
          <li>Experienced Academician</li>
          <li>Expert in clean state networking architectures</li> 
          <li>Information Security consultant</li> 
          <li>Ph.D from NIT Manipur</li>
          </ul>
        </div>
      </div>

      <div className='about-banner7-cards2'>
        <div className='about-banner7-card'>
        <div className='about-banner7-card-image'>
          <img src={HemanthKumar} alt="" />
        </div>
          <ul className='about-banner7-card-p'>
          <li><strong>Hemanth Kumar M</strong></li>
          <li>Head - Collaborations & Strategic Projects</li>
          <li>20+ years of expertise 
            in leading global application development and solution delivery.</li>
          <li>Proven track record in driving productivity, quality, and cost-effective results.</li>
          </ul>
        </div>
        <div className='about-banner7-card'>
        <div className='about-banner7-card-image'>
          <img src={SikanderKhan} alt="" />
        </div>
          <ul className='about-banner7-card-p'>
          <li><strong>Sikander Khan</strong></li> 
          <li>Solution Architect</li> 
          <li>Strategic Technologist and Experienced Product Manager</li>
          <li>Strong Background in Distributed Systems(Big data, caching mechanism, multi-node strategy) and Cloud</li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="about-banner5">
        <h1 className="about-banner5-h1">LIFE AT HUEBITS</h1>
        <div>
          <Aboutusimages />
        </div>
      </div>
      <div className='about-banner6'>
        <Contacts />
      </div>
      </div>
      <div className="homepage-footers-images">
      <Footer />
      <footer className="homepage-footers">
      <hr className='homepage-footer-hr'/>
        <div className="footer-social">
        <div className="homepage-footer-copyright">
          <p className="homepage-footer-copyrightp">© Huebits[2025].All Rights Reserved.</p>
        </div>
        <div className='footer-social-icons'>
          <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
          <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
          <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
          <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
          <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
          <Link onClick={scrollToFooterSection}>
          <FaArrowUp className="homepage-social-icon-link" />
          </Link>
        </div>
        </div>
      </footer>
      </div>
      </div>
    </div>
  )
}

export default AboutUs;
