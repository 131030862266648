import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./projectsprogress.css";
import ChangingProgressProvider from "./projectsprogeesprojects.jsx";

const getColorForProgress = (totalProjectsProgress) => {
  if (totalProjectsProgress >= 0 && totalProjectsProgress <= 20) {
    return "#fb7403";
  } else if (totalProjectsProgress >= 20 && totalProjectsProgress <= 40) {
    return "#fbb003";
  } else if (totalProjectsProgress >= 40 && totalProjectsProgress <= 70) {
    return "#bffb03";
  } else if (totalProjectsProgress >= 70 && totalProjectsProgress <= 100) {
    return "#008000";
  } else {
    return "#ffff";
  }
};

const VerticalProgressBar = ({ totalProjectsProgress }) => {
 // Ensure learningPathProgress is a valid number, default to 0 if not
 const progress = Number(totalProjectsProgress) || 0;

  return (
    <div className="learningpathprogresesbar-backend">
      <div style={{ padding: "10px" }} className="learningpathprogressbar-backend">
        <div className="default1">
          <h1 className="learningpathprogressbar">Projects Progress</h1>
          <div>
            <div className="total-courses-progress">
              <div className="learningpath-progress-project-bar">
            <ChangingProgressProvider
              values={[progress]} // Pass the progress value directly to the provider
            >
              {(value) => (
                <CircularProgressbar
                  value={value}
                  text={`${Math.round(value)}/100`}
                  circleRatio={0.5}
                  strokeWidth={12}
                  className="projectsprogress-color"
                  styles={buildStyles({
                    rotation: 1 / 2 + 2 / 8,
                    strokeLinecap: "round",
                    textColor: "#052659",
                    pathColor: getColorForProgress(value),
                    trailColor: "white",
                    pathTransitionDuration: 0.5,
                    textSize: "12px",
                  })}
                />
              )}
            </ChangingProgressProvider>
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalProgressBar;