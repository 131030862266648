import React from "react";
import { Button, Box, Typography, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import { GenerativeAI } from "./coursesdata"; // Import the courses data


const Start = () => {
    const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile screen
    const isTablet = useMediaQuery("(max-width:960px)"); // Detect tablet screen

  return (
    <Box sx={{padding:"10px 10px 10px 20px"}}>
    {/* <Typography variant="body1" gutterBottom sx={{paddingLeft:"10px"}}>
     coursess
    </Typography> */}

    {/* Flexbox container for the 4-column layout */}
     <Box 
        sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 3, 
          margin: "10px",
          justifyContent: isMobile ? "center" : isTablet ? "space-around" : "flex-start", // Center on mobile, space out on tablets
        }}
      >
      {GenerativeAI.map((courses, index) => {

        return (
          <Box key={`${courses.coursesId}-${index}`}
              sx={{ 
                flexBasis: isMobile ? '100%' : isTablet ? '45%' : '228px',  // Full width on mobile, two-column on tablets, fixed size on larger screens
                minWidth: '200px' 
              }}
            >
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {/* Card Media (Image) */}
              {/* Image Wrapper with Hover Effect */}
                <Box 
                sx={{ 
                  position: 'relative', 
                  '&:hover .description-overlay': { opacity: 1 } // Show description on hover
                }}
              >
                {/* Card Media (Image) */}
                <CardMedia
                  component="img"
                  sx={{ height: 160, objectFit: 'cover' }}
                  image={courses.image}
                  alt={courses.coursesTitle}
                />
                
                {/* Description Overlay */}
                <Box 
                  className="description-overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '10px',
                    opacity: 0, // Initially hidden
                    transition: 'opacity 0.3s ease-in-out', // Smooth transition
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                    {courses.coursesTitle}
                  </Typography>
                </Box>
              </Box>
              {/* Card Content (Text, Progress, Button) */}
              <CardContent sx={{ flexGrow: 1 }}>
              <Typography 
                variant="body1" 
                component="p" 
                sx={{ 
                  paddingBottom: 1, 
                  whiteSpace: 'nowrap',  // Ensures the title doesn't wrap
                  overflow: 'hidden',    // Hides overflow text
                  textOverflow: 'ellipsis', // Adds "..." when text overflows
                }}
              >
                {index + 1}. {courses.coursesTitle}
              </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#9b9b9b",
                        color: "#ffff",
                      }}
                    >
                      Coming Soon
                    </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </Box>
  </Box>
);
};

export default Start;