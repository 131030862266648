import React from "react";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Internet from "../../../assets/homepage/courses/industryReadyIoTProgram.png";
import Machine from "../../../assets/homepage/courses/aiMachineLearning.png";
//import Generative from "../../assets/homepage/banner3/5.png";
import DataScience from "../../../assets/homepage/courses/industryReadyDataScienceProgram.png";
import EmbeddedSystems from "../../../assets/homepage/courses/industryReadyEmbeddedSystemsProgram.png";

const programs = [
  { image: Internet, title: "Industry Ready IoT", description:"Program with guaranteed internship", path: "/learners/internet-of-things" },
  { image: Machine, title: "Industry Ready AI and Machine Learning", description:"Program with guaranteed internship", path: "/learners/data-science" },
    // { img: Generative, title: "Generative AI", path: "/datascience" },
  { image: DataScience, title: "Industry Ready Data Science", description:"Program with guaranteed internship", path: "/learners/data-science" },
  { image: EmbeddedSystems, title: "Industry Ready Embedded Systems", description:"Program with guaranteed internship", path: "/learners/embedded-systems" }
];

const coreFeatures = [
  "Access to all Courses and Projects",
  "1-to-1 Doubt Sessions: 2 hours per month",
  "Guaranteed internships with ₹40,000 stipend",
];

const IndustryReady = () => {

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap",alignItems: 'center',justifyContent: 'center' }}>
      {programs.map((program, index) => (
        <ProgramCard key={index} program={program}/>
      ))}
    </Box>
  );
};

const ProgramCard = ({ program }) => {
  const isEmbedded = program.title === "Industry Ready Embedded Systems";
  return (
    <Card
    sx={{
      maxWidth: 500,
      borderRadius: 2,
      padding: "6px",
      flex: "1 1 245px",
      transition: "0.3s",
      border: "0.5px solid #a1a1a1",
      margin: "10px",
      "&:hover": { transform: "scale(1.05)", boxShadow: 10 },
    }}
  >
    <CardMedia component="img" height="200" image={program.image} alt={program.title} sx={{ borderRadius: 2, mt: 0 }} />
    <CardContent>
      <Typography variant="h5" fontWeight="bold" sx={{ fontSize: '13.8px' }}>{program.title}</Typography>
      <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold", color: "primary.main", fontSize: "13.4px" }}>
        {program.description}
      </Typography>

      <Box sx={{ mt: 2 }}>
        {coreFeatures.map((feature, i) => (
          <Typography key={i} variant="body2" sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1, color: "text.secondary" }}>
            <FontAwesomeIcon icon={faCheck} color="green" />
            {feature.includes("₹40,000") && isEmbedded ? 
              "Guaranteed internships with ₹30,000 stipend" : feature}
          </Typography>
        ))}
      </Box>
      
      <Box display="flex" alignItems="center" mt={2}>
        <Link 
          to={program.path} 
          style={{ 
            textDecoration: "none", 
            display: "flex", 
            alignItems: "center", 
            color: "#1976d2", 
            fontWeight: "bold",
            fontSize: "16px",
            border: "1px solid #1976d2",  
            borderRadius: "4px",        
            padding: "6px 12px",        
            transition: "0.3s ease",    
          }}
        >
          Learn More
          <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: "6px" }} />
        </Link>
      </Box>
    </CardContent>
  </Card>
  );
};

export default IndustryReady;
