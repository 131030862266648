import React from "react";
import PdfDownloadForm from "./PdfDownloadForm";

export const PdfIotdownload = () => (
  <PdfDownloadForm brochureName="Industry-Ready-Program-IoT.pdf" programName="Industry Ready IoT Program" />
);

export const PdfDatadownload = () => (
  <PdfDownloadForm brochureName="Industry-Ready-Program-Data-Science.pdf" programName="Industry Ready AI & Data Science Program" />
);

export const PdfEmbeddeddownload = () => (
  <PdfDownloadForm brochureName="Industry-Ready-Embedded-Systems-Training-Program.pdf" programName="Industry Ready Embedded Systems Program" />
);
