import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, Card, CardContent, LinearProgress, Grid } from "@mui/material";
import SingleLogin from "./singlesign";
import { LearnersCourses } from "../../../helper/base_url";
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const CompletedCourses = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const { isOnline } = useNetworkStatus();

  const fetchCourses = async () => {
    setError(null);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found");
        return;
      }

      const response = await axios.get(
        `${LearnersCourses}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCourses(Array.isArray(response.data.items) ? response.data.items : []);
    } catch (err) {
      setError("Courses are not available.");
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <div>
    {isOnline ? (
    <Box sx={{ width: "100%", mx: "auto", mt: 1, p: 2 }}>
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        sx={{ color: "#052659", fontWeight: 700 }}
      >
        Completed Courses
      </Typography>
      {error ? (
        <Box sx={{ textAlign: "center", mt: 1 }}>
          {/* <Alert severity="error">{error}</Alert> */}
        </Box>
      ) : !courses || courses.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Courses are not available.
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {courses
            .filter((item) => item.productProgress > 99 )
            .map((item, index) => (
              <Grid item xs={12} sm={6} key={item.id}>
                <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2 }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: 700, fontSize: "18px" }}
                    >
                      {index + 1}. {item.productTitle}
                    </Typography>
                    <Box display="flex" gap={2} alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        Progress: {Math.min(item.productProgress || 0, 100).toFixed(0)}%
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={Math.min(item.productProgress || 0, 100).toFixed(0)}
                          sx={{
                            height: 8,
                            borderRadius: 4,
                            "& .MuiLinearProgress-bar": { backgroundColor: "#052659" },
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1, display: "flex" }}>
                      <SingleLogin videoUrl={item.productId} />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
    ) : (
    <div>
        <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
    </div>
  )}
  </div>
  );
};

export default CompletedCourses;