import React, { useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Button, TextField, Box, Dialog, DialogActions, DialogContent, DialogTitle, 
  Typography, IconButton, InputAdornment
} from "@mui/material";
import { Add, Edit, Delete, Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";

const Mentors = () => {
  const [mentors, setMentors] = useState([]);
  const [mentorData, setMentorData] = useState({ name: "", email: "", phone: "", password: "" });
  const [editMentor, setEditMentor] = useState(null);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);

  const handleChange = (e) => {
    setMentorData({ ...mentorData, [e.target.name]: e.target.value });
  };

  const handleAddMentor = () => {
    if (!mentorData.name || !mentorData.email || !mentorData.password) {
      toast.error("Name, Email, and Password are required.");
      return;
    }

    setMentors([...mentors, { ...mentorData, id: Date.now() }]);
    setMentorData({ name: "", email: "", phone: "", password: "" });
    setOpen(false);

    toast.success("Mentor added successfully!", { autoClose: 10000 });
  };

  const handleEdit = (mentor) => {
    setEditMentor(mentor);
    setEditOpen(true);
  };

  const handleSaveEdit = () => {
    setMentors(mentors.map((m) => (m.id === editMentor.id ? editMentor : m)));
    setEditOpen(false);
    toast.success("Mentor updated successfully.", { autoClose: 10000 });
  };

  const handleDelete = (mentorId) => {
    setMentors(mentors.filter((mentor) => mentor.id !== mentorId));
    toast.success("Mentor deleted successfully.", { autoClose: 10000 });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">Mentors Management</Typography>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setOpen(true)}>
          Add Mentor
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3 }}>
        <Table>
          <TableHead sx={{ bgcolor: "primary.main" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Name</TableCell>
              <TableCell sx={{ color: "white" }}>Email</TableCell>
              <TableCell sx={{ color: "white" }}>Phone</TableCell>
              <TableCell sx={{ color: "white" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mentors.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">No mentors available.</TableCell>
              </TableRow>
            ) : (
              mentors.map((mentor) => (
                <TableRow key={mentor.id}>
                  <TableCell>{mentor.name}</TableCell>
                  <TableCell>{mentor.email}</TableCell>
                  <TableCell>{mentor.phone || "N/A"}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleEdit(mentor)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(mentor.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Mentor Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Mentor</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="Name" name="name" value={mentorData.name} onChange={handleChange} margin="dense" />
          <TextField fullWidth label="Email" name="email" type="email" value={mentorData.email} onChange={handleChange} margin="dense" />
          <TextField fullWidth label="Phone (Optional)" name="phone" type="tel" value={mentorData.phone} onChange={handleChange} margin="dense" />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={mentorData.password}
            onChange={handleChange}
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
          <Button onClick={handleAddMentor} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Mentor Dialog */}
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Edit Mentor</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="Name" name="name" value={editMentor?.name || ""} onChange={(e) => setEditMentor({ ...editMentor, name: e.target.value })} margin="dense" />
          <TextField fullWidth label="Email" name="email" type="email" value={editMentor?.email || ""} onChange={(e) => setEditMentor({ ...editMentor, email: e.target.value })} margin="dense" />
          <TextField fullWidth label="Phone (Optional)" name="phone" type="tel" value={editMentor?.phone || ""} onChange={(e) => setEditMentor({ ...editMentor, phone: e.target.value })} margin="dense" />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type={showEditPassword ? "text" : "password"}
            value={editMentor?.password || ""}
            onChange={(e) => setEditMentor({ ...editMentor, password: e.target.value })}
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowEditPassword(!showEditPassword)} edge="end">
                    {showEditPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)} color="secondary">Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Mentors;
