import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Loginimage from "../../../assets/learnersSignin.png";
//import Google from "../../../assets/google.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Typography, TextField, Grid, Button, IconButton, CircularProgress, useMediaQuery } from '@mui/material';
import { FaEye, FaEyeSlash, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import Footer from "../../../mainpage/Footer/learnersFooter";
import { LearnersLogin } from '../../../helper/base_url';
// import { LearnersLogin, Learnersgooglelogin } from '../../../helper/base_url';
import ReCAPTCHA from "react-google-recaptcha";  // <-- Import this

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ email: "", password: "", role: "student" });
  const [captchaToken, setCaptchaToken] = useState(""); // Store reCAPTCHA token
  const recaptchaRef = useRef(null); // Create a reference for reCAPTCHA
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if user is already logged in
  useEffect(() => {
      if (localStorage.getItem('token')) {
          navigate("/learners/dashboardpage");
      }
  }, [navigate]);

  useEffect(() => {
    const error = new URLSearchParams(location.search).get('error');
    if (error) {
      toast.error(
        error === 'registration_required' 
          ? 'User not found. Please register first.' 
          : 'An unexpected error occurred.'
      );
    }
  }, [location.search]);

  //const loginwithgoogle = () => window.location.href = `${ Learnersgooglelogin }`;

  const handleOnChange = (e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    // reCAPTCHA callback
    const handleCaptchaChange = (token) => setCaptchaToken(token);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      toast.error("Please complete the reCAPTCHA.");
      return;
    }
    setLoading(true);
    const toastId = toast.loading("Processing login...");
    try {
      const response = await axios.post(`${LearnersLogin}`, { ...data, captchaToken }, {
        headers: { "Content-Type": "application/json" },
        //withCredentials: true, // Ensure the browser sends cookies
      });

      const { message, token, error, OrderDetails } = response.data;
      toast.update(toastId, { render: message, type: error ? "error" : "success", isLoading: false, autoClose: 1000 });
      if (!error) {
        localStorage.setItem('token', token);
        localStorage.setItem('OrderDetails', OrderDetails); // Store OrderDetails in localStorage
        setTimeout(() => navigate("/learners/dashboardpage"), 500);
      } 
      else {
        recaptchaRef.current?.reset(); // <-- Reset reCAPTCHA if login fails
        setCaptchaToken(""); // Clear stored token
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Network error, please try again.';
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 1000 });
      // Reset reCAPTCHA on failure
      recaptchaRef.current?.reset(); // <-- Reset reCAPTCHA if login fails
      setCaptchaToken(""); // Clear stored token
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <div style={{ width: "100%", overflowX: "hidden", overflowY: "auto", height: "91vh" }}>
        <ToastContainer position="top-center" />
      <Grid container justifyContent="center" alignItems="center" style={{ padding: "40px 20px" }}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
      {/* <Paper elevation={3} style={{ padding: 20, borderRadius: 10 }}> */}
        <Typography variant="h5" align="center" gutterBottom sx={{color:"#043885"}}>
        Log in to your Huebits account
        </Typography> 
         <form onSubmit={submitHandler}>
          <TextField label="Email" type="email" name="email" fullWidth margin="normal" value={data.email} onChange={handleOnChange} required disabled={loading} />
          <Box position="relative">
          <TextField label="Password" type={showPassword ? "text" : "password"} name="password" fullWidth margin="normal" value={data.password} onChange={handleOnChange} required disabled={loading} />
          <IconButton onClick={() => setShowPassword(prev => !prev)} style={{ position: "absolute", right: 10, top: "35%" }}>
              {showPassword ? <FaEye /> : <FaEyeSlash />}
          </IconButton>
          </Box>
          <TextField label="Confirm Password" type="password" name="confirmPassword" fullWidth margin="normal" value={data.confirmPassword} onChange={handleOnChange} required disabled={loading} />
          {/* Google reCAPTCHA */}
          <div style={{margin: "20px 0px"}}>
            <ReCAPTCHA
              sitekey="6Le83KcqAAAAAInqCfWquiIGanUjpu2RxTMgKwuJ"  
              onChange={handleCaptchaChange}
              ref={recaptchaRef}
            />
          </div>
          <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: 10 }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </form>
          <Typography sx={{marginTop:"10px"}}><Link to="/learners/forgot-password">Forgot Password</Link></Typography>
          {/* <div className="loginhr">
            <hr className="loginhrline" />
            <span className="login-hr-or">Or Continue With</span>
            <hr className="loginhrline1" />
          </div> */}
          {/* <div className="linkedin-google">
            <div className="signingoogle-google-login">
              <Link className="google-link" role="button" onClick={loginwithgoogle}>
                <img className="googleicon" src={Google} alt="Google" />
              </Link>
            </div>
          </div> */}
          <Typography align="center" style={{ marginTop: 15 }}>
            Don't have an account?{" "}<Link to="/learners/signup">Create an account</Link>
          </Typography>
            </Grid>
            {!isMobile && !isTablet && (
            <Grid item xs={12} sm={8} md={6} lg={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={Loginimage} alt="Signup Illustration" style={{ width: "100%", maxWidth: "auto", height: "550px", marginLeft: "40px", borderRadius:"10px" }} />
            </Grid>
            )}
          </Grid>
        <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
            <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin className="social-icon"/></Link>
                <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Login;