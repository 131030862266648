import React from "react";
import './capstoneaimagic.css';
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const Capstoneaimagic = () => {
    const { isOnline } = useNetworkStatus();
    
    return (
        <div>
         {isOnline ? (
        <div className="mylearningpaths">
        <div className="students-comingsoon">
          <h1 className="capstone">Capstone AI Magic Gateway to your Capstone</h1>
          <button className="students-comingsoon-capstonestart">
            Coming Soon
            </button>
        </div>
        </div>
        ) : (
        <div>
            <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
        </div>
       )}
     </div>
    );
};

export default Capstoneaimagic;