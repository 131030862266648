import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import avatar from "../../../assets/profile.png";
import convertToBase64 from "../../../helper/Convert";
import { Country, State, City } from "country-state-city";
import { Button, TextField, MenuItem, Card, Typography, Grid, Box, CircularProgress, Autocomplete } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { LearnersProfile } from "../../../helper/base_url";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [data, setData] = useState({
    email: "", mobile: "", city: "", state: "", country: "", profilePic: "",
    fullname: "", dateofbirth: "", gender: "", age: ""
  });
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (data.country) setStates(State.getStatesOfCountry(data.country));
  }, [data.country]);

  useEffect(() => {
    if (data.state) setCities(City.getCitiesOfState(data.country, data.state));
  }, [data.state, data.country]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onUpload = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setData((prevData) => ({ ...prevData, profilePic: base64 }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`${LearnersProfile}${userId}`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${token}`
        },
      });
      if (response.data.success) {
        setMessageType("success");
        setMessage(response.data.message);
        setData(response.data.data);
      } else {
        setMessageType("error");
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessageType("error");
      setMessage("Network error: try again.");
    } finally {
      setLoading(false);
    }
  }, [userId, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!/^\d{10}$/.test(data.mobile)) {
      setMessage("Please enter a 10-digit mobile number");
      setMessageType("error");
      setTimeout(() => setMessage(""), 1000);
      setLoading(false);
      return;
    }
    await handleUserProfile(data);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 1000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 3}}>
      <Card sx={{ width: "100%", maxWidth: 600, p: 4, borderRadius: 3, boxShadow: 3, backgroundColor: "#fff" }}>
        <Typography variant="h4" align="center" gutterBottom>Update Profile</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
              <label htmlFor="profile-upload" style={{ position: "relative", display: "inline-block" }}>
                <img src={data.profilePic || avatar} alt="Profile" style={{ width: 120, height: 120, borderRadius: "50%", cursor: "pointer" }} />
                <Edit style={{ position: "absolute", bottom: 5, right: 5, backgroundColor: "#fff", borderRadius: "50%", padding: 4, fontSize: 35, cursor: "pointer" }} />
              </label>
              <input type="file" id="profile-upload" name="profile" onChange={onUpload} style={{ display: "none" }} />
            </Grid>
            <Grid item xs={12}><TextField label="Email" value={data.email} fullWidth InputProps={{ readOnly: true }} /></Grid>
            <Grid item xs={12}><TextField name="fullname" label="Full Name" value={data.fullname} onChange={handleOnChange} fullWidth /></Grid>
            <Grid item xs={12}><TextField name="dateofbirth" label="Date of Birth" type="date" value={data.dateofbirth} onChange={handleOnChange} fullWidth InputLabelProps={{ shrink: true }} /></Grid>
            <Grid item xs={12}><TextField name="mobile" label="Mobile" value={data.mobile} onChange={handleOnChange} fullWidth /></Grid>
            <Grid item xs={12}><TextField name="gender" select label="Gender" value={data.gender} onChange={handleOnChange} fullWidth><MenuItem value="">Select Gender</MenuItem><MenuItem value="Male">Male</MenuItem><MenuItem value="Female">Female</MenuItem><MenuItem value="Other">Other</MenuItem></TextField></Grid>
            <Grid item xs={12}><TextField name="age" label="Age" type="number" value={data.age} onChange={handleOnChange} fullWidth /></Grid>
            <Grid item xs={12}>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => option.name}
              value={countries.find((c) => c.isoCode === data.country) || null}
              onChange={(_, newValue) => setData({ ...data, country: newValue ? newValue.isoCode : "" })}
              renderInput={(params) => <TextField {...params} label="Country" />}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={states}
              getOptionLabel={(option) => option.name}
              value={states.find((s) => s.isoCode === data.state) || null}
              onChange={(_, newValue) => setData({ ...data, state: newValue ? newValue.isoCode : "" })}
              renderInput={(params) => <TextField {...params} label="State" />}
              disabled={!data.country}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={cities}
              getOptionLabel={(option) => option.name}
              value={cities.find((city) => city.name === data.city) || null}
              onChange={(_, newValue) => setData({ ...data, city: newValue ? newValue.name : "" })}
              renderInput={(params) => <TextField {...params} label="City" />}
              disabled={!data.state}
            />
          </Grid>
          </Grid>
          {message && <Typography align="center" color={messageType === "success" ? "green" : "red"} mt={2}>{message}</Typography>}
          <Box textAlign="center" mt={3}>{loading ? <CircularProgress /> : <Button type="submit" variant="contained" color="primary">Update Profile</Button>}</Box>
        </form>
      </Card>
    </Box>
  );
};
export default Contact;
