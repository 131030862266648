import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const DataDisplay = () => {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editableData, setEditableData] = useState({});

  useEffect(() => {
    const fetchUserData = () => {
      const profileData = localStorage.getItem("userProfileData");
      const technicalSkills = localStorage.getItem("technicalSkills");

      const combinedData = {
        ...(profileData ? JSON.parse(profileData) : {}),
        ...(technicalSkills ? JSON.parse(technicalSkills) : {}),
      };

      if (Object.keys(combinedData).length > 0) {
        setUserData(combinedData);
        setEditableData(combinedData);
      }
    };

    fetchUserData();
    window.addEventListener("storage", fetchUserData);
    return () => window.removeEventListener("storage", fetchUserData);
  }, []);

  const handleEditToggle = () => {
    setEditMode((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    localStorage.setItem("userProfileData", JSON.stringify(editableData));
    alert("Data submitted!");
    setUserData(editableData);
    setEditMode(false);
  };

  return (
    <Container sx={{ mt: 0, width: "800px", maxWidth: 1200, mx: "auto" }}>
      <Card elevation={4} sx={{ p: 0, borderRadius: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" textAlign="center" gutterBottom>
            Application Data
          </Typography>
          <Divider sx={{ mb: 1 }} />

          {userData ? (
            <>
              <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                Academic Background
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography fontWeight="bold">Field</Typography></TableCell>
                      <TableCell><Typography fontWeight="bold">Value</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {["institution", "study", "year", "gpa"].map((key) => (
                      <TableRow key={key}>
                        <TableCell>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}:</TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              name={key}
                              value={editableData[key] || ""}
                              onChange={handleInputChange}
                              fullWidth
                              sx={{ mt: 1 }}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <Typography color="text.secondary">{userData[key] || "N/A"}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider sx={{ mb: 3 }} />

              <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                Technical Skills
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography fontWeight="bold">Field</Typography></TableCell>
                      <TableCell><Typography fontWeight="bold">Value</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {["programmingskills", "toolstechnologies"].map((key) => (
                      <TableRow key={key}>
                        <TableCell>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}:</TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              name={key}
                              value={editableData[key] || ""}
                              onChange={handleInputChange}
                              fullWidth
                              sx={{ mt: 1 }}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <Typography color="text.secondary">{userData[key] || "N/A"}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider sx={{ mb: 3 }} />

              <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                Areas of Interest
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography fontWeight="bold">Field</Typography></TableCell>
                      <TableCell><Typography fontWeight="bold">Value</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      "preferredIndustries",
                      "topicsOfInterest",
                      "experience",
                      "previousInternships",
                      "freelancingProjects",
                      "academicProjects",
                      "certifications",
                      "portfolio",
                      "title",
                      "description",
                      "languages",
                      "hobbies",
                      "preferredJobRole",
                      "preferredLocation",
                      "professionalSummary",
                      "references",
                      "additionalInformation",
                      "goalsAndAspirations",
                      "currentOccupation",
                      "availability",
                      "linkedInProfile",
                    ].map((key) =>
                      userData[key] ? (
                        <TableRow key={key}>
                          <TableCell>{key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}:</TableCell>
                          <TableCell>
                            {editMode ? (
                              <TextField
                                name={key}
                                value={editableData[key] || ""}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ mt: 1 }}
                                variant="outlined"
                                size="small"
                              />
                            ) : (
                              <Typography color="text.secondary">{userData[key] || "N/A"}</Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography textAlign="center" color="text.secondary">
              No user data available.
            </Typography>
          )}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleEditToggle}
              sx={{ flex: 1, mr: 1 }}
            >
              {editMode ? "Cancel Edit" : "Edit Data"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ flex: 1, ml: 1 }}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DataDisplay;
