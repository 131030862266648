import React from 'react';
import { FaBookOpen } from "react-icons/fa";
import { HiLightBulb, HiBriefcase } from "react-icons/hi2";
import Image from "../../../assets/learners/Pages/3.png";

const Embedded= () => {

  return (
    <div className='learners-iot-banner4-cards'>
    <div>
    <h1 className="learners-iot-banner4-h1"><span>Step into the Future of Embedded Systems</span>
    <p className="learners-iot-banner4-p">Empowering learners and professionals to innovate and excel</p></h1>
  <div className='learners-iot-banner4-card'>
      <div>
          <p className='learners-iot-banner4-card-h2'>
              <p><FaBookOpen className="learners-iot-banner4-card-icon" /></p>
              <p className='learners-iot-banner4-card-p'><strong>50+</strong></p> 
              <p className='learners-iot-banner4-card-p-span'>Courses covering every aspect of embedded systems</p>
          </p>
      </div>
      <div>
          <p className='learners-iot-banner4-card-h2'>
              <p><HiLightBulb className="learners-iot-banner4-card-icon" /></p>
              <p className='learners-iot-banner4-card-p'><strong>100+</strong></p> 
              <p className='learners-iot-banner4-card-p-span'>Hands-on projects to spark your embedded systems creativity</p>
          </p>
      </div>
      <div>
          <p className='learners-iot-banner4-card-h2'>
              <p><HiBriefcase className="learners-iot-banner4-card-icon" /></p>
              <p className='learners-iot-banner4-card-p'><strong>1000+</strong></p> 
              <p className='learners-iot-banner4-card-p-span'>Embedded systems internship opportunities for industry connections</p>
          </p>
      </div>
  </div>
  </div>
  <div className='learners-iot-banner4-card-image'>
  <img src={Image} alt='images' className='learners-iot-banner4-card-img' />
  </div>
  </div>
  )
}

export { Embedded };