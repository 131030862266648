import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp, FaFacebook } from 'react-icons/fa';
//import { FaCalendarCheck, FaBriefcase, FaRocket } from "react-icons/fa";
//import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { LiaRupeeSignSolid } from "react-icons/lia";
//import { AiOutlineThunderbolt } from "react-icons/ai";
//import { MdOutlineTrendingUp } from "react-icons/md";
import Empower from "../../../assets/learners/Pages/internetofThings.png";
import { PdfIotdownload } from "./pdfdownload";
import Razorpay from '../../../mainpage/RazorpayPayment/IotRazorpay';
import { IotCourses, LearningPaths } from "./IotCourses";
import Iotimage from "../../../assets/learners/Pages/4.png";
import { InternetCourses, IotProjects } from "./Internetcourse";
import { Button, Box } from '@mui/material';
//Technology
//Programming
import Python from "../../../assets/Technology/Internet-tools-logos/1.png";
import Arduion from "../../../assets/Technology/Internet-tools-logos/4.png";
import Coap from "../../../assets/Technology/Internet-tools-logos/2.png";
import Http from "../../../assets/Technology/Internet-tools-logos/5.png";
import Mqtt from "../../../assets/Technology/Internet-tools-logos/3.png";
import Javascript from "../../../assets/Technology/Internet-tools-logos/6.png";
//Hardware
import Raspberrypi from "../../../assets/Technology/Internet-tools-logos/8.png";
import Espressif from "../../../assets/Technology/Internet-tools-logos/7.png";
//Device Communication
import Can from "../../../assets/Technology/Internet-tools-logos/12.png";
import Ethernet from "../../../assets/Technology/Internet-tools-logos/11.png";
import Modbus from "../../../assets/Technology/Internet-tools-logos/9.png";
import Opcua from "../../../assets/Technology/Internet-tools-logos/10.png";
//Technologies
import Bluetooth from "../../../assets/Technology/Internet-tools-logos/13.png";
import Wifi from "../../../assets/Technology/Internet-tools-logos/14.png";
import Lorawan from "../../../assets/Technology/Internet-tools-logos/40.png";
import Gps from "../../../assets/Technology/Internet-tools-logos/16.png";
import Gsm from "../../../assets/Technology/Internet-tools-logos/15.png";
//Cloud Platforms
import Aws from "../../../assets/Technology/Internet-tools-logos/17.png";
import Azure from "../../../assets/Technology/Internet-tools-logos/18.png";
import Hivemq from "../../../assets/Technology/Internet-tools-logos/19.png";
import Blynk from "../../../assets/Technology/Internet-tools-logos/21.png";
import Thingspeak from "../../../assets/Technology/Internet-tools-logos/22.png";
import Thngsboard from "../../../assets/Technology/Internet-tools-logos/20.png";
//Data
import Grafana from "../../../assets/Technology/Internet-tools-logos/23.png";
import Kibana from "../../../assets/Technology/Internet-tools-logos/25.png";
import Influxdb from "../../../assets/Technology/Internet-tools-logos/24.png";
import Powerbi from "../../../assets/Technology/Internet-tools-logos/26.png";
import Tableau from "../../../assets/Technology/Internet-tools-logos/27.png";
//webapp
import Flask from "../../../assets/Technology/Internet-tools-logos/29.png";
import Mern from "../../../assets/Technology/Internet-tools-logos/31.png";
import Thunkable from "../../../assets/Technology/Internet-tools-logos/30.png";
import Nodered from "../../../assets/Technology/Internet-tools-logos/28.png";
//DataScience
import Numpy from "../../../assets/Technology/Internet-tools-logos/32.png";
import Kafka from "../../../assets/Technology/Internet-tools-logos/38.png";
import Plotly from "../../../assets/Technology/Internet-tools-logos/33.png";
import Learn from "../../../assets/Technology/Internet-tools-logos/34.png";
import Pandas from "../../../assets/Technology/Internet-tools-logos/37.png";
import Mongodb from "../../../assets/Technology/Internet-tools-logos/35.png";
import Matpltlib from "../../../assets/Technology/Internet-tools-logos/36.png";

import Reviews from "../../../mainpage/Homepage/LearnersReviews";
import Footer from "../../../mainpage/Footer/learnersFooter";
import Reveal from '../../../helper/useScrollposition';
import "./iotpage.css";
import "./Learning-paths.css";
import "./pages.css";

const InternetofThings = () => {
  const footerSectionRef = useRef(null);
  const [category, setCategory] = useState("courses");

  const scrollToFooterSection = () => {
  footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const [move, setMove] = useState(false);
  const [showRazorpay, setShowRazorpay] = useState(false); // New state

     const handleClick = () => {
       setMove(true);
       setTimeout(() => {
         setShowRazorpay(true); // Show Razorpay component on click
           setMove(false);
       }, 500);
   };

   useEffect(() => {
       if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
         var scriptElement = document.createElement("script");
         scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
         scriptElement.async = true;
         scriptElement.charset = "UTF-8";
         scriptElement.setAttribute("crossorigin", "*");
         
         document.body.appendChild(scriptElement);
       }
     }, []);

    return (
        <div className="students-flex homepagecoloresiot">
        <Headrepage />
        <div className="homepagecolores">
        <Reveal>
        <div>
        <div ref={footerSectionRef} className="learners-iot-banner1">
          <ul className="learners-iot-banner1-ul">
          <li>
            <h1 className="learners-iot-banner1-h1">
              Empowering Innovators In The
              <span className="learners-iot-banner1-h1-span"> IoT Universe</span>
            </h1>
            <p className="learners-iot-banner1-p1">Your Complete Solution for All IoT Training Needs</p>
            <p className="learners-iot-banner1-p2"><strong>2+ </strong>Boot Camps | <strong>50+ </strong> IoT Courses | Validity: 1 Year </p>
            <p className="learners-iot-banner1-p3"><span><FaRegCheckCircle /></span> Guaranteed Industrial Internship</p>
          </li>
        </ul>
        <img className="learners-iot-banner1-image" src={Empower} alt=""/>
        </div>
        <div className="learners-iot-banner2">
          <ul className="learners-iot-banner2-ul">
            <li className="learners-iot-banner2-ul-li1">
              <p><IoCalendarOutline className="learners-iot-banner2-p1-icon" /></p>
              <p className="learners-iot-banner2-ul-p1">
                <p>Admission Closes Today</p>
                <p className="learners-iot-banner2-li1-seates"> Limited Seats</p>
              </p>
            </li>
            <li className="learners-iot-banner2-ul-li2">
              <p><HiOutlineBriefcase className="learners-iot-banner2-p2-icon" /></p>
              <p className="learners-iot-banner2-ul-p2">Placement Support | 2.08 Lakh+ Opportunities</p>
            </li>
            <li className="learners-iot-banner2-ul-li3">
              <p><LiaRupeeSignSolid className="learners-iot-banner2-p3-icon" /></p>
              <p className="learners-iot-banner2-ul-p3">
                <p className="learners-iot-banner2-ul-p3-price">14,300</p>
                <p className="learners-iot-banner2-ul-p3-old-price-span"><span className="learners-iot-banner2-ul-p3-old-price">30,000 </span> <span className="span"> Save 15,700</span></p>
                <p className="learners-iot-banner2-li2-seates">Valid for Today</p>
              </p>
            </li>
            <li className="learners-iot-banners2-button">
              <PdfIotdownload />
            </li>
            <li className="learners-iot-banner2-button">
              <button
                className={`learners-iot-banner2-btn ${move ? "move-right" : ""}`}
                onClick={handleClick}
              >
                Enroll Now 
              </button>
              <span className="arrow-container">
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
              </span>
            </li>
            {/* <p className="learners-iot-banner2-ul-li5-p"><MdOutlineTrendingUp className="learners-iot-banner2-p5-icon" />
            192,523 already applied
            </p> */}
          </ul>
        </div>
         {/* Show Razorpay component when button is clicked */}
         {showRazorpay && <Razorpay onClose={() => setShowRazorpay(false)} />}
        </div>
        <div className="learners-iot-banner3">
          <h1 className="learners-iot-banner3-h1"><span>Comprehensive Trainings</span>
          <p className="learners-iot-banner3-p">Develop in-demand skills, gain hands-on experience, and receive expert mentorship through an industry-ready IoT program</p></h1>
        <div className="learners-iot-banner3-cards">
          <p>Foundations of Internet of Things</p>
          <p>Wireless Communication Mastery</p>
          <p>Cloud and Full Stack IoT Development</p>
          <p>Advanced Data Analytics and AI in IoT</p>
          <p>Industrial IoT Edge Computing</p>
        </div>
        </div>
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner4">
          <div>
            <IotCourses />
          </div>
        </div>
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner5">
          <h1 className="learners-iot-banner5-h1">IoT Learning Paths</h1>
          <p className="learners-iot-banner5-p">Explore our IoT learning paths tailored to your skill level. 
            Leverage AI-driven customization for a personalized experience that enhances both your career and organizational success.</p>
          <div>
            <LearningPaths />
          </div>
        </div>
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner6">
          <h1 className="learners-iot-banner6-h1"><span>Guaranteed Internships and Placement Assistance: Master IoT Skills With Us</span></h1>
          <div className="learners-iot-banner6-cards">
          <img src= {Iotimage} alt="iot" className="learners-iot-banner6-card-image"/>
          <div className="learners-iot-banner6-card">
          <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Boot Camps</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Expert Training: Master IoT with Hands-on Courses and Projects</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Guaranteed Internships: Secure Paid Internships</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Job Placement Assistance: Get Dedicated Job Placement Support</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Real-World Experience: Gain Practical Industry Experience</p>
            </div>
            </div>
          </div>
            {/* <div className="learners-iot-banner6-card-1-pdf">
              <a 
              href={Industry_Ready_Program_IoT} 
              download
              style={{ textDecoration: 'none', color: 'black', display: 'flex'}} className="learners-iot-banner6-card-1-pdf-button">
              Download
              <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '4px' }} />
              </a>
              <p className="learners-iot-banner6-card-1-pdf">Industry Ready Program IoT</p>
              </div> */}
            </div>
          </Reveal>
          <Reveal>
          <div className="learners-iot-banner8">
            <h1 className="learners-iot-banner8-h1">IoT Courses & Projects: Learn, Build, Experience, Innovate</h1>
            {/* Category Buttons */}
            <Box>
              <Box display="flex" 
              justifyContent={{ xs: "center", md: "flex-start" }} 
              marginTop={4} 
              marginBottom={1}
              sx={{ paddingLeft: { md: "24px" } }} // Add 10px left padding only on desktop
              >
                <Button 
                  variant={category === 'courses' ? 'contained' : 'outlined'} 
                  onClick={() => setCategory('courses')}
                  sx={{ marginRight: 2, textTransform: "none" }}
                >
                  Courses
                </Button>
                <Button 
                  variant={category === 'projects' ? 'contained' : 'outlined'} 
                  onClick={() => setCategory('projects')}
                  sx={{ textTransform: "none" }}
                >
                  Projects
                </Button>
              </Box>

              {/* Conditional Rendering Based on Selected Category */}
              {category === 'courses' ? <InternetCourses /> : <IotProjects />}
            </Box>
            </div>
            </Reveal>
            <Reveal>
            <div className="learners-iot-banner7">
              <h1 className="learners-iot-banner7-h1">Explore the World of IoT Technologies</h1>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Programming and Communication Protocols</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Python} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Python</p>
              </div>
              <div>
              <img src={Arduion} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Arduino</p>
              </div>
              <div>
              <img src={Coap} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Coap</p>
              </div>
              <div>
              <img src={Http} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>HTTP</p>
              </div>
              <div>
              <img src={Mqtt} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>MQTT</p>
              </div>
              <div>
              <img src={Javascript} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Javascript</p>
              </div>
              </div>
             
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Hardware Development</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Raspberrypi} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Raspberrypi</p>
              </div>
              <div>
              <img src={Espressif} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Espressif</p>
              </div>
              </div>
             
            </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Device Communication Protocols</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
                <div>
              <img src={Can} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>CAN</p>
              </div>
              <div>
              <img src={Ethernet} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Ethernet</p>
              </div>
              <div>
              <img src={Modbus} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Modbus</p>
              </div>
              <div>
              <img src={Opcua} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Opc UA</p>
              </div>
              </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Wireless Communication Technologies</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Bluetooth} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Bluetooth</p>
              </div>
              <div>
              <img src={Wifi} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Wifi</p>
              </div>
              <div>
              <img src={Lorawan} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>LoraWAN</p>
              </div>
              <div>
              <img src={Gps} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>GPS</p>
              </div>
              <div>
              <img src={Gsm} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>GSM</p>
              </div>
              </div>
            </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Cloud Platforms</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Aws} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>AWS</p>
              </div>
              <div>
              <img src={Azure} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Azure</p>
              </div>
              <div>
              <img src={Hivemq} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Hivemq</p>
              </div>
              <div>
              <img src={Blynk} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Blynk</p>
              </div>
              <div>
              <img src={Thingspeak} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Thingspeak</p>
              </div>
              <div>
              <img src={Thngsboard} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>ThingsBoard</p>
              </div>
              </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Data Analytics and Monitoring</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Grafana} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Grafana</p>
              </div>
              <div>
              <img src={Kibana} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Kibana</p>
              </div>
              <div>
              <img src={Influxdb} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Influxdb</p>
              </div>
              <div>
              <img src={Powerbi} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Power bi</p>
              </div>
              <div>
              <img src={Tableau} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Tableau</p>
              </div>
              </div>
            </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Web and App Development</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Flask} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Flask</p>
              </div>
              <div>
              <img src={Mern} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Mern Stack</p>
              </div>
              <div>
              <img src={Thunkable} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Thunkable</p>
              </div>
              <div>
              <img src={Nodered} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Node-Red</p>
              </div>
              </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Data Science, AI, ML</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Numpy} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Numpy</p>
              </div>
              <div>
              <img src={Kafka} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Kafka</p>
              </div>
              <div>
              <img src={Plotly} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Plotly</p>
              </div>
              <div>
              <img src={Learn} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Scikit Learn</p>
              </div>
              <div>
              <img src={Pandas} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Pandas</p>
              </div>
              <div>
              <img src={Mongodb} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Mongodb</p>
              </div>
              <div>
              <img src={Matpltlib} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Matpltlib</p>
              </div>
              </div>
            </div>
            </div>
            <div className="learners-iot-banner10-button">
            <div className="learners-iot-banner2-button">
              <button
                className={`learners-iot-banner2-btn ${move ? "move-right" : ""}`}
                onClick={handleClick}
              >
                Enroll Now
              </button>
              <span className="arrow-container">
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
              </span>
            </div>
            </div>
            </div>
            </Reveal>
            <Reveal>
           <div className="learners-iot-banner9">
           <h1 className="learners-iot-banner9-h1">What Our Customers Are Saying</h1>
            <Reviews />
            </div>
            </Reveal>
            <div className="homepage-footers-images">
            <Footer />
             <footer className="homepage-footers">
             <hr className='homepage-footer-hr'/>
             <div className="footer-social">
             <div className="homepage-footer-copyright">
              <p>© Huebits[2025].All Rights Reserved.</p>
             </div>
             <div className='footer-social-icons'>
                <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
                <Link onClick={scrollToFooterSection}>
                <FaArrowUp className="homepage-social-icon-link" />
                </Link>
            </div>
            </div>
          </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;