import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Brochurerequest, Brochuredownload } from "../../../helper/base_url";

const PdfDownloadForm = ({ brochureName, programName }) => {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "", userType: "" });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(Brochurerequest, formData);
      if (response.status === 200) {
        await downloadBrochure();
        setMessage({ type: "success", text: "Brochure downloaded successfully!" });
      }
    } catch (error) {
      setMessage({ type: "error", text: error.response?.data?.message || "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  const downloadBrochure = async () => {
    try {
        //console.log("📡 Sending request to:", `${Brochuredownload}?email=${encodeURIComponent(formData.email)}&brochureName=${encodeURIComponent(brochureName)}`);
        // Show success message before starting the download
        setMessage({ type: "success", text: "Brochure downloaded successfully!" });

        // Wait for 1 second before downloading
        await new Promise((resolve) => setTimeout(resolve, 500));
        
        const response = await axios.get(`${Brochuredownload}?email=${encodeURIComponent(formData.email)}&brochureName=${encodeURIComponent(brochureName)}`, {
            responseType: "blob",
        });

        if (response.status !== 200) throw new Error("Failed to download");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", brochureName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Close the form dialog after successful download
        setOpen(false); 

    } catch (error) {
        console.error("🚨 Error downloading brochure:", error);
        setMessage({ type: "error", text: error.response?.data?.message || "Error downloading brochure." });
    }
};

  return (
    <Box textAlign="center" p={2}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <IconButton onClick={() => setOpen(false)} color="error" sx={{ position: "absolute", top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle>{programName}</DialogTitle>

        <Snackbar
          open={!!message.text}
          autoHideDuration={1000}
          onClose={() => setMessage({ text: "" })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioned at the top center
        >
          <Alert severity={message.type} onClose={() => setMessage({ text: "" })}>
            {message.text}
          </Alert>
        </Snackbar>

        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
            <Stack spacing={2}>
              <TextField label="Full Name" name="name" fullWidth value={formData.name} onChange={handleChange} required />
              <TextField label="Email Address" name="email" type="email" fullWidth value={formData.email} onChange={handleChange} required />
              <Box>
                <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>Phone Number</Typography>
                <PhoneInput country="in" enableSearch inputStyle={{ width: "100%" }} value={formData.phone} onChange={handlePhoneChange} required />
              </Box>
              <FormControl component="fieldset">
                <FormLabel>User Type</FormLabel>
                <RadioGroup row name="userType" value={formData.userType} onChange={handleChange}>
                  <FormControlLabel value="Student" control={<Radio />} label="Student" />
                  <FormControlLabel value="Professional" control={<Radio />} label="Professional" />
                </RadioGroup>
              </FormControl>
              <Box sx={{ textAlign: "center" }}>
                <Button type="submit" variant="contained" disabled={loading} sx={{ background: "#0E68F9", color: "white" }}>
                  {loading ? <CircularProgress size={24} sx={{ color: "#0E68F9" }} /> : "Download Brochure"}
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)} 
      sx={{
        // background: "rgba(255, 255, 255, 0.2)",
        background: "rgb(14, 104, 249)",
        color: "white",
        fontWeight: 700,
        padding: "0.8rem 1rem",
        borderRadius: "10px",
        textTransform: "capitalize",
        outline: "none",
        border: "none",
        cursor: "pointer",
        fontSize: "1rem",
        marginTop:"14px",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        backdropFilter: "blur(5px)",
        textDecoration: "none",
        "&:hover": {
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      Download Brochure
    </Button>
    </Box>
  );
};

export default PdfDownloadForm;