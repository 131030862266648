import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import { School, Business, LocalOffer, People, Logout } from "@mui/icons-material";
import Students from "./Students";
import Companies from "./Companies";
import CreateCoupon from "./CreateCoupon";
import Mentors from "./Mentors";

const drawerWidth = 250; // Sidebar width

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("students");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("admin-token");
    if (!token) {
      navigate("/admin/signin");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    navigate("/admin/signin");
  };

  return (
    <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
      {/* Sidebar Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
        }}
      >
        <Toolbar>
          <Typography variant="h6">Admin Panel</Typography>
        </Toolbar>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setActiveTab("students")}>
              <ListItemIcon><School /></ListItemIcon>
              <ListItemText primary="Students Data" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setActiveTab("companies")}>
              <ListItemIcon><Business /></ListItemIcon>
              <ListItemText primary="Companies Data" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setActiveTab("mentors")}>
              <ListItemIcon><People /></ListItemIcon>
              <ListItemText primary="Mentors Data" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setActiveTab("createcoupon")}>
              <ListItemIcon><LocalOffer /></ListItemIcon>
              <ListItemText primary="Create Coupon" />
            </ListItemButton>
          </ListItem>
        </List>

        {/* Logout Button */}
        <Box sx={{ position: "absolute", bottom: 20, width: "100%", px: 2 }}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            startIcon={<Logout />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Drawer>

      {/* Main Content (Full Width) */}
      <Box sx={{ flexGrow: 1, width: `calc(100vw - ${drawerWidth}px)`, display: "flex", flexDirection: "column" }}>
        {/* Full-width Navigation Bar */}
        <AppBar position="static" sx={{ width: "100%", bgcolor: "primary.main" }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {activeTab === "students"
                ? "Students Data"
                : activeTab === "companies"
                ? "Companies Data"
                : activeTab === "mentors"
                ? "Mentors Data"
                : "Create Coupon"}
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Full Width Page Content */}
        <Box sx={{ flexGrow: 1, p: 3, width: "100%" }}>
          {activeTab === "students" && <Students />}
          {activeTab === "companies" && <Companies />}
          {activeTab === "mentors" && <Mentors />}
          {activeTab === "createcoupon" && <CreateCoupon />}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
