import Project1 from "../../../../assets/learnersCourses/iotCourses/projects/smartHomeusingESP32andBlynk.png";
import Project2 from "../../../../assets/learnersCourses/iotCourses/projects/smartCarParkingSystemusingESP32.png";
import Project3  from "../../../../assets/learnersCourses/iotCourses/projects/smartStreetLightingusingIoTProject.png";
import Project4 from "../../../../assets/learnersCourses/iotCourses/projects/smartIrrigationSystemusingESP32.png";
import Project5 from "../../../../assets/learnersCourses/iotCourses/projects/smartShoeDevelopmentusingESP32andBlynk.png";
import Project6 from "../../../../assets/learnersCourses/iotCourses/projects/smartShoeforBlindusingESP32Project.png";
import Project7 from "../../../../assets/learnersCourses/iotCourses/projects/smartAgricultureusingInternet.png";
import Project8 from "../../../../assets/learnersCourses/iotCourses/projects/mqttbasedEnvironmentMonitoring.png";
import Project9 from "../../../../assets/learnersCourses/iotCourses/projects/developingIoTBasedSmart.png";
import Project10 from "../../../../assets/learnersCourses/iotCourses/projects/smartLightBulbControllingonRaspberryPiUsingFlaskWebApp.png";
import Project11 from "../../../../assets/learnersCourses/iotCourses/projects/iotBasedSmartFeeder.png";
import Project12 from "../../../../assets/learnersCourses/iotCourses/projects/remoteHealthMonitoring.png";
import Project13 from "../../../../assets/learnersCourses/iotCourses/projects/smartEnergyMonitoringSystem.png";
import Project14 from "../../../../assets/learnersCourses/iotCourses/projects/iotPoweredSmartEnergy.png";
import Project15 from "../../../../assets/learnersCourses/iotCourses/projects/energyMonitoringusingEmoncms.png";

//iotdata
import iotdataProject1 from "../../../../assets/learnersCourses/iotCourses/projects/smartEnergyAnalyticsusingIoTandPowerBI.png";
import iotdataProject2 from "../../../../assets/learnersCourses/iotCourses/projects/Agriculture.png";
import iotdataProject3  from "../../../../assets/learnersCourses/iotCourses/projects/googleLookerStudio.png";
import iotdataProject4 from "../../../../assets/learnersCourses/iotCourses/projects/smartAirQualityMonitoringSystemUsingESP32.png";
import iotdataProject5 from "../../../../assets/learnersCourses/iotCourses/projects/machineHealthMonitoring.png";
import iotdataProject6 from "../../../../assets/learnersCourses/iotCourses/projects/indoorOccupancyDetectionBased.png";
import iotdataProject7 from "../../../../assets/learnersCourses/iotCourses/projects/anomalyDetection.png";
import iotdataProject8 from "../../../../assets/learnersCourses/iotCourses/projects/machineLearninginAWSforIoTbased.png";
import iotdataProject9 from "../../../../assets/learnersCourses/iotCourses/projects/aiVoiceAssistant.png";
import iotdataProject10 from "../../../../assets/learnersCourses/iotCourses/projects/smartWasteManagementusingIoTandMLwithESP32andAWS.png";

//Industrial4.0
import IndustrialProject1 from "../../../../assets/learnersCourses/iotCourses/projects/14.png";
import IndustrialProject2 from "../../../../assets/learnersCourses/iotCourses/projects/12.png";
import IndustrialProject3  from "../../../../assets/learnersCourses/iotCourses/projects/22.png";
import IndustrialProject4 from "../../../../assets/learnersCourses/iotCourses/projects/28.png";
import IndustrialProject5 from "../../../../assets/learnersCourses/iotCourses/projects/20.png";
import IndustrialProject6 from "../../../../assets/learnersCourses/iotCourses/projects/5.png";
import IndustrialProject7 from "../../../../assets/learnersCourses/iotCourses/projects/23.png";
import IndustrialProject8 from "../../../../assets/learnersCourses/iotCourses/projects/1.png";
import IndustrialProject9 from "../../../../assets/learnersCourses/iotCourses/projects/15.png";
import IndustrialProject10 from "../../../../assets/learnersCourses/iotCourses/projects/37.png";

const IoTdeveloperProjects = [
  {
    projectId: 1.1,
    projectTitle: "Smart Home using ESP32 and Blynk",
    videoUrl: "660e7e28c6083f17c8eb84fd",
    image: Project1,
    completion: 0
  },
  {
    projectId: 1.2,
    projectTitle: "Smart Car Parking System using ESP32 and Arduino",
    videoUrl: "660e7ee394fd573437e5b2b4",
    image: Project2,
    completion: 0
  },
  {
    projectId: 1.3,
    projectTitle: "Smart Street Lighting using IoT",
    videoUrl: "660e80e8a21241265847fff9",
    image: Project3,
    completion: 0
  },
  {
    projectId: 1.4,
    projectTitle: "Smart Irrigation System using ESP32",
    videoUrl: "660e8140a21241265848004d",
    image: Project4,
    completion: 0
  },
  {
    projectId: 1.5,
    projectTitle: "Smart Shoe Development using ESP32 and Blynk",
    videoUrl: "6613a2284443c779482576e3",
    image: Project5,
    completion: 0
  },
  {
    projectId: 1.6,
    projectTitle: "Smart Shoe for Blind using ESP32 Project",
    videoUrl: "66275a4609ec531accf7d82f",
    image:  Project6,
    completion: 0
  },
  {
    projectId: 1.7,
    projectTitle: "Smart Agriculture using Internet of Things and MQTT Protocol (Raspberry Pi)",
    videoUrl: "66e82df6153e7d6569f24c18", 
    image: Project7,
    completion: 0
  },
  {
    projectId: 1.8,
    projectTitle: "MQTT based Environment Monitoring in factories for Employee Safety (raspberry Pi)",
    videoUrl: "66e82e835f6cf505f4d50eb3",
    image: Project8,
    completion: 0
  },
  {
    projectId: 1.9,
    projectTitle: "Developing Smart Workspace Based IOT with Artificial intelligence and using Telegram CHATBOT",
    videoUrl: "66e82f3e688b0e0b4daf3367", 
    image: Project9,
    completion: 0
  },
  {
    projectId: 1.10,
    projectTitle: "Smart Light Bulb Controlling on Raspberry Pi Using Flask Web App",
    videoUrl: "66e82ff2db4d465ff95057c3", 
    image: Project10,
    completion: 0
  },
  {
    projectId: 1.11,
    projectTitle: "IoT Based Feeder Monitoring System",
    videoUrl: "66e830a28a4ba6652360f2ac", 
    image: Project11,
    completion: 0
  },
  {
    projectId: 1.12,
    projectTitle: "Remote Health Monitoring and Protection of Transformers Using IoT",
    videoUrl: "66e83202268fd80c0d02f9b3", 
    image: Project12,
    completion: 0
  },
  {
    projectId: 1.13,
    projectTitle: "Smart Energy Monitoring System",
    videoUrl: "673b393b78d88a692f165a16", 
    image: Project13,
    completion: 0
  },
  {
    projectId: 1.14,
    projectTitle: "IoT-Powered Smart Energy Meter Surveillance",
    videoUrl: "66e832f5f1f27f2d14777415",
    image: Project14,
    completion: 0
  },
  {
    projectId: 1.15,
    projectTitle: "Energy Monitoring using Emoncms",
    videoUrl: "66e83407db4d465ff95058b2",
    image: Project15,
    completion: 0
  }
];

//iotdata
const IoTDataScientistProjects = [
  {
    projectId: 1.16,
    projectTitle: "Smart Energy Analytics using IoT and Power BI",
    videoUrl: "660e89ac32d94d683a15c142",
    image: iotdataProject1,
    completion: 0
  },
  {
    projectId: 1.17,
    projectTitle: "Agriculture Monitoring using IoT and Power BI",
    videoUrl: "660e8a2b699598329d883b55",
    image: iotdataProject2,
    completion: 0
  },
  {
    projectId: 1.18,
    projectTitle: "Real Time Health Monitoring Dashboard using IoT and Google Looker Studio",
    videoUrl: "660e8a7f7104a33722b472c9",
    image: iotdataProject3,
    completion: 0
  },
  {
    projectId: 1.19,
    projectTitle: "Smart Air Quality Monitoring using ESP32 and Node-Red",
    videoUrl: "660e8b75547d1953124c3fb5",
    image: iotdataProject4,
    completion: 0
  },
  {
    projectId: 1.20,
    projectTitle: "Machine Health Monitoring Using Power BI",
    videoUrl: "66e8363468f4bf5bfc36162a",
    image: iotdataProject5,
    completion: 0
  },
  {
    projectId: 1.21,
    projectTitle: "Indoor Occupancy Detection Based on Environmental Data using Tableau",
    videoUrl: "66e836b5268fd80c0d02faf8",
    image:  iotdataProject6,
    completion: 0
  },
  {
    projectId: 1.22,
    projectTitle: "Anomaly detection in the temperature of an ac motor using embedded machine learning",
    videoUrl: "66e8371c688b0e0b4daf356c", 
    image: iotdataProject7,
    completion: 0
  },
  {
    projectId: 1.23,
    projectTitle: "Machine Learning in AWS for IoT-based Oil Pipeline Monitoring System",
    videoUrl: "66e837d5688b0e0b4daf35b5",
    image: iotdataProject8,
    completion: 0
  },
  {
    projectId: 1.24,
    projectTitle: "AI Voice Assistant using Raspberry Pi and Gen AI",
    videoUrl: "66e8385668f4bf5bfc3616bf", 
    image: iotdataProject9,
    completion: 0
  },
  {
    projectId: 1.25,
    projectTitle: "Smart Waste Management using IoT and ML with ESP32 and AWS",
    videoUrl: "66e838c8b546865c28df1863", 
    image: iotdataProject10,
    completion: 0
  }
];

const IndustrialProjects = [
  {
    projectId: 1,
    projectTitle: "Industrial IoT Gateway using Raspberry Pi",
    videoUrl: "",
    image: IndustrialProject1,
    completion: 0
  },
  {
    projectId: 2,
    projectTitle: "Fault identification model using IIoT for industrial application",
    videoUrl: "",
    image: IndustrialProject2,
    completion: 0
  },
  {
    projectId: 3,
    projectTitle: "Low Cost Industrial IoT System for Wireless Monitoring of Electric Motors Condition",
    videoUrl: "",
    image: IndustrialProject3,
    completion: 0
  },
  {
    projectId: 4,
    projectTitle: "Monitoring of Industrial Electrical Equipment using IoT",
    videoUrl: "",
    image: IndustrialProject4,
    completion: 0
  },
  {
    projectId: 5,
    projectTitle: "IoT-Based HVAC Monitoring",
    videoUrl: "",
    image: IndustrialProject5,
    completion: 0
  },
  {
    projectId: 6,
    projectTitle: "Design PLC using Raspberry Pi",
    videoUrl: "",
    image:  IndustrialProject6,
    completion: 0
  },
  {
    projectId: 7,
    projectTitle: "Low-Cost ESP32, Raspberry Pi, Node-Red, and MQTT Protocol Based SCADA System",
    videoUrl: "", 
    image: IndustrialProject7,
    completion: 0
  },
  {
    projectId: 8,
    projectTitle: "A Low-Cost IoT-Based System for Manufacturing Process Data Acquisition",
    videoUrl: "",
    image: IndustrialProject8,
    completion: 0
  },
  {
    projectId: 9,
    projectTitle: "Intelligent Low-Cost Monitoring for Smart Digital Manufacturing",
    videoUrl: "", 
    image: IndustrialProject9,
    completion: 0
  },
  {
    projectId: 10,
    projectTitle: "Supervisory Control and Data Acquisition Approach PLC in Node-RED",
    videoUrl: "", 
    image: IndustrialProject10,
    completion: 0
  }
];

export { IoTdeveloperProjects, IoTDataScientistProjects, IndustrialProjects };