import React from 'react';

const Freelancing = () => {
  return (
    <div>
        <h1>Freelancing</h1>
    </div>
  )
}

export default Freelancing;
