import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Sendenquiry } from "../../helper/base_url";

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "Student",
    company: "",
    description: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });

    if (!value || value.length < 10) {
      setErrorMessage("Invalid phone number.");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.phone || formData.phone.length < 10) {
      setErrorMessage("Invalid phone number.");
      return;
    }

    try {
      const response = await axios.post(`${Sendenquiry}`, {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        userType: formData.role,
        description: formData.description,
        companyName: formData.role === "Professional" ? formData.company : "",
      });

      setSuccessMessage(response.data.message);
      setErrorMessage("");
      setFormData({ name: "", email: "", phone: "", role: "Student", company: "", description: "" });
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ p: 4, border: "1px solid #ddd", borderRadius: 2, boxShadow: 3, backgroundColor: "#fff", textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>Send Enquiry</Typography>
        
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField label="Name" name="name" value={formData.name} onChange={handleChange} required fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Email" type="email" name="email" value={formData.email} onChange={handleChange} required fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" sx={{ textAlign: "left" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>Phone Number</Typography>
                <PhoneInput country={"in"} enableSearch inputStyle={{ width: "100%", height: "56px" }} value={formData.phone} onChange={handlePhoneChange} />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" sx={{ textAlign: "left" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>Select your role: Student or Professional</Typography>
                <Select name="role" value={formData.role} onChange={handleChange}>
                  <MenuItem value="Student">🎓 Student</MenuItem>
                  <MenuItem value="Professional">💼 Professional</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formData.role === "Professional" && (
              <Grid item xs={12} md={6}>
                <TextField label="Company Name" name="company" value={formData.company} onChange={handleChange} required fullWidth />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField label="Description" name="description" value={formData.description} onChange={handleChange} required multiline rows={4} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>Submit Enquiry</Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      {/* Error Message Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={2000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Success Message Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={2000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EnquiryForm;
