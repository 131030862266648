import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, Box, Typography, Pagination, useMediaQuery } from "@mui/material";
import Course1 from "../../../assets/Courses/embedded-systems/1.png";
import Course2 from "../../../assets/Courses/embedded-systems/2.png";
import Course3 from "../../../assets/Courses/embedded-systems/3.png";
import Course4 from "../../../assets/Courses/embedded-systems/4.png";
import Course5 from "../../../assets/Courses/embedded-systems/5.png";
import Course6 from "../../../assets/Courses/embedded-systems/6.png";
import Course7 from "../../../assets/Courses/embedded-systems/7.png";
import Course8 from "../../../assets/Courses/embedded-systems/8.png";
import Course9 from "../../../assets/Courses/embedded-systems/9.png";
import Course10 from "../../../assets/Courses/embedded-systems/10.png";
import Course11 from "../../../assets/Courses/embedded-systems/11.png";
import Course12 from "../../../assets/Courses/embedded-systems/12.png";
import Course13 from "../../../assets/Courses/embedded-systems/13.png";
import Course14 from "../../../assets/Courses/embedded-systems/24.png";
import Course15 from "../../../assets/Courses/embedded-systems/14.png";
import Course16 from "../../../assets/Courses/embedded-systems/16.png";
import Course17 from "../../../assets/Courses/embedded-systems/15.png";
import Course18 from "../../../assets/Courses/embedded-systems/17.png";
import Course19 from "../../../assets/Courses/embedded-systems/18.png";
import Course20 from "../../../assets/Courses/embedded-systems/20.png";
import Course21 from "../../../assets/Courses/embedded-systems/19.png";
import Course22 from "../../../assets/Courses/embedded-systems/21.png";
import Course23 from "../../../assets/Courses/embedded-systems/22.png";
import Course24 from "../../../assets/Courses/embedded-systems/23.png";

//projects
import Project1 from "../../../assets/Courses/embedded-systems-projects/6.png";
import Project2 from "../../../assets/Courses/embedded-systems-projects/7.png";
import Project3 from "../../../assets/Courses/embedded-systems-projects/8.png";
import Project4 from "../../../assets/Courses/embedded-systems-projects/9.png";
import Project5 from "../../../assets/Courses/embedded-systems-projects/10.png";
import Project6 from "../../../assets/Courses/embedded-systems-projects/11.png";
import Project7 from "../../../assets/Courses/embedded-systems-projects/12.png";
import Project8 from "../../../assets/Courses/embedded-systems-projects/13.png";
import Project9 from "../../../assets/Courses/embedded-systems-projects/14.png";
import Project10 from "../../../assets/Courses/embedded-systems-projects/15.png";
import Project11 from "../../../assets/Courses/embedded-systems-projects/16.png";
import Project12 from "../../../assets/Courses/embedded-systems-projects/17.png";
import Project13 from "../../../assets/Courses/embedded-systems-projects/18.png";
import Project14 from "../../../assets/Courses/embedded-systems-projects/19.png";
import Project15 from "../../../assets/Courses/embedded-systems-projects/20.png";
import Project16 from "../../../assets/Courses/embedded-systems-projects/21.png";
import Project17 from "../../../assets/Courses/embedded-systems-projects/22.png";
import Project18 from "../../../assets/Courses/embedded-systems-projects/23.png";
import Project19 from "../../../assets/Courses/embedded-systems-projects/24.png";
import Project20 from "../../../assets/Courses/embedded-systems-projects/25.png";

const courses = [
    { id: 1, img: Course1, title: "Introduction to Embedded Systems"},//1
    { id: 2, img: Course2, title: "Practical C Programming" },//2
    { id: 3, img: Course3, title: "Practical Electronics" },//3
    { id: 4, img: Course4, title: "Linux Fundamentals" },//4
    { id: 5, img: Course5, title: "Object-Oriented Programming with C++" },//5
    { id: 6, img: Course6, title: "Embedded Systems with ESP32 and ESP-IDF Programming" },//6
    { id: 7, img: Course7, title: "ARM Cortex-M Programming for Embedded Systems: STM32" },//7
    { id: 8, img: Course8, title: "Real-Time Embedded Systems with FreeRTOS" },//8
    { id: 9, img: Course9, title: "Interrupt Handling in Embedded Systems"},//9
    { id: 10, img: Course10, title: "Advanced Peripherals: PWM, CAN, LIN, Ethernet" },//10
    { id: 11, img: Course11, title: "Embedded Systems Security: Foundations, Secure Boot, and Cybersecurity Embedded Linux Development" },//11
    { id: 12, img: Course12, title: "Linux Fundamentals" },//12
    { id: 13, img: Course13, title: "Embedded AI" },//13
    { id: 14, img: Course14, title: "Introduction to Internet of Things" },//
    { id: 15, img: Course15, title: "Networking and TCP/IP: Protocols, Routing, and Socket Programming" },//14
    { id: 16, img: Course16, title: "IoT and Embedded Systems with ESP32" },//16
    { id: 17, img: Course17, title: "Wireless Communication Protocols" },//15
    { id: 18, img: Course18, title: "Building IoT Applications with STM32" },//17
    { id: 19, img: Course19, title: "Linux Device Drivers and Kernel Development" },//18
    { id: 20, img: Course20, title: "Embedded Linux Boot Process & U-Boot Development" },//20
    { id: 21, img: Course21, title: "Yocto & Embedded Linux Application Development" },//19
    { id: 22, img: Course22, title: "Automotive Embedded Systems & Communication Protocols" },//21
    { id: 23, img: Course23, title: "AUTOSAR & ECU Development: Architecture, Implementation, and Testing" },//22
    { id: 24, img: Course24, title: "Functional Safety & ADAS: ISO 26262 and Autonomous Vehicle Systems" },//23
];

const projects = [
    { id: 1, img: Project1, title: "Real-Time Data Acquisition System using FreeRTOS" },//6
    { id: 2, img: Project2, title: "Smart Industrial Motor Control with PWM & CAN Protocol" },//7
    { id: 3, img: Project3, title: "Embedded Linux-Based Smart Home Automation System" },//8
    { id: 4, img: Project4, title: "ARM Cortex-M Based Digital Oscilloscope" },//9
    { id: 5, img: Project5, title: "IoT-Based Energy Meter using ESP32 & MQTT" },//10
    { id: 6, img: Project6, title: "Smart Agricultural Irrigation System using LoRa & Sensors" },//11
    { id: 7, img: Project7, title: "Industrial IoT (IIoT) Smartbox for Predictive Maintenance" },//12
    { id: 8, img: Project8, title: "Remote Air Quality Monitoring System with STM32 & Cloud" },//13
    { id: 9, img: Project9, title: "Secure Firmware Update & OTA System for IoT Devices" },//14
    { id: 10, img: Project10, title: "Trusted Execution Environment (TEE) for Secure IoT Devices" },//15
    { id: 11, img: Project11, title: "AES & RSA Encryption for Secure Embedded Communication" },//16
    { id: 12, img: Project12, title: "Vehicle ECU Simulator using CAN Protocol" },//17
    { id: 13, img: Project13, title: "ADAS-Based Collision Avoidance System using Ultrasonic & AI" },//18
    { id: 14, img: Project14, title: "OBD-II Vehicle Diagnostics System with Embedded Linux" },//19
    { id: 15, img: Project15, title: "ISO 26262-Compliant Functional Safety Monitoring System" },//20
    { id: 16, img: Project16, title: "Hand Gesture Recognition for Smart Control using Edge AI" },//21
    { id: 17, img: Project17, title: "AI-Powered Smart CCTV with Embedded Vision & OpenCV" },//22
    { id: 18, img: Project18, title: "TinyML-Based Vibration Analysis for Predictive Maintenance" },//23
    { id: 19, img: Project19, title: "Automated Warehouse Robot with LiDAR-Based Navigation" },//24
    { id: 20, img: Project20, title: "Embedded FPGA-Based Image Processing System" },//25
];

const EmbeddedSystemsCourses = () => {
    const [currentPage, setCurrentPage] = useState(1);
      const [itemsPerPage, setItemsPerPage] = useState(8);
    
      const isPhone = useMediaQuery("(max-width: 480px)");
      //const isTablet = useMediaQuery("(max-width: 768px)");
    
      useEffect(() => {
        const updateItemsPerPage = () => {
          if (window.innerWidth <= 480) {
            setItemsPerPage(2);
          } else if (window.innerWidth <= 768) {
            setItemsPerPage(4);
          } else {
            setItemsPerPage(8);
          }
        };
    
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
      }, []);
    
      const totalPages = Math.ceil(courses.length / itemsPerPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = courses.slice(startIndex, startIndex + itemsPerPage);
    
      return (
        <Box>
          {/* Course Grid */}
          <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop="4px">
            {currentItems.map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id} display="flex" justifyContent="center">
                <Card sx={{ width: 280, height: 280, borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia component="img" height="170px" image={course.img} alt={course.title} />
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="h6" fontSize={14} textAlign="start">
                      {course.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ArrowBack />
            </IconButton> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              siblingCount={isPhone ? 0 : 1} // 0 on phones, 1 on tablets/desktops
              boundaryCount={isPhone ? 1 : 2} // Show first/last pages
              size={isPhone ? "small" : "medium"} // Smaller pagination on phones
            />
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ArrowForward />
            </IconButton> */}
          </Box>
        </Box>
      );
    };

//projects
const EmbeddedSystemsProjects = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    
    const isPhone = useMediaQuery("(max-width: 480px)");
    //const isTablet = useMediaQuery("(max-width: 768px)");
    
      useEffect(() => {
        const updateItemsPerPage = () => {
          if (window.innerWidth <= 480) {
            setItemsPerPage(2);
          } else if (window.innerWidth <= 768) {
            setItemsPerPage(4);
          } else {
            setItemsPerPage(8);
          }
        };
    
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
      }, []);
    
      const totalPages = Math.ceil(projects.length / itemsPerPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = projects.slice(startIndex, startIndex + itemsPerPage);
    
      return (
        <Box>
          {/* Course Grid */}
          <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop="4px">
            {currentItems.map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id} display="flex" justifyContent="center">
                <Card sx={{ width: 280, height: 280, borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia component="img" height="170px" image={course.img} alt={course.title} />
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="h6" fontSize={14} textAlign="start">
                      {course.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ArrowBack />
            </IconButton> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              siblingCount={isPhone ? 0 : 1} // 0 on phones, 1 on tablets/desktops
              boundaryCount={isPhone ? 1 : 2} // Show first/last pages
              size={isPhone ? "small" : "medium"} // Smaller pagination on phones
            />
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ArrowForward />
            </IconButton> */}
          </Box>
        </Box>
      );
    };

export { EmbeddedSystemsCourses, EmbeddedSystemsProjects};