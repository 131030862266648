import React, {useRef, useEffect } from "react";
import { Link } from "react-router-dom";
//import Empower from "../../assets/companiesimages/pages/3.png";
import Embeddedsystems2 from "../../assets/companiesimages/web/3.png";
import Internet from "../../assets/companiesimages/services/2.png";
import Industrial from "../../assets/companiesimages/services/3.png";
import IoT from "../../assets/companiesimages/services/4.png";
import Edge from "../../assets/companiesimages/services/5.png";
import Industry from "../../assets/companiesimages/services/6.png";
import AIoT from "../../assets/companiesimages/services/7.png";
//Technology
import Python from "../../assets/Technology/Internet-tools-logos/1.png";
import Arduion from "../../assets/Technology/Internet-tools-logos/2.png";
import Coap from "../../assets/Technology/Internet-tools-logos/3.png";
import Http from "../../assets/Technology/Internet-tools-logos/4.png";
import Mqtt from "../../assets/Technology/Internet-tools-logos/5.png";
import Javascript from "../../assets/Technology/Internet-tools-logos/6.png";
import Bluetooth from "../../assets/Technology/Internet-tools-logos/13.png";
import Wifi from "../../assets/Technology/Internet-tools-logos/14.png";
import Lorawan from "../../assets/Technology/Internet-tools-logos/40.png";
import Gps from "../../assets/Technology/Internet-tools-logos/16.png";
import Gsm from "../../assets/Technology/Internet-tools-logos/15.png";

import Aws from "../../assets/Technology/Internet-tools-logos/17.png";
import Azure from "../../assets/Technology/Internet-tools-logos/18.png";
import Hivemq from "../../assets/Technology/Internet-tools-logos/19.png";
import Blynk from "../../assets/Technology/Internet-tools-logos/21.png";
import Thingspeak from "../../assets/Technology/Internet-tools-logos/22.png";
import Thngsboard from "../../assets/Technology/Internet-tools-logos/20.png";
import Flowfuse from "../../assets/Technology/Internet-tools-logos/42.png";
import N3uron from "../../assets/Technology/Internet-tools-logos/43.png";

import Grafana from "../../assets/Technology/Internet-tools-logos/23.png";
import Kibana from "../../assets/Technology/Internet-tools-logos/25.png";
import Influxdb from "../../assets/Technology/Internet-tools-logos/24.png";
import Powerbi from "../../assets/Technology/Internet-tools-logos/26.png";
import Tableau from "../../assets/Technology/Internet-tools-logos/27.png";

import Raspberrypi from "../../assets/Technology/Internet-tools-logos/8.png";
import Espressif from "../../assets/Technology/Internet-tools-logos/7.png";
import Stm32 from "../../assets/Technology/Embedded-tools-logos/2.png";
import Texas from "../../assets/Technology/Embedded-tools-logos/40.png";

import Flutter from "../../assets/Technology/Internet-tools-logos/41.png";
import Flask from "../../assets/Technology/Internet-tools-logos/29.png";
import Mern from "../../assets/Technology/Internet-tools-logos/31.png";
import Thunkable from "../../assets/Technology/Internet-tools-logos/30.png";
import Nodered from "../../assets/Technology/Internet-tools-logos/28.png";

import Numpy from "../../assets/Technology/Internet-tools-logos/32.png";
import Kafka from "../../assets/Technology/Internet-tools-logos/38.png";
import Plotly from "../../assets/Technology/Internet-tools-logos/33.png";
import Learn from "../../assets/Technology/Internet-tools-logos/34.png";
import Pandas from "../../assets/Technology/Internet-tools-logos/37.png";
import Mongodb from "../../assets/Technology/Internet-tools-logos/35.png";
import Matpltlib from "../../assets/Technology/Internet-tools-logos/36.png";

import Footerimage from "../../assets/companiesimages/organizations/banner10.png";
import Reveal from '../../helper/useScrollposition';

//Partners
import Partnerships from "./Partners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import "./OrganizationBanner.css";
import "./pages.css";

const InternetofThings = () => {
  const footerSectionRef = useRef(null);
  const solutionsSectionRef = useRef(null);
  const techtalentSectionRef = useRef(null);
  
  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToSolutionsSection = () => {
    solutionsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTechtalentSection = () => {
    techtalentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
      if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
        var scriptElement = document.createElement("script");
        scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
        scriptElement.async = true;
        scriptElement.charset = "UTF-8";
        scriptElement.setAttribute("crossorigin", "*");
        
        document.body.appendChild(scriptElement);
      }
    }, []);

    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
          <div className="homepagecolor">
          <div ref={footerSectionRef} className="organization-iot-tech-talent-banners1">
            <ul className="organization-iot-tech-talent-banner1">
              <li>
              <h1>Driving Success With</h1>
              <h1><span>IoT and Tech Talent</span></h1>
              <p>Empowering Your Business with IoT Solutions and Skilled Tech Talent</p>
              </li>
            </ul>
              {/* <button className="organization-iot-tech-talent-banner1-btn">Learn More</button> */}
            {/* <img className="organization-iot-tech-talent-banner1-image" src={Empower} alt=""/> */}
            </div>

             <div ref={solutionsSectionRef} className="organization-iot-tech-talent-banner2">
             <h1 className="organization-iot-tech-talent-banner2-h1">Harness the Full Potential of IoT with Our Tailored Solutions</h1>
              <div className="organization-iot-tech-talent-banner2-card">
                <div>
                <p className="organization-iot-tech-talent-banner2-p">
                From developing smart devices to building integrated IoT
                systems, we deliver customized solutions that enable innovation,
                optimize processes, and drive growth. Let us help you harness the
                power of IoT to turn real-time data into actionable insights and
                accelerate your success in the connected world
                </p>
                <div className="organization-iot-tech-talent-banner2-button">
                  <button onClick={scrollToSolutionsSection}>Get IoT Solutions</button>
                  <button onClick={scrollToTechtalentSection}>Hire IoT Tech Talent</button>
                </div>
                </div>
              <div className="organization-iot-tech-talent-banner2-image">
                <img src={Embeddedsystems2} alt="Embedded Systems" />
              </div>
              </div>
            </div>

            <div ref={solutionsSectionRef} className="organization-iot-tech-talent-banner3">
                <h1>IoT Professional Services Include</h1>
                <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Internet} alt="" className="organization-iot-tech-talent-banner3-card-image1"/>
          <p>Internet of Things</p>
        </div>
        <div className="animated-card">
          <img src={Industrial} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Industrial IoT</p>
        </div>
        <div className="animated-card">
          <img src={IoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>IoT Applications</p>
        </div>
      </div>
      <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Edge} alt="" className="organization-iot-tech-talent-banner3-card-image4"/>
          <p>Edge AI</p>
        </div>
        <div className="animated-card">
          <img src={Industry} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Industry 4.0</p>
        </div>
        <div className="animated-card">
          <img src={AIoT} alt="" className="organization-iot-tech-talent-banner3-card-image6"/>
          <p>AIoT</p>
        </div>
        </div>
            </div>
          </div>
        <div className="organization-iot-tech-talent-banner4">
        <h1 className="organization-iot-tech-talent-banner4-h1">How We Drive Your Success</h1>
        <p className="organization-iot-tech-talent-banner4-div1-p">
          We collaborate with your team to develop innovative IoT and AIoT
          solutions, driving transformation across industries with seamless
          integration of smart technologies. Our expertise in IoT, AIoT, and
          Industry 4.0 enables us to deliver customized systems and solutions
          that break new ground and create limitless possibilities. Together,
          we’ll revolutionize your business, accelerate growth, and ensure you
          lead in the connected world.
        </p>
      <div className="organization-iot-tech-talent-banner4-card">
        <div className="organization-iot-tech-talent-banner4-card-1">
          <p>POC of IoT based Solution</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-2">
          <p>MVP of IoT based Product</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-3">
          <p>Custom Product Development Research</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-4">
          <p>IoT Data Solutions</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-5">
          <p>Hire Tech Talent</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-6">
          <p>Industrial IoT Solutions</p>
        </div>
        </div>
        </div>
          {/* iot */}
          <div className="organization-iot-tech-talent-banner5">
            <h1>Our Technology Expertise Framework</h1>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Data Analytics and Monitoring</h2>
             <div className="organization-iot-tech-talent-banner5-card-protocols">
            <div>
            <img src={Grafana} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Grafana</p>
            </div>
            <div>
            <img src={Kibana} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Kibana</p>
            </div>
            <div>
            <img src={Influxdb} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Influxdb</p>
            </div>
            <div>
            <img src={Powerbi} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Power bi</p>
            </div>
            <div>
            <img src={Tableau} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Tableau</p>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>IoT Hardware</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Raspberrypi} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Raspberrypi</p>
              </div>
              <div>
              <img src={Espressif} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Espressif</p>
              </div>
              <div>
              <img src={Stm32} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Stm32</p>
              </div>
              <div>
              <img src={Texas} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Texas</p>
              </div>
              </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>IoT App Development</h2>
            <div className="organization-iot-tech-talent-banner5-card-protocols">
            <div>
            <img src={Flutter} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Flutter</p>
            </div>
            <div>
            <img src={Flask} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Flask</p>
            </div>
            <div>
            <img src={Mern} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Mern Stack</p>
            </div>
            <div>
            <img src={Thunkable} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Thunkable</p>
            </div>
            <div>
            <img src={Nodered} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Node-Red</p>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Data Science, AI, ML</h2>
             <div className="organization-iot-tech-talent-banner5-card-protocols">
            <div>
            <img src={Numpy} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Numpy</p>
            </div>
            <div>
            <img src={Kafka} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Kafka</p>
            </div>
            <div>
            <img src={Plotly} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Plotly</p>
            </div>
            <div>
            <img src={Learn} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Scikit Learn</p>
            </div>
            <div>
            <img src={Pandas} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Pandas</p>
            </div>
            <div>
            <img src={Mongodb} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Mongodb</p>
            </div>
            <div>
            <img src={Matpltlib} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
            <p>Matpltlib</p>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-2">
              <h2>Programming and Protocols</h2>
             <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Python} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Python</p>
              </div>
              <div>
              <img src={Arduion} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Arduino</p>
              </div>
              <div>
              <img src={Coap} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Coap</p>
              </div>
              <div>
              <img src={Http} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>HTTP</p>
              </div>
              <div>
              <img src={Mqtt} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>MQTT</p>
              </div>
              <div>
              <img src={Javascript} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Javascript</p>
              </div>
              <div>
              <img src={Bluetooth} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Bluetooth</p>
              </div>
               <div>
              <img src={Wifi} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Wifi</p>
              </div>
              <div>
              <img src={Lorawan} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>LoraWAN</p>
              </div>
              <div>
              <img src={Gps} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>GPS</p>
              </div>
              <div>
              <img src={Gsm} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>GSM</p>
              </div>
              </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Cloud Platforms</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Aws} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>AWS</p>
              </div>
              <div>
              <img src={Azure} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure</p>
              </div>
              <div>
              <img src={Hivemq} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Hivemq</p>
              </div>
              <div>
              <img src={Blynk} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Blynk</p>
              </div>
              <div>
              <img src={Thingspeak} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Thingspeak</p>
              </div>
              <div>
              <img src={Thngsboard} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ThingsBoard</p>
              </div>
              <div>
              <img src={Flowfuse} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Flow fuse</p>
              </div>
              <div>
              <img src={N3uron} alt="" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>N3uron</p>
              </div>
              </div>
              {/* <img src={N3uron} alt="" className="organization-iot-tech-talent-banner5-logos"/>
              <img src={Flowfuse} alt="" className="organization-iot-tech-talent-banner5-logos"/> */}
            </div>
            </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner6">
            <h1>Streamlined Process for IoT Solutions and Tech Talent Engagement</h1>
            <p className="organization-iot-tech-talent-banner6-p">Achieve your IoT vision with our end-to-end workflow, seamlessly
              integrating every component—from sensors to data repositories.
              From development to deployment, we ensure flawless execution
              and lasting impact for your business.</p>
          <div className="organization-iot-tech-talent-banner6-steps">
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Discovery and Ideation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step1</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Design and Prototyping</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step2</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Development and Integration</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step3</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Testing and Validation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step4</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Deployment, Monitoring and Feedback</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step5</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Hire Tech Talent</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step6</div>
          </div>
        </div>
        </div>
          <div ref={techtalentSectionRef} className="organization-iot-tech-talent-banner7">
            <h1 className="organization-iot-tech-talent-banner7-h1">Take the Next Step</h1>
            <p className="organization-iot-tech-talent-banner7-p">Whether you're looking to hire expert tech talent or need tailored
            data solutions, we're here to help!</p>
            <div className="organization-iot-tech-talent-banner7-contact">
              <h1>Contact Us</h1>
              <p>Schedule a meeting to discuss your needs</p>
            </div>
            <div className="organization-iot-tech-talent-banner7-btn">
              <div className="organization-iot-tech-talent-banner7-btn1">
              <Link to="/organizations/new-post-project"><button>Post Your Project</button></Link>
              <p>Click here to share your requirements</p>
              </div>
              <div className="organization-iot-tech-talent-banner7-btn1">
                <button>Hire Tech Talent</button>
                {/* <p>Click Here to find the perfect fit for your team</p> */}
                <p>Contact Sales</p>
                <p className="organizations-page-banner8-p">
                  <a 
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@huebits.in" 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      target="_blank"
                      rel="noopener noreferrer" >
                      sales@huebits.in
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner8">
             <p className="organization-iot-tech-talent-banner8-p">
              <FontAwesomeIcon icon={faHandshake} /> Partners
             </p>
             <p className="embedded-system-container5-contact">Write to us for collaboration
             : <span>partnerships@huebits.in</span></p>
            < Partnerships />
          </div>
          <Reveal>
         <div className="organizations-page-banner10">
          <div className="organizations-page-banner10-card">
            <ul className="organizations-page-banner10-ul">
            <li>
            <h1 className="organizations-page-banner10-h1">Business Partnership</h1>
            <p className="organizations-page-banner10-p">Partner with Huebits to Fast-track</p>
            <p className="organizations-page-banner10-p">Talent Engagement and industry Solutions</p>
            </li>
            </ul>
            <div className="organizations-page-banner10-images">
            <img className="organizations-page-banner10-image" src={Footerimage} alt=""/>
            </div>
          </div>
        </div>
        </Reveal>
          <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
          <div className="homepage-footer-copyright">
            <p>© Huebits[2025].All Rights Reserved.</p>
          </div>
          <div className='footer-social-icons'>
              <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
              <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
              <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
              <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
              <Link onClick={scrollToFooterSection}>
              <FaArrowUp className="homepage-social-icon-link" />
              </Link>
          </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;