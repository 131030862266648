import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Organizations from "../../assets/companiesimages/organizations/banner1.png";
// import customers1 from "../../assets/customers1.png";
// import customers2 from "../../assets/customers2.png";
// import customers3 from "../../assets/customers3.jpg";
// import customers4 from "../../assets/customers4.png";
import Icons1 from "../../assets/companiesimages/organizations/icons/1.png";
import Icons2 from "../../assets/companiesimages/organizations/icons/3.png";
import Icons3 from "../../assets/companiesimages/organizations/icons/4.png";
import Icons4 from "../../assets/companiesimages/organizations/icons/5.png";
// import {Companieslearningpaths,Companieslearning} from "./Organizationslearningpaths";
import {Companieslearning} from "./Organizationslearningpaths";
import {Customersreviews} from "./OrganizationsReviews";
//import { BasketSimpleIcon, MeetingIcon, ShareIcon, WebDesignIcon } from "./icons";
import { FaTwitter, FaInstagram,FaLinkedin,FaYoutube, FaArrowUp, FaFacebook } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import PostInternships from "../../assets/companiesimages/organizations/banner7.png";
import { IndustryOrganizations } from "../../mainpage/Homepage/Organizations";
import HireFreshers from "../../assets/companiesimages/organizations/banners7.png";
import Footerimage from "../../assets/companiesimages/organizations/banner10.png";
import Reveal from '../../helper/useScrollposition';
import useNetworkStatus from "../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import "./organizations.css";

const Compaines = () => {
  const compainesSectionRef = useRef(null);
  const hireTechtalentRef = useRef(null);
  const footerSectionRef = useRef(null);
  const { isOnline } = useNetworkStatus();

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToCompainesSection = () => {
    compainesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollTohireTechtalent = () => {
    hireTechtalentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
      if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
        var scriptElement = document.createElement("script");
        scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
        scriptElement.async = true;
        scriptElement.charset = "UTF-8";
        scriptElement.setAttribute("crossorigin", "*");
        
        document.body.appendChild(scriptElement);
      }
    }, []);

    return (
      <div className="students-flex">
        <Headrepage />
        {isOnline ? (
        <div className="homepagecolores">
            <div ref={footerSectionRef} className="organizations-page-banner1">
            <ul className="organizations-page-banner1-ul">
                <li>
                <h1 className="organizations-page-banner1-h1">Accelerating Organizations with <span >Transformative Tech</span>
                </h1>
                {/* <h1 className="organizations-page-banner1-h1">Accelerating Innovation With <span>Next-Gen Tech Talent</span> and<span> Transformative Solutions</span></h1> */}
                  <p className="organizations-page-banner1-p">Empowering with Elite Tech Talent, Cutting-Edge IoT & Data Solutions for Industry 4.0 Excellence
 
                  </p>
                </li>
                <li className="organizations-page-banner1-buttons">
                <button className="organizations-page-banner1-btn" onClick={scrollToCompainesSection}>Post Project
                <FontAwesomeIcon icon={faArrowRight} className="organizations-page-banner1-btn-arrow"/>
                </button>
                <button className="organizations-page-banner1-btn" onClick={scrollTohireTechtalent}>Hire Tech Talent
                <FontAwesomeIcon icon={faArrowRight} className="organizations-page-banner1-btn-arrow"/>
                </button>
                </li>
                </ul>
                <div className="organizations-page-banner1-images">
                <img className="organizations-page-banner1-image" src={Organizations} alt=""/>
                </div>
            </div>
            {/* <div className="organizations-page-banner2">
            <Reveal>
              <h1 className="organizations-page-banner2-h1"><span>Some Of Our Happy Customers</span></h1>
              <div className="organizations-page-banner2-imgs">
              <img className="organizations-page-banner2-customers-imgs" src={customers1} alt="" />
              <img className="organizations-page-banner2-customers-imges" src={customers2} alt="" />
              <img className="organizations-page-banner2-customers-imgs" src={customers3} alt="" />
              <img className="organizations-page-banner2-customers-imgs" src={customers4} alt="" />
              </div>
            </Reveal>
            </div> */}
            <div className="organizations-page-banner3">
              {/* <h1 className="organizations-page-banner3-h1"><span>Industry Solutions: From Problem To Prototype</span></h1> */}
              <h1 className="organizations-page-banner3-h1"><span>Transforming Ideas into Solutions for Organizations
              </span>
              <p className="organizations-page-banner3-p">We offer solutions in the following domains</p></h1>
              {/* <Companieslearningpaths /> */}
              <div className="homepage-banner7-cards"> 
                <IndustryOrganizations />
                </div>
              </div>
              <div className="organizations-page-banner4">
              <h1 className="organizations-page-banner4-h1"><span>Explore The Benefits</span></h1>
                <Companieslearning />
            </div>
            <Reveal>
            <div className="organizations-page-banner5">
              <h1 className="organizations-page-banner5-h1"><span>How It Works</span></h1>
              <div className="organizations-page-banner5-cards">
              <hr className="organizations-page-banner5-cards-icons1-dotted-hr1" />
              <div className="organizations-page-banner5-cards-card1">
              <img src={Icons1} alt="icon1" className="organizations-page-banner5-cards-icons1"/>
              <p className="organizations-page-banner5-cards-card1-p">Submit Your Project</p>
              </div>
              <hr className="organizations-page-banner5-cards-icons1-dotted-hr1" />
              <p className="organizations-page-banner5-cards-icons1-dotted-p">.</p>
              <div className="organizations-page-banner5-cards-card1">
              <img src={Icons2} alt="icon1" className="organizations-page-banner5-cards-icons1"/>
              <p className="organizations-page-banner5-cards-card1-p">Get Feasibility Report</p>
              </div>
              <hr className="organizations-page-banner5-cards-icons1-dotted-hr1" />
              <p className="organizations-page-banner5-cards-icons1-dotted-p">.</p>
              <div className="organizations-page-banner5-cards-card1">
              <img src={Icons3} alt="icon1" className="organizations-page-banner5-cards-icons1"/>
                <p className="organizations-page-banner5-cards-card1-p">Project Execution</p>
              </div>
              <hr className="organizations-page-banner5-cards-icons1-dotted-hr1" />
              <p className="organizations-page-banner5-cards-icons1-dotted-p">.</p>
              <div className="organizations-page-banner5-cards-card1">
              <img src={Icons4} alt="icon1" className="organizations-page-banner5-cards-icons1"/>
              <p className="organizations-page-banner5-cards-card1-p">Project Delivery</p>
              </div>
              </div>
              {/* <div className="brz-img-1-container">
            <ImageComponent />
            </div> */}
            </div>
            </Reveal>
            <div ref={compainesSectionRef} className="organizations-page-banner6">
            <Reveal>
              <h1 className="organizations-page-banner6-h1">Choose Service</h1>
              <div className="organizations-page-banner6-cards">
                  <div className="organizations-page-banner6-cards-card1">
                    <h1 className="organizations-page-banner6-cards-card1-h1">Rapid POC & Product Development</h1>
                    <div className="organizations-page-banner6-cards-card2">
                      <div className="organizations-page-banner6-cards-card3">
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card3-p">
                        Internet of Things</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card3-p">
                        AI and Machine Learning</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card3-p">
                        Generative AI</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card3-p">
                        Data Science Engineering</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card3-p">
                        Embedded Systems</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card3-p">
                        Full Stack Development</p>
                      </div>
                        </div>
                        <div className="organizations-page-banner6-cards-card2-button1">
                        <Link to="/organizations/new-post-project"><button className="organizations-page-banner6-cards-card-bttn">Post Project
                        <FontAwesomeIcon icon={faArrowRight} className="organizations-page-banner6-cards-card-bttn-faArrowRight" />
                        </button></Link>
                        </div>
                    </div>
                    </div>
                    <div className="organizations-page-banner6-cards-card1">                    
                      <h1 className="organizations-page-banner6-cards-card1-h1">Expert Research Consultancy</h1>
                    <div className="organizations-page-banner6-cards-card2">
                      <div className="organizations-page-banner6-cards-card3">
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card6-p">
                        Data Science </p>
                        </div>
                       <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card6-p">
                        Internet of Things</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card6-p">
                        Edge Computing</p>
                      </div>
                      <div className="organizations-page-banner6-cards-card3-card">
                      <p><FontAwesomeIcon icon={faCheck} className="organizations-page-banner6-cards-card3-p-icon" /></p>
                        <p className="organizations-page-banner6-cards-card6-p">
                        Edge AI</p>
                      </div>
                      </div>
                        <div className="organizations-page-banner6-cards-card2-button2">
                        <Link to="/organizations/new-post-project"><button className="organizations-page-banner6-cards-card-bttn">Post Project
                        <FontAwesomeIcon icon={faArrowRight} className="organizations-page-banner6-cards-card-bttn-faArrowRight" />
                        </button></Link>
                        </div>
                    </div>
                    </div>
                   </div>
              </Reveal>
            </div>
            <div className="organizations-page-banner7" ref={hireTechtalentRef}>
            <h1 className="organizations-page-banner7-h1"><span>Hire Tech Talent</span></h1>
            <p className="organizations-page-banner7-p">
            Huebits delivers industry-ready talent that drives innovation and success. 
            Access top professionals equipped with the skills to elevate your business
            </p>
            <div className="organizations-page-banner7-containers">
              <div className="organizations-page-banner7-containers-cards1">
                <div className="organizations-page-banner7-containers-cards-cord">
                 <h1 className="organizations-page-banner7-containers-cards-cord-h1">Post Internships</h1>
              <div>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Unlock Exceptional Talent</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Connect with highly skilled, innovative interns from 100+ top colleges and universities
                </p>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Pre-Assessed & Job-Ready</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Each intern is evaluated with a Hands-on Experience Score, ensuring real-world expertise
                </p>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Fuel Growth & Innovation</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Onboard interns who bring fresh ideas, technical skills, and adaptability to drive your success
                </p>
              </div>
                </div>
                <div className="organizations-page-banner7-containers-cards-cord-image">
                <img src={PostInternships} alt="Post Internships" className="organizations-page-banner7-containers-cards-cord-img"/>
                </div>
                </div>
                <div className="organizations-page-banner7-containers-cards2">
                <div>
                <img src={HireFreshers} alt="Post Internships" className="organizations-page-banner7-containers-cards-cord-imageses"/>
                </div>
                <div className="organizations-page-banner7-containers-cards-cord">
                <h1 className="organizations-page-banner7-containers-cards-cord-h1">Hire Freshers</h1>
                <div>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Pre-Assessed & Industry-Ready</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Select candidates with a Hands-on Experience Score and real-world expertise
                </p>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Hire Through Hackathons</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Identify top talent by evaluating problem-solving and technical skills in action
                </p>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Seamless & Efficient Recruitment</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Streamline hiring by discovering high-potential candidates through competitive challenges
                </p>
                <h6 className="organizations-page-banner7-containers-cards-cord-h6">Innovate & Grow</h6>
                <p className="organizations-page-banner7-containers-cards-cord-p">
                Onboard creative thinkers who bring fresh ideas and technical excellence to drive your organization forward
                </p>
              </div>
              </div>
              <div>
                <img src={HireFreshers} alt="Post Internships" className="organizations-page-banner7-containers-cards-cord-Internships"/>
              </div>
               {/* <img src={hireFreshers} alt="Post Internships" className="organizations-page-banner7-containers-cards-cord-img"/> */}
              </div>
            </div>
            <div className="organizations-page-banner8">
            {/* <h1 className="organizations-page-banner8-h1">Contact Sales</h1>
              <p className="organizations-page-banner8-p">
                  <a 
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@huebits.in" 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      target="_blank"
                      rel="noopener noreferrer" >
                      sales@huebits.in
                  </a>
              </p> */}
              <h1 className="organizations-page-banner8-h1">Contact Sales</h1>
              <p className="organizations-page-banner8-p">
                  <p style={{ textDecoration: 'none', color: 'inherit' }}>sales@huebits.in</p>
              </p>
            </div>
            </div>
            <div className="compaines-creativeproducts-reviews">
                <Reveal>
                  <Customersreviews />
                </Reveal>
            </div>
            <Reveal>
         <div className="organizations-page-banner10">
          <div className="organizations-page-banner10-card">
            <ul className="organizations-page-banner10-ul">
            <li>
            <h1 className="organizations-page-banner10-h1">Business Partnership</h1>
            <p className="organizations-page-banner10-p">Partner with Huebits to Fast-track</p>
            <p className="organizations-page-banner10-p">Talent Engagement and industry Solutions</p>
            </li>
            </ul>
            <div className="organizations-page-banner10-images">
            <img className="organizations-page-banner10-image" src={Footerimage} alt=""/>
            </div>
          </div>
        </div>
        </Reveal>
        <div className="homepage-footers-images">
        <Footer />
        <footer className="homepage-footers">
        <hr className='homepage-footer-hr'/>
        <div className="footer-social">
        <div className="homepage-footer-copyright">
          <p>© Huebits[2025].All Rights Reserved.</p>
        </div>
        <div className='footer-social-icons'>
          <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
          <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
          <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
          <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
          <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
          <Link onClick={scrollToFooterSection}>
          <FaArrowUp className="homepage-social-icon-link" />
          </Link>
        </div>
        </div>
        </footer>
        </div>
        </div>
         ) : (
          <div>
              <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
          </div>
      )}
    </div>
    )
}


export default Compaines;