import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Importing images
import Image5 from "../../assets/AboutUs/image1.png";
import Image6 from "../../assets/AboutUs/image2.png";
import Image3 from "../../assets/AboutUs/image3.png";
import Image4 from "../../assets/AboutUs/image4.png";
import Image1 from "../../assets/AboutUs/image6.jpg";
import Image2 from "../../assets/AboutUs/image5.jpg";

// Images array
const images = [
  Image1, Image2,Image3, Image4, Image5, Image6
];

const Aboutusimages = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to open image in modal
  const openImage = (image) => {
    setSelectedImage(image);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="aboutus-slider-container">
      <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={3} // Default for larger screens
          breakpoints={{
            320: { slidesPerView: 1 }, // 1 slide on small screens (phones)
            768: { slidesPerView: 2 }, // 2 slides on tablets
            1024: { slidesPerView: 3 }, // 3 slides on desktops
          }}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
        >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Course ${index + 1}`}
              className="slider-image"
              onClick={() => openImage(image)} // Open modal on click
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Modal Popup */}
      {selectedImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Selected Course" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Aboutusimages;
