import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, Box, Typography, Pagination, useMediaQuery } from "@mui/material";
//import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Course1 from "../../../assets/learnersCourses/iotCourses/introductiontoInternetofThings.png";
import Course2 from "../../../assets/learnersCourses/iotCourses/masterESP32BluetoothandBLEHandsOnProjectsforIoTCommunication.png";
import Course3 from "../../../assets/learnersCourses/iotCourses/dataVisualizationforIoTPowerBITableauGrafanaandKibana.png";
import Course4 from "../../../assets/learnersCourses/iotCourses/ioTusingRaspberryPiandPython.png";
import Course5 from "../../../assets/learnersCourses/iotCourses/mING TechnologiesinIndustrialIoT.png";
import Course6 from "../../../assets/learnersCourses/iotCourses/developingWebApplicationsforIoTUsingPythonandJavaScript.png";
import Course7 from "../../../assets/learnersCourses/iotCourses/ioTDataInsights withKafkaRealtimeAnalytics.png";
import Course8 from "../../../assets/learnersCourses/iotCourses/ioTDataAnalyticsUsingPython.png";
import Course9 from "../../../assets/learnersCourses/iotCourses/fullStackIoTJSMERNRaspberryPi.png";
import Course10 from "../../../assets/learnersCourses/iotCourses/ioTandEmbeddedSystemswithESP32.png";
import Course11 from "../../../assets/learnersCourses/iotCourses/microsoftAzureIoTCertification.png";
import Course12 from "../../../assets/learnersCourses/iotCourses/aWSIoTCertificationTraining.png";
import Course13 from "../../../assets/learnersCourses/iotCourses/pythonProgramming.png";
import Course14 from "../../../assets/learnersCourses/iotCourses/masterWIFIcommunication.png";
import Course15 from "../../../assets/learnersCourses/iotCourses/longdistancewirelessnetworksCellularLoRaNBIOT.png";
import Course16 from "../../../assets/learnersCourses/iotCourses/masteringMQTT.png";
import Course17 from "../../../assets/learnersCourses/iotCourses/masterNode-RedfromBasictoAdvanced.png";
import Course18 from "../../../assets/learnersCourses/iotCourses/industrialInternetofThings.png";
import Course19 from "../../../assets/learnersCourses/iotCourses/edgeComputing.png";
import Course20 from "../../../assets/learnersCourses/iotCourses/artificialIntelligenceofThings.png";

//projects
import Project1 from "../../../assets/learnersCourses/iotCourses/projects/smartEnergyMonitoringSystem.png";
import Project2 from "../../../assets/learnersCourses/iotCourses/projects/smartHealthMonitoringwithIoTandMernStack.png";
import Project3 from "../../../assets/learnersCourses/iotCourses/projects/smartWasteManagementusingIoTandMLwithESP32andAWS.png";
import Project4 from "../../../assets/learnersCourses/iotCourses/projects/aiVoiceAssistant.png";
import Project5 from "../../../assets/learnersCourses/iotCourses/projects/machineLearninginAWSforIoTbased.png";
import Project6 from "../../../assets/learnersCourses/iotCourses/projects/indoorOccupancyDetectionBased.png";
import Project7 from "../../../assets/learnersCourses/iotCourses/projects/remoteHealthMonitoring.png";
import Project8 from "../../../assets/learnersCourses/iotCourses/projects/developingIoTBasedSmart.png";
// import Project9 from "../../../assets/Courses/iotprojects/30.png";
import Project10 from "../../../assets/learnersCourses/iotCourses/projects/elderlyfallDetectonsystemusingIOT.png";
import Project11 from "../../../assets/learnersCourses/iotCourses/projects/smartShoeforBlindusingESP32Project.png";
import Project12 from "../../../assets/learnersCourses/iotCourses/projects/smartShoeDevelopmentusingESP32andBlynk.png";
import Project13 from "../../../assets/learnersCourses/iotCourses/projects/smartAirQualityMonitoringSystemUsingESP32.png";
import Project14 from "../../../assets/learnersCourses/iotCourses/projects/googleLookerStudio.png";
import Project15 from "../../../assets/learnersCourses/iotCourses/projects/Agriculture.png";
import Project16 from "../../../assets/learnersCourses/iotCourses/projects/smartEnergyAnalyticsusingIoTandPowerBI.png";
import Project17 from "../../../assets/learnersCourses/iotCourses/projects/smartIrrigationSystemusingESP32.png";
import Project18 from "../../../assets/learnersCourses/iotCourses/projects/smartCarParkingSystemusingESP32.png";
import Project19 from "../../../assets/learnersCourses/iotCourses/projects/smartHomeusingESP32andBlynk.png";
import Project20 from "../../../assets/learnersCourses/iotCourses/projects/anomalyDetection.png";
import Project21 from "../../../assets/learnersCourses/iotCourses/projects/machineHealthMonitoring.png";
import Project22 from "../../../assets/learnersCourses/iotCourses/projects/energyMonitoringusingEmoncms.png";
import Project23 from "../../../assets/learnersCourses/iotCourses/projects/iotPoweredSmartEnergy.png";
import Project24 from "../../../assets/learnersCourses/iotCourses/projects/smartLightBulbControllingonRaspberryPiUsingFlaskWebApp.png";
import Project25 from "../../../assets/learnersCourses/iotCourses/projects/smartAgricultureusingInternet.png";
import Project26 from "../../../assets/learnersCourses/iotCourses/projects/smartStreetLightingusingIoTProject.png";
import Project27 from "../../../assets/learnersCourses/iotCourses/projects/iotBasedSmartFeeder.png";
import Project28 from "../../../assets/learnersCourses/iotCourses/projects/mqttbasedEnvironmentMonitoring.png";
// import Project29 from "../../../assets/Courses/iotprojects/84.png";

const courses = [
    { id: 1, img: Course1, title: "Introduction to Internet of Things"},
    { id: 2, img: Course2, title: "Master ESP32, Bluetooth, and BLE: Hands-On Projects for IoT Communication" },
    { id: 3, img: Course3, title: "Data Visualization for IoT: PowerBI, Tableau, Grafana, and Kibana" },
    { id: 4, img: Course4, title: "IoT using Raspberry Pi and Python" },
    { id: 5, img: Course5, title: "MING Technologies in Industrial IoT" },
    { id: 6, img: Course6, title: "Developing Web Applications for IoT Using Python and JavaScript" },
    { id: 7, img: Course7, title: "IoT Data Insights with Kafka: Real-time Analytics" },
    { id: 8, img: Course8, title: "IoT Data Analytics Using Python" },
    { id: 9, img: Course9, title: "Full Stack IoT Development with JavaScript, MERN Stack, and Raspberry Pi" },
    { id: 10, img: Course10, title: "IoT and Embedded Systems with ESP32" },
    { id: 11, img: Course11, title: "Microsoft Azure IoT Certification" },
    { id: 12, img: Course12, title: "AWS IoT Certification Training" },
    { id: 13, img: Course13, title: "Python Programming" },
    { id: 14, img: Course14, title: "Master WIFI communication" },
    { id: 15, img: Course15, title: "Long distance wireless networks: Cellular, LoRa, NBIOT" },
    { id: 16, img: Course16, title: "Mastering MQTT" },
    { id: 17, img: Course17, title: "Master Node-Red from Basic to Advanced" },
    { id: 18, img: Course18, title: "Industrial Internet of Things" },
    { id: 19, img: Course19, title: "Edge Computing" },
    { id: 20, img: Course20, title: "Artificial Intelligence of Things" },//30
    // { id: 20, img: Course20, title: "Artificial Intelligence of Things" },
];

const projects = [
    { id: 1, img: Project1, title: "Smart Energy Monitoring System" },//3
    { id: 2, img: Project2, title: "Smart Health Monitoring with IoT and Mern Stack" },//6
    { id: 3, img: Project3, title: "Smart Waste Management using IoT and ML with ESP32 and AWS" },//9
    { id: 4, img: Project4, title: "AI Voice Assistant using Raspberry Pi and Gen AI" },//11
    { id: 5, img: Project5, title: "Machine Learning in AWS for IoT-based Oil Pipeline Monitoring System" },//16
    { id: 6, img: Project6, title: "Indoor Occupancy Detection Based on Environmental Data using Tableau" },//18
    { id: 7, img: Project7, title: "Remote Health Monitoring and Protection of Transformers Using IoT" },//23
    { id: 8, img: Project8, title: "Developing IoT-Based Smart Workspace using Telegram AI Chatbot" },//25
    { id: 10, img: Project10, title: "Elderly fall Detection system using IOT" },//33
    { id: 11, img: Project11, title: "Smart Shoe for Blind using ESP32 Project" },//35
    { id: 12, img: Project12, title: "Smart Shoe Development using ESP32 and Blynk" },//36
    { id: 13, img: Project13, title: "Smart Air Quality Monitoring System Using ESP32 and Node-Red" },//39
    { id: 14, img: Project14, title: "Real Time Health Monitoring Dashboard using IoT and Google Looker Studio" },//41
    { id: 15, img: Project15, title: "Agriculture Monitoring using IoT and Power BI" },//45
    { id: 16, img: Project16, title: "Smart Energy Analytics using IoT and Power BI" },//47
    { id: 17, img: Project17, title: "Smart Irrigation System using ESP32" },//49
    { id: 18, img: Project18, title: "Smart Car Parking System using ESP32 and Arduino" },//52
    { id: 19, img: Project19, title: "Smart Home using ESP32 and Blynk" },//55
    { id: 20, img: Project20, title: "Anomaly detection in the temperature of an ac motor using embedded machine learning" },//60
    { id: 21, img: Project21, title: "Machine Health Monitoring using Power BI" },//62
    { id: 22, img: Project22, title: "Energy Monitoring using Emoncms" },//66
    { id: 23, img: Project23, title: "IoT-Powered Smart Energy Meter Surveillance" },//70
    { id: 24, img: Project24, title: "Smart Light Bulb Controlling on Raspberry Pi Using Flask Web App" },//71
    { id: 25, img: Project25, title: "Smart Agriculture using Internet of Things and MQTT Protocol" },//73
    { id: 26, img: Project26, title: "Smart Street Lighting using IoT Project" },//75
    { id: 27, img: Project27, title: "IOT Based Smart Feeder Monitoring System" },//77
    { id: 28, img: Project28, title: "MQTT based Environment Monitoring in factories for Employee Safety" },//81
    // { id: 29, img: Project29, title: "Smart Home Energy Monitoring System using Thingspeak" },//84
];

const InternetCourses = () => {
    const [currentPage, setCurrentPage] = useState(1);
      const [itemsPerPage, setItemsPerPage] = useState(8);
    
      const isPhone = useMediaQuery("(max-width: 480px)");
      //const isTablet = useMediaQuery("(max-width: 768px)");
    
      useEffect(() => {
        const updateItemsPerPage = () => {
          if (window.innerWidth <= 480) {
            setItemsPerPage(2);
          } else if (window.innerWidth <= 768) {
            setItemsPerPage(4);
          } else {
            setItemsPerPage(8);
          }
        };
    
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
      }, []);
    
      const totalPages = Math.ceil(courses.length / itemsPerPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = courses.slice(startIndex, startIndex + itemsPerPage);
    
      return (
        <Box>
          {/* Course Grid */}
          <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop="2px">
            {currentItems.map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id} display="flex" justifyContent="center">
                <Card sx={{ width: 280, height: 260, borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia component="img" height="170px" image={course.img} alt={course.title} />
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="h6" fontSize={14} textAlign="start">
                      {course.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ArrowBack />
            </IconButton> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              siblingCount={isPhone ? 0 : 1} // 0 on phones, 1 on tablets/desktops
              boundaryCount={isPhone ? 1 : 2} // Show first/last pages
              size={isPhone ? "small" : "medium"} // Smaller pagination on phones
            />
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ArrowForward />
            </IconButton> */}
          </Box>
        </Box>
      );
    };

//projects
const IotProjects = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    
    const isPhone = useMediaQuery("(max-width: 480px)");
    //const isTablet = useMediaQuery("(max-width: 768px)");
    
      useEffect(() => {
        const updateItemsPerPage = () => {
          if (window.innerWidth <= 480) {
            setItemsPerPage(2);
          } else if (window.innerWidth <= 768) {
            setItemsPerPage(4);
          } else {
            setItemsPerPage(8);
          }
        };
    
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
      }, []);
    
      const totalPages = Math.ceil(projects.length / itemsPerPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = projects.slice(startIndex, startIndex + itemsPerPage);
    
      return (
        <Box>
          {/* Course Grid */}
          <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop="4px">
            {currentItems.map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id} display="flex" justifyContent="center">
                <Card sx={{ width: 280, height: 260, borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia component="img" height="170px" image={course.img} alt={course.title} />
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="h6" fontSize={14} textAlign="start">
                      {course.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ArrowBack />
            </IconButton> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              siblingCount={isPhone ? 0 : 1} // 0 on phones, 1 on tablets/desktops
              boundaryCount={isPhone ? 1 : 2} // Show first/last pages
              size={isPhone ? "small" : "medium"} // Smaller pagination on phones
            />
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ArrowForward />
            </IconButton> */}
          </Box>
        </Box>
      );
    };

export { InternetCourses, IotProjects};