import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { OrganizationsCompaniesprofile } from "../../helper/base_url";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Divider } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download'; // Import the download icon
import jsPDF from 'jspdf';

export const CompaniesDashboard = () => {
    const [postProjects, setPostProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // Track the current page
    const itemsPerPage = 1; // Display one project at a time

    // Fetch data on component mount
    useEffect(() => {
        const fetchPostProjects = async () => {
            try {
                const response = await axios.post(`${OrganizationsCompaniesprofile}`, {}, {
                    headers: {
                        "content-type": "application/json",
                        authorization: `jwt ${localStorage.getItem('company_token')}`
                    },
                });
                setPostProjects(response.data.data.postprojects);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchPostProjects();
    }, []);

    // Handle the "Next" button click
    const handleNextPage = () => {
        if ((currentPage + 1) * itemsPerPage < postProjects.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle the "Previous" button click
    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Get the current set of items to display
    const currentItems = postProjects.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // Function to download the current project as a PDF
    const downloadCurrentProjectAsPDF = () => {
        const doc = new jsPDF();
        const project = currentItems[0]; // Get the current project

        doc.setFontSize(16);
        doc.text("Project Data", 10, 10);
        doc.setFontSize(12);

        let yPosition = 20;

        if (project.developmentType) {
            doc.text(`Development: ${project.developmentType}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.developmentIn) {
            doc.text(`Development Skill: ${project.developmentIn}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.projectTitle) {
            doc.text(`Project Title: ${project.projectTitle}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.industry) {
            doc.text(`Industry: ${project.industry}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.skills) {
            doc.text(`Skills: ${project.skills}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.programming) {
            doc.text(`Programming: ${project.programming}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.duration) {
            doc.text(`Duration: ${project.duration}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.budget) {
            doc.text(`Budget: ${project.budget}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.description) {
            doc.text(`Description: ${project.description}`, 10, yPosition);
            yPosition += 10;
        }
        if (project.s3Url) {
            doc.textWithLink("Project PDF Link", 10, yPosition, { url: project.s3Url });
            yPosition += 10;
        }

        doc.save(`project_${project.projectTitle || 'data'}.pdf`); // Save the PDF with a name based on the project title
    };

    return (
        <Box sx={{ backgroundColor: '#f4f6f8', padding: '60px 0px 40px 0px' }}>
            <Box sx={{display: "flex", justifyContent: 'space-between', alignItems: 'center', margin: '0px 20px' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: '#052659' }}>
                Pending Projects
            </Typography>
            </Box>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', color: '#052659' }}>Field</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: '#052659' }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.map((project) => (
                            <>
                                {project.developmentType && (
                                    <TableRow key={`${project._id}-title`}>
                                        <TableCell><strong>Development:</strong></TableCell>
                                        <TableCell>{project.developmentType}</TableCell>
                                    </TableRow>
                                )}
                                {project.developmentIn && (
                                    <TableRow key={`${project._id}-skill`}>
                                        <TableCell><strong>Development Skill:</strong></TableCell>
                                        <TableCell>{project.developmentIn}</TableCell>
                                    </TableRow>
                                )}
                                {project.projectTitle && (
                                    <TableRow key={`${project._id}-projectTitle`}>
                                        <TableCell><strong>Project Title:</strong></TableCell>
                                        <TableCell>{project.projectTitle}</TableCell>
                                    </TableRow>
                                )}
                                {project.industry && (
                                    <TableRow key={`${project._id}-industry`}>
                                        <TableCell><strong>Industry:</strong></TableCell>
                                        <TableCell>{project.industry}</TableCell>
                                    </TableRow>
                                )}
                                {project.skills && (
                                    <TableRow key={`${project._id}-skills`}>
                                        <TableCell><strong>Skills:</strong></TableCell>
                                        <TableCell>{project.skills}</TableCell>
                                    </TableRow>
                                )}
                                {project.programming && (
                                    <TableRow key={`${project._id}-programming`}>
                                        <TableCell><strong>Programmings:</strong></TableCell>
                                        <TableCell>{project.programming}</TableCell>
                                    </TableRow>
                                )}
                                {project.duration && (
                                    <TableRow key={`${project._id}-duration`}>
                                        <TableCell><strong>Duration:</strong></TableCell>
                                        <TableCell>{project.duration}</TableCell>
                                    </TableRow>
                                )}
                                {project.budget && (
                                    <TableRow key={`${project._id}-budget`}>
                                        <TableCell><strong>Budget:</strong></TableCell>
                                        <TableCell>{project.budget}</TableCell>
                                    </TableRow>
                                )}
                                {project.description && (
                                    <TableRow key={`${project._id}-description`}>
                                        <TableCell><strong>Project Description:</strong></TableCell>
                                        <TableCell>{project.description}</TableCell>
                                    </TableRow>
                                )}
                               {/* {project.s3Url && (
                                    <TableRow key={`${project._id}-s3Url`}>
                                        <TableCell><strong>Project File:</strong></TableCell>
                                        <TableCell>
                                            <a href={project.s3Url} target="_blank" rel="noopener noreferrer">
                                                View PDF
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )} */}
                                <Button
                                variant="contained"
                                onClick={downloadCurrentProjectAsPDF}
                                sx={{
                                    backgroundColor: '#052659',
                                    color: '#fff',
                                    margin: '20px',
                                    textTransform: 'none',
                                }}
                                disabled={currentItems.length === 0}
                                endIcon={<DownloadIcon />}
                            >
                                Project Download
                            </Button>
                                <TableRow key={`${project._id}-divider`}>
                                    <TableCell colSpan={2}>
                                        <Divider sx={{ marginY: 1, backgroundColor: '#ccc' }} />
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Navigation Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 4 }}>
                <Button
                    variant="contained"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                    sx={{
                        backgroundColor: '#052659',
                        color: '#fff',
                        marginRight: 2,
                        '&:disabled': {
                            backgroundColor: '#B0BEC5', // Disabled button color
                        },
                    }}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNextPage}
                    disabled={(currentPage + 1) * itemsPerPage >= postProjects.length}
                    sx={{
                        backgroundColor: '#052659',
                        color: '#fff',
                        '&:disabled': {
                            backgroundColor: '#B0BEC5', // Disabled button color
                        },
                    }}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default CompaniesDashboard;