import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import IndustryReadyIoTProgram from "../../assets/homepage/courses/industryReadyIoTProgram.png";
import MachineLearning from "../../assets/homepage/courses/aiMachineLearning.png";
//import Generative from "../../assets/homepage/banner3/5.png";
import IndustryReadyAIandDataScienceProgram from "../../assets/homepage/courses/industryReadyDataScienceProgram.png";
import IndustryReadyEmbeddedSystemsProgram from "../../assets/homepage/courses/industryReadyEmbeddedSystemsProgram.png";
//
import Internet1 from "../../assets/homepage/courses/internetofThings.png";
import Machine2 from "../../assets/homepage/courses/machineLearning.png";
import DataScience3 from "../../assets/homepage/courses/dataScienceEngineering.png";
import EmbeddedSystems4 from "../../assets/homepage/courses/embeddedSystems.png";

const courses = [
  { img: IndustryReadyIoTProgram, title: "Internet of Things", description1:"2.08 Lakh+ Opportunities", description2:"Get Placed with ₹ 4-10 LPA", path: "/learners/internet-of-things" },
  { img: MachineLearning, title: "AI and Machine Learning", description1:"1.5 Lakh+ Opportunities", description2:"Get Placed with ₹ 5-12 LPA", path: "/learners/data-science" },
    // { img: Generative, title: "Generative AI", path: "/datascience" },
  { img: IndustryReadyAIandDataScienceProgram, title: "Data Science and Engineering", description1:"1.5 Lakh+ Opportunities", description2:"Get Placed with ₹ 6-15 LPA", path: "/learners/data-science" },
  { img: IndustryReadyEmbeddedSystemsProgram, title: "Embedded Systems", description1:"1.2 Lakh+ Opportunities", description2:"Get Placed with ₹ 4-9 LPA", path: "/learners/embedded-systems" }
];

const IndustryCourses = () => {
  const navigate = useNavigate(); // For navigation

  const handleCardClick = (path) => {
    console.log(`Navigating to ${path}`);
    navigate(path); // Redirects to course page
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap",alignItems: 'center',justifyContent: 'center' }}>
      {courses.map((course, index) => (
        <Card
          key={index}
          sx={{
            maxWidth: 500,
            borderRadius: 2,
            padding: "6px",
            flex: "1 1 240px",
            border: "1px solid rgba(220, 220, 220, 0.24)",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out",
            margin: "10px",
            "&:hover": {
              boxShadow: 6,
              transform: "scale(1.03)",
              backgroundColor: "#f0f7ff"
            }
          }}     
          onClick={() => handleCardClick(course.path)}
        >
          <CardMedia component="img" height="180" image={course.img} alt={course.title} sx={{borderRadius: "10px"}} />
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{fontSize: "0.9rem"}}>
              {course.title}
            </Typography>

            {/* Displaying Icons with Descriptions */}
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <FontAwesomeIcon icon={faBriefcase} color="#1976d2" />
              <Typography variant="body2" color="text.secondary">
                {course.description1}
              </Typography>
            </Box>
            
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <FontAwesomeIcon icon={faIndianRupeeSign} color="#1976d2" />
              <Typography variant="body2" color="text.secondary">
                {course.description2}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mt={2}>
              <Link 
                to={course.path} 
                style={{ 
                  textDecoration: "none", 
                  display: "flex", 
                  alignItems: "center", 
                  color: "#1976d2", 
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "1px solid #1976d2",
                  borderRadius: "4px",
                  padding: "6px 12px",
                  transition: "0.3s ease",
                }}
              >
                Learn More
                <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: "6px" }} />
              </Link>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const Organizationscourses = [
    { img: Internet1, title: "Internet of Things", path: "/organizations/internet-of-things" },
    { img: Machine2, title: "AI and Machine Learning", path: "/organizations/data-science" },
    { img: DataScience3, title: "Data Science Engineering", path: "/organizations/data-science" },
    { img: EmbeddedSystems4, title: "Embedded Systems", path: "/organizations/embedded-systems" },
];

const OrganizationsIndustry = () => {
  const navigate = useNavigate(); // For navigation

  const handleCardClick = (path) => {
    console.log(`Navigating to ${path}`);
    navigate(path); // Redirects to course page
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap",alignItems: 'center',justifyContent: 'center' }} >
      {Organizationscourses.map((course, index) => (
        <Card
          key={index}
          sx={{
            maxWidth: 500,
            borderRadius: 2,
            padding: "6px",
            flex: "1 1 240px",
            border: "1px solid rgba(220, 220, 220, 0.24)",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out",
            margin: "10px",
            "&:hover": {
              boxShadow: 6,
              transform: "scale(1.03)",
              backgroundColor: "#f0f7ff"
            }
          }}     
          onClick={() => handleCardClick(course.path)}
        >
          <CardMedia component="img" height="180" image={course.img} alt={course.title} sx={{borderRadius: "10px"}} />
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{fontSize: "0.9rem"}}>
              {course.title}
            </Typography>

            <Box display="flex" alignItems="center" mt={2}>
              <Link 
                to={course.path} 
                style={{ 
                  textDecoration: "none", 
                  display: "flex", 
                  alignItems: "center", 
                  color: "#1976d2", 
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "1px solid #1976d2",
                  borderRadius: "4px",
                  padding: "6px 12px",
                  transition: "0.3s ease",
                }}
              >
                Know More
                <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: "6px" }} />
              </Link>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export {IndustryCourses, OrganizationsIndustry};
