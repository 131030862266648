import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { ExitToApp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LearnersProfile } from "../../../helper/base_url";

const LearnersHeader = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [data, setData] = useState({ username: "", displayName: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const token = localStorage.getItem("token");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Detect phone & tablets

  const toggleMenu = () => setMenuActive(!menuActive);
  const closeMenu = () => setMenuActive(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };  

  const handleUserProfile = useCallback(async () => {
    try {
      const response = await axios.post(
        `${LearnersProfile}`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `jwt ${token}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile", error);
    }
  }, [token]);

  useEffect(() => {
    if (token) handleUserProfile();
  }, [token, handleUserProfile]);

  const getFirstLetter = (name) => (name ? name.charAt(0).toUpperCase() : "");

  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("clickedPaths");
    navigate("/learners");
  };

  const location = useLocation();

  const menuItems = [
    { label: "Home", path: "/" },
    { label: "Learners", path: "/learners" },
    { label: "Dashboard", path: "/learners/dashboardpage" },
  ];

  return (
    <AppBar position="static" sx={{ bgcolor: "#ffff", boxShadow: "none" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 2, sm: 6, md: 6 },
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* Brand Name */}
        <Typography
          variant="h1"
          sx={{
            fontSize: "40px",
            fontWeight: "700",
            color: "rgb(2, 41, 100)",
            textDecoration: "none",
          }}
          component={Link}
          to="/learners"
        >
          Hue<span style={{ color: "rgb(27, 110, 244)" }}>bits</span>
        </Typography>

        {/* Mobile Menu Button */}
        {isSmallScreen && (
          <IconButton
            edge="end"
            onClick={toggleMenu}
            sx={{ color: "rgb(2, 41, 100)" }}
          >
            <MenuOutlinedIcon fontSize="large" />
          </IconButton>
        )}

        {/* Desktop Menu */}
        {!isSmallScreen && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            {menuItems.map((item, index) => {
              const isActive = location.pathname === item.path;
              return (
                <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
                  <Box
                    sx={{
                      color: isActive ? "#1c84e4" : "black",
                      fontWeight: 400,
                      fontSize: "16px",
                      padding: "6px",
                      borderRadius: "4px",
                      // backgroundColor: isActive ? "#1c84e4" : "transparent",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "#1c84e4",
                        color: "#ffffff",
                      },
                    }}
                  >
                    {item.label}
                  </Box>
                </Link>
              );
            })}

            {/* Profile Icon */}
            <IconButton
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              width: 42,
              height: 42,
              borderRadius: "50%",
              backgroundColor: "#1c84e4",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "18px",
              transition: "0.3s",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
          >
            {getFirstLetter(data.displayName || data.username)}
          </IconButton>

          {/* Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose} // Use the defined function here
            onMouseLeave={handleMenuClose} // Closes menu when moving cursor away
            MenuListProps={{
              onMouseLeave: handleMenuClose, // Closes menu when moving cursor away
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ margin: "10px 0px 0px 40px" }}
          >
            <MenuItem onClick={handleLogOut} sx={{ color: "black", fontWeight: 500 }}>
              <ExitToApp sx={{ marginRight: 1 }} /> Logout
            </MenuItem>
          </Menu>
          </Box>
        )}
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{ "& .MuiDrawer-paper": { bgcolor: "#ffff", width: 250 } }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={closeMenu}
            sx={{
              color: "#052659",
              padding: "4px 16px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Mobile Menu Items */}
      <Box sx={{ padding: 2, textAlign: "center" }}>
        {/* Profile Section in Drawer */}
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
        <IconButton
            sx={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              backgroundColor: "#1c84e4",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "22px",
              mb: 1,
              transition: "0.3s ease-in-out",
              "&:hover": { backgroundColor: "#1565c0", transform: "scale(1.1)" }, // Desktop Hover
              "&:focus": { backgroundColor: "#1565c0", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }, // Tap Effect
              "&:active": { transform: "scale(0.95)", backgroundColor: "#0d47a1" }, // Mobile Tap Effect
            }}
          >
            {getFirstLetter(data.displayName || data.username)}
          </IconButton>

          <Typography variant="h6" sx={{ fontWeight: 600, color: "#052659" }}>
            {data.displayName || ""}
          </Typography>
          <Typography variant="body2" sx={{ color: "#7a7a7a" }}>
            {data.username || "example@email.com"}
          </Typography>
        </Box>

        {/* Navigation Links */}
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={closeMenu}
            component={Link}
            to={item.path}
            sx={{
              color: location.pathname === item.path ? "#1c84e4" : "#052659",
              fontWeight: 500,
              fontSize: "16px",
              borderRadius: "8px",
              padding: "12px",
              marginBottom: "8px",
              transition: "0.3s",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          >
            {item.label}
          </MenuItem>
        ))}

        {/* Logout Button */}
        <MenuItem
          onClick={handleLogOut}
          sx={{
            color: "#052659",
            fontWeight: 600,
            fontSize: "16px",
            borderRadius: "8px",
            padding: "12px",
            marginTop: "12px",
            transition: "0.3s",
          }}
        >
          <ExitToApp sx={{ marginRight: 1 }} /> Logout
        </MenuItem>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default LearnersHeader;
