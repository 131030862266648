import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import { Button, CircularProgress, Typography, Box, Paper, Chip, Container, LinearProgress } from "@mui/material";
import OrganizationsHeader from "../PopupOrganizations/topbar";

const Postproject4 = () => {
  const navigate = useNavigate();
  const [selectedSkillsArray, setSelectedSkillsArray] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(50);
  const [loading, setLoading] = useState(false);
  const postDataSelectRef = useRef(null);

  const handlePostDataChange = useCallback(() => {
    const selectedSkill = postDataSelectRef.current.value.trim();
    if (selectedSkill) {
      if (selectedSkillsArray.length < 9) {
        if (!selectedSkillsArray.includes(selectedSkill)) {
          const newSkillsArray = [...selectedSkillsArray, selectedSkill];
          setSelectedSkillsArray(newSkillsArray);
          postDataSelectRef.current.value = ""; // Reset the dropdown
        }
      } else {
        toast.error("You can select a maximum of 9 skills.");
      }
    }
  }, [selectedSkillsArray]);

  useEffect(() => {
    const postDataSelect = postDataSelectRef.current;
    if (postDataSelect) {
      postDataSelect.addEventListener("change", handlePostDataChange);
      return () => {
        postDataSelect.removeEventListener("change", handlePostDataChange);
      };
    }
  }, [handlePostDataChange]);

  const handleSkillDelete = (skillToDelete) => {
    const updatedSkills = selectedSkillsArray.filter(skill => skill !== skillToDelete);
    setSelectedSkillsArray(updatedSkills);
  };

  const validateSkills = () => {
    if (selectedSkillsArray.length === 0) {
      toast.error("Please select at least one skill.");
      return false;
    }
    if (selectedSkillsArray.length > 9) {
      toast.error("You can select a maximum of 9 skills.");
      return false;
    }
    return true;
  };

   // Display selected skills in the console
   //console.log("Selected Skills:", selectedSkillsArray);
   localStorage.setItem('selectedSkillsArray', JSON.stringify(selectedSkillsArray));
   
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateSkills()) {
      return;
    }

    setLoading(true);

    // Removed backend logic for API request

    toast.success("Skills selected successfully!");
    setCompletionPercentage(prevPercentage => prevPercentage + 20);
    setTimeout(() => {
      navigate("/organizations/project-timeline", { state: { selectedSkillsArray }});
    }, 1500);
    
    setLoading(false);
  };

  return (
    <div className="students-flex">
      <OrganizationsHeader />
      <div className="signup-pages-flex">
         <Box
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
            padding: "20px 10px",
            height: { xs: "100vh", sm: "100vh", md: "100vh" },
            }}
            >
          <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
          <Box sx={{ marginTop: -10 }}>
            <Container maxWidth="sm">
              <Paper elevation={4} sx={{ borderRadius: 2, textAlign: "center", padding: "10px 20px 30px 20px" }} >
                <form action="/postproject4" method="POST" id="lable" onSubmit={handleSubmit}>
                  <Typography variant="h5" style={{ color: "#052659" }}>
                    Which Programming languages do you need?
                  </Typography>
                  <Typography color="textSecondary" sx={{ padding: "4px" }}>
                    Tell us which languages you'd like your dedicated freelancer to use.
                  </Typography>
                  <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <select id="postData" ref={postDataSelectRef}
                      style={{
                        width: '100%',
                        padding: '10px 15px',
                        fontSize: '16px',
                        fontFamily: 'Arial, sans-serif',
                        border: '2px solid #ddd',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                        marginTop: "10px",
                        color: '#333',
                        outline: 'none',
                        cursor: 'pointer',
                        boxSizing: 'border-box',
                        transition: 'border-color 0.3s ease, background-color 0.3s ease',
                      }}
                      onFocus={(e) => e.target.style.borderColor = '#5c6bc0'}
                      onBlur={(e) => e.target.style.borderColor = '#ddd'}
                    >
                      <option value="">Select languages</option>
                      <option>Python</option>
                      <option>C/C++</option>
                      <option>Arduino</option>
                      <option>JavaScript</option>
                      <option>Lua</option>
                      <option>Nodejs</option>
                      <option>node-red</option>
                      <option>flutter</option>
                      <option>R</option>
                      <option>SQL</option>
                      <option>Scala</option>
                      <option>Julia</option>
                      <option>CUDA (for GPU acceleration)</option>
                      <option>TensorFlow</option>
                      <option>PyTorch</option>
                      <option>ROS (Robot Operating System)</option>
                      <option>MATLAB</option>
                      <option>Go</option>
                      <option>Rust</option>
                    </select>
                  </Box>
                  <Box sx={{ marginY: 1 }}>
                    {selectedSkillsArray.map((skill, index) => (
                      <Chip key={index}
                        label={skill}
                        onDelete={() => handleSkillDelete(skill)}
                        color="primary"
                        sx={{ margin: 0.5,
                          backgroundColor: "#052659",
                          color: "#ffff"
                         }} 
                      />
                    ))}
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
                    <Button
                      component={Link}
                      to="/organizations/select-industry"
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                      disabled={loading}
                      sx={{
                        margin: 0.5,
                        color: "#052659", // Ensures text is readable
                        "& .MuiChip-deleteIcon": {
                          color: "#052659",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={loading ? <CircularProgress size={16} /> : <FontAwesomeIcon icon={faArrowRight} />}
                      disabled={loading}
                      sx={{
                        margin: 0.5,
                        backgroundColor: "#043885",
                        "&:hover": {
                          backgroundColor: "#052659",
                        },
                      }}
                    >
                      {loading ? "Submitting..." : "Next"}
                    </Button>
                  </Box>
                </form>
                <Box sx={{ marginTop: 4 }}>
                  <Typography variant="body2" gutterBottom>
                    Step 4 of 6
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={completionPercentage}
                    sx={{
                      borderRadius: 1,
                      height: 10,
                      backgroundColor: "#f5f5f5", // Background of the track
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "green", // Progress bar color
                      },
                    }}
                  />
                </Box>
                <Typography variant="body2" align="right"
                  sx={{ mt: 1, fontWeight: 600 }}>
                  {completionPercentage}%
                </Typography>
                <Box sx={{ textAlign: "center", mt: 3, color: "text.secondary" }}>
                  <Typography variant="caption"> &copy; 2024 Huebits All Rights Reserved </Typography>
                  <br />
                  <Typography variant="caption">Terms of Business</Typography>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Postproject4;