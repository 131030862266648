import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { fetchPaymentStatus } from "./LlearnersSubscription";
import useNetworkStatus from "./NetworkStatus";
import ContentLoader from "react-content-loader";

const SkeletonLoader = () => (
  <ContentLoader speed={2} width="100%" height={400} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
    <rect x="0" y="200" rx="5" ry="5" width="60%" height="20" />
    <rect x="0" y="230" rx="5" ry="5" width="80%" height="15" />
  </ContentLoader>
);

const ProtectedRoute = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const token = localStorage.getItem("token");
  const { isOnline } = useNetworkStatus();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      console.warn("No token found. Redirecting...");
      window.location.href = "/learners/dashboardpage";
      return;
    }

    const checkAuthorization = async () => {
      try {
        const status = await fetchPaymentStatus(token);
        console.log("Fetched Payment Status:", status);
        setTimeout(() => {
          setPaymentStatus(status);
        }, 2000);
      } catch (error) {
        console.error("Error verifying subscription:", error);
        window.location.href = "/learners/dashboardpage";
      }
    };

    checkAuthorization();
  }, [token]);

  if (paymentStatus === null) {
    return isOnline ? <SkeletonLoader /> : <div>No Internet Connection. Please try again.</div>;
  }

  // Allow access to IoT dashboard if user has IoT subscription
  if (paymentStatus.iot && location.pathname.startsWith("/learners/dashboard")) {
    return <Outlet />;
  }

  // Allow access to Data Science dashboard if user has Data Science subscription
  if (paymentStatus.dataScience && location.pathname.startsWith("/learners/data-science-dashboard")) {
    return <Outlet />;
  }

  // Redirect unauthorized users
  console.warn("User not authorized for this route. Redirecting...");
  return <Navigate to="/learners/dashboardpage" replace />;
};

export default ProtectedRoute;