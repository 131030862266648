import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {AdminLearnerslist, AdminLearnersuploads} from "../helper/base_url";
import './Admindashboard.css';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('admin-token');
    if (!token) {
      navigate('/admin/signin');
      return;
    }

    const fetchUsers = async () => {
      try {
        const userResponse = await axios.get(`${AdminLearnerslist}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUsers(userResponse.data.users);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const handleDownload = async (path) => {
    try {
      const response = await axios({
        url: `${AdminLearnersuploads}${path}`,
        method: 'GET',
        responseType: 'blob', 
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', path);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  

  return (
    <div className='admin-students-dashboard'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2 className='admin-students-dashboard-p'>Students Data</h2>
          <table className='admin-students-dashboard-table'>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Email Id</th>
                <th>Mobile No</th>
                <th>Full Name</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Resume</th>
                <th>Courses</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td className="index-number">{index + 1}</td>
                  <td>{user.username}</td>
                  <td className="number">{user.mobile}</td>
                  <td>{user.fullname}</td>
                  <td className="number">{user.dateofbirth}</td>
                  <td>{user.gender}</td>
                  <td>{user.country}</td>
                  <td>{user.state}</td>
                  <td>{user.city}</td>
                  <td>
                    <ul>
                  {user.resume && user.resume.length > 0 ? (
                        user.resume.map((resum, idx) => (
                          <li key={idx}>
                          {resum.originalname && (
                            <div>
                              <p><span className='admin-dashboard-projects-span'>Project_Pdf: </span>{resum.originalname}</p>
                              <button onClick={() => handleDownload(resum.path)} className='admin-dashboard-projects-download-button'>
                               Download Project PDF <FontAwesomeIcon icon={faDownload} />
                              </button>
                            </div>
                          )}
                        </li>
                      ))
                    ) : (
                      <p>No projects available</p>
                    )}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {user.courses && user.courses.map((course, idx) => (
                        <li key={idx}>
                          <div>Course ID: <span className="number">{course.courseId}</span></div>
                          <div>Course Title: {course.courseTitle}</div>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;