import { useEffect, useRef, useCallback, useMemo } from 'react';
import crypto from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RazorpaypaymentCapture } from '../../helper/base_url';

const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => resolve(true);
  script.onerror = () => resolve(false);
  document.body.appendChild(script);
});

const RenderRazorpay = ({
  orderId,
  keyId,
  keySecret,
  currency,
  amount,
  items,
  coupon,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const paymentId = useRef(null);
  const rzp1 = useRef(null);

  const options = useMemo(() => {
    const handlePayment = async (status, orderDetails = {}) => {
      try {
        const response = await axios.post(`${RazorpaypaymentCapture}`, { status, orderDetails });
    
        if (response.data.status === 'ok') {
          if (rzp1.current) {
            rzp1.current.close();
          }
          onSuccess?.();
          navigate('/learners/signin');
        }
      } catch (error) {
        console.error('Error in payment verification:', error);
      }
    };    

    const description = items?.[0]?.packageTitle || 'Purchase';

    return {
      key: keyId,
      amount,
      currency,
      name: 'Huebits',
      description,
      order_id: orderId,
      notes: { 
        coupon, 
        orderId, 
        items: JSON.stringify(items) // Ensure complex objects are stringified
      },
      handler: (response) => {
        const succeeded = crypto
          .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret)
          .toString() === response.razorpay_signature;

        if (succeeded) {
          handlePayment('succeeded', {
            orderId,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            items,
            coupon,
            timestamp: new Date().toISOString(),
            status: 'success',
            amount,
          });
          rzp1.current.close();
          navigate('/learners/signin');
        } else {
          handlePayment('failed', { orderId, paymentId: response.razorpay_payment_id });
        }
      },
      modal: {
        confirm_close: true,
        ondismiss: () => handlePayment('Cancelled'),
      },
      retry: { enabled: false },
      timeout: 900,
      theme: { color: '#3399cc' },
    };
  }, [keyId, amount, currency, orderId, items, keySecret, navigate, onSuccess, coupon]);

  const displayRazorpay = useCallback(async () => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  
    if (!res) {
      console.error('Razorpay SDK failed to load.');
      return;
    }
  
    rzp1.current = new window.Razorpay(options);
  
    rzp1.current.on('payment.failed', (response) => {
      paymentId.current = response.error.metadata.payment_id;
      console.error('Payment Failed:', response.error);
    });
  
    rzp1.current.open();
  }, [options]); // Only depend on `options`  

  useEffect(() => {
    displayRazorpay(options);
  }, [displayRazorpay, options]);

  return null;
};

export default RenderRazorpay;