import React, { useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp, FaFacebook } from 'react-icons/fa';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { LiaRupeeSignSolid } from "react-icons/lia";
//import { AiOutlineThunderbolt } from "react-icons/ai";
import { PdfDatadownload } from "./pdfdownload";
import DataScienceRazorpay from '../../../mainpage/RazorpayPayment/DataScience-razorpay';
//import { MdOutlineTrendingUp } from "react-icons/md";
import DataScience from "../../../assets/learners/Pages/dataScience.png";
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
//import Empower from "../../../assets/Internetofthings.png";
import { IotCourses } from "./datascience-Programing";
import Iotimage from "../../../assets/learners/Pages/5.png";
import { Button, Box } from '@mui/material';
import { DataScienceCourses, DataScienceProjects } from "./dataScience-courses";
//Technology
//Programming
import Colob from "../../../assets/Technology/Internet-tools-logos/2.png";
import Jupyter from "../../../assets/Technology/Datascience-tools-logos/1.png";
import Numpy from "../../../assets/Technology/Datascience-tools-logos/2.png";
import Matplotlib from "../../../assets/Technology/Datascience-tools-logos/4.png";
import Plotly from "../../../assets/Technology/Datascience-tools-logos/5.png";
import Ggplat2 from "../../../assets/Technology/Datascience-tools-logos/7.png";
import Dplyr from "../../../assets/Technology/Datascience-tools-logos/6.png";
import Pandas from "../../../assets/Technology/Datascience-tools-logos/3.png";
//Hardware
import MySQL from "../../../assets/Technology/Datascience-tools-logos/9.png";
import SQLite from "../../../assets/Technology/Datascience-tools-logos/10.png";
import PostgreSQL from "../../../assets/Technology/Datascience-tools-logos/12.png";
import MongoDB from "../../../assets/Technology/Datascience-tools-logos/11.png";
//Device Communication
import HuggingFace from "../../../assets/Technology/Datascience-tools-logos/15.png";
import PyTorch from "../../../assets/Technology/Datascience-tools-logos/14.png";
import Keras from "../../../assets/Technology/Datascience-tools-logos/17.png";
import Scikitlear from "../../../assets/Technology/Datascience-tools-logos/16.png";
import Spacy from "../../../assets/Technology/Datascience-tools-logos/19.png";
import TensorFlow from "../../../assets/Technology/Datascience-tools-logos/18.png";
//Technologies
import kubernetes from "../../../assets/Technology/Datascience-tools-logos/26.png";
import Docker from "../../../assets/Technology/Datascience-tools-logos/21.png";
import Git from "../../../assets/Technology/Datascience-tools-logos/25.png";
import Flask from "../../../assets/Technology/Datascience-tools-logos/22.png";
import Streamlit from "../../../assets/Technology/Datascience-tools-logos/24.png";
import Js from "../../../assets/Technology/Datascience-tools-logos/23.png";
import Pythonanywhere from "../../../assets/Technology/Datascience-tools-logos/27.png";
//Cloud Platforms
import Power from "../../../assets/Technology/Datascience-tools-logos/29.png";
import Tableau from "../../../assets/Technology/Datascience-tools-logos/30.png";
import GoogleLookerStudio from "../../../assets/Technology/Datascience-tools-logos/31.png";
import OlikSense from "../../../assets/Technology/Datascience-tools-logos/32.png";
//webapp
import Talend from "../../../assets/Technology/Datascience-tools-logos/33.png";
import AWS from "../../../assets/Technology/Datascience-tools-logos/35.png";
import Grafana from "../../../assets/Technology/Datascience-tools-logos/34.png";
import Snowflake from "../../../assets/Technology/Datascience-tools-logos/32.png";
import Azure from "../../../assets/Technology/Datascience-tools-logos/36.png";
import GoogleBigQuery from "../../../assets/Technology/Datascience-tools-logos/38.png";
import Databricks from "../../../assets/Technology/Datascience-tools-logos/37.png";
import Pentaho from "../../../assets/Technology/Datascience-tools-logos/39.png";
//Data
import ApacheKafka from "../../../assets/Technology/Datascience-tools-logos/41.png";
import ApacheSpark from "../../../assets/Technology/Datascience-tools-logos/42.png";
import ApacheStorm from "../../../assets/Technology/Datascience-tools-logos/43.png";
import ApacheFlink from "../../../assets/Technology/Datascience-tools-logos/44.png";
//DataScience
import Hugging from "../../../assets/Technology/Datascience-tools-logos/46.png";
import Hadoop from "../../../assets/Technology/Datascience-tools-logos/47.png";
import OpenAI from "../../../assets/Technology/Datascience-tools-logos/48.png";
import MistalAL from "../../../assets/Technology/Datascience-tools-logos/52.png";
import LongChain from "../../../assets/Technology/Datascience-tools-logos/50.png";
import LongGraph from "../../../assets/Technology/Datascience-tools-logos/51.png";
import Reviews from "../../../mainpage/Homepage/LearnersReviews";
import Footer from "../../../mainpage/Footer/learnersFooter";
import Reveal from '../../../helper/useScrollposition';

const DataSciencepage = () => {
  const footerSectionRef = useRef(null);
  const [category, setCategory] = useState("courses");

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [move, setMove] = useState(false);
  const [showRazorpay, setShowRazorpay] = useState(false); // New state
  
       const handleClick = () => {
         setMove(true);
         setTimeout(() => {
           setShowRazorpay(true); // Show Razorpay component on click
             setMove(false);
         }, 500);
     };

    useEffect(() => {
          if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
            var scriptElement = document.createElement("script");
            scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
            scriptElement.async = true;
            scriptElement.charset = "UTF-8";
            scriptElement.setAttribute("crossorigin", "*");
            
            document.body.appendChild(scriptElement);
          }
        }, []);

    return (
        <div className="students-flex homepagecoloresiot">
        <Headrepage />
        <div className="homepagecolores">
        <Reveal>
        <div>
        <div ref={footerSectionRef}>
        <div className="learners-datascience-banner1">
          <ul className="learners-iot-banner1-ul">
            <li>
            <h1 className="learners-iot-banner1-h1">
              Artificial Intelligence and 
            <span className="learners-iot-banner1-h1-span"> Data Science</span>
            </h1>          
            <p className="learners-iot-banner1-p1">Your Complete Solution for All AI Data Scince and Engineering Training Needs</p>
            {/* <p className="learners-iot-banner1-p2"><strong>80+ </strong> Hours of Bootcamps | <strong>50+ </strong> AI Data Scince and Engineering Courses | <strong>30+</strong> Technologies</p> */}
            <p className="learners-iot-banner1-p2"><strong>2+ </strong> Boot Camps | <strong>50+ </strong> AI Data Scince and Engineering Courses | Validity: 1 Year </p>
            <p className="learners-iot-banner1-p3"><span><FaRegCheckCircle /></span>  Guaranteed Industrial Internship</p>
            </li>
          </ul>
            <img className="learners-iot-banner1-image" src={DataScience} alt=""/>
          </div>
          <div className="learners-dataScience-banner2">
          <ul className="learners-iot-banner2-ul">
            <li className="learners-iot-banner2-ul-li1">
              <p><IoCalendarOutline className="learners-iot-banner2-p1-icon" /></p>
              <p className="learners-iot-banner2-ul-p1">
                <p>Admission Closes Today</p>
                <p className="learners-iot-banner2-li1-seates"> Limited Seats</p>
              </p>
              </li>
              <li className="learners-iot-banner2-ul-li2">
              <p><HiOutlineBriefcase className="learners-iot-banner2-p2-icon" /></p>
              <p className="learners-iot-banner2-ul-p2">Placement Support | 1.5 Lakh+ Opportunities</p>
            </li>
            <li className="learners-iot-banner2-ul-li3">
              <p><LiaRupeeSignSolid className="learners-iot-banner2-p3-icon" /></p>
              <p className="learners-iot-banner2-ul-p3">
                  <p className="learners-iot-banner2-ul-p3-price">14,300</p>
                  <p className="learners-iot-banner2-ul-p3-old-price-span"><span className="learners-iot-banner2-ul-p3-old-price">30,000 </span> <span className="span"> 15,700</span>
                  </p>
                  <p className="learners-iot-banner2-li2-seates">Valid for Today</p>
              </p>
            </li>
             <li className="learners-iot-banners2-button">
              <PdfDatadownload />
            </li>
            <li className="learners-iot-banner2-button">
              <button
                className={`learners-iot-banner2-btn ${move ? "move-right" : ""}`}
                onClick={handleClick}
              >
                Enroll Now
              </button>
              <span className="arrow-container">
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
              </span>
            </li>
            {/* <p className="learners-iot-banner2-ul-li5-p"><MdOutlineTrendingUp className="learners-iot-banner2-p5-icon" />
            192,523 already applied
            </p> */}
          </ul>
          </div>
          {/* Show Razorpay component when button is clicked */}
          {showRazorpay && <DataScienceRazorpay onClose={() => setShowRazorpay(false)} />}
          </div>
        </div>
        <div className="learners-dataScience-banner3">
          <h1 className="learners-iot-banner3-h1"><span>Comprehensive Trainings</span>
          <p className="learners-iot-banner3-p">Gain in-demand skills, hands-on experience, and career support to turn your ambitions into reality</p></h1>
        <div className="learners-iot-banner3-cards">
          <p>Data Science and Analytics foundation</p>
          <p>Business Intelligence</p>
          <p>Machine Learning and AI</p>
          <p>Data Engineering</p>
          <p>Generative AI and Advanced AI Technologies</p>
        </div>
        </div>
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner4">
          <div>
            <IotCourses />
          </div>
        </div>
        </Reveal>
        {/* <div className="learners-iot-banner4">
          <DataScienceFuture />
        </div> */}
        <Reveal>
        {/* <div className="learners-iot-banner5">
          <h1 className="learners-iot-banner5-h1">IoT Learning Paths</h1>
          <p className="learners-iot-banner5-p">Explore our IoT learning paths customized for your skill level Leverage AI-driven customization for a personalized experience 
            that advances both your career and organizational success</p>
          <div>
            <LearningPaths />
          </div>
        </div> */}
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner6">
          <h1 className="learners-iot-banner6-h1"><span>Guaranteed Internships and Placement Assistance: Master Data Science Skills With Us</span></h1>
          <div className="learners-iot-banner6-cards">
          <img src= {Iotimage} alt="iot" className="learners-iot-banner6-card-image"/>
          <div className="learners-iot-banner6-card">
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Boot Camps</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Expert Training: Master Data Science With Hands-on Courses and Projects</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Guaranteed Internships: Secure Paid Internships</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Job Placement Assistance: Get Dedicated Job Placement Support</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Real-World Experience: Gain Practical Industry Experience</p>
            </div>
            </div>
          </div>
          </div>
          </Reveal>
          <Reveal>
          <div className="learners-iot-banner8">
            <h1 className="learners-iot-banner8-h1">Data Science Courses & Projects: Learn, Build, Experience, Innovate</h1>
            {/* Category Buttons */}
            <Box>
              <Box display="flex" 
              justifyContent={{ xs: "center", md: "flex-start" }} 
              marginTop={4} 
              marginBottom={1}
              sx={{ paddingLeft: { md: "24px" } }} // Add 10px left padding only on desktop
              >
                <Button 
                  variant={category === 'courses' ? 'contained' : 'outlined'} 
                  onClick={() => setCategory('courses')}
                  sx={{ marginRight: 2, textTransform: "none" }}
                >
                  Courses
                </Button>
                <Button 
                  variant={category === 'projects' ? 'contained' : 'outlined'} 
                  onClick={() => setCategory('projects')}
                  sx={{ textTransform: "none" }}
                >
                  Projects
                </Button>
              </Box>
               {/* Conditional Rendering Based on Selected Category */}
               {category === 'courses' ? <DataScienceCourses /> : <DataScienceProjects />}
            </Box>
            </div>
            </Reveal>
            <Reveal>
            <div className="learners-iot-banner7">
              <h1 className="learners-iot-banner7-h1">Explore the World of Data Science Technologies</h1>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Data Analysis and Visualization</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Colob} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Colab</p>
              </div>
              <div>
              <img src={Jupyter} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Jupyter</p>
              </div>
              <div>
              <img src={Numpy} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Numpy</p>
              </div>
              <div>
              <img src={Matplotlib} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Matpltlib</p>
              </div>
              <div>
              <img src={Plotly} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Plotly</p>
              </div>
              <div>
              <img src={Ggplat2} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Ggplot2</p>
              </div>
              <div>
              <img src={Dplyr} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Dplyr</p>
              </div>
              <div>
              <img src={Pandas} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Pandas</p>
              </div>
              </div>
              
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Database</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
                <div>
              <img src={MySQL} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Mysql</p>
              </div>
              <div>
              <img src={SQLite} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>SqLite</p>
              </div>
              <div>
              <img src={PostgreSQL} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>PostgreSQL</p>
              </div>
              <div>
              <img src={MongoDB} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>MongoDB</p>
              </div>
              </div>
              
            </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Machine Learning and Deep Learning</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
                <div>
              <img src={HuggingFace} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Hugging Face</p>
              </div>
              <div>
              <img src={PyTorch} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>PyTorch</p>
              </div>
              <div>
              <img src={Keras} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Keras</p>
              </div>
              <div>
              <img src={Scikitlear} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Scikit Learn</p>
              </div>
              <div>
              <img src={Spacy} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Spacy</p>
              </div>
              <div>
              <img src={TensorFlow} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>TensorFlow</p>
              </div>
              </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>ML Deployment</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={kubernetes} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Kubernetes</p>
              </div>
              <div>
              <img src={Docker} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Docker</p>
              </div>
              <div>
              <img src={Git} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Git</p>
              </div>
              <div>
              <img src={Flask} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Flask</p>
              </div>
              <div>
              <img src={Streamlit} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Streamlit</p>
              </div>
              <div>
              <img src={Js} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Javascript</p>
              </div>
              <div>
              <img src={Pythonanywhere} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Pythonanywhere</p>
              </div>
              </div>
            </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Business Intelligence</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Power} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Power bi</p>
              </div>
              <div>
              <img src={Tableau} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Tableau</p>
              </div>
              <div>
              <img src={GoogleLookerStudio} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Google Looker Studio</p>
              </div>
              <div>
              <img src={OlikSense} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Qlik Sense</p>
              </div>
              </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Data Engineering Platforms</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Talend} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Talend</p>
              </div>
              <div>
              <img src={AWS} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>AWS</p>
              </div>
              <div>
              <img src={Grafana} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Grafana</p>
              </div>
              <div>
              <img src={Snowflake} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Snowflake</p>
              </div>
              <div>
              <img src={Azure} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Azure</p>
              </div>
              <div>
              <img src={GoogleBigQuery} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Google Big Query</p>
              </div>
              <div>
              <img src={Databricks} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Databricks</p>
              </div>
              <div>
              <img src={Pentaho} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Pentaho</p>
              </div>
              </div>
            </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Big Data and Streaming</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={ApacheKafka} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Kafka</p>
              </div>
              <div>
              <img src={ApacheSpark} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Apache Spark</p>
              </div>
              <div>
              <img src={ApacheStorm} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Apache Storm</p>
              </div>
              <div>
              <img src={ApacheFlink} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Apache Flink</p>
              </div>
              </div>
            </div>
            <div className="learners-iot-tech-talent-banner7-card-1">
              <h2>Generative AI</h2>
              <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div>
              <img src={Hugging} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Hugging Face</p>
              </div>
              <div>
              <img src={Hadoop} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Hadoop</p>
              </div>
              <div>
              <img src={OpenAI} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>OpenAI</p>
              </div>
              <div>
              <img src={LongGraph} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Llamaindex</p>
              </div>
              <div>
              <img src={LongChain} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>LangChain</p>
              </div>
              <div>
              <img src={MistalAL} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
              <p>Mistral AL</p>
              </div>
              </div>
              </div>
              </div>
            <div className="learners-iot-banner10-button">
            <div className="learners-iot-banner2-button">
              <button
                className={`learners-iot-banner2-btn ${move ? "move-right" : ""}`}
                onClick={handleClick}
              >
                Enroll Now
              </button>
              <span className="arrow-container">
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
              </span>
            </div>
            </div>
            </div>
            </Reveal>
            <Reveal>
           <div className="learners-iot-banner9">
           <h1 className="learners-iot-banner9-h1">What Our Customers Are Saying</h1>
            <Reviews />
            </div>
            </Reveal>
            <div className="homepage-footers-images">
            <Footer />
             <footer className="homepage-footers">
             <hr className='homepage-footer-hr'/>
             <div className="footer-social">
             <div className="homepage-footer-copyright">
              <p>© Huebits[2025].All Rights Reserved.</p>
             </div>
             <div className='footer-social-icons'>
                <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
                <Link onClick={scrollToFooterSection}>
                <FaArrowUp className="homepage-social-icon-link" />
                </Link>
            </div>
            </div>
          </footer>
      </div>
    </div>
    </div>
    )
}


export default DataSciencepage;