import React from 'react';
import { FaBookOpen } from "react-icons/fa";
import { HiLightBulb, HiBriefcase } from "react-icons/hi2";
import Image from "../../../assets/learners/Pages/2.png";

const IotCourses = () => {

  return (
    <div className='learners-iot-banner4-cards'>
    <div>
    <h1 className="learners-iot-banner4-h1"><span>Step into the Future of Data Science</span>
    <p className="learners-iot-banner4-p">Empowering learners and professionals to innovate and excel</p></h1>
  <div className='learners-iot-banner4-card'>
      <div>
          <p className='learners-iot-banner4-card-h2'>
              <p><FaBookOpen className="learners-iot-banner4-card-icon" /></p>
              <p className='learners-iot-banner4-card-p'><strong>50+</strong></p> 
              <p className='learners-iot-banner4-card-p-span'>Courses covering every aspect of AI, Data Science, and Engineering.</p>
          </p>
      </div>
      <div>
          <p className='learners-iot-banner4-card-h2'>
              <p><HiLightBulb className="learners-iot-banner4-card-icon" /></p>
              <p className='learners-iot-banner4-card-p'><strong>40+</strong></p> 
              <p className='learners-iot-banner4-card-p-span'>Hands-on projects to spark your creativity in AI, Data Science, and Engineering.</p>
          </p>
      </div>
      <div>
          <p className='learners-iot-banner4-card-h2'>
              <p><HiBriefcase className="learners-iot-banner4-card-icon" /></p>
              <p className='learners-iot-banner4-card-p'><strong>600+</strong></p> 
              <p className='learners-iot-banner4-card-p-span'>AI, Data Science, and Engineering internship opportunities to build industry connections.</p>
          </p>
      </div>
  </div>
  </div>
  <div className='learners-iot-banner4-card-image'>
  <img src={Image} alt='images' className='learners-iot-banner4-card-img' />
  </div>
  </div>
  )
}

const DataScienceFuture = () => {

    return (
        <div>
        <h1 className="learners-iot-banner4-h1">Step Into The Future Of Data Science</h1>
        <p className="learners-iot-banner4-p">Empowering learners and professionals to innovate and excel</p>
        <div className='learners-data-science-banner4-cards1'>
          <div className='learners-data-science-banner4-cards1-card1'>
            <p><FaBookOpen className="learners-data-science-banner4-cards1-card-icon" /></p>
            <p className='learners-data-science-banner4-cards1-card-p'><strong>40+</strong></p> 
            <p className='learners-data-science-banner4-cards1-card-p-span'>courses covering every aspect of IoT</p>
          </div>
          <div className='learners-data-science-banner4-cards1-card1'>
            <p><HiLightBulb className="learners-data-science-banner4-cards1-card-icon" /></p>
            <p className='learners-data-science-banner4-cards1-card-p'><strong>30+</strong></p> 
            <p className='learners-data-science-banner4-cards1-card-p-span'>hands-on projects</p>
          </div>
          <div className='learners-data-science-banner4-cards1-card1'>
            <p><HiBriefcase className="learners-data-science-banner4-cards1-card-icon" /></p>
            <p className='learners-data-science-banner4-cards1-card-p'><strong>1300+</strong></p> 
            <p className='learners-data-science-banner4-cards1-card-p-span'>IoT internship opportunities</p>
          </div>
          <div className='learners-data-science-banner4-cards1-card1'>
            <p><HiBriefcase className="learners-data-science-banner4-cards1-card-icon" /></p>
            <p className='learners-data-science-banner4-cards1-card-p'><strong>900+</strong></p> 
            <p className='learners-data-science-banner4-cards1-card-p-span'>Placement Opportunities</p>
          </div>
      </div>
      </div>
    )
  }

export { IotCourses, DataScienceFuture };