import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
    Boolean(localStorage.getItem("admin-token"))
  );

  useEffect(() => {
    const checkAuth = () => setIsAuthenticated(Boolean(localStorage.getItem("admin-token")));
    window.addEventListener("storage", checkAuth);
    return () => window.removeEventListener("storage", checkAuth);
  }, []);

  // const handleLogout = () => {
  //   localStorage.removeItem("admin-token");
  //   setIsAuthenticated(false);
  //   navigate("/signin");
  // };
  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    navigate('/admin/signin');
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button color="inherit" component={Link} to="#">
          Home
        </Button>
        <Box>
          {!isAuthenticated ? (
            <>
              <Button color="inherit" component={Link} to="/admin/signup">
                Signup
              </Button>
              <Button color="inherit" component={Link} to="/admin/signin">
                Signin
              </Button>
            </>
          ) : (
            <>
              <Button color="inherit" component={Link} to="/admin/dashboard">
                Dashboard
              </Button>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
