import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import Getaccesstoallcourses from "../learningpath/hooks/getAccesstoallCourses";
import { LearnersLiveSessions, LearnersBookevent } from "../../../helper/base_url";
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const CalendlyEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bookingUrl, setBookingUrl] = useState(null);
  const { isOnline } = useNetworkStatus();

  // Function to convert minutes to hours and minutes
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    let formattedTime = "";

    if (hours > 0) {
      formattedTime += `${hours} hour${hours > 1 ? "s" : ""}`;
    }

    if (mins > 0) {
      formattedTime += ` ${mins} minute${mins > 1 ? "s" : ""}`;
    }

    return formattedTime || "Less than 1 minute"; // If 0 minutes, return a fallback
  };

  // ✅ Fetch available events
  const fetchEvents = async () => {
    try {
      setLoading(true);
      setError(null);
      //console.log("Fetching events from:", LearnersLiveSessions);

      const response = await axios.get(LearnersLiveSessions);
      //console.log("Events fetched successfully:", response.data);

      if (response.data && response.data.events) {
        setEvents(response.data.events);
      } else {
        throw new Error("Invalid response format: No 'events' key in response");
      }
    } catch (error) {
      //console.error("Error fetching events:", error);
      setError("No events are available at the moment. Stay tuned for upcoming sessions!");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Book a meeting
  const bookMeeting = async (eventUri) => {
    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found. Please log in again.");
      }

      //console.log("Booking event for URI:", eventUri);

      const response = await axios.post(
        LearnersBookevent,
        { eventUri },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      //console.log("Meeting booked successfully:", response.data);

      if (response.data && response.data.link) {
        setBookingUrl(response.data.link);
      } else {
        throw new Error("Invalid booking response: No 'link' key in response");
      }
    } catch (error) {
      //console.error("Error booking meeting:", error);
      setError("No events are available at the moment. Stay tuned for upcoming sessions!");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Close Booking
  const closeBooking = () => {
    setBookingUrl(null);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div>
     {isOnline ? (
    <div className="mylearningpaths">
      {bookingUrl ? (
        <Box sx={{ marginTop: "10px", textAlign: "center", width: "100%" }}>
          <Typography variant="h5" color="primary">
            Book Your Event
            <IconButton
              sx={{ position: "absolute", right: 10 }}
              onClick={closeBooking}
              color="primary"
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <iframe
            src={bookingUrl}
            width="100%"
            height="600px"
            title="Calendly Booking"
          ></iframe>
        </Box>
      ) : (
        <Box
          sx={{
            padding: 4,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 4,
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : events.length === 0 ? (
            <Typography>No events found.</Typography>
          ) : (
            events.map((event, index) => (
              <Card
                key={index}
                sx={{
                  width: 320,
                  height: 250,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: 1,
                  boxShadow: 3,
                  borderTop: "8px solid #052659",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1)",
                    boxShadow: 10,
                  },
                }}
              >
                <CardContent sx={{ textAlign: "left", padding: "4px" }}>
                  <Typography variant="h6">{event.eventName}</Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {formatDuration(event.duration)} One-on-One
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "400", marginTop: 1 }}>
                    <strong>Event Mentor: </strong>{event.mentor?.name || "Unknown"}
                  </Typography>
                </CardContent>

                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "150px",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px 20px",
                      backgroundColor: "#052659",
                      fontWeight: "bold",
                    }}
                    onClick={() => bookMeeting(event.URI)}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={20} color="inherit" /> : "Book Now"}
                  </Button>
                </CardActions>
              </Card>
            ))
          )}
        </Box>
      )}
    </div>
    ) : (
    <div>
        <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
    </div>
  )}
  </div>
  );
};

export default CalendlyEvents;
