import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Banner1 from "../../assets/homepage/future.png";
import Icon1 from "../../assets/homepage/icon1.png";
import Icon2 from "../../assets/homepage/icon2.png";
import Icon3 from "../../assets/homepage/icon3.png";
import Icon4 from "../../assets/homepage/icon4.png";
import Icon5 from "../../assets/homepage/icon5.png";
import Icon6 from "../../assets/homepage/icon6.png";
import { IndustryCourses, OrganizationsIndustry} from "./Industry-courses";
import ScrollCount from "./Scrollopositioncount";
import Huebits from "./huebit";
import { Organizations } from "./Organizations";
import Professionals from "../../assets/homepage/superiorSolutions.png";
import Reviews from "./LearnersReviews";
import Footer from "../Footer/Footer";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import Headerpage from "../Headerpages/HeaderPage";
import Reveal from '../../helper/useScrollposition';
import useNetworkStatus from "../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import "./Home.css";
import "./homepage.css";

const Homepage = ({ isPopupOpen }) => { 
  const footerSectionRef = useRef(null);
  const learnersSectionRef = useRef(null);
  const OrganizationsSectionRef = useRef(null);
  const { isOnline } = useNetworkStatus();

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollTolearnersSection = () => {
    learnersSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToOrganizationsSection = () => {
    OrganizationsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
      var scriptElement = document.createElement("script");
      scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
      scriptElement.async = true;
      scriptElement.charset = "UTF-8";
      scriptElement.setAttribute("crossorigin", "*");
      
      document.body.appendChild(scriptElement);
    }
  }, []);

  return (
    <div className={`students-flex ${isPopupOpen ? 'popup-open' : ''}`}>
      <Headerpage className="homepage-headerpage" />
      {isOnline ? (
         <div className="homepagecolores">
            <div ref={footerSectionRef} className="homepage-banner1">
              <ul>
                <li>
                {/* <h1 className='homepage-banner1-spark-h1'>Shaping the Future With <span>Innovative Minds</span></h1> */}
                <h1 className="homepage-banner1-spark-h1">Bridging Talent and Opportunity
                <p><span>for a Smarter Future</span></p></h1>
                <p className='homepage-banner1-spark-p'>Upskilling Future Tech Leaders & Strategic Solutions for Organizational Growth
                </p>
                {/* <p className='homepage-banner1-spark-p'>Where Innovation Takes Shape: Tailored Upskilling for Future Tech Leaders and Strategic Solutions for Organizational Growth</p> */}
                </li>
                <li className="homepage-banner1-buttons">
                  <button onClick={scrollTolearnersSection}>Gain Industry Experience <FontAwesomeIcon icon={faArrowRight} className="homepage-banner1-button-arrow-arrowright"/></button>
                  <button onClick={scrollToOrganizationsSection}>Solutions and Tech Talent <FontAwesomeIcon icon={faArrowRight} className="homepage-banner1-button-arrow-arrowright"/></button>
                </li>
              </ul>
              <div className="homepage-banner1-images">
                <img src={Banner1} alt="homepage" className="homepage-banner1-image"/>
              </div>
              </div>
              <div className="homepage-banner2" ref={learnersSectionRef} >
                {/* <h1 className="homepage-banner2-h1"><span> Unlock Your Potential and Shape Your Future Today!</span>
                <p className="homepage-banner2-p"><span> The right guidance, hands-on experience, and career opportunities—all in one place</span></p>
                </h1> */}
                {/* <h1 className="homepage-banner2-h1"><span> Ignite Your Future with Real-World Experience</span> */}
                <h1 className="homepage-banner2-h1"><span> Real Industry Experience for <span className="span">Learners</span>
                </span>
                <p className="homepage-banner2-p"><span> Learn, Evolve, and Step into Your Career with Industry-Ready Programs</span></p>
                </h1>
                {/* <h1 className="homepage-banner2-h2"> Learners
                <FontAwesomeIcon icon={faUsers} className="homepage-banner2-h2-icon-users" />
                </h1> */}
                <Reveal>
                <div className="homepage-banner2-card1">
                  <div className='homepage-banner2-card2-1'>
                  <img className="homepage-banner2-card-img" src={Icon3} alt="" />
                  <p className="homepage-banner2-card2-1-p">Real Industry Experience</p>
                  </div>
                  <div className='homepage-banner2-card2-1'>
                  <img className="homepage-banner2-card-img" src={Icon4} alt="" />
                  <p className="homepage-banner2-card2-1-p">Guaranteed Internship | Upto Total ₹40K Stipend!</p>
                  </div>
                  <div className='homepage-banner2-card2-1'>
                  <img className="homepage-banner2-card-img" src={Icon1} alt="" />
                  <p className="homepage-banner2-card2-1-p">Guaranteed Placement</p>
                  </div>
                  <div className='homepage-banner2-card2-1'>
                  <img className="homepage-banner2-card-img" src={Icon5} alt="" />
                  <p className="homepage-banner2-card2-1-p">Hybrid Learning Through Boot Camps</p>
                  </div>
                  <div className='homepage-banner2-card2-1'>
                  <img className="homepage-banner2-card-img" src={Icon6} alt="" />
                  <p className="homepage-banner2-card2-1-p">Mentorship and Support in Every Phase</p>
                  </div>
                  <div className='homepage-banner2-card2-1'>
                  <img className="homepage-banner2-card-img" src={Icon2} alt="" />
                  <p className="homepage-banner2-card2-1-p">Personalized Learning Paths</p>
                  </div>
                  </div>
                  </Reveal>
                  <div>
                </div>
              </div>
              <Reveal>
              <div className="homepage-banner2">
                <ScrollCount />
              </div>
              </Reveal>
              <Reveal>
              <div className="homepage-banner3">
                <h1 className="homepage-banner3-h1"><span> Get Job-Ready With Our Hybrid Industry Ready Programs</span></h1>
                {/* <p className="homepage-banner3-p">Prepare for the future with our hybrid, industry-ready programs and boot camps (offline/live). 
                   Explore trending engineering courses, work on hands-on projects, and gain real-world experience. 
                   Enhance your skills and get expert placement guidance to kickstart your career.
                </p> */}
                 <p className="homepage-banner3-p">Empower your future with our industry ready programs. 
                  Dive into emerging technologies through interactive boot camps,
                  hands-on courses, projects and real-world experience. Enhance your skills and get placed
                </p>
                <IndustryCourses />
              </div>
              </Reveal>
              <Reveal>
              <div className="homepage-banner5">
                <h1 className="homepage-banner5-h1"><span>From Skills to Success: Your Pathway to a Guaranteed Tech Career!</span>
                <p className="homepage-banner5-p">Choose your path or unlock all! Learn, build projects, join hackathons & get job-ready</p></h1>
                <Huebits />
                <div className="homepage-banner5-btn">
                    <Link to="/learners" >
                    <button className="homepage-banner5-botton">Learn More
                    <FontAwesomeIcon icon={faArrowRight} className="homepage-banner8-card1-botton-faArrowRight" />
                    </button>
                    </Link>
                </div>
              </div>
              </Reveal>
              <Reveal>
              <div className="homepage-banner6" ref={OrganizationsSectionRef} >
              <h1 className="homepage-banner2-h1"><span>Empower<span className="span">Organization's</span> Growth
                </span>
                <p className="homepage-banner2-p"><span> With Emerging Technology Solutions and Elite Talent</span></p>
              </h1>
                {/* <h1 className="homepage-banner6-h1"><span>Empower <span className="span">Organizations'</span> Growth</span> */}
                  {/* <p className="homepage-banner6-p"><span>with Emerging Technology Solutions and Elite Talent</span></p></h1> */}
               {/* <img src={Empower} alt="Empower" style={{ marginLeft: 10, height: 40 }} /></span></h1> */}
                {/* <p className="homepage-banner3-p" >Empower Your Growth with Emerging Technology Solutions and Elite Talent for Organizational Success</p> */}
                <Organizations />
              </div>
              </Reveal>
              <Reveal>
              <div className="homepage-banner7">
                <h1 className="homepage-banner7-h1"><span> Transforming Ideas into <spa className="Solutions">Solutions</spa> from Concept to Deployment
                </span></h1>
                <div className="homepage-banner7-cards">
                <OrganizationsIndustry />
                </div>
              </div>
              </Reveal>
              <Reveal>
              <div className="homepage-banner8">
                <div className="homepage-banner8-cards">
                <div className="homepage-banner8-card2">
                <img src={Professionals} alt="" className="homepage-banner8-card2-image"/>
                </div>
                <div className="homepage-banner8-card1">
                    <p className="homepage-banner8-card1-p">
                    {/* <FontAwesomeIcon icon={faBolt} className="homepage-banner8-card1-p-icon" /> */}
                      10X Cost-Effective and 10X Superior Solutions</p>
                    <p className="homepage-banner8-card1-p">
                    {/* <FontAwesomeIcon icon={faUserGraduate} className="homepage-banner8-card1-p-icon" /> */}
                      Pool of Skilled Individuals With Real-World Project Exposure</p>
                    <p className="homepage-banner8-card1-p">
                    {/* <FontAwesomeIcon icon={faRocket} className="homepage-banner8-card1-p-icon" /> */}
                      Accelerating Ideas From Concept To Reality</p>
                    <p className="homepage-banner8-card1-p">
                    {/* <FontAwesomeIcon icon={faUsers} className="homepage-banner8-card1-p-icon" /> */}
                      Connect With Innovators and Early Career Professionals</p>
                    <div  className="homepage-banner8-card1-btn">
                    <Link to="/organizations" >
                    <button className="homepage-banner8-card1-botton">Know More
                    <FontAwesomeIcon icon={faArrowRight} className="homepage-banner8-card1-botton-faArrowRight" />
                    </button>
                    </Link>
                </div>
                </div>
                </div>
              </div>
              </Reveal>
              <Reveal>
              <div className="homepage-banner9">
               <h1 className="homepage-banner9-h1">
                    What Our Learners Say About Us
                </h1>
                <p className="homepage-banner9-p">
                    Tremendous Feedback from learners across India
                </p>
                <Reviews />
              </div>
              </Reveal>
              <div className="homepage-footers-images">
              <Footer />
             <footer className="homepage-footers">
              <hr className='homepage-footer-hr'/>
                <div className="footer-social">
                <div className="homepage-footer-copyright">
                  <p className="homepage-footer-copyrightp">© Huebits[2025].All Rights Reserved.</p>
                </div>
                {/* <div>
                  <p>HUEBITS TECH PRIVATE LIMITED</p>
                </div> */}
                <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                  <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
                  <Link onClick={scrollToFooterSection}>
                  <FaArrowUp className="homepage-social-icon-link" />
                  </Link>
                </div>
                </div>
              </footer>
              </div>
            </div>
            ) : (
              <div>
                  <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
              </div>
          )}
      </div>
  );
}

export default Homepage;