import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PersonOutlined, ExitToApp, AccountCircle } from "@mui/icons-material";
import { LearnersProfile } from "../../../helper/base_url";

const DashboardHeader = () => {
  const [data, setData] = useState({ fullname: "", username: "", displayName: "", profilePic: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  const handleUserProfile = useCallback(async () => {
    try {
      const response = await axios.post(LearnersProfile, {}, { headers: { Authorization: `jwt ${token}` } });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile", error);
    }
  }, [token]);

  useEffect(() => { if (token) handleUserProfile(); }, [token, handleUserProfile]);

  const handleMenuClose = () => setAnchorEl(null);
  const handleLogOut = () => { localStorage.clear(); navigate("/learners"); };

  return (
    <Box sx={{ display: "flex", alignItems: "center", padding: "10px 40px", background: "#0E68F9", position: "sticky", top: 0, zIndex: 1000, borderBottom: "1px solid #ddd", width: "100%", justifyContent: "space-between" }}>
      <Box>
      <Typography variant={isMobile ? "h6" : "h5"} sx={{ fontWeight: "bold", fontSize: isMobile ? "16px" : isTablet ? "18px" : "20px", color: "#fff" }}>
        Welcome <span style={{ color: "#ffd700" }}>{data.fullname || data.username || data.displayName}</span> Keep Going
      </Typography>
      <Typography variant="body2" sx={{ color: "#fff", fontSize: isMobile ? "12px" : "14px", textAlign: "right", marginTop: "4px", }} >
        Keep going—you're doing amazing!
      </Typography>
      </Box>
      <IconButton onMouseEnter={(e) => setAnchorEl(e.currentTarget)} sx={{ padding: 0 }}>
        <Box sx={{ width: 45, height: 45, borderRadius: "50%", backgroundColor: "#f5f5f5", display: "flex", alignItems: "center", justifyContent: "center", transition: "0.3s", "&:hover": { transform: "scale(1.1)", backgroundColor: "#e0e0e0" } }}>
          {data.profilePic ? <img src={data.profilePic} alt="Profile" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} /> : <PersonOutlined sx={{ color: "#4081c2", fontSize: 30 }} />}
        </Box>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} MenuListProps={{ onMouseLeave: handleMenuClose }} sx={{ width: 250, boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "10px" }}>
        <MenuItem onClick={() => { navigate("/learners/data-science-dashboard/userprofile"); handleMenuClose(); }} sx={{ padding: "12px 18px", fontWeight: "500", fontSize: "15px" }}>
          <AccountCircle sx={{ marginRight: 1, color: "#4081c2" }} /> Edit Profile
        </MenuItem>
        <MenuItem onClick={handleLogOut} sx={{ padding: "12px 18px", fontWeight: "500", fontSize: "15px", color: "#d32f2f", "&:hover": { backgroundColor: "#fbe9e7" } }}>
          <ExitToApp sx={{ marginRight: 1 }} /> Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DashboardHeader;
