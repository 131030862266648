import axios from 'axios';
import { LearnersSubscription } from './base_url';

export const fetchPaymentStatus = async (token) => {
  try {
    const response = await axios.get(`${LearnersSubscription}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.paymentStatus || {
      subcribe: false,
      iot: false,
      dataScience: false,
      embedded: false
    };
  } catch (error) {
    console.error("Error fetching payment status:", error);
    return { subcribe: false, iot: false, dataScience: false, embedded: false };
  }
};