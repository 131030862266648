import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, Card, CardContent, LinearProgress, Grid } from "@mui/material";
import SingleLogin from "../courses/singlesign";
import {LearnersProjects} from "../../../helper/base_url";
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const CompletedProjects = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const { isOnline } = useNetworkStatus();

  const fetchProjects = async () => {
    //setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found");
        return;
      }

      const response = await axios.get(
        `${LearnersProjects}`, 
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );      
    // Log response and handle data structure
    //console.log("API Response:", response.data);
    setProjects(Array.isArray(response.data.items) ? response.data.items : []);
    } catch (err) {
    //console.error("Error fetching courses:", err);
    setError("Projects are not available.");
    }
    };

    useEffect(() => {
    fetchProjects();
    }, []);

  return (
    <div>
     {isOnline ? (
    <Box sx={{ width: "100%", mx: "auto", mt: 1, p: 2 }}>
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        sx={{ color: "#052659", fontWeight: 700 }}
      >
        Completed Projects
      </Typography>
      {error ? (
        <Box sx={{ textAlign: "center", mt: 1 }}>
          {/* <Alert severity="error">{error}</Alert> */}
        </Box>
      ) : !projects || projects.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Projects are not available.
        </Typography>
      ) : (
        <Grid container spacing={3}>
            {projects
          .filter((item) => item.productProgress > 99)
          .map((item, index) => (
            <Grid item xs={12} sm={6} key={item.id}>
              <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 700, fontSize: "18px" }}
                  >
                    {index + 1}. {item.productTitle}
                  </Typography>
                  <Box display="flex" gap={2} alignItems="center">
                    <Typography variant="body2" color="textSecondary">
                      Progress: {Math.min(item.productProgress || 0, 100).toFixed(0)}%
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={Math.min(item.productProgress || 0, 100).toFixed(0)}
                        sx={{
                          height: 8,
                          borderRadius: 4,
                          "& .MuiLinearProgress-bar": { backgroundColor: "#052659" },
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 1, display: "flex" }}>
                    <SingleLogin videoUrl={item.productId} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    )}
  </Box>
   ) : (
    <div>
        <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
    </div>
  )}
  </div>
  );
};

export default CompletedProjects;