import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import './instrument'; // Import Sentry configuration
import * as Sentry from "@sentry/react";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container, {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack);
  }),
  onCaughtError: Sentry.reactErrorHandler(),
  onRecoverableError: Sentry.reactErrorHandler(),
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

(function() {
  try {
    let e = typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : {};
    if (typeof globalThis === "undefined") {
      Object.defineProperty(e, 'globalThis', {
        configurable: true,
        enumerable: false,
        writable: true,
        value: e
      });
    }
    const n = new e.Error().stack;
    if (n) {
      e._sentryDebugIds = e._sentryDebugIds || {};
      e._sentryDebugIds[n] = "dfb8cca9-af15-5423-a3a1-10bc0eb6c820";
    }
  } catch (e) {}
})();
//# debugId=dfb8cca9-af15-5423-a3a1-10bc0eb6c820
