import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, CircularProgress, IconButton, Button, Divider, TextField, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RenderRazorpay from './RenderRazorpay';
import { Razorpayorder } from '../../helper/base_url';

const Razorpay = ({ onClose }) => {
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // New state for error message
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: 'INR',
    amount: 14300,
    items: [{ packageTitle: 'Industry Ready AI & Data Science Program', productId: '67726440ca019d73a0c78fb8' }],
    coupon: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (orderDetails.orderId) setDisplayRazorpay(true);
  }, [orderDetails.orderId]);

  const handleApplyCoupon = async () => {
    if (!coupon) {
      setErrorMessage('Please enter a coupon code.');
      return;
    }
  
    setErrorMessage(''); // Reset error
  
    await handleCreateOrder(); // Wait for order creation
    setDisplayRazorpay(false); // Ensure the button is enabled
  };
  
  const handleCreateOrder = async () => {
    setLoading(true);
    setErrorMessage(''); // Reset error before making the request

     // Retrieve token from localStorage
  const token = localStorage.getItem('token'); 

  // Check if the user is logged in (token exists)
  const headers = token ? { Authorization: `Bearer ${token}` } : {}; 

    try {
      const response = await axios.post(`${Razorpayorder}`, {
        amount: orderDetails.amount * 100,
        currency: orderDetails.currency,
        keyId: process.env.REACT_APP_RAZORPAY_KEY_ID,
        keySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
        items: orderDetails.items,
        productId: orderDetails.items[0].productId,
        coupon: coupon || null,  // Pass the entered coupon
      }, 
      { headers } // Only send token if user is logged in
      );

      if (response.data && response.data.order_id) {
        setOrderDetails(prev => ({
          ...prev,
          orderId: response.data.order_id,
          amount: response.data.discountedAmount || prev.amount,
          coupon: response.data.valid ? coupon : null,
        }));
      } else {
        setErrorMessage('Error creating order. Please try again.');
      }
    } catch (error) {
      console.error('Error creating order:', error);
  
      if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
      } else {
          setErrorMessage('Failed to process payment. Please try again.');
      }
  }  
    setLoading(false);
  };
  useEffect(() => {
  if (errorMessage) {
    const timer = setTimeout(() => {
      setErrorMessage('');
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }
}, [errorMessage]);

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: '75%', md: '55%', lg: '45%' },
        bgcolor: 'white',
        boxShadow: 24,
        borderRadius: 3,
        p: 4,
        textAlign: 'center',
      }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
          <CloseIcon />
        </IconButton>
        
        <Typography variant="h4" fontWeight="bold" sx={{ color: "#043885" }}>
          Hue<span style={{ color: "#1B6EF4" }}>bits</span>
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Typography variant="h5" fontWeight="bold">Industry Ready AI & Data Science Program</Typography>
        <Typography variant="body2" color="textSecondary" mb={3}>
          Shape your future with comprehensive end-to-end AI & Data Science Training
        </Typography>

        <Box sx={{ bgcolor: '#F5F7FA', p: 2, borderRadius: 2, mb: 3 }}>
          <Typography variant="h6" fontWeight="bold">Order Summary</Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" color="textSecondary" mb={1}>
            1-Year Access | Hands-on Training | Certification Included
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2">Original Price</Typography>
            <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'red' }}>₹30,000.00</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" fontWeight="bold">Discounted Price</Typography>
            <Typography variant="h6" color="success.main" fontWeight="bold">₹{orderDetails.amount}</Typography>
          </Box>
        </Box>

        {errorMessage && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {errorMessage}
          </Typography>
        )}

        <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <FormControlLabel
            control={<Checkbox checked={showCouponInput} onChange={() => setShowCouponInput(!showCouponInput)} color="primary" />}
            label="Do you have a discount coupon?"
          />
          {showCouponInput && (
            <Box sx={{ display: 'flex', gap: 1, mt: 1, width: '100%' }}>
              <TextField fullWidth size="small" label="Enter Coupon Code" variant="outlined" value={coupon} onChange={(e) => setCoupon(e.target.value)} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyCoupon}
                sx={{ px: 3, textTransform: 'none' }}
              >
                Apply
              </Button>
            </Box>
          )}
        </Box>

        {!displayRazorpay ? (
          <Button variant="contained" color="primary" fullWidth onClick={handleCreateOrder} disabled={loading} sx={{ py: 1.5 }}>
            {loading ? <CircularProgress size={24} /> : 'Proceed to Payment'}
          </Button>
        ) : (
          <RenderRazorpay
            amount={orderDetails.amount}
            currency={orderDetails.currency}
            orderId={orderDetails.orderId}
            keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
            keySecret={process.env.REACT_APP_RAZORPAY_KEY_SECRET}
            items={orderDetails.items}
            productId={orderDetails.items[0].productId}
            coupon={orderDetails.coupon}
            onSuccess={onClose}
          />
        )}
        {/* <RenderRazorpay {...orderDetails} keyId={process.env.REACT_APP_RAZORPAY_KEY_ID} keySecret={process.env.REACT_APP_RAZORPAY_KEY_SECRET} onSuccess={onClose} /> */}
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Secure Payment Options
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" alt="MasterCard" width={40} />
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg" alt="Visa" width={40} />
            <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg" alt="PayPal" width={50} />
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d1/RuPay.svg" alt="RuPay" width={40} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default Razorpay;