import { useEffect, useState } from "react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [networkType, setNetworkType] = useState(navigator.connection?.effectiveType || "unknown");

  useEffect(() => {
    const updateNetworkStatus = () => {
      setIsOnline(navigator.onLine);
      setNetworkType(navigator.connection?.effectiveType || "unknown");
    };

    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);
    navigator.connection?.addEventListener("change", updateNetworkStatus);

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
      navigator.connection?.removeEventListener("change", updateNetworkStatus);
    };
  }, []);

  return { isOnline, networkType, ShimmerSimpleGallery };
};

export default useNetworkStatus;