import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Typography, Box, Grid, Paper, LinearProgress, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import OrganizationsHeader from "../PopupOrganizations/topbar";

const Postproject2 = () => {
  const [completionPercentage, setCompletionPercentage] = useState(10);
  const [selectedSkills1, setSelectedSkills1] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate();

  const handlePostDataClick = useCallback(
    (category) => {
      const categorySkills = {
        "Any": ["Adaptability", "Problem-solving", "Communication", "Critical Thinking"],
        "Internet of Things": ["C", "Python", "Arduino", "Raspberry Pi", "MQTT", "HTTP", "TCP/IP", "AWS IoT", "Azure IoT"],
        "Data Science": ["Python", "R", "MATLAB", "SQL", "NoSQL", "Tableau", "Power BI", "Pandas", "NumPy", "Matplotlib", "Seaborn"],
        "Machine Learning": ["Python", "Scikit-learn", "TensorFlow", "PyTorch", "Docker", "Flask"],
        "Embedded Systems": ["C", "C++", "Assembly", "Python", "UART", "SPI", "I2C", "RTOS"],
        "Cyber Security": ["SSL", "TLS", "VPN", "Kali Linux", "Metasploit", "Wireshark"],
        "Robotics": ["ROS", "AI", "Computer Vision", "Kinematics"],
        "Full Stack Web Development": ["HTML", "CSS", "JavaScript", "React", "Angular", "Node.js", "Django", "Flask", "SQL", "MongoDB", "Docker", "Kubernetes", "AWS", "Heroku"],
      };

      if (selectedCategory !== category) {
        setSelectedCategory(category);
        setSelectedSkills1(categorySkills[category]);
        // Save the selected category to localStorage
        localStorage.setItem("selectedCategory", category);
      }
    },
    [selectedCategory]
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedSkills1.length === 0) {
      toast.error("Please select at least one skill.");
      return;
    }

    //console.log("Selected Skills:", selectedSkills1);

    setCompletionPercentage((prevPercentage) => prevPercentage + 20);

    setTimeout(() => {
      navigate("/organizations/select-skills", { state: { skills: selectedSkills1, category: selectedCategory } });
    }, 2000);

    toast.success("Form submitted successfully!");
  };

  return (
    <div className="students-flex">
      <OrganizationsHeader />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="signup-pages-flex">
      <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
            padding: "20px 10px",
            height: { xs: "auto", sm: "100vh", md: "92vh" },
          }}
        >
          <Box sx={{ marginTop: { xs: 0, sm: 0 } }}>
            <Container maxWidth="sm">
              <Paper
                elevation={2}
                sx={{ borderRadius: 2, textAlign: "center", padding: "10px 20px 30px 20px" }}
              >
                <form onSubmit={handleSubmit}>
                  <Typography variant="h4" style={{ color: "#052659" }}>
                    Which industry is this project for?
                  </Typography>
                  <Typography color="textSecondary" sx={{ marginBottom: 2, textAlign: "center" }}>
                    Choose the industry for your project to match you with the perfect engineering team.
                  </Typography>
                  <Grid container spacing={1}>
                    {[
                      "Any",
                      "Internet of Things",
                      "Data Science",
                      "Machine Learning",
                      "Embedded Systems",
                      "Cyber Security",
                      "Robotics",
                      "Full Stack Web Development",
                    ].map((skill, index) => (
                      <Grid item xs={6} sm={6} key={index}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => handlePostDataClick(skill)}
                          disabled={selectedCategory === skill}
                        >
                          {skill}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ marginY: 6, display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" sx={{ marginRight: 2 }}>
                    {selectedCategory}
                  </Typography>
                  {selectedCategory && (
                    <FontAwesomeIcon 
                      icon={faTrash} 
                      style={{ cursor: "pointer", color: "#FF0000" }} 
                      onClick={() => setSelectedCategory("")} 
                    />
                  )}
                </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
                    <Button
                      component={Link}
                      to="/organizations/post-project"
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                      sx={{
                        margin: 0.5,
                        color: "#052659",
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      sx={{
                        margin: 0.5,
                        backgroundColor: "#043885",
                        "&:hover": {
                          backgroundColor: "#052659",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </form>

                {/* Progress bar */}
                <Box sx={{ marginTop: 4 }}>
                  <Typography variant="body2" gutterBottom>
                    Step 2 of 6
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={completionPercentage}
                    sx={{
                      borderRadius: 1,
                      height: 10,
                      backgroundColor: "#f5f5f5",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "green",
                      },
                    }}
                  />
                  <Typography variant="body2" align="right" sx={{ mt: 1, fontWeight: 600 }}>
                    {completionPercentage}%
                  </Typography>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Postproject2;