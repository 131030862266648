import React,{ useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FaRegCheckCircle } from "react-icons/fa";
import { Learning } from "./LearningPath";
import Banner1 from "../../../assets/learners/industryExperience.png";
import Reviews from "../../../mainpage/Homepage/LearnersReviews";
import IndustryReadyIoT from "./IndustryReady";
import Icon1 from "../../../assets/learners/icon1.png";
import Icon2 from "../../../assets/learners/icon2.png";
import Icon3 from "../../../assets/learners/icon3.png";
import Icon4 from "../../../assets/learners/icon4.png";
import Courseslist from "./courseslist";
//import Workese from "./workses";
import Pathlearners from "../../../mainpage/Homepage/huebit";
import Studentscourses from "./achievements";
import { FaTwitter, FaInstagram,FaLinkedin, FaYoutube, FaArrowUp, FaFacebook } from 'react-icons/fa';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import Footer from "../../../mainpage/Footer/learnersFooter";
import Footerimage from "../../../assets/learners/footer.png";
import Reveal from '../../../helper/useScrollposition';
import "./learners.css";

const Students = ({ openModel }) => {
  const footerSectionRef = useRef(null);
  const StartnowSectionRef = useRef(null);

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToStartnowSection = () => {
    StartnowSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  useEffect(() => {
      if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
        var scriptElement = document.createElement("script");
        scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
        scriptElement.async = true;
        scriptElement.charset = "UTF-8";
        scriptElement.setAttribute("crossorigin", "*");
        
        document.body.appendChild(scriptElement);
      }
    }, []);

    return (
    <div className={`students-flex companies-flex ${openModel ? 'modal-open-opacity' : '0.50'}`}>
        <Headrepage />
        <div className="homepagecolores">
          <div className="learnerspage-banners1">
            <div ref={footerSectionRef} className="learnerspage-banner1">
            <ul className="learnerspage-banner1-ul">
                <li>
                <h1 className="learnerspage-banner1-h1">Empower Your Future With
                <h1 className="learnerspage-banner1-h1"><span>Real Industry Experience!</span></h1></h1>
                  <p className="learnerspage-banner1-p1">Gain invaluable industry experience with our industry-ready programs, 
                    featuring AI-powered boot camps, courses, projects, and strong industry connections</p>
                  <p className="learnerspage-banner1-p2"><span><FaRegCheckCircle /></span> 100% Industry Experience</p>
                  <p className="learnerspage-banner1-p3"><span><FaRegCheckCircle /></span> Guaranteed Internship With Stipend</p>
                  <p className="learnerspage-banner1-p4"><span><FaRegCheckCircle /></span> Placement Assistance!</p>
                </li>
                <li onClick={scrollToStartnowSection} ><button className="learnerspage-banner1-btn">Learn More
                <FontAwesomeIcon icon={faArrowRight} className="learnerspage-banner1-arrow-arrowright"/>
                </button>
                </li>
                </ul>
                <div className="learnerspage-banner1-images">
                <img className="learnerspage-banner1-image" src={Banner1} alt=""/>
                </div>
            </div>
            </div>
            <div className="learnerspage-banner2" ref={StartnowSectionRef}>
              {/* <p className="learnerspage-banner2-p">Industry Ready Programs</p> */}
              <div className="learnerspage-banner2-list">
              {/* <h1 className="learnerspage-banner2-h1"><span>A Guaranteed Way to Start Your Career</span></h1> */}
              <h1 className="learnerspage-banner2-h1"><span>Your Gateway to IoT, AI, Data Science & Embedded Systems!</span>
              <p className="learnerspage-banner2-p">Choose your industry-ready program! Learn, build projects, participate in hackathons and become job-ready</p></h1>
              {/* <ul className="learnerspage-banner2-ul">
                <li><FontAwesomeIcon icon={faCheck} className="learnerspage-banner2-ul-icon"/> Guaranteed placement</li>
                <li><FontAwesomeIcon icon={faCheck} className="learnerspage-banner2-ul-icon"/> 100% refund if you're not hired.</li>
                <li><FontAwesomeIcon icon={faCheck} className="learnerspage-banner2-ul-icon"/> Become job ready</li>
              </ul> */}
              </div>
              <Reveal>
              <div className="learnerspage-banner2-industryreadyiot">
                <IndustryReadyIoT />
              </div>
              </Reveal>
              </div>
              <Reveal>
              <div className="learnerspage-banner3">
                <h1 className="learnerspage-banner3-h1"><span>Elevate Your Learning Journey With Our Unique Offerings!</span>
                <p className="learnerspage-banner3-p">Embark on an educational adventure like no other with our learner-centric platform.</p></h1>
                <p className="learnerspage-banner3-p1"> Discover what sets us apart and explore the array of benefits waiting for you</p>
              <Learning />
              </div>
              </Reveal>
              <Reveal>
              <div className="learnerspage-banner8">
                <h1 className="learnerspage-banner8-h1"><span> The Huebits Trainings Advantage</span>
                <p className="learnerspage-banner8-p">Industry-Driven Content with Hybrid Learning, Crafted for Internships and Job Placements</p></h1>
                <div className="learnerspage-banner8-cards">
                  <div className="learnerspage-banner8-card1">
                    <img className="learnerspage-banner8-card1-img" src={Icon1} alt="" />
                    <h1>Industry Grade Content</h1>
                    <p className="learnerspage-banner8-card1-p">With practical learning</p>
                  </div>
                  <div className="learnerspage-banner8-card1">
                    <img className="learnerspage-banner8-card1-img" src={Icon2} alt="" />
                    <h1>100% Placement Assistance</h1>
                    <p className="learnerspage-banner8-card1-p">To help you launch your career</p>
                  </div>
                  <div className="learnerspage-banner8-card1">
                    <img className="learnerspage-banner8-card1-img" src={Icon4} alt="" />
                    <h1>Real Industry Experience</h1>
                    <p className="learnerspage-banner8-card1-p">Through freelance opportunities and internships</p>
                  </div>
                  <div className="learnerspage-banner8-card1">
                    <img className="learnerspage-banner8-card1-img" src={Icon3} alt="" />
                    <h1>Excellence Certified</h1>
                    <p className="learnerspage-banner8-card1-p">And recognized by top companies</p>
                  </div>
                </div>
              </div>
              </Reveal>
              <Reveal>
              <div className="learnerspage-banner4">
                <h1 className="learnerspage-banner4-h1">Explore Our Courses and Hands-on Projects</h1>
                  <Courseslist />
              </div>
              </Reveal>
              <div className="learnerspage-banner5">
                <h1 className="learnerspage-banner5-h1"><span>Design the Future with Creative Journey</span></h1>
                <Reveal>
                  <Pathlearners />
                {/* <Workese /> */}
              </Reveal>
              </div>
              <div>
              <div>
                <div className="learnerspage-banner6">
                <h1 className="learnerspage-banner6-h1">Our Achievements</h1>
                <Reveal>
                <div>
                <Studentscourses />
                </div>
                <div> 
                <div className="learnerspage-banner6-button">
                <p onClick={scrollToStartnowSection} ><button className="learnerspage-banner6-btn">Join Now
                </button>
                </p>
                </div>
                </div>
                </Reveal>
              </div>
              <div className="learnerspage-banner7">
              <h1 className="learnerspage-banner7-h1">Testimonials</h1>
              <p className="learnerspage-banner7-p">Discover what our learners have to say about their experience with us.</p>
              <Reveal>
                <Reviews />
              </Reveal>
              </div>
             <Reveal>
            <div className="backgroundcolorin-images">
              <div className="learnerspage-page-banner10-card">
                <ul className="organizations-page-banner10-ul">
                <li>
                <h1 className="organizations-page-banner10-h1">Learn Skills</h1>
                <h1 className="organizations-page-banner10-h1">Earn Experience</h1>
                <p className="organizations-page-banner10-p">Join huebits and become expert</p>
                </li>
                <li>
                <div className="buttonget-join-now-btn-link">
                  <button onClick={scrollToStartnowSection} className="buttonget-join-now-btn">Join Now</button>
                  </div>
                </li>
                </ul>
                <div className="learnerspage-page-banner10-images">
                <img className="learnerspage-page-banner10-image" src={Footerimage} alt=""/>
                </div>
              </div>
            </div>
            </Reveal>
             <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                  <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
                <Link onClick={scrollToFooterSection}> 
                <FaArrowUp className="homepage-social-icon-link" />
                </Link>
            </div>
            </div>
            </footer>
          </div>
        </div>
        </div>
       </div>
      </div>
    )
}

export default Students;
