import React from 'react';
import { FaBookOpen } from "react-icons/fa";
import { HiLightBulb, HiBriefcase } from "react-icons/hi2";
import Image from "../../../assets/learners/Pages/1.png";
import Course1 from "../../../assets/icons/icons/1.png";
import Course2 from "../../../assets/icons/icons/2.png";
import Course3 from "../../../assets/icons/icons/3.png";
import Course4 from "../../../assets/icons/icons/4.png";
import Course5 from "../../../assets/icons/icons/5.png";
//import Course6 from "../../../assets/icons/icons/6.png";

const IotCourses = () => {

  return (
    <div className='learners-iot-banner4-cards'>
      <div>
      <h1 className="learners-iot-banner4-h1"><span>Step into the Future of IOT</span>
      <p className="learners-iot-banner4-p">Empowering learners and professionals to innovate and excel</p></h1>
    <div className='learners-iot-banner4-card'>
        <div>
            <p className='learners-iot-banner4-card-h2'>
                <p><FaBookOpen className="learners-iot-banner4-card-icon" /></p>
                <p className='learners-iot-banner4-card-p'><strong>50+</strong></p> 
                <p className='learners-iot-banner4-card-p-span'>Courses covering every aspect of IoT</p>
            </p>
        </div>
        <div>
            <p className='learners-iot-banner4-card-h2'>
                <p><HiLightBulb className="learners-iot-banner4-card-icon" /></p>
                <p className='learners-iot-banner4-card-p'><strong>70+</strong></p> 
                <p className='learners-iot-banner4-card-p-span'>Hands-on projects to spark your IoT creativity</p>
            </p>
        </div>
        <div>
            <p className='learners-iot-banner4-card-h2'>
                <p><HiBriefcase className="learners-iot-banner4-card-icon" /></p>
                <p className='learners-iot-banner4-card-p'><strong>1000+</strong></p> 
                <p className='learners-iot-banner4-card-p-span'>IoT internship opportunities for industry connections</p>
            </p>
        </div>
    </div>
    </div>
    <div className='learners-iot-banner4-card-image'>
    <img src={Image} alt='images' className='learners-iot-banner4-card-img' />
    </div>
</div>
  )
}
const LearningPaths = () => {
  return (
    <div>
     <ul className="learners-iot-banner5-ul">
      <p className="learners-iot-banner5-li-p">
        <div className='learners-iot-banner5-iot-image'>
        <img src={Course1} alt="" className="learners-iot-banner5-iot-img"/>
        </div>
        <li className="learners-iot-banner5-li">IoT Developer</li>
      </p>
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course2} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">IoT Data Scientist</li>
      </p>
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course3} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">Industrial IoT & Industry 4.0</li>
      </p>
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course4} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">IoT Hardware Engineer</li>
      </p>
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course5} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">IoT Security Specialist</li>
      </p>
      {/* <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course6} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">Customized Learning Path with AI</li>
      </p> */}
    </ul>
    {/* <ul className="learners-iot-banner5-ul">
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course4} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">IoT Hardware Engineer</li>
      </p>
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course5} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">IoT Security Specialist</li>
      </p>
      <p className="learners-iot-banner5-li-p">
      <div className='learners-iot-banner5-iot-image'>
      <img src={Course6} alt="" className="learners-iot-banner5-iot-img"/>
      </div>
      <li className="learners-iot-banner5-li">Customized Learning Path with AI</li>
      </p>
    </ul> */}
  </div>
  );
};


export { IotCourses, LearningPaths };