import React, { useState } from "react";
import { TextField, Button, Container, Grid, Typography, Paper } from "@mui/material";

const AreasOfInterestForm = ({ setActiveLink, activeLink }) => {
  const [formData, setFormData] = useState({
    preferredIndustries: "",
    topicsOfInterest: "",
    experience: "",
    previousInternships: "",
    freelancingProjects: "",
    academicProjects: "",
    certifications: "",
    portfolio: "",
    title: "",
    description: "",
    technicalSkills: "",
    languages: "",
    hobbies: "",
    preferredJobRole: "",
    preferredLocation: "",
    professionalSummary: "",
    references: "",
    additionalInformation: "",
    goalsAndAspirations: "",
    currentOccupation: "",
    availability: "",
    linkedInProfile: "",
  });

  const [errors, setErrors] = useState({});

  const placeholders = {
    preferredIndustries: "e.g. Software Development, Finance",
    topicsOfInterest: "e.g. AI, Web Development",
    experience: "e.g. 3 years in Software Engineering",
    previousInternships: "e.g. Google Summer Internship 2023",
    freelancingProjects: "e.g. Built a portfolio website for a client",
    academicProjects: "e.g. Machine Learning Research Paper",
    certifications: "e.g. AWS Certified Developer",
    portfolio: "e.g. https://yourportfolio.com",
    title: "e.g. Full Stack Developer",
    description: "e.g. Passionate about building scalable web apps",
    technicalSkills: "e.g. React, Node.js, MongoDB",
    languages: "e.g. English, Spanish, French",
    hobbies: "e.g. Reading, Coding, Hiking",
    preferredJobRole: "e.g. Frontend Developer",
    preferredLocation: "e.g. Remote, New York",
    professionalSummary: "e.g. Experienced developer with a passion for coding",
    references: "e.g. John Doe - Senior Developer at XYZ",
    additionalInformation: "e.g. Willing to relocate",
    goalsAndAspirations: "e.g. Become a lead software architect",
    currentOccupation: "e.g. Software Engineer at ABC Corp",
    availability: "e.g. Full-time, Immediate",
    linkedInProfile: "e.g. https://linkedin.com/in/yourprofile",
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // Clear error when user types
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let validationErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        validationErrors[key] = "* Required";
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log("User Data:", formData);
    localStorage.setItem("userProfileData", JSON.stringify(formData));

    // Move to next section (Technical Skills)
    setActiveLink("applywork");
  };

  const handleBack = () => {
    // Move to previous section (you can update this based on your form navigation logic)
    setActiveLink("skills"); // Replace with the appropriate section name
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Areas of Interest Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {Object.keys(formData).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  variant="outlined"
                  placeholder={placeholders[key]}
                  error={!!errors[key]}
                  helperText={errors[key]}
                />
              </Grid>
            ))}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Button variant="outlined" color="secondary" fullWidth onClick={handleBack}>
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default AreasOfInterestForm;
