import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { Box, Typography, Paper, Grid } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LanguageIcon from '@mui/icons-material/Language';

const Reveal = ({ children }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, y: 50, scale: 0.9 },
        visible: { opacity: 1, y: 0, scale: 1, transition: { duration: 0.8, type: 'spring' } },
    };

    const stats = [
        { title: 'Students', count: 20, suffix: 'K+', icon: <SchoolIcon fontSize="large" /> },
        { title: 'Courses', count: 40, suffix: '+', icon: <MenuBookIcon fontSize="large" /> },
        { title: 'Projects', count: 75, suffix: '+', icon: <BusinessCenterIcon fontSize="large" /> },
        { title: 'Companies', count: 20, suffix: '+', icon: <ApartmentIcon fontSize="large" /> },
        { title: 'Deep Techs', count: 5, suffix: '+', icon: <LanguageIcon fontSize="large" /> },
    ];

    return (
        <motion.div ref={ref} animate={controls} initial="hidden" variants={variants}>
            <Box sx={{ maxWidth: "100%", mx: 'auto', textAlign: 'center', p: 4 }}>
                <Grid container spacing={4} justifyContent="center">
                    {stats.map((item, index) => (
                        <Grid item xs={12} sm={2.4} key={index}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Paper 
                                    elevation={8} 
                                    sx={{ 
                                        p: 2, 
                                        bgcolor: 'background.paper',
                                        color: 'rgb(27, 110, 244)',
                                        borderRadius: 3,
                                        transition: '0.4s',
                                        textAlign: 'center',
                                        '&:hover': {
                                            bgcolor: 'rgb(27, 110, 244)',
                                            color: 'white',
                                            boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
                                        }
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {item.icon}
                                        <Typography variant="h3" fontWeight={700} sx={{ pt: 2 }}>
                                            {inView && <CountUp end={item.count} duration={3} />} {item.suffix}
                                        </Typography>
                                        <Typography variant="h6" fontWeight={600} sx={{ pt: 1 }}>
                                            {item.title}
                                        </Typography>
                                    </Box>
                                </Paper>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {children}
        </motion.div>
    );
};

export default Reveal;
