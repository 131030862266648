import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp, FaFacebook } from 'react-icons/fa';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { LiaRupeeSignSolid } from "react-icons/lia";
//import { AiOutlineThunderbolt } from "react-icons/ai";
//import { MdOutlineTrendingUp } from "react-icons/md";
import DataScience from "../../../assets/learners/Pages/embeddedSystems.png";
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
//import Empower from "../../../assets/Internetofthings.png";
import { Embedded } from "./embedded";
import { PdfEmbeddeddownload } from "./pdfdownload";
import Razorpay from '../../../mainpage/RazorpayPayment/EmbeddedSystems-razorpay';
import Iotimage from "../../../assets/learners/Pages/6.png";
import { Button, Box } from '@mui/material';
import { EmbeddedSystemsCourses, EmbeddedSystemsProjects } from "./EmbeddedSystemCourses";
//Technology
//Tools & Development Environments
import KeilµVision from "../../../assets/Technology/Embedded-tools-logos/1.png";
import STM32CubeIDE from "../../../assets/Technology/Embedded-tools-logos/2.png";
import ESPIDF from "../../../assets/Technology/Embedded-tools-logos/3.png";
import EclipseIDE from "../../../assets/Technology/Embedded-tools-logos/4.png";
import VSCode from "../../../assets/Technology/Embedded-tools-logos/5.png";
import GNUToolchain from "../../../assets/Technology/Embedded-tools-logos/6.png";
import Buildroot from "../../../assets/Technology/Embedded-tools-logos/7.png";
import YoctoProject from "../../../assets/Technology/Embedded-tools-logos/8.png";
import MPLABXIDE from "../../../assets/Technology/Embedded-tools-logos/9.png";
import QEMU from "../../../assets/Technology/Embedded-tools-logos/10.png";
//Embedded Software & Operating Systems
import FreeRTOS from "../../../assets/Technology/Embedded-tools-logos/12.png";
import ZephyrRTOS from "../../../assets/Technology/Embedded-tools-logos/13.png";
import LinuxKernel from "../../../assets/Technology/Embedded-tools-logos/14.png";
import UBoootBootloader from "../../../assets/Technology/Embedded-tools-logos/15.png";
import Androidthings from "../../../assets/Technology/Embedded-tools-logos/16.png";
import ThreadX from "../../../assets/Technology/Embedded-tools-logos/17.png";
//Cloud Platforms & IoT Services
import AWSIoTCore from "../../../assets/Technology/Embedded-tools-logos/19.png";
import Microsoft from "../../../assets/Technology/Embedded-tools-logos/20.png";
import Google from "../../../assets/Technology/Embedded-tools-logos/21.png";
import ThingsBoard from "../../../assets/Technology/Embedded-tools-logos/22.png";
import NodeRED from "../../../assets/Technology/Embedded-tools-logos/23.png";
import MQTT from "../../../assets/Technology/Embedded-tools-logos/24.png";
import CoAP from "../../../assets/Technology/Embedded-tools-logos/25.png";
import HTTP from "../../../assets/Technology/Embedded-tools-logos/26.png";
import Firebase from "../../../assets/Technology/Embedded-tools-logos/27.png";
//Communication & Networking Protocols
import Modbus from "../../../assets/Technology/Embedded-tools-logos/28.png";
import Modbuses from "../../../assets/Technology/Embedded-tools-logos/29.png";
import WebSockets from "../../../assets/Technology/Embedded-tools-logos/30.png";
import LoRaWAN from "../../../assets/Technology/Embedded-tools-logos/31.png";
//Embedded Hardware & Platforms
import ARMCortex from "../../../assets/Technology/Embedded-tools-logos/33.png";
import ESP32 from "../../../assets/Technology/Embedded-tools-logos/34.png";
import Raspberrypi from "../../../assets/Technology/Embedded-tools-logos/35.png";
import BeagleBone from "../../../assets/Technology/Embedded-tools-logos/36.png";
import Android from "../../../assets/Technology/Embedded-tools-logos/37.png";
import NAVIDIAjetson from "../../../assets/Technology/Embedded-tools-logos/38.png";
import Platforms from "../../../assets/Technology/Embedded-tools-logos/39.png";
import Pic from "../../../assets/Technology/Embedded-tools-logos/40.png";
//Embedded Security & Cryptography
import Security from "../../../assets/Technology/Embedded-tools-logos/42.png";
import Azure from "../../../assets/Technology/Embedded-tools-logos/43.png";
import Hardware from "../../../assets/Technology/Embedded-tools-logos/44.png";
import Cryptography from "../../../assets/Technology/Embedded-tools-logos/45.png";
import EmbeddedDevices from "../../../assets/Technology/Embedded-tools-logos/46.png";
import TrustedPlatform  from "../../../assets/Technology/Embedded-tools-logos/47.png";
//Programming Languages & Frameworks
import C from "../../../assets/Technology/Embedded-tools-logos/49.png";
import Pyhton from "../../../assets/Technology/Embedded-tools-logos/50.png";
import Assembly from "../../../assets/Technology/Embedded-tools-logos/51.png";
import BashScripting from "../../../assets/Technology/Embedded-tools-logos/52.png";
import JavaScript from "../../../assets/Technology/Embedded-tools-logos/53.png";
import MATLAB from "../../../assets/Technology/Embedded-tools-logos/54.png";
import Rust from "../../../assets/Technology/Embedded-tools-logos/55.png";
//AI & Machine Learning for Embedded Systems
import TensorFlow from "../../../assets/Technology/Embedded-tools-logos/57.png";
import EdgeImpulse from "../../../assets/Technology/Embedded-tools-logos/58.png";
import OpenCV from "../../../assets/Technology/Embedded-tools-logos/59.png";
import PyTorchMobile from "../../../assets/Technology/Embedded-tools-logos/60.png";
import TinyML from "../../../assets/Technology/Embedded-tools-logos/61.png";

import Reviews from "../../../mainpage/Homepage/LearnersReviews";
import Footer from "../../../mainpage/Footer/learnersFooter";
import Reveal from '../../../helper/useScrollposition';


const EmbeddedSystems = () => {
  const footerSectionRef = useRef(null);
  const [category, setCategory] = useState("courses");

  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
    const [move, setMove] = useState(false);
    const [showRazorpay, setShowRazorpay] = useState(false); // New state
  
       const handleClick = () => {
         setMove(true);
         setTimeout(() => {
           setShowRazorpay(true); // Show Razorpay component on click
             setMove(false);
         }, 500);
     };

    useEffect(() => {
          if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
            var scriptElement = document.createElement("script");
            scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
            scriptElement.async = true;
            scriptElement.charset = "UTF-8";
            scriptElement.setAttribute("crossorigin", "*");
            
            document.body.appendChild(scriptElement);
          }
        }, []);

    return (
        <div className="students-flex homepagecoloresiot">
        <Headrepage />
        <div className="homepagecolores">
        <Reveal>
        <div>
        <div ref={footerSectionRef}>
        <div className="learners-datascience-banner1">
          <ul className="learners-iot-banner1-ul">
            <li>
            <h1 className="learners-iot-banner1-h1">
                Industry-Ready Embedded Systems Training Program
            </h1>          
            <p className="learners-iot-banner1-p1">Your Complete Solution for All Embedded Systems Training Needs</p>
            {/* <p className="learners-iot-banner1-p2"><strong>2+ </strong>Bootcamps | <strong>50+ </strong> Embedded Systems Courses | <strong>30+</strong> Embedded Systems Technologies</p> */}
            <p className="learners-iot-banner1-p2"><strong>2+ </strong>Boot Camps | <strong>50+ </strong> Embedded Systems Courses | Validity: 1 Year </p>
            <p className="learners-iot-banner1-p3"><span><FaRegCheckCircle /></span> Guaranteed Industrial Internship</p>
            </li>
          </ul>
            <img className="learners-iot-banner1-image" src={DataScience} alt=""/>
          </div>
          <div className="learners-dataScience-banner2">
          <ul className="learners-iot-banner2-ul">
            <li className="learners-iot-banner2-ul-li1">
              <p><IoCalendarOutline className="learners-iot-banner2-p1-icon" /></p>
              <p className="learners-iot-banner2-ul-p1">
                <p>Admission Closes Today</p>
                <p className="learners-iot-banner2-li1-seates"> Limited Seats</p>
              </p>
              </li>
              <li className="learners-iot-banner2-ul-li2">
              <p><HiOutlineBriefcase className="learners-iot-banner2-p2-icon" /></p>
              <p className="learners-iot-banner2-ul-p2">Placement Support | 1.2 Lakh+ Opportunities</p>
            </li>
            <li className="learners-iot-banner2-ul-li3">
              <p><LiaRupeeSignSolid className="learners-iot-banner2-p3-icon" /></p>
              <p className="learners-iot-banner2-ul-p3">
                  <p className="learners-iot-banner2-ul-p3-price">14,300</p>
                  <p className="learners-iot-banner2-ul-p3-old-price-span"><span className="learners-iot-banner2-ul-p3-old-price">30,000 </span> <span className="span"> 15,700</span>
                  </p>
                  <p className="learners-iot-banner2-li2-seates">Valid for Today</p>
              </p>
            </li>
            <li className="learners-iot-banners2-button">
              <PdfEmbeddeddownload />
            </li>
            <li className="learners-iot-banner2-button">
              <button
                className={`learners-iot-banner2-btn ${move ? "move-right" : ""}`}
                onClick={handleClick}
              >
                Enroll Now
              </button>
              <span className="arrow-container">
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
              </span>
            </li>
            {/* <p className="learners-iot-banner2-ul-li5-p"><MdOutlineTrendingUp className="learners-iot-banner2-p5-icon" />
            192,523 already applied
            </p> */}
          </ul>
          </div>
          {/* Show Razorpay component when button is clicked */}
          {showRazorpay && <Razorpay onClose={() => setShowRazorpay(false)} />}
        </div>
        </div>
        <div className="learners-dataScience-banner3">
          <h1 className="learners-iot-banner3-h1"><span>Comprehensive Trainings</span>
          <p className="learners-iot-banner3-p">Gain in-demand skills, hands-on experience, and career support to turn your ambitions into reality</p></h1>
        <div className="learners-embedded-systems-banner3-cards">
          <p>Establish a Strong Foundation in Embedded Systems & Programming</p>
          <p>Master Real-Time Systems, Security, and Embedded Linux</p>
          <p>Learn IoT Architectures, Protocols, and Cloud Integration</p>
          <p>Develop and Optimize Embedded Linux Solutions</p>
          <p>Specialize in Automotive Embedded Systems & Safety Standards</p>
        </div>
        </div>
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner4">
          <div>
            <Embedded />
          </div>
        </div>
        </Reveal>
        <Reveal>
        {/* <div className="learners-iot-banner5">
          <h1 className="learners-iot-banner5-h1">IoT Learning Paths</h1>
          <p className="learners-iot-banner5-p">Explore our IoT learning paths customized for your skill level Leverage AI-driven customization for a personalized experience 
            that advances both your career and organizational success</p>
          <div>
            <LearningPaths />
          </div>
        </div> */}
        </Reveal>
        <Reveal>
        <div className="learners-iot-banner6">
          <h1 className="learners-iot-banner6-h1"><span>Guaranteed Internships and Placement Assistance: Master Embedded Systems Skills With Us</span></h1>
          <div className="learners-iot-banner6-cards">
          <img src= {Iotimage} alt="iot" className="learners-iot-banner6-card-image"/>
          <div className="learners-iot-banner6-card">
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Boot Camps</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Expert Training: Master Embedded Systems With Hands-on Courses and Projects</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Guaranteed Internships: Secure Paid Internships</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Job Placement Assistance: Get Dedicated Job Placement Support</p>
            </div>
            <div className="learners-iot-banner6-card-1">
              <p className="learners-iot-banner6-card-1-p1">Real-World Experience: Gain Practical Industry Experience</p>
            </div>
            </div>
          </div>
          </div>
          </Reveal>
          <Reveal>
          <div className="learners-iot-banner8">
            <h1 className="learners-iot-banner8-h1">Embedded Systems Courses & Projects: Learn, Build, Experience, Innovate</h1>
            {/* Category Buttons */}
            <Box>
              <Box display="flex" 
              justifyContent={{ xs: "center", md: "flex-start" }} 
              marginTop={4} 
              marginBottom={1}
              sx={{ paddingLeft: { md: "24px" } }} // Add 10px left padding only on desktop
              >
                <Button 
                  variant={category === 'courses' ? 'contained' : 'outlined'} 
                  onClick={() => setCategory('courses')}
                  sx={{ marginRight: 2, textTransform: "none" }}
                >
                  Courses
                </Button>
                <Button 
                  variant={category === 'projects' ? 'contained' : 'outlined'} 
                  onClick={() => setCategory('projects')}
                  sx={{ textTransform: "none" }}
                >
                  Projects
                </Button>
              </Box>
               {/* Conditional Rendering Based on Selected Category */}
               {category === 'courses' ? <EmbeddedSystemsCourses /> : <EmbeddedSystemsProjects />}
            </Box>
            </div>
            </Reveal>
            <Reveal>
            <div className="learners-iot-banner7">
              <h1 className="learners-iot-banner7-h1">Explore the World of Embedded Systems Technologies</h1>
            <div className="learners-iot-tech-talent-banner7-cards">
            <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Development Environments</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={KeilµVision} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Keil µVision</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={STM32CubeIDE} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>STM32CubeIDE</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={ESPIDF} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>ESP-IDF</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={EclipseIDE} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Eclipse IDE</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={VSCode} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>VS Code</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={GNUToolchain} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>GNU Toolchain</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Buildroot} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Buildroot</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={YoctoProject} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Yocto Project</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={MPLABXIDE} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>MPLAB X IDE</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={QEMU} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>QEMU</p>
            </div>
            </div>
            
          </div>
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Embedded Software & Operating Systems</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={FreeRTOS} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>FreeRTOS</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={ZephyrRTOS} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Zephyr RTOS</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={LinuxKernel} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Linux Kernel</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={UBoootBootloader} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>U-Booot Bootloader</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Androidthings} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Android Things(IoT)</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={ThreadX} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>ThreadX</p>
            </div>
            </div>
            
          </div>
          </div>
          <div className="learners-iot-tech-talent-banner7-cards">
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Cloud Platforms & IoT Services</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
              <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={AWSIoTCore} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>AWS IoT Core</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Microsoft} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Microsoft Azure IoT Hub</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Google} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Google Cloud IoT Core</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={ThingsBoard} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>ThingsBoard</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={NodeRED} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Node-RED</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={MQTT} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>MQTT</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={CoAP} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>CoAP</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={HTTP} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>HTTP</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Firebase} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Firebase</p>
            </div>
            </div>
          </div>
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Communication & Networking Protocols</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Modbuses} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>UART, SPI, I2C,RS-232</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Modbus} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>CAN, LIN, FlexRay, 
              <p>RS-485, Modbus</p></p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={WebSockets} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>MQTT, CoAP, HTTP,
            <p>WebSockets</p></p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={LoRaWAN} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>BLE, LoRaWAN, Zigbee,
              <p>Z-Wave, 6LoWPAN</p></p>
            </div>
            </div>
          </div>
          </div>
          <div className="learners-iot-tech-talent-banner7-cards">
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Embedded Hardware & Platforms</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={ARMCortex} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>ARM Cortex</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={ESP32} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>ESP32, ESP8266</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Raspberrypi} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Raspberry Pi</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={BeagleBone} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>BeagleBone Black</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Android} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Android</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={NAVIDIAjetson} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>NAVIDIA jetson</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Platforms} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>FPGA & SoC Platforms (Xilinx, Altera)</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Pic} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>TI MSP430, PIC Microcontrollers</p>
            </div>
            </div>
          </div>
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Embedded Security & Cryptography</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Security} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Secure Boot & Firmware Security</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Azure} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Microsoft Azure IoT Hub</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Hardware} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Hardware Security Modules (HSM)</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Cryptography} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>AES, RSA, ECC Cryptography</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={EmbeddedDevices} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>TLS/SSL for Embedded Devices</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={TrustedPlatform} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>TPM (Trusted Platform Module)</p>
            </div>
            </div>
          </div>
          </div>
          <div className="learners-iot-tech-talent-banner7-cards">
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>Programming Languages & Frameworks</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={C} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>C/C++</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Pyhton} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Pyhton</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Assembly} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Assembly (ARM, AVR, x86)</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={JavaScript} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>JavaScript & Node.js</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={MATLAB} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>MATLAB</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={Rust} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Rust</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={BashScripting} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Bash Scripting (Linux for Embedded Systems)</p>
            </div>
            </div>
          </div>
          <div className="learners-embedded-tech-talent-banner7-card-1">
            <h2>AI & Machine Learning for Embedded Systems</h2>
            <div className="learners-iot-tech-talent-banner7-card-protocols">
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={TensorFlow} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>TensorFlow Lite</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={EdgeImpulse} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>Edge Impulse</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={OpenCV} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>OpenCV for Embedded Vision</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={PyTorchMobile} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>PyTorch Mobile</p>
            </div>
            <div className="learners-embedded-tech-talent-banner7-cards-1">
            <img src={TinyML} alt="" className="learners-iot-tech-talent-banner7-logos-iot"/>
            <p>TinyML</p>
            </div>
            </div>
            </div>
            </div>
            <div className="learners-iot-banner10-button">
            <div className="learners-iot-banner2-button">
              <button
                className={`learners-iot-banner2-btn ${move ? "move-right" : ""}`}
                onClick={handleClick}
              >
                Enroll Now
              </button>
              <span className="arrow-container">
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
                <span className="fas fa-chevron-right" onClick={handleClick}></span>
              </span>
            </div>
            </div>
            </div>
            </Reveal>
            <Reveal>
           <div className="learners-iot-banner9">
           <h1 className="learners-iot-banner9-h1">What Our Customers Are Saying</h1>
            <Reviews />
            </div>
            </Reveal>
            <div className="homepage-footers-images">
            <Footer />
             <footer className="homepage-footers">
             <hr className='homepage-footer-hr'/>
             <div className="footer-social">
             <div className="homepage-footer-copyright">
              <p>© Huebits[2025].All Rights Reserved.</p>
             </div>
             <div className='footer-social-icons'>
                <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
                <Link onClick={scrollToFooterSection}>
                <FaArrowUp className="homepage-social-icon-link" />
                </Link>
            </div>
            </div>
          </footer>
      </div>
    </div>
    </div>
    )
}


export default EmbeddedSystems;