import ReactGA from "react-ga4";

const GA_ID = process.env.VITE_GOOGLE_ANALYTICS_ID; // Get ID from .env file

export const initializeGA = () => {
  if (GA_ID) {
    ReactGA.initialize(GA_ID);
    console.log("Google Analytics initialized:", GA_ID);
  }
};

export const trackPageView = (path) => {
  if (GA_ID) {
    ReactGA.send({ hitType: "pageview", page: path });
    console.log("Page view tracked:", path);
  }
};

export const trackEvent = (category, action, label = "", value = 0) => {
  if (GA_ID) {
    ReactGA.event({ category, action, label, value });
    console.log("Event tracked:", { category, action, label, value });
  }
};
