import React, { useState } from 'react';
import ApplyJobs from './Applynow/Apply-work';
import { Box, Button, Card, Typography } from '@mui/material';

const Applyforworks = () => {
    const [showApplyJobs, setShowApplyJobs] = useState(false);

    return (
        <Box 
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: { xs: 2, sm: 3, md: 4 }, // Adjust padding based on screen size
            }}
        >
            <Card 
                sx={{ 
                    width: '100%', 
                    // maxWidth: { xs: 340, sm: 450, md: 500 }, // Adjust width for different screens
                    p: { xs: 3, sm: 4, md: 5 }, // Responsive padding
                    textAlign: 'center', 
                    boxShadow: 4, 
                    borderRadius: '12px',
                }}
            >
                {showApplyJobs ? (
                    <ApplyJobs />
                ) : (
                    <>
                        <Typography 
                            variant="h5" 
                            sx={{ 
                                fontWeight: 'bold', 
                                color: '#052659', 
                                mb: { xs: 1, sm: 2 } // Responsive margin bottom
                            }}
                        >
                            Ready to Take the Next Step? 🚀
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: '#666', 
                                mb: { xs: 2, sm: 3 } // Adjust spacing for readability
                            }}
                        >
                            Click below to apply for exciting job opportunities.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => setShowApplyJobs(true)}
                            sx={{
                                background: 'linear-gradient(135deg, #5483B3, #052659)',
                                '&:hover': { background: 'linear-gradient(135deg, #052659, #345A80)' },
                                fontSize: { xs: '0.9rem', sm: '1rem' }, // Responsive font size
                                px: { xs: 3, sm: 4 }, // Button padding for different devices
                                py: { xs: 1, sm: 1.5 },
                                borderRadius: '8px',
                                boxShadow: 2,
                            }}
                        >
                            Apply Now
                        </Button>
                    </>
                )}
            </Card>
        </Box>
    );
};

export default Applyforworks;
