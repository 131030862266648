import React from "react";
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const courses = [
];

const Launched = () => {
    const chunkedCourses = [];
    const { isOnline } = useNetworkStatus();
    for (let i = 0; i < courses.length; i += 2) {
        chunkedCourses.push(courses.slice(i, i + 2));
    }

    return (
        <div>
         {isOnline ? (
        <div className="courseslist-courses">
            <div className="courseslist-launched-courses">
            <h1 className='courseslist'>Coming Soon</h1>
            {chunkedCourses.map((courseGroup, index) => (
                    <div key={index} className="courseslist-course-row">
                        {courseGroup.map((course, subIndex) => (
                            <div key={subIndex} className="courseslist-course-box">
                                <p className="courseslistp-myactive-courses">{course}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}
        </div>
        </div>
         ) : (
        <div>
            <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
        </div>
        )}
    </div>
    );
};

export default Launched;