import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import { getLearningPathData } from '../dashboard/api';
import { IoTdevelopercourses, IoTDataScientistcourses, IndustrialiotCourses } from '../learningpath/hooks/coursesData';
import { IoTdeveloperProjects, IoTDataScientistProjects } from '../learningpath/hooks/projectsList';
import Applyforworks from "./Apply-for-works";
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const LearningPathProgressDisplay = () => {
  const [selectedOption] = useState(localStorage.getItem('selectedLearningPath') || '');
  const [responseData, setResponseData] = useState({ HOE: 0 });
  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    if (selectedOption) {
  const fetchData = async () => {
    try {
      switch (selectedOption) {
        case 'IoT Developer / Engineer':
          getLearningPathData(selectedOption, IoTdevelopercourses, IoTdeveloperProjects, setResponseData);
          break;
        case 'IoT Data Scientist':
          getLearningPathData(selectedOption, IoTDataScientistcourses, IoTDataScientistProjects, setResponseData);
          break;
        case 'Industrial IoT & Industry 4.0':
          getLearningPathData(selectedOption, IndustrialiotCourses, [], setResponseData);
          break;
        default:
          console.log(`Invalid learning path in localStorage: "${selectedOption}"`);
      }
      } catch (error) {
        console.error("Error fetching learning path data:", error);
      } 
    };

    fetchData();
    }
  }, [selectedOption, setResponseData]);

  return (
    <div>
     {isOnline ? (
    <Box>
      {selectedOption && responseData.HOE >= 75 ? (
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f4f6f9',
          p: 3,
        }}
      >
        <Applyforworks />
      </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: "40px 0px"
          }}
        >
          <Card
            sx={{
              maxWidth: 500,
              padding: 3,
              textAlign: 'center',
              boxShadow: 3,
              borderRadius: '12px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#052659', mb: 1, fontSize: "1.4rem" }}>
              You're Almost There! 🚀
            </Typography>
            <Typography sx={{ color: '#333', mb: 1, fontSize: "1.2rem" }}>
              Your Hands-on Experience (HOE) score is {responseData.HOE || 'N/A'}%.  
              Reach 75% to qualify for job opportunities!
            </Typography>
            <Typography variant="body1" sx={{ color: '#666', mb: 4 }}>
              Keep learning, practicing, and improving your skills.  
              Every step forward brings you closer to your goal! 💪
            </Typography>
            <Link to="/learners/dashboard/internetofthings" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                sx={{
                  background: 'rgb(14, 104, 249)',
                  '&:hover': { background: 'rgb(10, 80, 200)' },
                  fontSize: '1.2rem',
                }}
              >
                Keep Learning
              </Button>
            </Link>
          </Card>
        </Box>
      )}
    </Box>
    ) : (
    <div>
        <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
    </div>
  )}
  </div>
);
};

export default LearningPathProgressDisplay;

// import React from "react";
// import Applyforworks from "./Apply-for-works";

// const Work = () => {
//     return (
//       <div>
//       <div className='mylearningpaths'>
//       <div className="students-works">
//         <Applyforworks />
//      </div>
//     </div>
//     </div>
//     )
// }

// export default Work;