import React, {useRef} from 'react';
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import Footer from "../Footer/Footer";
import Headerpage from "../Headerpages/HeaderPage";
import "./Abouts.css";

const Partnerships = () => {
   const footerSectionRef = useRef(null);
    
    const scrollToFooterSection = () => {
      footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <div className="students-flex companies-flex">
      <Headerpage />
      <div className="homepagecolores">
        <div ref={footerSectionRef} className='Careers-banner1'>
          <h1 className='Careers-banner1-h1'>Put your skills to work</h1>
        </div>
      <div className="homepage-footers-images">
      <Footer />
      <footer className="homepage-footers">
      <hr className='homepage-footer-hr'/>
        <div className="footer-social">
        <div className="homepage-footer-copyright">
          <p className="homepage-footer-copyrightp">© Huebits[2025].All Rights Reserved.</p>
        </div>
        <div className='footer-social-icons'>
          <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
          <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
          <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
          <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
          <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
          <Link onClick={scrollToFooterSection}>
          <FaArrowUp className="homepage-social-icon-link" />
          </Link>
        </div>
        </div>
      </footer>
      </div>
      </div>
    </div>
  )
}

export default Partnerships;
