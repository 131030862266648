import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Course1 from "../../../assets/learnersCourses/iotCourses/introductiontoInternetofThings.png";
import Course2 from "../../../assets/learnersCourses/iotCourses/microsoftAzureIoTCertification.png";
import Course3 from "../../../assets/learnersCourses/iotCourses/aWSIoTCertificationTraining.png";
import Course4 from "../../../assets/learnersCourses/iotCourses/pythonProgramming.png";
import Course5 from "../../../assets/Courses/datasciencecourses/DataScienceFoundation.png";
import Course6 from "../../../assets/Courses/datasciencecourses/PythonforDataScience.png";
import Course7 from "../../../assets/Courses/datasciencecourses/IntroductiontoBusinessIntelligence.png";
import Course8 from "../../../assets/Courses/datasciencecourses/DataVisualization.png";
import Course9 from "../../../assets/Courses/embedded-systems/1.png";
import Course10 from "../../../assets/Courses/embedded-systems/2.png";
import Course11 from "../../../assets/Courses/embedded-systems/13.png";
import Course12 from "../../../assets/Courses/embedded-systems/12.png";

const allCourses = [
  { id: 1, img: Course1, title: "Introduction to Internet of Things", category: "IoT" },
  { id: 2, img: Course2, title: "Microsoft Azure IoT Certification", category: "IoT" },
  { id: 3, img: Course3, title: "AWS IoT Certification Training", category: "IoT" },
  { id: 4, img: Course4, title: "Python Programming", category: "IoT" },
  // { id: 13, img: Course10, title: "Machine Learning", category: "IoT" },
  { id: 5, img: Course5, title: "Data Science Foundation", category: "Data Science" },
  { id: 6, img: Course6, title: "Python for Data Science", category: "Data Science" },
  { id: 7, img: Course7, title: "Business Intelligence", category: "Data Science" },
  { id: 8, img: Course8, title: "Data Visualization", category: "Data Science" },
  // { id: 14, img: Course10, title: "Machine Learning", category: "Data Science" },
  { id: 9, img: Course9, title: "Introduction to Embedded Systems", category: "Embedded Systems" },
  { id: 10, img: Course10, title: "Practical C Programming", category: "Embedded Systems" },
  { id: 11, img: Course11, title: "Embedded AI", category: "Embedded Systems" },
  { id: 12, img: Course12, title: "Linux Fundamentals", category: "Embedded Systems" },
  // { id: 15, img: Course8, title: "IoT Data Insights with Kafka", category: "Embedded Systems" },
];

const Courses = () => {
  const [selectedCategory, setSelectedCategory] = useState("IoT");
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const updateItemsPerPage = () => {
      setItemsPerPage(window.innerWidth <= 480 ? 2 : 5);
    };
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const filteredCourses = allCourses.filter((course) => course.category === selectedCategory);

  return (
    <div>
      {/* Category Selection Buttons */}
      <Grid
        container
        spacing={2}
        justifyContent={{ xs: "left", sm: "flex-start" }}
        sx={{ mt: 1, mb: 3, ml: { xs: 0, sm: 2 } }} // Using "ml" shorthand
      >
        <Grid item>
          <Button
            variant={selectedCategory === "IoT" ? "contained" : "outlined"}
            onClick={() => setSelectedCategory("IoT")}
          >
            Internet of Things
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedCategory === "Data Science" ? "contained" : "outlined"}
            onClick={() => setSelectedCategory("Data Science")}
          >
            Data Science
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedCategory === "Embedded Systems" ? "contained" : "outlined"}
            onClick={() => setSelectedCategory("Embedded Systems")}
          >
            Embedded Systems
          </Button>
        </Grid>
      </Grid>
      <Grid sx={{display:"flex", flexWrap:"wrap", gap:2, justifyContent: "center"}}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "center" }}>
        {filteredCourses.slice(0, itemsPerPage).map((course) => (
          <Card 
            key={course.id} 
            sx={{ 
              flex: "1 1 calc(20% - 16px)", 
              width: "280px",  /* Updated width */
              height: "240px", /* Updated height */
              padding: "4px", 
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <CardMedia 
              component="img" 
              height="160"  /* Adjusted to fit inside 180px height */
              image={course.img} 
              alt={course.title} 
              sx={{ 
                borderRadius: "10px", 
                objectFit: "cover"
              }} 
            />
            <CardContent sx={{ flexGrow: 1, padding: "8px" }}>
              <Typography variant="h6" sx={{ fontSize: "14px", color: "#333333" }}>
                {course.title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
      {/* View All Button */}
      <Grid sx={{ marginTop: "auto" }}>
        <Button
          component={Link}
          to={selectedCategory === "IoT" ? "/learners/internet-of-things" : selectedCategory === "Data Science" ? "/learners/data-science" : "/learners/embedded-systems"}
          variant="contained"
          color="primary"
          sx={{ marginTop: "10px", width:"130px"
           }}
        >
          View All<ArrowForwardIosIcon fontSize="small" style={{ marginLeft: "2px" }} />
        </Button>
      </Grid>
      </Grid>
    </div>
  );
};

export default Courses;
