import React, {useRef} from 'react';
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import Footer from "../Footer/Footer";
import Headerpage from "../Headerpages/HeaderPage";
//import Application from "./Applications/Iotapplication";

const Careers = () => {
   const footerSectionRef = useRef(null);
   //const [selectedJob, setSelectedJob] = useState(null); // Track selected job for the application popup
   //const [open, setOpen] = useState(false); // Control popup visibility

    const scrollToFooterSection = () => {
      footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    // const handleOpen = (jobTitle) => {
    //   setSelectedJob(jobTitle);
    //   setOpen(true);
    // };
  
    // const handleClose = () => {
    //   setOpen(false);
    //   setSelectedJob(null);
    // };

  return (
    <div className="students-flex companies-flex">
      <Headerpage />
      <div className="homepagecolores">
        <div ref={footerSectionRef} className='careers-banner'>
        <div className="careers-banner1">
        <h1 className="careers-banner1-h1">Careers</h1>
        <p className="careers-banner1-p">Join our current opening</p>
      </div>
      <div className="careers-banner2">
        <h1 className="careers-banner2-h1">Current Openings</h1>
        <div className='careers-banner2-cards'>
        <div className='careers-banner2-card'>
        <h2 className='careers-banner2-card-h2'>IoT Intern</h2>
        <p className='careers-banner2-card-p'>Join us as an IoT Intern and gain hands-on experience in building smart, connected solutions. 
          Work with cutting-edge sensors, embedded systems, and cloud technologies to develop innovative IoT applications. 
          Perfect for tech enthusiasts passionate about automation, real-time data, and intelligent systems.</p>
        <p>Send your resume to <strong>careers@huebits.in</strong></p>
        {/* <Application /> */}
        {/* <button onClick={() => handleOpen("IoT Intern")} className='careers-banner-btn'>Apply Now</button> */}
      </div>
      <div className='careers-banner2-card'>
        <h2 className='careers-banner2-card-h2'>Data science and Engineering Intern</h2>
        <p className='careers-banner2-card-p'>
        Kickstart your career as a Data Science & Engineering Intern! Work with large datasets, 
        build machine learning models, and optimize data pipelines to drive insights. 
        Ideal for those passionate about AI, analytics, and solving real-world data challenges.
        </p>
        <p>Send your resume to <strong>careers@huebits.in</strong></p>
        {/* <button onClick={() => handleOpen("Data Science and Engineering Intern")} className='careers-banner-btn'>Apply Now</button> */}
      </div>
      </div>
      <div className='careers-banner2-cards'>
      <div className='careers-banner2-card'>
        <h2 className='careers-banner2-card-h2'>Embedded Systems Intern</h2>
        <p className='careers-banner2-card-p'>
          Join as an Embedded Systems Intern and work on designing, programming, and testing microcontroller-based solutions.
           Gain hands-on experience with firmware development, hardware integration, and real-time operating systems. 
           Perfect for those passionate about IoT, automation, and low-level programming.</p>
        <p>Send your resume to <strong>careers@huebits.in</strong></p>
      </div>
      <div>
      <div className='careers-banner2-card'>
        <h2 className='careers-banner2-card-h2'>Marketing Intern</h2>  
        <p className='careers-banner2-card-p'>As a Marketing Intern, you'll assist in creating campaigns, analyzing market trends, and managing social media strategies.  
          Gain hands-on experience in brand promotion, content creation, and digital marketing.  
          Ideal for creative thinkers passionate about storytelling and audience engagement.</p>  
        <p>Send your resume to <strong>careers@huebits.in</strong></p> 
      </div>
      </div>
      </div>
      <div className='careers-banner2-cards-card'>  
        <h2 className='careers-banner2-card-h2'>Digital Marketing Intern</h2>  
        <p className='careers-banner2-card-p'>As a Digital Marketing Intern, you'll work on SEO, social media, email marketing, and content strategy.  
          Assist in campaign planning, performance tracking, and audience engagement to enhance brand visibility.  
          Perfect for those passionate about online marketing, analytics, and growth strategies.</p>  
        <p>Send your resume to <strong>careers@huebits.in</strong></p>
      </div>
      </div>
      </div>
       {/* Application Popup */}
       {/* {selectedJob && <Application jobTitle={selectedJob} open={open} handleClose={handleClose} />} */}
      <div className="homepage-footers-images">
      <Footer />
      <footer className="homepage-footers">
      <hr className='homepage-footer-hr'/>
        <div className="footer-social">
        <div className="homepage-footer-copyright">
          <p className="homepage-footer-copyrightp">© Huebits[2025].All Rights Reserved.</p>
        </div>
        <div className='footer-social-icons'>
          <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
          <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
          <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
          <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
          <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
          <Link onClick={scrollToFooterSection}>
          <FaArrowUp className="homepage-social-icon-link" />
          </Link>
        </div>
        </div>
      </footer>
      </div>
      </div>
    </div>
  )
}

export default Careers;
