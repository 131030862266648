import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";

const OrganizationsHeader = () => {
  const [menuActive, setMenuActive] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("company_token"); // Clear the company_token
    navigate("/organizations"); // Redirect to the companies page
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#ffff",
        boxShadow: "none",
      }}
    >
      <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: { xs: 2, sm: 4, md: 6 },
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
      }}
      >
        {/* Logo */}
        <Typography
          variant="h1"
          component={Link}
          to="/organizations"
          sx={{
            fontSize: { xs: "40px", md: "40px" },
            fontWeight: "700",
            color: "#052659",
            textDecoration: "none",
          }}
        >
          Hue<span style={{ color: "rgb(27, 110, 244)" }}>bits</span>
        </Typography>

        {/* Desktop Links */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: "20px" }}>
          <Button
            color="#052659"
            component={Link}
            to="/organizations/dashboard"
            sx={{ fontSize: "18px", color: "#052659", fontWeight: "400", textTransform: "capitalize", // Capitalize the first letter only
              "&:hover": { 
                backgroundColor: "#052659", 
                color: "#ffff",
                textTransform: "capitalize", // Capitalize the first letter only
               },
             }}
          >
            Dashboard
          </Button>
          <Button
            color="#052659"
            onClick={handleLogOut}
            sx={{
              fontSize: '18px',
              color: "#052659",
              fontWeight: "400",
              textTransform: "capitalize", // Capitalize the first letter only
              "&:hover": { 
                backgroundColor: "#052659",
                 color: "#ffff",
                 textTransform: "capitalize", // Capitalize the first letter only
               },
            }}
          >
            Logout
          </Button>
        </Box>

        {/* Mobile Menu Icon */}
        <IconButton
          edge="end"
          color="#052659"
          aria-label="menu"
          sx={{ display: { xs: "flex", md: "none" } }}
          onClick={toggleMenu}
        >
          <MenuOutlinedIcon sx={{ fontSize: 28, color: "#052659" }} />
        </IconButton>
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#ffff",
            color: "#052659",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={closeMenu}>
            <CloseIcon sx={{ color: "#052659" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          role="presentation"
          onClick={closeMenu}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/organizations/dashboard"
                sx={{
                  color: "#052659",
                  "&:hover": { backgroundColor: "#052659", color: "#ffff" },
                }}
              >
                <ListItemText primary="Dashboard"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleLogOut}
                sx={{
                  color: "#052659",
                  "&:hover": { backgroundColor: "#052659", color: "#ffff" },
                }}
              >
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default OrganizationsHeader;