import React, { useState } from 'react';
import Product from "../../assets/companiesimages/organizations/banner3.png";
import { Container, Grid, Card, Typography, Box, IconButton, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const sections = [
  {
    title: "Talent Connection Hub",
    content:
      "Connect with innovators and early career professionals to boost awareness about your company.",
  },
  {
    title: "Future-Ready Insights",
    content:
      "Stay ahead with insights from future leaders for shaping your organization's future.",
  },
  {
    title: "Skill-Verified Talent Pool",
    content:
      "Tap into a diverse pool of skilled individuals with real-world project experience.",
  },
  {
    title: "Problem-Solving Partners",
    content:
      "Access innovative solutions for industry challenges.",
  },
];

const Companieslearning = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const isPhone = useMediaQuery("(max-width: 480px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  return (
    <Container
    maxWidth="100%"
    sx={{
      mt: 4,
      mb: 4,
      display: "flex",
      flexDirection: isPhone || isTablet ? "column" : "row",
      justifyContent: "center",
      alignItems: "center",
      gap: isPhone ? "20px" : "40px",
      textAlign: "left",
    }}
  >
    <Grid container spacing={isPhone ? 2 : 4} sx={{ justifyContent: "center" }}>
      {sections.map((section, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          key={index}
          sx={{ position: "relative", display: "flex", justifyContent: "left", flexDirection: "column" }}
          onMouseEnter={() => !isPhone && !isTablet && setSelectedIndex(index)}
          onMouseLeave={() => !isPhone && !isTablet && setSelectedIndex(null)}
          onClick={() => (isPhone || isTablet) && setSelectedIndex(selectedIndex === index ? null : index)}
        >
          <Card
            sx={{
              width: "100%",
              height: "100px",
              margin:"20px 0px",
              backgroundColor: "rgb(14, 104, 249)",
              color: "#ffffff",
              boxShadow: 10,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": !isPhone && !isTablet ? { transform: "scale(1.05)", boxShadow: 6 } : {},
              borderLeft: "6px solid #fff",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Typography variant="h6" fontWeight="bold" fontSize="16px">
              {section.title}
            </Typography>
            <IconButton sx={{ color: "#fff" }}>
              <ExpandMoreIcon />
            </IconButton>
          </Card>

          {/* Phone & Tablet View: Show Description Below on Click */}
          {selectedIndex === index && (isPhone || isTablet) && (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#ffffff",
                color: "#052659",
                p: 2,
                borderRadius: 2,
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                transition: "opacity 0.3s ease, transform 0.3s ease",
                opacity: 1,
                transform: "translateY(0)",
                marginTop: "10px",
              }}
            >
              <Typography fontSize="16px">{section.content}</Typography>
            </Box>
          )}

          {/* Desktop View: Show Description on Hover (Right Side) */}
          {selectedIndex === index && !isPhone && !isTablet && (
            <Box
              sx={{
                position: "absolute",
                top: "90%", // Aligns with the top of the card
                left: "10%", // Moves the box slightly away from the card
                width: "auto",
                backgroundColor: "#ffffff",
                color: "#052659",
                p: 2,
                borderRadius: 1,
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                transition: "opacity 0.3s ease, transform 0.3s ease",
                opacity: 1,
                transform: "translateY(0)", // Removes vertical shifting
                zIndex: 10,
                textAlign: "left",
              }}
            >
              <Typography fontSize="16px">{section.content}</Typography>
            </Box>
          )}
        </Grid>
      ))}
    </Grid>

      {/* Product Image */}
      <Box>
        <img
          src={Product}
          alt="Product"
          className='learnerspage-banner3-image'
        />
      </Box>
    </Container>
  );
};

export { Companieslearning };
