import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { RazorpayCreateCoupon } from "../helper/base_url";

const CreateCoupon = () => {
  const [couponData, setCouponData] = useState({
    discountType: "",
    discountValue: "",
    minOrderAmount: "",
    maxOrderAmount: "",
    startDate: "",
    expiryDate: "",
    usageLimit: "",
    maxUsageLimit: "",
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [errors, setErrors] = useState({});
  const [createdCoupon, setCreatedCoupon] = useState(null); // Store created coupon data
  const [openDialog, setOpenDialog] = useState(false); // Popup state

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "number" && value < 0) return;
    setCouponData({ ...couponData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // Validate form fields
  const validateForm = () => {
    let newErrors = {};
    if (!couponData.discountType) newErrors.discountType = "Required";
    if (!couponData.discountValue || couponData.discountValue <= 0) newErrors.discountValue = "Must be greater than 0";
    if (!couponData.minOrderAmount || couponData.minOrderAmount <= 0) newErrors.minOrderAmount = "Required";
    if (!couponData.maxOrderAmount || couponData.maxOrderAmount <= 0) newErrors.maxOrderAmount = "Required";
    if (!couponData.startDate) newErrors.startDate = "Required";
    if (!couponData.expiryDate) newErrors.expiryDate = "Required";
    if (!couponData.usageLimit) newErrors.usageLimit = "Required";
    if (couponData.usageLimit === "Multiple" && (!couponData.maxUsageLimit || couponData.maxUsageLimit <= 0)) {
      newErrors.maxUsageLimit = "Must be greater than 0";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackbar({ open: true, message: "Please fill in all required fields.", severity: "error" });
      return;
    }

    try {
      const response = await axios.post(`${RazorpayCreateCoupon}`, couponData);
      setSnackbar({ open: true, message: "Coupon created successfully!", severity: "success" });

      // Store the created coupon data in state
      setCreatedCoupon(response.data.data);
      setOpenDialog(true); // Open the popup

    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data?.message || "Error creating coupon", severity: "error" });
    }
  };

  return (
    <Box sx={{ maxWidth: 500, margin: "auto", p: 3, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h5" fontWeight="bold" textAlign="center" mb={2}>
        Create a New Coupon
      </Typography>

      {/* Snackbar for messages */}
      <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>

      {/* Discount Type */}
      <TextField fullWidth select label="Discount Type" name="discountType" value={couponData.discountType} onChange={handleChange} required sx={{ mb: 2 }} error={!!errors.discountType} helperText={errors.discountType}>
        <MenuItem value="Flat">Flat</MenuItem>
        <MenuItem value="Percentage">Percentage</MenuItem>
      </TextField>

      {/* Discount Value */}
      <TextField fullWidth label="Discount Value" name="discountValue" type="number" value={couponData.discountValue} onChange={handleChange} required sx={{ mb: 2 }} error={!!errors.discountValue} helperText={errors.discountValue} />

      {/* Min & Max Order Amount */}
      <TextField fullWidth label="Min Order Amount" name="minOrderAmount" type="number" value={couponData.minOrderAmount} onChange={handleChange} required sx={{ mb: 2 }} error={!!errors.minOrderAmount} helperText={errors.minOrderAmount} />
      <TextField fullWidth label="Max Order Amount" name="maxOrderAmount" type="number" value={couponData.maxOrderAmount} onChange={handleChange} required sx={{ mb: 2 }} error={!!errors.maxOrderAmount} helperText={errors.maxOrderAmount} />

      {/* Start & Expiry Date */}
      <TextField fullWidth label="Start Date" name="startDate" type="date" value={couponData.startDate} onChange={handleChange} required InputLabelProps={{ shrink: true }} sx={{ mb: 2 }} error={!!errors.startDate} helperText={errors.startDate} />
      <TextField fullWidth label="Expiry Date" name="expiryDate" type="date" value={couponData.expiryDate} onChange={handleChange} required InputLabelProps={{ shrink: true }} sx={{ mb: 2 }} error={!!errors.expiryDate} helperText={errors.expiryDate} />

      {/* Usage Limit */}
      <TextField fullWidth select label="Usage Limit" name="usageLimit" value={couponData.usageLimit} onChange={handleChange} required sx={{ mb: 2 }} error={!!errors.usageLimit} helperText={errors.usageLimit}>
        <MenuItem value="One-time">One-time</MenuItem>
        <MenuItem value="Multiple">Multiple</MenuItem>
      </TextField>

      {/* Max Usage Limit (Only for "Multiple") */}
      {couponData.usageLimit === "Multiple" && (
        <TextField fullWidth label="Max Usage Limit" name="maxUsageLimit" type="number" value={couponData.maxUsageLimit} onChange={handleChange} sx={{ mb: 2 }} error={!!errors.maxUsageLimit} helperText={errors.maxUsageLimit} />
      )}

      {/* Submit Button */}
      <Button fullWidth variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
        Create Coupon
      </Button>

      {/* Popup (Dialog) for Coupon Details */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
    <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Coupon Details
    </DialogTitle>
    <DialogContent>
        {createdCoupon && (
        <>
            <Typography><b>Code:</b> {createdCoupon.code}</Typography>
            <Typography><b>Discount Type:</b> {createdCoupon.discountType}</Typography>
            <Typography><b>Discount Value:</b> {createdCoupon.discountValue}</Typography>
            <Typography><b>Min Order:</b> {createdCoupon.minOrderAmount}</Typography>
            <Typography><b>Max Order:</b> {createdCoupon.maxOrderAmount}</Typography>
            <Typography><b>Start Date:</b> {new Date(createdCoupon.startDate).toDateString()}</Typography>
            <Typography><b>Expiry Date:</b> {new Date(createdCoupon.expiryDate).toDateString()}</Typography>
            <Typography><b>Usage Limit:</b> {createdCoupon.usageLimit}</Typography>

            {/* Display maxUsageLimit only when usageLimit is "Multiple" */}
            {createdCoupon.usageLimit === "Multiple" && (
            <Typography><b>Max Usage Limit:</b> {createdCoupon.maxUsageLimit || "N/A"}</Typography>
            )}
        </>
        )}
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary" variant="contained">
        Close
        </Button>
    </DialogActions>
    </Dialog>
    </Box>
  );
};

export default CreateCoupon;
