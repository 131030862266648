import React, { useState } from 'react';
import Product from "../../../assets/learners/offerings.png";
import { Container, Grid, Card, Typography, Box, IconButton, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const sections = [
  {
    title: "Comprehensive Training",
    content:
      "Unlock the full spectrum of each technology with our exclusive training modules. From fundamentals to advanced concepts, we've got you covered.",
  },
  {
    title: "AI-Powered Dynamic Learning Paths",
    content:
      "Experience personalized learning at its finest. Our platform harnesses the power of AI to tailor your learning journey, ensuring it aligns perfectly with your needs and goals.",
  },
  {
    title: "Real Industrial Experiences",
    content:
      "Bridge theory with practice seamlessly. Solve real industry problems, earn valuable experience, and receive stipends through our industrial collaborations and get hired.",
  },
  {
    title: "Unlimited Access",
    content:
      "Access our platform and its extensive resources, including courses and projects, anytime, anywhere, 24/7.",
  },
  {
    title: "Live Support and Mentoring",
    content:
      "Receive personalized guidance every step of the way. Our dedicated mentors provide live support, ensuring you stay on track and maximize your learning potential.",
  },
];

const Learning = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const isPhone = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  return (
    <Container
      maxWidth="100%"
      sx={{
        mt: 4,
        mb: 4,
        display: "flex",
        flexDirection: isPhone || isTablet ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        gap: isPhone ? "20px" : "40px",
        textAlign: "left",
      }}
    >
      <Grid container spacing={isPhone ? 2 : 4} sx={{ justifyContent: "left" }}>
        {sections.map((section, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ position: "relative", display: "flex", justifyContent: "left", flexDirection: "column" }}
            onMouseEnter={() => !isPhone && !isTablet && setSelectedIndex(index)}
            onMouseLeave={() => !isPhone && !isTablet && setSelectedIndex(null)}
            onClick={() => (isPhone || isTablet) && setSelectedIndex(selectedIndex === index ? null : index)}
          >
            <Card
              sx={{
                width: "100%",
                height: "140px",
                backgroundColor: "rgb(14, 104, 249)",
                color: "#ffffff",
                boxShadow: 10,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": !isPhone && !isTablet ? { transform: "scale(1.05)", boxShadow: 6 } : {},
                borderLeft: "6px solid #fff",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 20px",
                marginBottom:"20px",
              }}
            >
              <Typography variant="h6" fontWeight="bold" fontSize="16px">
                {section.title}
              </Typography>
              <IconButton sx={{ color: "#fff" }}>
                <ExpandMoreIcon />
              </IconButton>
            </Card>

            {/* Phone & Tablet View: Show Description Below on Click */}
            {selectedIndex === index && (isPhone || isTablet) && (
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  color: "#052659",
                  p: 2,
                  borderRadius: 2,
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                  opacity: 1,
                  transform: "translateY(0)",
                  marginTop: "10px",
                }}
              >
                <Typography fontSize="16px">{section.content}</Typography>
              </Box>
            )}

            {/* Desktop View: Show Description on Hover (Right Side) */}
            {selectedIndex === index && !isPhone && !isTablet && (
              <Box
                sx={{
                  position: "absolute",
                  top: "60%",
                  left: "10%",
                  width: "260px",
                  height:"200px",
                  backgroundColor: "#ffffff",
                  color: "#052659",
                  p: 2,
                  borderRadius: 2,
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  transition: "opacity 0.3s ease, transform 0.3s ease",
                  opacity: 1,
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  textAlign: "left",
                }}
              >
                <Typography fontSize="16px">{section.content}</Typography>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>

      {/* Product Image */}
      <Box>
        <img
          src={Product}
          alt="Product"
          className='learnerspage-banner3-image'
        />
      </Box>
    </Container>
  );
};

export { Learning };
