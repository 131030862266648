import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField, Button, Container, Typography, Box, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LearnersGenerateOtp, LearnersVerifyOtp, LearnersForgotPassword } from '../../../helper/base_url';
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import Headrepage from "../../../mainpage/Headerpages/learners_hederpage";
import Footer from "../../../mainpage/Footer/learnersFooter";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailVerificationToken, setEmailVerificationToken] = useState(null);
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(60);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (step === 2 && timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [step, timer]);

  const notify = (message, type) => {
    toast[type](message);
  };

  const requestOTP = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${LearnersGenerateOtp}`, { email });
      setEmailVerificationToken(response.data.emailVerificationToken);
      setStep(2);
      setTimer(60);
      notify("OTP sent successfully!", "success");
    } catch (error) {
      notify("Failed to send OTP. Try again.", "error");
    }
    setLoading(false);
  };

  const verifyOTP = async () => {
    setLoading(true);
    try {
      await axios.post(`${LearnersVerifyOtp}`, { email, code: otp, emailVerificationToken });
      setStep(3);
      notify("OTP verified successfully!", "success");
    } catch (error) {
      notify("Invalid OTP. Try again.", "error");
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      notify("Passwords do not match.", "error");
      return;
    }
    setLoading(true);
    try {
      await axios.post(`${LearnersForgotPassword}`, { email, newPassword, emailVerificationToken });
      notify("Password reset successful!", "success");
      setTimeout(() => navigate("/learners/signin"), 500);
      setStep(4);
    } catch (error) {
      notify("Failed to reset password. Try again.", "error");
    }
    setLoading(false);
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <div style={{ width: "100%", overflowX: "hidden", overflowY: "auto", height: "91vh" }}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        <Container maxWidth="sm">
          <Typography variant="h5" fontWeight="bold" textAlign= "center" mb={2}>Reset Password</Typography>
          <Box p={4} boxShadow={3} borderRadius={2} bgcolor="white" textAlign="center">
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} closeOnClick pauseOnHover draggable theme="light" />
            {step === 1 && (
              <>
                <TextField label="Enter your email" variant="outlined" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
                <Button variant="contained" color="primary" fullWidth onClick={requestOTP} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : "Send OTP"}
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                {timer > 0 && <Typography color="error">OTP expires in {timer} seconds</Typography>}
                <TextField 
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  disabled // Optional: disable the field since email is already verified
                />
                <TextField label="Enter OTP" variant="outlined" fullWidth value={otp} onChange={(e) => setOtp(e.target.value)} margin="normal" />
                <Button variant="contained" color="primary" fullWidth onClick={verifyOTP} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : "Verify OTP"}
                </Button>
                {timer === 0 && <Button onClick={requestOTP} color="secondary">Resend OTP</Button>}
              </>
            )}
           {step === 3 && (
              <>
                <TextField 
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  disabled // Optional: disable the field since email is already verified
                />
                
                <TextField 
                  label="Enter new password" 
                  type={showPassword ? "text" : "password"} 
                  variant="outlined" 
                  fullWidth 
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                  margin="normal" 
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                
                <TextField 
                  label="Confirm new password" 
                  type={showConfirmPassword ? "text" : "password"} 
                  variant="outlined" 
                  fullWidth 
                  value={confirmPassword} 
                  onChange={(e) => setConfirmPassword(e.target.value)} 
                  margin="normal" 
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Button variant="contained" color="primary" fullWidth onClick={resetPassword} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : "Reset Password"}
                </Button>
              </>
            )}
          </Box>
        </Container>
      </Box>
      <div className="homepage-footers-images">
        <Footer />
        <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
            <div className="homepage-footer-copyright">
              <p>© Huebits[2025].All Rights Reserved.</p>
            </div>
            <div className='footer-social-icons'>
            <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
            <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
            <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
            <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
            <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
            <Link><FaArrowUp className="homepage-social-icon-link" /></Link>
          </div>
          </div>
        </footer>
      </div>
      </div>
    </div>
  );
}
