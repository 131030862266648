import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, Container, Box, Card, CardMedia, CardContent } from '@mui/material';
import IndustryReadyIoT from "../../../assets/homepage/courses/industryReadyIoTProgram.png";
import DataScience from "../../../assets/homepage/courses/industryReadyDataScienceProgram.png";
import EmbeddedSystems from "../../../assets/homepage/courses/industryReadyEmbeddedSystemsProgram.png";
import Navbar from "./Navebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { fetchPaymentStatus } from '../../../helper/LlearnersSubscription';
import useNetworkStatus from "../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const LearningPathCard = ({ image, title, description, buttonLabel, onClick, isDisabled }) => {
  const coreFeatures = [
    "Access to all Courses and Projects",
    "1-to-1 Doubt Sessions: 2 hours per month",
    title === "Industry Ready Embedded Systems Program"
      ? "Guaranteed internships with ₹30,000 stipend"
      : "Guaranteed internships with ₹40,000 stipend"
  ];

  return (
    <Grid item xs={12} sm={4} align="start">
      <Card sx={{ boxShadow: 3, borderRadius: '8px', overflow: 'hidden', backgroundColor: "#fff", width: '100%' }}>
        <CardMedia component="img" height="200" image={image} alt={title} sx={{ objectFit: 'cover' }} />
        <CardContent sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 700, color: '#052659', fontSize: "1rem" }} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ color: '#333', fontSize: "1rem" }}>
          {description}
        </Typography>

          {buttonLabel === "Enroll Now" && (
            <Box sx={{ mt: 2 }}>
              {coreFeatures.map((feature, i) => (
                <Typography key={i} variant="body2" sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1, color: "text.secondary" }}>
                  <FontAwesomeIcon icon={faCheck} color="green" />
                  {feature}
                </Typography>
              ))}
            </Box>
          )}

          <Button
            variant={isDisabled ? "outlined" : "contained"}
            color={isDisabled ? "secondary" : "primary"}
            sx={{
              width: '100%',
              background: !isDisabled ? 'linear-gradient(135deg, #5483B3, #052659)' : undefined,
              '&:hover': !isDisabled ? { background: 'linear-gradient(135deg, #052659, #345A80)' } : undefined,
              mt: 2
            }}
            onClick={onClick}
            disabled={isDisabled}
          >
            {buttonLabel}
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

const LearningPath = () => {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState({ subcribe: false, iot: false, dataScience: false, embedded: false });
  const [clickedPaths, setClickedPaths] = useState(
    JSON.parse(localStorage.getItem("clickedPaths")) || {}
  );
  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      navigate("/learners/signin");
    } else {
      fetchPaymentStatus(storedToken)
        .then((status) => {
          setPaymentStatus(status);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [navigate]);

  const handleLearningPathClick = (id, link) => {
    console.log(`Button clicked for ${id}, navigating to: ${link}`); // Log the button click event
  
    if (link !== "#") {
      localStorage.setItem("selectedPath", link);
      navigate(link);
  
      let response;
      if (paymentStatus[id]) {
        const updatedPaths = { ...clickedPaths, [id]: true };
        setClickedPaths(updatedPaths);
        localStorage.setItem("clickedPaths", JSON.stringify(updatedPaths));
        response = { status: "resumed", id, link };
      } else {
        // Update localStorage when a user starts a new course
        const updatedPaymentStatus = { ...paymentStatus, [id]: true };
        setPaymentStatus(updatedPaymentStatus);
        localStorage.setItem("paymentStatus", JSON.stringify(updatedPaymentStatus));
        response = { status: "enrolled", id, link };
      }
  
      console.log("Response:", response); // Log the response
      return response; // Return the response
    }
  
    return { status: "invalid_link", id, link }; // Return a response for invalid links
  };

  const learningPaths = [
    {
      id: "iot",
      image: IndustryReadyIoT,
      title: "Industry Ready IoT Program",
      description: "Master the technology behind smart, connected solutions.",
      buttonLabel: paymentStatus.iot ? (clickedPaths.iot ? "Resume" : "Start") : "Enroll Now",
      isDisabled: false,
      link: paymentStatus.iot ? "/learners/dashboard/internetofthings" : "/learners/internet-of-things"
    },
    {
      id: "dataScience",
      image: DataScience,
      title: "Industry Ready Data Science Program",
      description: "Harness the power of data to drive innovation and decisions.",
      buttonLabel: paymentStatus.dataScience ? (clickedPaths.dataScience ? "Resume" : "Start") : "Enroll Now",
      isDisabled: false,
      //link: paymentStatus.dataScience ? "#" : "/learners/data-science"
      link: paymentStatus.dataScience ? "/learners/data-science-dashboard/data-science-learningpath" : "/learners/data-science"
    },
    {
      id: "embedded",
      image: EmbeddedSystems,
      title: "Industry Ready Embedded Systems Program",
      description: "Build intelligent systems shaping the future of technology.",
      buttonLabel: paymentStatus.embedded ? "Coming Soon" : clickedPaths.embedded ? "Resume" : "Enroll Now",
      isDisabled: paymentStatus.embedded,
      link: "/learners/embedded-systems"
    }
  ];

  return (
    <div className="students-flex">
      <Navbar />
      <div className="mylearningpaths">
      <div>
         {isOnline ? (
        <Container>
          <Box mb={6}>
            <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 900, color: '#052659', paddingTop: '20px' }}>
              Explore Your Future with Our Industry-Ready Programs
            </Typography>
            <Typography variant="h6" align="center" paragraph sx={{ color: '#333' }}>
              Thank you for joining us! Choose your industry-ready program.
            </Typography>
          </Box>
          <Grid container spacing={4} justifyContent="center">
            {learningPaths.map((path) => (
              <LearningPathCard
                key={path.id}
                image={path.image}
                title={path.title}
                description={path.description}
                buttonLabel={path.buttonLabel}
                isDisabled={path.isDisabled}
                onClick={() => handleLearningPathClick(path.id, path.link)}
              />
            ))}
          </Grid>
          <Box mt={5}>
            <Typography variant="body1" align="center" paragraph sx={{ color: '#333', paddingBottom: '40px' }}>
              Your journey begins here. Select your program and take the first step today!
            </Typography>
          </Box>
        </Container>
         ) : (
          <div>
              <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default LearningPath;