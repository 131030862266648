import React from 'react';
import EdgeImpulse from "../../assets/companiesimages/Partners/5.png";
import Awses from "../../assets/companiesimages/Partners/6.png";
import Azures from "../../assets/companiesimages/Partners/7.png";

const Partnersships = () => {
    return (
        <div>
            <div className="organization-iot-tech-talent-banner8-1">
                <div className="organization-iot-tech-talent-banner8-cards">
                </div>
                <div className="organization-iot-tech-talent-banner8-cards">
                    <div>
                        <img src={EdgeImpulse} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
                    </div>
                </div>
                <div className="organization-iot-tech-talent-banner8-cards">
                    <div>
                        <img src={Awses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
                    </div>
                    <div>
                        <img src={Azures} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partnersships;