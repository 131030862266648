import React, { useState } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Button, Grid, InputAdornment, IconButton, Paper, Box, Typography, Link as MuiLink } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AdminHeader from "../adminHeader"
import {Adminsignup} from "../../helper/base_url"

function AdminSignup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [adminsecretKey, setAdminsecretKey] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    userType: "admin",
    adminSecretKey: ""
  });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingNextPage(true);

    const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$/;

    if (data.password.length < 8) {
      toast.error("Password should be more than 8 characters");
    } else if (!regExspecialChars.test(data.password)) {
      toast.error("Password should contain at least 1 digit, 1 special character, and 1 lower and upper case letter");
    } else if (data.password !== data.confirmPassword) {
      toast.error("Confirm password does not match");
      setLoading(false);
      setLoadingNextPage(false);
    } else {
      try {
        const response = await axios.post(`${Adminsignup}`, data, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.error) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setData({ username: "", password: "", confirmPassword: "", adminSecretKey: "" });
          setTimeout(() => navigate('/admin/signin'), 500);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message || "An error occurred");
        } else {
          toast.error("Network error: Please check your connection and try again.");
        }
      } finally {
        setLoading(false);
        setLoadingNextPage(false);
      }
    }
  };

  return (
    <div className="admin-dashboard-users-data">
      <AdminHeader />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      {loadingNextPage && <div className="spinner"></div>}
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={6} md={4} sx={{marginTop:10}}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h4" align="center" sx={{ marginBottom: 2 }}>Admin Sign Up</Typography>
            <form id="signup-form" onSubmit={handleSubmit}>
              <TextField
                label="Enter Email"
                type="email"
                name="username"
                value={data.username}
                onChange={handleOnChange}
                fullWidth
                required
                disabled={loading}
                variant="outlined"
                margin="normal"
              />
              <TextField
                label="Enter Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={data.password}
                onChange={handleOnChange}
                fullWidth
                required
                disabled={loading}
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                value={data.confirmPassword}
                onChange={handleOnChange}
                fullWidth
                required
                disabled={loading}
                variant="outlined"
                margin="normal"
              />
              <TextField
                  label="Admin Secret Key"
                  type={adminsecretKey ? "text" : "password"} // Toggle visibility
                  name="adminSecretKey"
                  value={data.adminSecretKey}
                  onChange={handleOnChange}
                  fullWidth
                  required
                  disabled={loading}
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setAdminsecretKey(!adminsecretKey)}>
                          {adminsecretKey ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              <div className="users-login-btn">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                className="students-signup-btn"
              >
                {loading ? 'Signup...' : 'Sign Up'}
              </Button>
              </div> 
            </form>
            <Box className="students-signup-accounts" sx={{ marginTop: 2 }}>
           <Typography variant="body2">
            <span className="signup-already-account">Already have an account? </span>
            <MuiLink 
              component={Link} 
              to="/admin/signin" 
              sx={{ textDecoration: 'none', fontWeight: 'bold', color: 'primary.main' }}
            >
              Login here
            </MuiLink>
          </Typography>
          </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminSignup;