import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Typography, Paper, Avatar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Googlereview from "../../assets/googleReview.jpg";
import Reveal from "../../helper/useScrollposition";

// Custom slide component
const SlideItem = ({ name, code, feedback }) => (
    <Paper elevation={3} sx={{ p: 2, textAlign: 'center', margin:"20px", borderRadius: 2, backgroundColor: '#1c84e4', color: 'white', width: 300, height: 380, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mx: 'auto' }}>
        <Avatar sx={{ bgcolor: 'white', color: '#1c84e4', width: 80, height: 80, fontSize: 24, fontWeight: 'bold', mb: 2 }}>{code}</Avatar>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{name}</Typography>
        <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>{feedback}</Typography>
    </Paper>
);

const Slide = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : isTablet ? 2 : 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };

    return (
        <Box textAlign="center" py={3}>
            <Reveal>
            <Box component="img" src={Googlereview} alt="Google Review" sx={{ width: 300, mb: 2, display: isMobile || isTablet ? 'none' : 'block', mx: 'auto' }} />
            <Box sx={{ maxWidth: '100%', mx: 'auto', px: 2 }}>
                    <Slider {...settings}>
                        <SlideItem name="Kishore Rao" code="KR" feedback="Service is really good and I like his explanation. I have taken IoT course from here and they provide kit also." />
                        <SlideItem name="Sai Bhaskar" code="SB" feedback="Thanks for educating us for IoT, Especially BAPUJI Sir thanks for teaching us. Learned well and good." />
                        <SlideItem name="Ravikiran Munganda" code="RM" feedback="It was amazing, experienced and the best faculty at Huebits platform, it was my dream to learn projects." />
                        <SlideItem name="SaSi Kanth" code="SK" feedback="It's a good organisation. Learned a lot of knowledge in just 15 days of internship." />
                        <SlideItem name="Suryarao Nagiredla" code="SN" feedback="Best place to learn machine learning course. Organizers are very friendly and will provide good amount of content." />
                        <SlideItem name="Divya Gedela" code="DG" feedback="It's fortune to have internship at Huebits platform. They explain the concept clearly and care about you like family." />
                    </Slider>
                </Box>
            </Reveal>
        </Box>
    );
};

export default Slide;
