import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import Interships from './Applynow/Interships';
import Freelancing from './Applynow/Freelancing';
import Applnow from "./Apply-Now";

const Applyforworks = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Box sx={{ width: '100%', minHeight: '100vh', backgroundColor: '#f9f9f9', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ position: 'sticky', top: 0, zIndex: 10, width: '100%' }}>
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    sx={{ backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
                >
                    <Tab label="Internships" />
                    <Tab label="Jobs" />
                    <Tab label="Freelancing" />
                </Tabs>
            </Box>
            <Box 
                sx={{ 
                    mt: 3, 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: "center", 
                    alignItems: "center",
                    p: 2
                }}
            >
                {activeTab === 0 && <Interships setActiveLink={setActiveTab} />}
                {activeTab === 1 && <Applnow setActiveLink={setActiveTab} />}
                {activeTab === 2 && <Freelancing setActiveLink={setActiveTab} />}
            </Box>
        </Box>
    );
};

export default Applyforworks;
