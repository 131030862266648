import React, { useState } from "react";
import { Button, LinearProgress, Box, Typography, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import addActiveProject from "../hooks/AddactiveProjects";
import useLearningProgress from '../hooks/projectslearningPath';
import { IoTDataScientistProjects } from "../hooks/projectsList"; // Import the projects data
import useNetworkStatus from "../../../../helper/NetworkStatus";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const Start = () => {
  const [, setMessage] = useState(null);
  const learningProgress = useLearningProgress(); // Use the custom hook for learning progress
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile screen
  const isTablet = useMediaQuery("(max-width:960px)"); // Detect tablet screen
  const { isOnline } = useNetworkStatus();

  return (
    <Box sx={{padding:"10px 10px 10px 20px"}}>
    {/* <Typography variant="body1" gutterBottom sx={{paddingLeft:"10px"}}>
     Projects
    </Typography> */}

    {/* Flexbox container for the 4-column layout */}
   {isOnline ? (
   <Box 
      sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 3, 
        margin: "10px",
        justifyContent: isMobile ? "center" : isTablet ? "space-around" : "flex-start", // Center on mobile, space out on tablets
      }}
    >
      {IoTDataScientistProjects.map((project, index) => {
        const projectProgress = learningProgress.find(
          (progress) => progress.productId === project.videoUrl
        );
        const progress = projectProgress ? projectProgress.productProgress : 0;

        return (
          <Box key={`${project.projectId}-${index}`}
          sx={{ 
            flexBasis: isMobile ? '100%' : isTablet ? '45%' : '228px',  // Full width on mobile, two-column on tablets, fixed size on larger screens
            minWidth: '200px' 
          }}
          onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)}
          >
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
               {/* Image Wrapper with Hover Effect */}
              <Box 
                sx={{ 
                  position: 'relative', 
                  '&:hover .description-overlay': { opacity: 1 } // Show description on hover
                }}
              >
                {/* Card Media (Image) */}
                <CardMedia
                  component="img"
                  sx={{ height: 160, objectFit: 'cover' }}
                  image={project.image}
                  alt={project.projectTitle}
                />
                
                {/* Description Overlay */}
                <Box 
                  className="description-overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '10px',
                    opacity: 0, // Initially hidden
                    transition: 'opacity 0.3s ease-in-out', // Smooth transition
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                    {project.projectTitle}
                  </Typography>
                </Box>
              </Box>
              {/* Card Content (Text, Progress, Button) */}
              <CardContent sx={{ flexGrow: 1 }}>
              <Typography 
                variant="body1" 
                component="p" 
                sx={{ 
                  paddingBottom: 1, 
                  whiteSpace: 'nowrap',  // Ensures the title doesn't wrap
                  overflow: 'hidden',    // Hides overflow text
                  textOverflow: 'ellipsis', // Adds "..." when text overflows
                }}
              >
                {index + 1}. {project.projectTitle}
              </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="body2" sx={{ marginRight: 2 }}>
                    {Math.min(progress || 0, 100).toFixed(0)}%
                  </Typography>
                  {/* <LinearProgress variant="determinate" value={progress} sx={{ flexGrow: 1 }} /> */}
                  <LinearProgress 
                  variant="determinate" 
                  value={progress} 
                  sx={{ 
                    flexGrow: 1, 
                    height: "8px", // Increased height to 40px
                    backgroundColor: "#E0E0E0", // Light gray background
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "", // Light black progress color
                    },
                  }} 
                />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {progress === 100 ? (
                    <Button
                      variant="contained"
                      color="success"
                      // onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)}
                    >
                      Completed
                    </Button>
                  ) : progress >= 1 && progress < 100 ? (
                    <Button
                    variant="outlined"
                    // onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)}
                    sx={{
                      backgroundColor: 'blue',   // Blue background
                      color: 'white',            // White text color
                      borderColor: 'blue',       // Blue border color
                      '&:hover': {
                        backgroundColor: 'darkblue', // Darker blue on hover
                        borderColor: 'darkblue',     // Darker border color on hover
                      },
                    }}
                  >
                    Resume
                  </Button>                    
                  ) : (
                    <Button
                      variant="outlined"
                      // onClick={() => addActiveProject(project.projectTitle, project.videoUrl, setMessage)}
                    >
                      Start
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </Box>
    ) : (
    <div>
        <ShimmerSimpleGallery card imageHeight={180} row={3} col={3} gap={20} />
    </div>
  )}
  </Box>
);
};

export default Start;
