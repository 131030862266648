import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemButton, ListItemText, CircularProgress, Box } from "@mui/material";

const OrganizationsHeader = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Get current route

  const isLoggedIn = localStorage.getItem("company_token") !== null;

  const toggleMenu = () => setMenuActive(!menuActive);
  const closeMenu = () => setMenuActive(false);

  const handleLoginClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const menuItems = [
    { label: "Home", path: "/" }, // Added Home Button
    { label: "Organizations", path: "/organizations" },
    {
      label: isLoggedIn ? "Dashboard" : "Sign In",
      path: isLoggedIn ? "/organizations/dashboard" : "/organizations/signin",
      action: isLoggedIn ? closeMenu : handleLoginClick,
    },
  // Add Sign Up menu item for users who are not logged in
  ...(!isLoggedIn ? [{ label: "Sign Up", path: "/organizations/signup" }] : []),
  ];

  return (
    <AppBar position="static" sx={{ bgcolor: "#fff", boxShadow: "none" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 2, sm: 6, md: 6 },
          boxShadow: "0px 0.5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
       <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "26px",
              sm: "30px",
              md: "40px",
              lg: "35px", // Apply 35px for 14-inch screens
            },
            fontWeight: "700",
            color: "rgb(2, 41, 100)",
            textDecoration: "none",
            "@media (min-width: 1366px) and (max-width: 1536px)": {
              fontSize: "35px",
            },
          }}
          component={Link}
          to="/"
        >
          Hue<span style={{ color: "rgb(27, 110, 244)" }}>bits</span>
        </Typography>


        <IconButton
          edge="end"
          onClick={toggleMenu}
          sx={{ color: "rgb(2, 41, 100)", display: { xs: "block", md: "none" } }}
        >
          <MenuOutlinedIcon fontSize="large" />
        </IconButton>

        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 3 }}>
          {menuItems.map((item, index) => {
            const isActive = location.pathname === item.path; // Check if menu item is active
            return (
              <Link key={index} to={item.path} onClick={item.action} style={{ textDecoration: "none" }}>
                <Box
                   sx={{
                    color: item.label === "Sign Up" ? "#fff" : isActive ? "#1c84e4" : "#303141", // Active color for current page
                    fontWeight: isActive ? "400" : "400",
                    fontSize: "16px",
                    padding: "6px",
                    borderRadius: "4px",
                    border: item.label === "Sign In" ? "1px solid #1c84e4" : item.label === "Sign Up" ? "1px solid #1c84e4" : "none",
                    backgroundColor: item.label === "Sign Up" ? "#1c84e4" : "transparent",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: item.label === "Sign Up" ? "#fff" : "#1c84e4",
                      color: item.label === "Sign Up" ? "#1c84e4" : "#fff",
                    },
                  }}
                >
                  {loading && item.label === "Sign In" ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    item.label
                  )}
                </Box>
              </Link>
            );
          })}
        </Box>
      </Toolbar>

      <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{ "& .MuiDrawer-paper": { bgcolor: "#fff", width: 250 } }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={closeMenu}
            sx={{
              color: "#052659",
              fontWeight: 400,
              fontSize: "16px",
              padding: "8px",
              borderRadius: "4px",
              transition: "background-color 0.3s ease",
              "&:hover": { backgroundColor: "#1c84e4", color: "#ffff" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item, index) => {
            const isActive = location.pathname === item.path;
            return (
              <ListItemButton key={index} component={Link} to={item.path}>
                <ListItemText
                  primary={item.label}
                  sx={{
                    color: isActive ? "#1c84e4" : "#052659", // Active color for current page
                    fontWeight: isActive ? "600" : "400",
                    padding: "8px",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    "&:hover": { backgroundColor: "#1c84e4", color: "#ffff" },
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default OrganizationsHeader;
