import React, {useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
//import Empower from "../../assets/Embeddedsystems.png";
import Embeddedsystems2 from "../../assets/companiesimages/web/5.png";
import Internet from "../../assets/companiesimages/services/Embeddedsystems/1.png";
import Industrial from "../../assets/companiesimages/services/Embeddedsystems/2.png";
import IoT from "../../assets/companiesimages/services/Embeddedsystems/3.png";
import Edge from "../../assets/companiesimages/services/Embeddedsystems/4.png";
import Industry from "../../assets/companiesimages/services/Embeddedsystems/5.png";
import AIoT from "../../assets/companiesimages/services/Embeddedsystems/6.png";
//Technology
//Microcontrollers & Microprocessors
import ARMCortex from "../../assets/Technology/icons/Embedded-tools-logos/1.png";
import AVR from "../../assets/Technology/icons/Embedded-tools-logos/2.png";
import Microchip from "../../assets/Technology/icons/Embedded-tools-logos/3.png";
import TexasInstruments from "../../assets/Technology/icons/Embedded-tools-logos/4.png";
import ESP32 from "../../assets/Technology/icons/Embedded-tools-logos/5.png";
import STM32 from "../../assets/Technology/icons/Embedded-tools-logos/6.png";
import RISCV from "../../assets/Technology/icons/Embedded-tools-logos/7.png";
//Embedded Software Development
import C from "../../assets/Technology/icons/Embedded-tools-logos/10.png";
import Linux from "../../assets/Technology/icons/Embedded-tools-logos/11.png";
import Python from "../../assets/Technology/icons/Embedded-tools-logos/12.png";
import Assembly from "../../assets/Technology/icons/Embedded-tools-logos/15.png";
import Rust from "../../assets/Technology/icons/Embedded-tools-logos/14.png";
import Lua from "../../assets/Technology/icons/Embedded-tools-logos/13.png";
//Connectivity & Networking
import MQTT from "../../assets/Technology/icons/Embedded-tools-logos/23.png";
import HTTP from "../../assets/Technology/icons/Embedded-tools-logos/24.png";
import CoAP from "../../assets/Technology/icons/Embedded-tools-logos/26.png";
import Modbus from "../../assets/Technology/icons/Embedded-tools-logos/25.png";
// import HTTPs from "../../assets/Technology/icons/Embedded-tools-logos18.png";
import WebSockets from "../../assets/Technology/icons/Embedded-tools-logos/27.png";
import Cellular from "../../assets/Technology/icons/Embedded-tools-logos/30.png";
//import Fiveg from "../../assets/Technology/icons/Embedded-tools-logos20.png";
//import Nbiot from "../../assets/Technology/icons/Embedded-tools-logos21.png";
//Real-Time Operating Systems (RTOS)
import FreeRTOS from "../../assets/Technology/icons/Embedded-tools-logos/17.png";
import VxWorks from "../../assets/Technology/icons/Embedded-tools-logos/18.png";
import µCOS from "../../assets/Technology/icons/Embedded-tools-logos/21.png";
import ThreadX from "../../assets/Technology/icons/Embedded-tools-logos/19.png";
import NuttX from "../../assets/Technology/icons/Embedded-tools-logos/20.png";
//Communication Protocols
//import I2C from "../../assets/Technology/icons/Embedded-tools-logos27.png";
//import SPI from "../../assets/Technology/icons/Embedded-tools-logos28.png";
//import UART from "../../assets/Technology/icons/Embedded-tools-logos29.png";
//import CAN from "../../assets/Technology/icons/Embedded-tools-logos30.png";
//import Ethernet from "../../assets/Technology/icons/Embedded-tools-logos31.png";
import Uart from "../../assets/Technology/Embedded-tools-logos/29.png";
import Bluetooth from "../../assets/Technology/Embedded-tools-logos/30.png";
import Zigbee from "../../assets/Technology/icons/Embedded-tools-logos/37.png";
import LoRaWAN from "../../assets/Technology/icons/Embedded-tools-logos/38.png";
import WiFi from "../../assets/Technology/Internet-tools-logos/2.png";
//import NFC from "../../assets/Technology/icons/Embedded-tools-logos36.png";
//Automotive Embedded Systems
//import Classic  from "../../assets/Technology/icons/Embedded-tools-logos37.png";
import BSW from "../../assets/Technology/icons/Embedded-tools-logos/40.png";
import RTE from "../../assets/Technology/icons/Embedded-tools-logos/41.png";
import Communication from "../../assets/Technology/icons/Embedded-tools-logos/42.png";
import UDS from "../../assets/Technology/icons/Embedded-tools-logos/43.png";
import ISO from "../../assets/Technology/icons/Embedded-tools-logos/44.png";
import Vectortools from "../../assets/Technology/icons/Embedded-tools-logos/45.png";
import Hardware from "../../assets/Technology/icons/Embedded-tools-logos/46.png";
import Isoftware from "../../assets/Technology/icons/Embedded-tools-logos/47.png";
//import ADAS  from "../../assets/Technology/icons/Embedded-tools-logos46.png";
//Partners
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import Footerimage from "../../assets/companiesimages/organizations/banner10.png";
import Reveal from '../../helper/useScrollposition';
import Footer from "../../mainpage/Footer/FooterOrganizations";

const InternetofThings = () => {
    const footerSectionRef = useRef(null);
     const solutionsSectionRef = useRef(null);
      const techtalentSectionRef = useRef(null);
      
      const scrollToFooterSection = () => {
        footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };
      const scrollToSolutionsSection = () => {
        solutionsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };
    
      const scrollToTechtalentSection = () => {
        techtalentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      useEffect(() => {
          if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
            var scriptElement = document.createElement("script");
            scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
            scriptElement.async = true;
            scriptElement.charset = "UTF-8";
            scriptElement.setAttribute("crossorigin", "*");
            
            document.body.appendChild(scriptElement);
          }
        }, []);

    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
          <div className="homepagecolor">
            <div ref={footerSectionRef} className="organization-embedded-tech-talent-banners1">
              <ul className="organization-iot-tech-talent-banner1">
                <li>
                <h1>Powering Innovation with</h1>
                <h1><span>Embedded Systems & Top Tech Talent</span></h1>
                <p>Empowering Your Business with Embedded Systems Solutions and Skilled Tech Talent</p>
                </li>
              </ul>
                {/* <button className="organization-iot-tech-talent-banner1-btn">Learn More</button> */}
              {/* <img className="organization-iot-tech-talent-banner1-image" src={Empower} alt=""/> */}
            </div>
            <div ref={solutionsSectionRef} className="organization-iot-tech-talent-banner2">
            <h1 className="organization-iot-tech-talent-banner2-h1">Transform Your Technology with Expert Embedded Systems</h1>
            <div className="organization-iot-tech-talent-banner2-card">
            <div>
              <p className="organization-iot-tech-talent-banner2-p">
                Leverage our expertise in embedded systems to tackle your toughest technology and business challenges. 
                From hardware design to software development, we provide comprehensive solutions to bring your ideas to life. 
                Whether developing new systems or enhancing existing ones, we support your journey from concept to market-ready solution.
              </p>
              <div className="organization-iot-tech-talent-banner2-button">
                <button onClick={scrollToSolutionsSection}>Explore Embedded Solutions</button>
                <button onClick={scrollToTechtalentSection}>Hire Embedded Talent</button>
              </div>
            </div>
            <div className="organization-iot-tech-talent-banner2-image">
              <img src={Embeddedsystems2} alt="Embedded Systems" />
            </div>
          </div>
          </div>
          <div ref={solutionsSectionRef} className="organization-iot-tech-talent-banner3">
              <h1>Embedded professional services include</h1>
            <div className="organization-iot-tech-talent-banner3-card">
           <div className="animated-card">
              <img src={Internet} alt="" className="organization-Embedded-tech-talent-banner3-card-image"/>
              <p>Embedded Systems</p>
            </div>
            <div className="animated-card">
              <img src={Industrial} alt="" className="organization-Embedded-tech-talent-banner3-card-image"/>
              <p>Automotive Embedded Systems</p>
            </div>
            <div className="animated-card">
              <img src={IoT} alt="" className="organization-Embedded-tech-talent-banner3-card-image"/>
              <p>Firmware Development</p>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner3-card">
            <div className="animated-card">
              <img src={Edge} alt="" className="organization-Embedded-tech-talent-banner3-card-image"/>
              <p>Embedded AI</p>
            </div>
            <div className="animated-card">
              <img src={Industry} alt="" className="organization-Embedded-tech-talent-banner3-card-image"/>
              <p>Embedded Linux</p>
            </div>
            <div className="animated-card">
              <img src={AIoT} alt="" className="organization-Embedded-tech-talent-banner3-card-image"/>
              <p>GUI Development</p>
            </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner4">
        <h1 className="organization-iot-tech-talent-banner4-h1">How We Drive Your Success</h1>
        <p className="organization-iot-tech-talent-banner4-div1-p">
          We partner with your team to develop cutting-edge embedded systems solutions, driving innovation across
          industries with the seamless integration of advanced technologies. Our expertise in embedded systems
          empowers us to deliver tailored hardware, software, and interface solutions that unlock new possibilities and
          redefine performance. Together, we’ll transform your business, accelerate development, and ensure you stay
          ahead in the evolving tech landscape.
        </p>
      <div className="organization-iot-tech-talent-banner4-card">
        <div className="organization-iot-tech-talent-banner4-card-1">
          <p>Embedded Systems Solution</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-2">
          <p>MVP of embedded system based Product</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-3">
          <p>Custom Product Development Research</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-4">
          <p>POC Development</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-5">
          <p>Hire Tech Talent</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-6">
          <p>Industrial IoT Solutions</p>
        </div>
       </div>
       </div>
       {/* iot */}
          <div className="organization-iot-tech-talent-banner5">
            <h1>Our Technology Expertise Framework</h1>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Microcontrollers & Microprocessors</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={ARMCortex} alt="armcortex" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ARM Cortex</p>
              </div>
              <div>
              <img src={AVR} alt="avr" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>AVR</p>
              </div>
              <div>
              <img src={Microchip} alt="avr" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Microchip</p>
              </div>
              <div>
              <img src={TexasInstruments} alt="Texas Instruments" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Texas Instruments</p>
              </div>
              <div>
              <img src={ESP32} alt="ESP32" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ESP32</p>
              </div>
              <div>
              <img src={STM32} alt="STM32" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>STM32</p>
              </div>
              <div>
              <img src={RISCV} alt=" RISC-V" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p> RISC-V</p>
              </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Embedded Software Development</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={C} alt="C C++" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>C / C++</p>
              </div>
              <div>
              <img src={Linux} alt="Linux" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Linux</p>
              </div>
              <div>
              <img src={Python} alt="Python" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Python</p>
              </div>
              <div>
              <img src={Assembly} alt="Assembly" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Assembly</p>
              </div>
              <div>
              <img src={Lua} alt="Rust and Lua" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Lua</p>
              </div>
              <div>
              <img src={Rust} alt="Rust and Lua" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Rust</p>
              </div>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Real-Time Operating Systems (RTOS)</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={FreeRTOS} alt="FreeRTOS" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>FreeRTOS</p>
              </div>
              <div>
              <img src={VxWorks} alt="VxWorks" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>VxWorks</p>
              </div>
              <div>
              <img src={µCOS} alt="µC/OS embOS" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>µC/OS embOS</p>
              </div>
              <div>
              <img src={ThreadX} alt="ThreadX" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ThreadX</p>
              </div>
              <div>
              <img src={NuttX} alt="NuttX" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>NuttX</p>
              </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Connectivity & Networking</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={MQTT} alt="MQTT" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>MQTT</p>
              </div>
              <div>
              <img src={HTTP} alt="HTTP/HTTPS" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>HTTP/HTTPS</p>
              </div>
              <div>
              <img src={CoAP} alt="CoAP" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>CoAP</p>
              </div>
              <div>
              <img src={Modbus} alt="Modbus" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Modbus</p>
              </div>
              <div>
              <img src={WebSockets} alt="WebSockets" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>WebSockets</p>
              </div>
              <div>
              <img src={Cellular} alt="Cellular LTE, 5G, NB-IoT" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Cellular (LTE, 5G, NB-IoT)</p>
              </div>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-1">
              <h2>Communication Protocols</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Uart} alt="I2C, SPI, UART,CAN" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>I2C, SPI, UART,CAN, </p>
              </div>
              <div>
              <img src={Bluetooth} alt="MQTT" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Ethernet / TCP/IP, Bluetooth (BLE,Classic)</p>
              </div>
              <div>
              <img src={Zigbee} alt="Zigbee" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Zigbee</p>
              </div>
              <div>
              <img src={LoRaWAN} alt="LoRaWAN" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>LoRaWAN</p>
              </div>
              <div>
              <img src={WiFi} alt="Wi-Fi" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Wi-Fi</p>
              </div>
              {/* <div>
              <img src={NFC} alt="NFC" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>NFC</p>
              </div> */}
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-data-1">
              <h2>Automotive Embedded Systems</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              {/* <div>
              <img src={Classic} alt="Classic & Adaptive Platforms" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Classic & Adaptive Platforms</p>
              </div> */}
              <div>
              <img src={BSW} alt="BSW" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>BSW</p>
              </div>
              <div>
              <img src={RTE} alt="RTE" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>RTE</p>
              </div>
              <div>
              <img src={Communication} alt="CAN/LIN/FlexRay communication" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>CAN/LIN/FlexRay communication</p>
              </div>
              <div>
              <img src={UDS} alt="UDS" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>UDS</p>
              </div>
              <div>
              <img src={ISO} alt="ISO 26262" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ISO 26262</p>
              </div>
              <div>
              <img src={Vectortools} alt="Vector/ETAS tools" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Vector/ETAS tools</p>
              </div>
              <div>
              <img src={Hardware} alt="Infineon/NXP/Renesas hardware" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Infineon/NXP/Renesas hardware</p>
              </div>
              <div>
              <img src={Isoftware} alt="ECU software" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ECU software</p>
              </div>
              {/* <div>
              <img src={ADAS} alt="ADAS and  EVs" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>ADAS and  EVs</p>
              </div> */}
            </div>
            </div>
            </div>
          </div>

          <div className="organization-iot-tech-talent-banner6">
            <h1>Streamlined Process for Embedded Systems Solutions and Tech Talent Engagement</h1>
            <p className="organization-iot-tech-talent-banner6-p">Transform your embedded systems vision into reality with our seamless end-to-end process, integrating every
               component—from cutting-edge hardware to advanced software. We drive innovation from concept to deployment,
               ensuring exceptional performance and lasting impact on your business.</p>
          <div className="organization-iot-tech-talent-banner6-steps">
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Discovery and Ideation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step1</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Design and Prototyping</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step2</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Development and Integration</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step3</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Testing and Validation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step4</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Deployment , Monitoring and Feedback</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step5</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Hire Tech Talent</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step6</div>
          </div>
        </div>
        </div>

        <div ref={techtalentSectionRef} className="organization-iot-tech-talent-banner7">
            <h1 className="organization-iot-tech-talent-banner7-h1">Take the Next Step</h1>
            <p className="organization-iot-tech-talent-banner7-p">Whether you're looking to hire expert tech talent or need tailored
            data solutions, we're here to help!</p>
            <div className="organization-iot-tech-talent-banner7-contact">
              <h1>Contact Us</h1>
              <p>Schedule a meeting to discuss your needs</p>
            </div>
            <div className="organization-iot-tech-talent-banner7-btn">
              <div className="organization-iot-tech-talent-banner7-btn1">
              <Link to="/organizations/new-post-project"><button>Post Your Project</button></Link>
              <p>Click here to share your requirements</p>
              </div>
              <div className="organization-iot-tech-talent-banner7-btn1">
                <button>Hire Tech Talent</button>
                {/* <p>Click Here to find the perfect fit for your team</p> */}
                <p>Contact Sales</p>
                <p className="organizations-page-banner8-p">
                  <a 
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@huebits.in" 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      target="_blank"
                      rel="noopener noreferrer" >
                      sales@huebits.in
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="embedded-system-container5">
          <p className="embedded-system-container5-p">
            <FontAwesomeIcon icon={faHandshake} /> Partners
          </p>
          <div className="embedded-system-container5-content">
            <p className="embedded-system-container5-contact">Write to us for collaboration
            : <span>partnerships@huebits.in</span></p>
          </div>
        </div>
          </div>
          <Reveal>
         <div className="organizations-page-banner10">
          <div className="organizations-page-banner10-card">
            <ul className="organizations-page-banner10-ul">
            <li>
            <h1 className="organizations-page-banner10-h1">Business Partnership</h1>
            <p className="organizations-page-banner10-p">Partner with Huebits to Fast-track</p>
            <p className="organizations-page-banner10-p">Talent Engagement and industry Solutions</p>
            </li>
            </ul>
            <div className="organizations-page-banner10-images">
            <img className="organizations-page-banner10-image" src={Footerimage} alt=""/>
            </div>
          </div>
        </div>
        </Reveal>
          <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
          <div className="homepage-footer-copyright">
            <p>© Huebits[2025].All Rights Reserved.</p>
          </div>
          <div className='footer-social-icons'>
              <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
              <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
              <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
              <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
              <Link onClick={scrollToFooterSection}>
              <FaArrowUp className="homepage-social-icon-link" />
              </Link>
          </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;