import React, { useState } from "react";
import { Link } from "react-router-dom";
import Iotdeveloper from "../../../assets/learnersDashboard/1.png";
import Industrialiot from "../../../assets/learnersDashboard/2.png";
import Iotsecurity from "../../../assets/learnersDashboard/3.png";
import Iotdatascientist from "../../../assets/learnersDashboard/4.png";
import Iothardware from "../../../assets/learnersDashboard/5.png";
import Starts from "../../../assets/learnersDashboard/6.png";
import "./learningpath.css";

const IearningPath = () => {
  const [clickedPaths, setClickedPaths] = useState(() => {
    const savedPaths = localStorage.getItem("clickedPaths");
    return savedPaths ? JSON.parse(savedPaths) : {};
  });

  const handleStartClick = (path) => {
    const updatedPaths = { ...clickedPaths, [path]: true };
    setClickedPaths(updatedPaths);
    localStorage.setItem("clickedPaths", JSON.stringify(updatedPaths));
  };

  const renderButton = (path) => {
    const isClicked = clickedPaths[path];
    return (
      <button
        className="learning-path-button"
        onClick={() => handleStartClick(path)}
      >
        {isClicked ? "Resume" : "Start"}
      </button>
    );
  };

  return (
    <div>
      <div className="mylearningpaths">
        <div className="learning-path-container">
          <div className="learning-path-cards">
            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Iotdeveloper}
                alt="IoT Developer"
              />
              <h2 className="learning-path-title">IoT Developer / Engineer</h2>
              <Link to="./iot-developer-engineer" style={{ textDecoration: "none" }}>
                {renderButton("iot-developer-engineer")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Iotdatascientist}
                alt="IoT Data Scientist"
              />
              <h2 className="learning-path-title">IoT Data Scientist</h2>
              <Link to="./iot-data-scientist" style={{ textDecoration: "none" }}>
                {renderButton("iot-data-scientist")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Industrialiot}
                alt="Industrial IoT"
              />
              <h2 className="learning-path-title">Industrial IoT & Industry 4.0</h2>
              <Link to="./industrial-iot-industry" style={{ textDecoration: "none" }}>
                {renderButton("industrial-iot-and-industry")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Iotsecurity}
                alt="IoT Security"
              />
              <h2 className="learning-path-title">IoT Security Specialist</h2>
              <Link style={{ textDecoration: "none" }}>
                <button className="learning-path-button coming-soon">
                  Coming Soon
                </button>
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Iothardware}
                alt="IoT Hardware"
              />
              <h2 className="learning-path-title">IoT Hardware Engineer</h2>
              <Link style={{ textDecoration: "none" }}>
                <button className="learning-path-button coming-soon">
                  Coming Soon
                </button>
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Starts}
                alt="Customized Learning Path"
              />
              <h2 className="learning-path-title">
                Customized Learning Path with AI
              </h2>
              <Link style={{ textDecoration: "none" }}>
                <button className="learning-path-button coming-soons">
                  Coming Soon
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IearningPath;
