import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemButton, ListItemText, CircularProgress, Box } from "@mui/material";
import { LearnersProfile } from "../../helper/base_url";
import axios from "axios";

const LearnersHeader = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Get current URL path
  const [data, setData] = useState({
    username: "",
    displayName: "",
  });
  const token = localStorage.getItem("token");
  const isActive = (path) => location.pathname === path;

  const learnersLoggedIn = localStorage.getItem("token") !== null;

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  // Wrap handleUserProfile with useCallback
  const handleUserProfile = useCallback(async () => {
    try {
      const response = await axios.post(
        `${LearnersProfile}`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `jwt ${token}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile", error);
    }
  }, [token]); // Dependency array ensures function is stable unless token changes

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token, handleUserProfile]); // Include handleUserProfile in the dependencies

  const handleLoginClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // Function to get the first letter of the username or display name
  const getFirstLetter = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  const menuItems = [
    { label: "Home", path: "/" },
    { label: "Learners", path: "/learners" },
  {
    label: learnersLoggedIn ? "Dashboard" : "Sign In",
    path: learnersLoggedIn ? "/learners/dashboardpage" : "/learners/signin",
    action: learnersLoggedIn ? closeMenu : handleLoginClick,
  },
// Add Sign Up menu item for users who are not logged in
...(!learnersLoggedIn ? [{ label: "Sign Up", path: "/learners/signup" }] : []),
];

  return (
    <AppBar position="static" sx={{ bgcolor: "#fff", boxShadow: "none" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 2, sm: 6, md: 6 },
          boxShadow: "0px 0.5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "26px",
              sm: "30px",
              md: "40px",
              lg: "35px", // Apply 35px for 14-inch screens
            },
            fontWeight: "700",
            color: "rgb(2, 41, 100)",
            textDecoration: "none",
            "@media (min-width: 1366px) and (max-width: 1536px)": {
              fontSize: "35px",
            },
          }}
          component={Link}
          to="/"
        >
          Hue<span style={{ color: "rgb(27, 110, 244)" }}>bits</span>
        </Typography>

        {/* Profile Avatar Section */}
        <IconButton
          edge="end"
          onClick={toggleMenu}
          sx={{ color: "rgb(2, 41, 100)", display: { xs: "block", md: "none" } }}
        >
          <MenuOutlinedIcon fontSize="large" />
        </IconButton>

        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 3 }}>
          {menuItems.map((item, index) => (
            <Link key={index} to={item.path} onClick={item.action} style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  color: item.label === "Sign Up" ? "#fff" : isActive(item.path) ? "#1c84e4" : "#303141", // Active color for current page
                  fontWeight: isActive(item.path) ? "400" : "400",
                  fontSize: "16px",
                  padding: "6px",
                  borderRadius: "4px",
                  border: item.label === "Sign In" ? "1px solid #1c84e4" : item.label === "Sign Up" ? "1px solid #1c84e4" : "none",
                  backgroundColor: item.label === "Sign Up" ? "#1c84e4" : "transparent",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: item.label === "Sign Up" ? "#fff" : "#1c84e4",
                    color: item.label === "Sign Up" ? "#1c84e4" : "#fff",
                  },
                }}
              >
                {loading && item.label === "Sign In" ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  item.label
                )}
              </Box>
            </Link>
          ))}
          {learnersLoggedIn && (
            <Link to="/learners/dashboardpage" style={{ textDecoration: "none" }}>
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                backgroundColor: "#1c84e4",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "18px",
                textDecoration:"none"
              }}
            >
              {getFirstLetter(data.displayName || data.username)}
            </Box>
            </Link>
          )}
        </Box>
      </Toolbar>

      {/* Drawer for Mobile View */}
      <Drawer
        anchor="left"
        open={menuActive}
        onClose={closeMenu}
        sx={{ "& .MuiDrawer-paper": { bgcolor: "#fff", width: 250 } }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={closeMenu}
            sx={{
              color: "#052659",
              fontWeight: 400,
              fontSize: "16px",
              padding: "8px",
              borderRadius: "4px",
              transition: "background-color 0.3s ease",
              "&:hover": { backgroundColor: "#1c84e4", color: "#fff" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item, index) => (
            <ListItemButton key={index} component={Link} to={item.path} onClick={closeMenu} selected={location.pathname === item.path}>
              <ListItemText
                primary={item.label}
                sx={{
                  color: location.pathname === item.path ? "#1c84e4" : "#052659",
                  fontWeight: location.pathname === item.path ? "600" : "400",
                  padding: "8px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s ease",
                  "&:hover": { backgroundColor: "#1c84e4", color: "#fff" },
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default LearnersHeader;
