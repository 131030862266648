import IntroductiontoInternetofThings from "../../../../assets/learnersCourses/iotCourses/introductiontoInternetofThings.png";
import PythonProgramming from "../../../../assets/learnersCourses/iotCourses/pythonProgramming.png";
import IoTEmbeddedSystemswithESP32 from "../../../../assets/learnersCourses/iotCourses/ioTandEmbeddedSystemswithESP32.png";
import IoTusingRaspberryPiandPython from "../../../../assets/learnersCourses/iotCourses/ioTusingRaspberryPiandPython.png";
import MicrosoftAzureIoTCertification from "../../../../assets/learnersCourses/iotCourses/microsoftAzureIoTCertification.png";
import AWSIoTCertificationTraining from "../../../../assets/learnersCourses/iotCourses/aWSIoTCertificationTraining.png";
import MasterESP32 from "../../../../assets/learnersCourses/iotCourses/masterESP32BluetoothandBLEHandsOnProjectsforIoTCommunication.png";
import MasterWIFIcommunication from "../../../../assets/learnersCourses/iotCourses/masterWIFIcommunication.png";
import Longdistance from "../../../../assets/learnersCourses/iotCourses/longdistancewirelessnetworksCellularLoRaNBIOT.png";
import MasteringMQTT from "../../../../assets/learnersCourses/iotCourses/masteringMQTT.png";
import Fullstackiot from "../../../../assets/learnersCourses/iotCourses/fullStackIoTJSMERNRaspberryPi.png";
import Developingwebapplications from "../../../../assets/learnersCourses/iotCourses/developingWebApplicationsforIoTUsingPythonandJavaScript.png";
import IoTDataAnalyticsUsingPython from "../../../../assets/learnersCourses/iotCourses/ioTDataAnalyticsUsingPython.png";
import IndustrialMATLAB from "../../../../assets/learnersCourses/iotCourses/iotMATLAB.png";
import MINGTechnologies from "../../../../assets/learnersCourses/iotCourses/mING TechnologiesinIndustrialIoT.png";
import NodeRedCourse from "../../../../assets/learnersCourses/iotCourses/node-RedCourse.jpg";

//iotdata
import ArtificialIntelligenceofThings from "../../../../assets/learnersCourses/iotCourses/artificialIntelligenceofThings.png";
//import PowerBI from "../../../assets/dashboard-images/images/images/projects/9.png";
//import Kafka from "../../../assets/dashboard-images/images/images/projects/8.png";
import Datavisualization from "../../../../assets/learnersCourses/iotCourses/dataVisualizationforIoTPowerBITableauGrafanaandKibana.png";
import IoTDataInsightswithKafka from "../../../../assets/learnersCourses/iotCourses/ioTDataInsights withKafkaRealtimeAnalytics.png";
import MachineLearning from "../../../../assets/learnersCourses/iotCourses/machineLearning.jpg";
import IndustrialInternet from "../../../../assets/learnersCourses/iotCourses/industrialInternetofThings.png";
//IndustrialIoTInsutry
//import IntroductiontoInternetofThings from "../../../assets/dashboard-images/images/images/projects/16.png";
//import PythonProgramming from "../../../assets/dashboard-images/images/images/15.jpg";
//import IoTEmbeddedSystemswithESP32  from "../../../assets/dashboard-images/images/images/7.png";
//import Internet from "../../../assets/dashboard-images/images/images/4.png";
//import SmartHome from "../../../assets/dashboard-images/images/images/3.png";
//import ESP32Arduino from "../../../assets/dashboard-images/images/images/2.png";
//import NodeMCUMATLAB from "../../../assets/dashboard-images/images/images/17.jpg";
//import NternetESP32Arduino from "../../../assets/dashboard-images/images/images/16.jpg";
//import NodeRed from "../../../assets/dashboard-images/images/images/18.jpg";
//import BluetoothTechnology from "../../../assets/dashboard-images/images/images/19.jpg";
//import JavaScript from "../../../../assets/dashboard-images/images/images/13.jpg";
//import SystemsProject from "../../../../assets/dashboard-images/images/images/projects/30.png";
//import SmartusingESP32 from "../../../assets/dashboard-images/images/images/projects/27.png";
//import Industry from "../../../../assets/dashboard-images/images/images/projects/35.png";
//import IndustrialInternet from "../../../assets/dashboard-images/images/images/10.png";


const IoTdevelopercourses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "66e8028064721c6daa143ae8",
    image: IntroductiontoInternetofThings,
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "660e8bc9699598329d883cf6",
    image: PythonProgramming,
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "660e7e601e0fac451502fb0c",
    image: IoTEmbeddedSystemswithESP32,
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "66044b9cdf77f21d16e2c40c",
    image: IoTusingRaspberryPiandPython,
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "660ea20144dad02ad4c6bfcb",
    image: MicrosoftAzureIoTCertification,
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "660ea25494fd573437e5c251",
    image: AWSIoTCertificationTraining,
  },
  {
    courseId: 0.7,
    courseTitle: "Master ESP32, Bluetooth, and BLE",
    videoUrl: "660e80340229d83cda0e6e48",
    image: MasterESP32,
  },
  {
    courseId: 0.8,
    courseTitle: "Master WIFI communication",
    videoUrl: "66acd995d6f9082bdc424251",
    image: MasterWIFIcommunication,
  },
  {
    courseId: 0.9,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "66acdc5519d25e5892c64c42",
    image: Longdistance,
  },
  {
    courseId: 0.10,
    courseTitle: "Mastering MQTT",
    videoUrl: "660e8acf44dad02ad4c6b89c",
    image: MasteringMQTT,
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "660e7f660229d83cda0e6da7",
    image: Fullstackiot,
  },
  {
    courseId: 0.12,
    courseTitle: "Developing Web Applications for IoT Using Python and JavaScript",
    videoUrl: "66e807c6ece5194df3bc5213",
    image: Developingwebapplications,
  },
  {
    courseId: 0.13,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "660eb969cf0168138b68bec0",
    image: IoTDataAnalyticsUsingPython,
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using MATLAB",
    videoUrl: "660e7e9334780c3dfae59a8f",
    image: IndustrialMATLAB,
  }
];

//iotdata
const IoTDataScientistcourses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "66e8028064721c6daa143ae8",
    image: IntroductiontoInternetofThings,
    completion: 0
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "660e8bc9699598329d883cf6",
    image: PythonProgramming,
    completion: 0
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "660e7e601e0fac451502fb0c",
    image: IoTEmbeddedSystemswithESP32,
    completion: 0
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "66044b9cdf77f21d16e2c40c",
    image: IoTusingRaspberryPiandPython,
    completion: 0
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "660ea20144dad02ad4c6bfcb",
    image: MicrosoftAzureIoTCertification,
    completion: 0
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "660ea25494fd573437e5c251",
    image:  AWSIoTCertificationTraining,
    completion: 0
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "660eb969cf0168138b68bec0", 
    image: IoTDataAnalyticsUsingPython,
    completion: 0
  },
  {
    courseId: 0.7,
    courseTitle: "Master ESP32, Bluetooth, and BLE",
    videoUrl: "660e80340229d83cda0e6e48",
    image: MasterESP32,
    completion: 0
  },
  {
    courseId: 0.8,
    courseTitle: "Master WIFI communication",
    videoUrl: "66acd995d6f9082bdc424251", 
    image: MasterWIFIcommunication,
    completion: 0
  },
  {
    courseId: 0.9,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "66acdc5519d25e5892c64c42", 
    image: Longdistance,
    completion: 0
  },
  {
    courseId: 0.10,
    courseTitle: "Mastering MQTT",
    videoUrl: "660e8acf44dad02ad4c6b89c", 
    image: MasteringMQTT,
    completion: 0
  },
  {
    courseId: 0.15,
    courseTitle: "IoT Data analysis using MATLAB",
    videoUrl: "660e7e9334780c3dfae59a8f", 
    image: IndustrialMATLAB,
    completion: 0
  },
  // {
  //   courseId: 0.16,
  //   courseTitle: "Machine Learning",
  //   videoUrl: "https://learn.huebits.in/courses/Machine-Learning-using-Python-66752d77e501170a8d812ec9", 
  //   image: SmartusingESP32,
  //   completion: 0
  // },
  {
    courseId: 0.17,
    courseTitle: "Artificial Intelligence of things",
    videoUrl: "66e80efe3939d23d140bf34c",
    image: ArtificialIntelligenceofThings,
    completion: 0
  },
  // {
  //   courseId: 0.18,
  //   courseTitle: "Embedded Machine Learning",
  //   videoUrl: "https://learn.huebits.in/courses/Embedded-Machine-Learning-66ace0f7003a2e656e1f67c2",
  //   image: PowerBI,
  //   completion: 0
  // },
  // {
  //   courseId: 0.19,
  //   courseTitle: "Edge Computing",
  //   videoUrl: "https://learn.huebits.in/courses/Edge-Computing--66e8134ff1f27f2d14776ac9",
  //   image: Kafka,
  //   completion: 0
  // },
  {
    courseId: 0.20,
    courseTitle: "Data Visualization for IoT PowerBI, Tableau, Grafana, and Kibana",
    videoUrl: "66ace17c277599313406d4f1",
    image: Datavisualization,
    completion: 0
  },
  {
    courseId: 0.21,
    courseTitle: "IoT Data Insights with Kafka: Real-time Analytics",
    videoUrl: "66ace1fcd6f9082bdc424591",
    image: IoTDataInsightswithKafka,
    completion: 0
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "660e7f660229d83cda0e6da7",
    image: Fullstackiot,
    completion: 0
  }
];

const IndustrialiotCourses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "66e8028064721c6daa143ae8",
    image: IntroductiontoInternetofThings,
    completion: 0
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "660e8bc9699598329d883cf6",
    image: PythonProgramming,
    completion: 0
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "660e7e601e0fac451502fb0c",
    image: IoTEmbeddedSystemswithESP32,
    completion: 0
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "66044b9cdf77f21d16e2c40c",
    image: IoTusingRaspberryPiandPython,
    completion: 0
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "660ea20144dad02ad4c6bfcb",
    image: MicrosoftAzureIoTCertification,
    completion: 0
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "660ea25494fd573437e5c251",
    image:  AWSIoTCertificationTraining,
    completion: 0
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "660eb969cf0168138b68bec0", 
    image: IoTDataAnalyticsUsingPython,
    completion: 0
  },
  {
    courseId: 0.10,
    courseTitle: "Mastering MQTT",
    videoUrl: "660e8acf44dad02ad4c6b89c",
    image: MasteringMQTT,
    completion: 0
  },
  {
    courseId: 0.16,
    courseTitle: "Machine Learning",
    videoUrl: "66752d77e501170a8d812ec9", 
    image: MachineLearning,
    completion: 0
  },
  {
    courseId: 0.22,
    courseTitle: "Node-Red Course",
    videoUrl: "660e805c06697760666c0abf", 
    image: NodeRedCourse,
    completion: 0
  },
  {
    courseId: 0.23,
    courseTitle: "Industrial Internet of Things",
    videoUrl: "660e8686337f1467aec01fad", 
    image: IndustrialInternet,
    completion: 0
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "660e7f660229d83cda0e6da7",
    image: Fullstackiot,
    completion: 0
  },
  {
    courseId: 0.25,
    courseTitle: "MING technologies",
    videoUrl: "66e8190e268fd80c0d02f1ec", 
    image: MINGTechnologies,
    completion: 0
  },
  // {
  //   courseId: 0.26,
  //   courseTitle: "Smart manufacturing and Industry4.0",
  //   videoUrl: "66e819953939d23d140bf840",
  //   image: Industry,
  //   completion: 0
  // },
  // {
  //   courseId: 0.24,
  //   courseTitle: "PLC, SCADA Systems and IoT",
  //   videoUrl: "66e817a16e1bbb1191401a75", 
  //   image: SystemsProject,
  //   completion: 0
  // },
];

export { IoTdevelopercourses, IoTDataScientistcourses, IndustrialiotCourses };
