import React from "react";
//import { RiLoginBoxFill } from "react-icons/ri";
import Icon1 from "../../assets/homepage/icons/1.png";
import Icon2 from "../../assets/homepage/icons/2.png";
import Icon3 from "../../assets/homepage/icons/3.png";
import Icon4 from "../../assets/homepage/icons/4.png";
import Icon5 from "../../assets/homepage/icons/5.png";
import Icon6 from "../../assets/homepage/icons/6.png";
import Icon7 from "../../assets/homepage/icons/7.png";

const Bestplan = () => {
  return (
    <div className="homepage-banner5-cards">
      <hr className="dotted-hr" />
      <div className="homepage-banner5-cards-card1">
        <div className="homepage-banner5-cards-card1-img">
        <img src={Icon1} alt="icon1" className="homepage-banner5-cards-card1-icons1"/>
          {/* <RiLoginBoxFill className="homepage-banner5-cards-card1-icons1" /> */}
          <p className="homepage-banner5-cards-card1-p">
          <img src={Icon1} alt="icon1" className="homepage-banner5-cards-card1-icons1"/>
          {/* <RiLoginBoxFill className="homepage-banner5-cards-card1-icons1" /> */}
          <p className="homepage-banner5-cards-card1-icons1-p">Student Enrollment</p>
            <p className="homepage-banner5-cards-card1-icons1-p1">Registration & Onboarding</p></p>
        </div>
          <h4>Student Enrollment</h4>
      </div>
      <div className="homepage-banner5-cards-card1">
      <div className="homepage-banner5-cards-card1-img">
      <img src={Icon2} alt="icon2" className="homepage-banner5-cards-card1-icons2"/>
      <p className="homepage-banner5-cards-card1-p">
        <img src={Icon2} alt="icon2" className="homepage-banner5-cards-card1-icons2"/>
        <p className="homepage-banner5-cards-card1-icons2-p">Boot Camp 1 (40+ Hours)</p>
        <p className="homepage-banner5-cards-card1-icons2-p1">In-depth Classroom/Offline Training Hands-on Learning with Industry Experts</p>
      </p>
      </div>
          <h4>Boot camp-1</h4>
      </div>
      <div className="homepage-banner5-cards-card1">
      <div className="homepage-banner5-cards-card1-img">
      <img src={Icon3} alt="icon3" className="homepage-banner5-cards-card1-icons3"/>
      <p className="homepage-banner5-cards-card1-p">
      <img src={Icon3} alt="icon3" className="homepage-banner5-cards-card1-icons3"/>
        <p className="homepage-banner5-cards-card1-icons3-p">Access to 60+ Courses</p>
          <p className="homepage-banner5-cards-card1-icons3-p1">Self-paced Learning Resources Skill Enhancement & Specializations</p>
      </p>
      </div>
          <h4>Access to 60+ Courses</h4>
      </div>
      <div className="homepage-banner5-cards-card1">
      <div className="homepage-banner5-cards-card1-img">
      <img src={Icon4} alt="icon4" className="homepage-banner5-cards-card1-icons4"/>
      <p className="homepage-banner5-cards-card1-p">
      <img src={Icon4} alt="icon4" className="homepage-banner5-cards-card1-icons4"/>
        <p className="homepage-banner5-cards-card1-icons4-p">Boot Camp 2 (40+ Hours)</p> 
        <p className="homepage-banner5-cards-card1-icons4-p1">Advanced Training & Industry Case Studies Real-world Applications</p>
      </p>
      </div>
          <h4>Boot camp-2</h4>
      </div>
      <div className="homepage-banner5-cards-card1">
      <div className="homepage-banner5-cards-card1-img">
      <img src={Icon5} alt="icon5" className="homepage-banner5-cards-card1-icons5"/>
      <p className="homepage-banner5-cards-card1-p">
      <img src={Icon5} alt="icon4" className="homepage-banner5-cards-card1-icons5"/>
        <p className="homepage-banner5-cards-card1-icons5-p">Hackathon & Project Challenge</p>
          <p className="homepage-banner5-cards-card1-icons5-p1">Practical Application of Skills Problem-Solving & Innovation</p>
      </p>
      </div>
          <h4>Hackthon & Project Challenge</h4> 
      </div>
      <div className="homepage-banner5-cards-card1">
      <div className="homepage-banner5-cards-card1-img">
      <img src={Icon6} alt="icon6" className="homepage-banner5-cards-card1-icons6"/>
      <p className="homepage-banner5-cards-card1-p">
      <img src={Icon6} alt="icon6" className="homepage-banner5-cards-card1-icons6"/>
        <p className="homepage-banner5-cards-card1-icons6-p">HOE Score</p>
        <p className="homepage-banner5-cards-card1-icons6-p1">Performance Assessment Skill Proficiency Benchmarking</p>
      </p>
      </div>
          <h4>HOE Score</h4>
      </div>
      <div className="homepage-banner5-cards-card1">
      <div className="homepage-banner5-cards-card1-img">
      <img src={Icon7} alt="icon7" className="homepage-banner5-cards-card1-icons7"/>
      <p className="homepage-banner5-cards-card1-p">
      <img src={Icon7} alt="icon7" className="homepage-banner5-cards-card1-icons7"/>
        <p className="homepage-banner5-cards-card1-icons7-p">Career Pathway</p> 
         <p className="homepage-banner5-cards-card1-icons7-p">Guaranteed Internship (₹30K+ Total Stipend) Placement 
          Assistance & Job Readiness Industry  Connections & Hiring Support</p>
        </p>
      </div>
          <h4>Career Pathway Guaranteed Internship</h4>
      </div>
    </div>
  );
};

export default Bestplan;