import React, {useState, useEffect} from "react";
import { Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import behaves from "../../assets/organizationsSignin.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Typography, TextField, Grid, Button, IconButton, CircularProgress, useMediaQuery } from '@mui/material';
import { FaEye, FaEyeSlash, FaTwitter, FaInstagram,FaLinkedin, FaYoutube } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import {OrganizationsLogin} from "../../helper/base_url";

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ username: "", password: "", role: "company" });
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const navigate = useNavigate();

    // Check if user is already logged in
    useEffect(() => {
        if (localStorage.getItem('company_token')) {
            navigate("/organizations");
        }
    }, [navigate]);

    const handleOnChange = (e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const toastId = toast.loading("Your login request is being processed...");
        try {
            const response = await axios.post(`${OrganizationsLogin}`, data, {
                headers: { "Content-Type": "application/json" },
              });
              const { message, token, error } = response.data;
              toast.update(toastId, { render: message, type: error ? "error" : "success", isLoading: false, autoClose: 1000 });
      if (!error) {
        localStorage.setItem('company_token', token);
        // setTimeout(() => navigate("/organizations"), 1500);
        setTimeout(() => navigate("/organizations/dashboard"), 500);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Network error, please try again.';
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 1000 });
    } finally {
      setLoading(false);
    }
  };

    return (
        <div className="students-flex">
            <Headrepage />
          <div style={{ width: "100%", overflowX: "hidden", overflowY: "auto", height: "91vh" }}>
            <ToastContainer position="top-center" />
          <Grid container justifyContent="center" alignItems="center" style={{ padding: "40px 20px" }}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
          {/* <Paper elevation={3} style={{ padding: 20, borderRadius: 10 }}> */}
            <Typography variant="h5" align="center" gutterBottom sx={{color:"#043885"}}>
            Log in to your Huebits account
            </Typography>            
                <form onSubmit={submitHandler}>
                    <TextField label="Email" type="email" name="username" fullWidth margin="normal" value={data.username} onChange={handleOnChange} required disabled={loading} />
                 <Box position="relative">
                    <TextField label="Password" type={showPassword ? "text" : "password"} name="password" fullWidth margin="normal" value={data.password} onChange={handleOnChange} required disabled={loading} />
                    <IconButton onClick={() => setShowPassword(prev => !prev)} style={{ position: "absolute", right: 10, top: "35%" }}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </IconButton>
                    </Box>
                    <TextField label="Confirm Password" type="password" name="confirmPassword" fullWidth margin="normal" value={data.confirmPassword} onChange={handleOnChange} required disabled={loading} />
                     <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: 10 }} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Sign In'}
                    </Button>
                </form>
                    <Typography sx={{marginTop:"10px"}}><Link to="/organizations/forgot-password">Forgot Password</Link></Typography>
                    <Typography align="center" style={{ marginTop: 15 }}>
                     Don't have an account?{" "}<Link to="/organizations/signup">Create an account</Link>
                    </Typography>
                </Grid>
                {!isMobile && !isTablet && (
                    <Grid item xs={12} sm={8} md={6} lg={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={behaves} alt="Signup Illustration" style={{ width: "100%", maxWidth: "500px", height: "100%", marginLeft: "40px", borderRadius:"10px" }} />
                    </Grid>
                )}
            </Grid>
           <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
            </div>
            </div>
            </footer>
            </div>
            </div>
        </div>
    );
}

export default Login;
