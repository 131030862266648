import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import behaves from "../../assets/organizationsSignup.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Typography, TextField, Grid, Button, IconButton, FormControlLabel, Checkbox, CircularProgress, useMediaQuery } from '@mui/material';
import { FaEye, FaEyeSlash, FaTwitter, FaInstagram,FaLinkedin, FaYoutube } from 'react-icons/fa';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import {OrganizationsSignup} from "../../helper/base_url";

function Signup(){
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ name: "", username: "", password: "", confirmPassword: "", company: "", role: "company" });
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');

  const navigate = useNavigate();

  // Check if user is already logged in
      useEffect(() => {
          if (localStorage.getItem('company_token')) {
              navigate("/organizations");
          }
      }, [navigate]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$/;

    if (data.password.length < 8) {
      toast.error("Password should be more than 8 characters");
      setLoading(false);
    } else if (!regExspecialChars.test(data.password)) {
      toast.error("Password should contain at least 1 digit, 1 special character, and 1 lower and upper case letter");
      setLoading(false);
    } else if (data.password !== data.confirmPassword) {
      toast.error("Confirm password does not match"); 
      setLoading(false);
    } else {
      try {
        const response = await axios.post(`${OrganizationsSignup}`, data, {
          headers: { "Content-Type": "application/json" },
        });

        const { message, token, error } = response.data;
        
        if (error) {
          toast.error(message || "An error occurred.");
        } else {
          toast.success(message || "Registration successful!");
          localStorage.setItem('company_token', token);
          setTimeout(() => navigate("/organizations/dashboard"), 500);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Network error, please try again.';
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <div style={{ width: "100%", overflowX: "hidden", overflowY: "auto", height: "91vh" }}>
      <ToastContainer position="top-center" />
      <Grid container justifyContent="center" alignItems="center" style={{ padding: "40px 20px" }}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          {/* <Paper elevation={3} style={{ padding: 20, borderRadius: 10 }}> */}
            <Typography variant="h5" align="center" gutterBottom sx={{color:"#043885"}}>
              Sign Up for a Company Account
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField label="Name" name="name" fullWidth margin="normal" value={data.name} onChange={handleOnChange} required disabled={loading} />
              <TextField label="Company Name" name="company" fullWidth margin="normal" value={data.company} onChange={handleOnChange} required disabled={loading} />
              <TextField label="Email" type="email" name="username" fullWidth margin="normal" value={data.username} onChange={handleOnChange} required disabled={loading} />
              <Box position="relative">
                <TextField label="Password" type={showPassword ? "text" : "password"} name="password" fullWidth margin="normal" value={data.password} onChange={handleOnChange} required disabled={loading} />
                <IconButton onClick={() => setShowPassword(prev => !prev)} style={{ position: "absolute", right: 10, top: "35%" }}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </IconButton>
              </Box>
              <TextField label="Confirm Password" type="password" name="confirmPassword" fullWidth margin="normal" value={data.confirmPassword} onChange={handleOnChange} required disabled={loading} />
              <FormControlLabel control={<Checkbox required />} label="I agree with the User terms and privacy policy" />
              <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: 10 }} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Sign Up'}
              </Button>
            </form>
            <Typography align="center" style={{ marginTop: 15 }}>
              Already have an account? <Link to="/organizations/signin">Login here</Link>
            </Typography>
          {/* </Paper> */}
        </Grid>
         {!isMobile && !isTablet && (
            <Grid item xs={12} sm={8} md={6} lg={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={behaves} alt="Signup Illustration" style={{ width: "100%", maxWidth: "500px", height: "100%", marginLeft: "40px", borderRadius:"10px" }} />
            </Grid>
          )}
        </Grid>
        <div className="homepage-footers-images">
            <Footer />
            <footer className="homepage-footers">
            <hr className='homepage-footer-hr'/>
              <div className="footer-social">
              <div className="homepage-footer-copyright">
                <p>© Huebits[2025].All Rights Reserved.</p>
              </div>
              <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
            </div>
            </div>
            </footer>
          </div>
          </div>
      </div>
  );
};

export default Signup;
