import React, {useRef, useEffect } from "react";
import { Link } from "react-router-dom";
//import Empower from "../../assets/DataScience.png";
import Embeddedsystems2 from "../../assets/companiesimages/web/4.png";
import Internet from "../../assets/companiesimages/services/8.png";
import Industrial from "../../assets/companiesimages/services/9.png";
import IoT from "../../assets/companiesimages/services/10.png";
import Edge from "../../assets/companiesimages/services/11.png";
import Industry from "../../assets/companiesimages/services/12.png";
import AIoT from "../../assets/companiesimages/services/13.png";
//Technology
import Python from "../../assets/Technology/Internet-tools-logos/1.png";
import Numpy from "../../assets/Technology/Internet-tools-logos/32.png";
import Pandas from "../../assets/Technology/Internet-tools-logos/33.png";
import Learn from "../../assets/Technology/Internet-tools-logos/34.png";
import Pytorch from "../../assets/Technology/Datascience-tools-logos/14.png";
import Tensorflow from "../../assets/Technology/Datascience-tools-logos/18.png";
import Seaborn from "../../assets/Technology/Datascience-tools-logos/55.png";
import Beautiful from "../../assets/Technology/Datascience-tools-logos/54.png";
import Plotly from "../../assets/Technology/Internet-tools-logos/37.png";

import Spark from "../../assets/Technology/Datascience-tools-logos/42.png";
import Keras from "../../assets/Technology/Datascience-tools-logos/17.png";
import Kafka from "../../assets/Technology/Datascience-tools-logos/41.png";
import Postgresql from "../../assets/Technology/icons/14.png";
import Elastic from "../../assets/Technology/icons/18.png";

import Powerbi from "../../assets/Technology/icons/20.png";
import Tableau from "../../assets/Technology/icons/21.png";
import Superset from "../../assets/Technology/icons/26.png";
import Amazon from "../../assets/Technology/icons/25.png";
import AzureSynapseAnalytics from "../../assets/Technology/icons/27.png";
import AzureanalysisServices from "../../assets/Technology/icons/24.png";
import Qlik from "../../assets/Technology/icons/23.png";
import Grafana from "../../assets/Technology/icons/22.png";

import Databricks from "../../assets/Technology/icons/40.png";
import Flink from "../../assets/Technology/icons/41.png";
import Airflow from "../../assets/Technology/icons/42.png";
import Dbt from "../../assets/Technology/icons/43.png";
import Datafactory from "../../assets/Technology/icons/44.png";
//import Datacatalog from "../../assets/companiesimages/Technology/58.png";
import Eventhubs from "../../assets/Technology/icons/45.png";
import Azureiothub from "../../assets/Technology/icons/46.png";
import Azuredata from "../../assets/Technology/icons/47.png";
import Azuremachine from "../../assets/Technology/icons/48.png";
import Azuredatabricks from "../../assets/Technology/icons/49.png";
import Cosmosdb from "../../assets/Technology/icons/50.png";
import Azurefunctions from "../../assets/Technology/icons/51.png";
import Snowflake from "../../assets/Technology/icons/52.png";
import Sql from "../../assets/Technology/icons/53.png";

import Gpt from "../../assets/Technology/icons/32.png";
import Llama from "../../assets/Technology/icons/28.png";
import Midjourney from "../../assets/Technology/icons/38.png";
import Bert from "../../assets/Technology/icons/34.png";
import Stablediffusion from "../../assets/Technology/icons/33.png";
import Stanza from "../../assets/Technology/icons/17.png";
import Flair from "../../assets/Technology/icons/30.png";
import Spacy from "../../assets/Technology/icons/31.png";
import Stylegan from "../../assets/Technology/icons/36.png";
import Cyclegan from "../../assets/Technology/icons/37.png";
import Huggingface from "../../assets/Technology/icons/35.png";

//Partners
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
//import Snowflakes from "../../assets/companiesimages/Partners/8.png";
import Awses from "../../assets/companiesimages/Partners/6.png";
import Databrickses from "../../assets/companiesimages/Partners/10.png";
import Azures from "../../assets/companiesimages/Partners/7.png";
// import InternetofThings3 from "../../assets/companiesimages/InternetofThings3.jpg";
//import { faArrowRight,faStar} from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin,FaYoutube, FaArrowUp } from 'react-icons/fa';
import Footerimage from "../../assets/companiesimages/organizations/banner10.png";
import Reveal from '../../helper/useScrollposition';
import Headrepage from "../../mainpage/Headerpages/OrganizationsHeaderpage";
import Footer from "../../mainpage/Footer/FooterOrganizations";
import "./OrganizationBanner.css";

const InternetofThings = () => {
    const footerSectionRef = useRef(null);
    const solutionsSectionRef = useRef(null);
      const techtalentSectionRef = useRef(null);
      
      const scrollToFooterSection = () => {
        footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };
      const scrollToSolutionsSection = () => {
        solutionsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };
    
      const scrollToTechtalentSection = () => {
        techtalentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      useEffect(() => {
          if (!document.querySelector("script[src='https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv']")) {
            var scriptElement = document.createElement("script");
            scriptElement.src = "https://embed.tawk.to/66a73d06becc2fed692c524c/1i3ukcovv";
            scriptElement.async = true;
            scriptElement.charset = "UTF-8";
            scriptElement.setAttribute("crossorigin", "*");
            
            document.body.appendChild(scriptElement);
          }
        }, []);

    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
          <div className="homepagecolor">
            <div ref={footerSectionRef} className="organization-data-tech-talent-banners1">
              <ul className="organization-iot-tech-talent-banner1">
                <li>
                <h1>Driving Innovation with</h1>
                <h1><span>Data Science & AI</span></h1>
                <p>Empowering Your Business with Advanced Data Science, AI Solutions, and Skilled Tech Talent</p>
                </li>
              </ul>
                {/* <button className="organization-iot-tech-talent-banner1-btn">Learn More</button> */}
              {/* <img className="organization-iot-tech-talent-banner1-image" src={Empower} alt=""/> */}
            </div>

            <div ref={solutionsSectionRef} className="organization-iot-tech-talent-banner2">
            <h1 className="organization-iot-tech-talent-banner2-h1">Unlock the Power of Data with Our Expert Solutions</h1>
            <div className="organization-iot-tech-talent-banner2-card">
            <div>
              <p className="organization-iot-tech-talent-banner2-p">
                Transform your business with cutting-edge Artificial Intelligence,Machine Learning, 
                and Data Engineering solutions. From building
                intelligent models to designing robust data pipelines, we deliver
                tailored solutions to help you innovate, optimize, and grow. Let us
                turn your data into actionable insights and empower your success
                in the digital age
              </p>
              <div className="organization-iot-tech-talent-banner2-button">
                <button onClick={scrollToSolutionsSection}>Explore Data Science Solutions</button>
                <button onClick={scrollToTechtalentSection}>Hire Data Science Talent</button>
              </div>
              </div>
             <div className="organization-iot-tech-talent-banner2-image">
              <img src={Embeddedsystems2} alt="Embedded Systems" />
            </div>
            </div>
            </div>

        <div className="organization-iot-tech-talent-banner3">
            <h1>Data science Professional Services Include</h1>
            <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Internet} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Data Science</p>
        </div>
        <div className="animated-card">
          <img src={Industrial} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Data Engineering</p>
        </div>
        <div className="animated-card">
          <img src={IoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Machine Learning</p>
        </div>
      </div>
      <div className="organization-iot-tech-talent-banner3-card">
        <div className="animated-card">
          <img src={Edge} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Natural Language Processing</p>
        </div>
        <div className="animated-card">
          <img src={Industry} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Generative AI</p>
        </div>
        <div className="animated-card">
          <img src={AIoT} alt="" className="organization-iot-tech-talent-banner3-card-image"/>
          <p>Business Intelligence</p>
        </div>
        </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner4">
        <h1 className="organization-iot-tech-talent-banner4-h1">How We Can Support Your Success</h1>
        <p className="organization-iot-tech-talent-banner4-div1-p">
          We collaborate with teams to deliver innovative solutions for projects
          of any scale or complexity. Leveraging our robust tech talent pool—
          spanning AI, data science, data engineering, IoT, and generative AI—
          we create tailored systems, solutions, and products that give your
          business a competitive edge. Together, we’ll achieve impactful results
          and drive your success.
        </p>
      <div className="organization-iot-tech-talent-banner4-card">
        <div className="organization-iot-tech-talent-banner4-card-1">
          <p>POC of AI based Solution</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-2">
          <p>MVP of AI based Product</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-3">
          <p>Custom Model Development</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-4">
          <p>Data Engineering Solutions</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-5">
          <p>Hire Tech Talent</p>
        </div>
        <div className="organization-iot-tech-talent-banner4-card-6">
          <p>BI Solutions</p>
        </div>
      </div>
      </div>
          {/* iot */}
          <div className="organization-iot-tech-talent-banner5">
            <h1>Our Tech Expertise Matrix</h1>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-data-1">
              <h2>Data Science</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
               <div>
              <img src={Python} alt="python" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Python</p>
              </div>
               <div>
              <img src={Numpy} alt="numpy" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>NumPy</p>
              </div>
               <div>
              <img src={Pandas} alt="pandas" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Pandas</p>
              </div>
              <div>
              <img src={Learn} alt="scikit learn" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Scikit Learn</p>
              </div>
              <div>
              <img src={Pytorch} alt="pytorch" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Pytorch</p>
              </div>
              <div>
              <img src={Tensorflow} alt="tensorflow" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>TensorFlow</p>
              </div>
              <div>
              <img src={Seaborn} alt="seaborn" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Seaborn</p>
              </div>
              <div>
              <img src={Beautiful} alt="beautiful" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>BeautifulSoup</p>
              </div>
              <div>
              <img src={Plotly} alt="plotly" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Plotly</p>
              </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-data-1">
              <h2>Artificial Intelligence</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Python} alt="python" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Python</p>
              </div>
              <div>
              <img src={Tensorflow} alt="tensorflow" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>TensorFlow</p>
              </div>
              <div>
              <img src={Learn} alt="scikit learn" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Scikit Learn</p>
              </div>
              <div>
              <img src={Spark} alt="apache spark" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Apache Spark</p>
              </div>
              <div>
              <img src={Keras} alt="keras" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Keras</p>
              </div>
              <div>
              <img src={Stanza} alt="stanza" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Stanza</p>
              </div>
              <div>
              <img src={Postgresql} alt="postgresql" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>PostgreSQL</p>
              </div>
              <div>
              <img src={Elastic} alt="elastic" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Elastic</p>
              </div>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-data-1">
              <h2>Business Intelligence</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Powerbi} alt="powerbi" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Power BI</p>
              </div>
              <div>
              <img src={Tableau} alt="tableau" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Tableau</p>
              </div>
              <div>
              <img src={Superset} alt="apache auperset" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Apache Superset</p>
              </div>
              <div>
              <img src={Amazon} alt="amazon quicksight" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Amazon QuickSight</p>
              </div>
              <div>
              <img src={AzureSynapseAnalytics} alt="azureaynapseanalytics" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Synapse Analytics</p>
              </div>
              <div>
              <img src={AzureanalysisServices} alt="azureanalysisservices" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure analysis Services</p>
              </div>
              <div>
              <img src={Grafana} alt="grafana" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Grafana</p>
              </div>
              <div>
              <img src={Qlik} alt="qlik" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Qlik</p>
              </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-data-1">
              <h2>Generative AI</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Gpt} alt="gpt-4" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>GPT-4, Whisper, DALL.E</p>
              </div>
              <div>
              <img src={Llama} alt="llama 2" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>LLAMA 2</p>
              </div>
              <div>
              <img src={Midjourney} alt="midjourney" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Midjourney</p>
              </div>
              <div>
              <img src={Bert} alt="bert" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>BERT</p>
              </div>
              <div>
              <img src={Stablediffusion} alt="stable diffusion" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Stable Diffusion</p>
              </div>
              <div>
              <img src={Stanza} alt="stanza" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Stanza</p>
              </div>
              <div>
              <img src={Flair} alt="Flair" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Flair</p>
              </div>
              <div>
              <img src={Spacy} alt="spacy" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>SpaCy</p>
              </div>
              <div>
              <img src={Stylegan} alt="stylegan" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>StyleGAN</p>
              </div>
              <div>
              <img src={Cyclegan} alt="cyclegan" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>CycleGAN</p>
              </div>
              <div>
              <img src={Huggingface} alt="hugging face" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Hugging Face</p>
              </div>
            </div>
            </div>
            </div>
            <div className="organization-iot-tech-talent-banner5-cards">
            <div className="organization-iot-tech-talent-banner5-data-2">
              <h2>Data Engineering</h2>
              <div className="organization-iot-tech-talent-banner5-card-protocols">
              <div>
              <img src={Python} alt="python" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Python</p>
              </div>
              <div>
              <img src={Databricks} alt="databricks" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Databricks</p>
              </div>
              <div>
              <img src={Spark} alt="stanza" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Apache Spark</p>
              </div>
              <div>
              <img src={Flink} alt="flink" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Flink</p>
              </div>
              <div>
              <img src={Airflow} alt="apache airflow" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Apache Airflow</p>
              </div>
              <div>
              <img src={Dbt} alt="dbt" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Dbt</p>
              </div>
              <div>
              <img src={Kafka} alt="kafka" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Kafka</p>
              </div>
              <div>
              <img src={Datafactory} alt="data factory" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Data Factory</p>
              </div>
              {/* <div>
              <img src={Datafactory} alt="stanza" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Data Catalog</p>
              </div> */}
               <div>
              <img src={Eventhubs} alt="event hubs" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Event Hubs</p>
              </div>
              <div>
              <img src={Azureiothub} alt="azureiothub" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure IOT Hub</p>
              </div>
              <div>
              <img src={Azuredata} alt="azure data lake storage" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Data Lake Storage</p>
              </div>
              <div>
              <img src={Powerbi} alt="powerbi" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Power BI</p>
              </div>
              <div>
              <img src={AzureSynapseAnalytics} alt="azure synapse analytics" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Synapse Analytics</p>
              </div>
              <div>
              <img src={Azuremachine} alt="azure machine learning" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Machine Learning</p>
              </div>
              <div>
              <img src={Azuredatabricks} alt="azure databricks" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Databricks</p>
              </div>
              <div>
              <img src={Cosmosdb} alt="cosmos db" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Cosmos DB</p>
              </div>
              <div>
              <img src={Azurefunctions} alt="azure functions" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Functions</p>
              </div>
              <div>
              <img src={AzureanalysisServices} alt="azure analysis services" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Azure Analysis Service</p>
              </div>
              <div>
              <img src={Snowflake} alt="snowflake" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>Snowflake</p>
              </div>
              <div>
              <img src={Sql} alt="aql" className="organization-iot-tech-talent-banner5-logos-iot"/>
              <p>SQL</p>
              </div>
            </div>
            </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner6">
            <h1>Streamlined Process for Data Solutions and Tech Talent Engagement</h1>
            <p className="organization-iot-tech-talent-banner6-p">Achieve your goals with our end-to-end process, combining
              tailored data solutions with top-tier tech talent. From project
              kickoff to deployment, we ensure seamless execution and lasting
              value for your business.
            </p>
          <div className="organization-iot-tech-talent-banner6-steps">
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Post Requirements & Kickoff</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step1</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Proof of Concept (PoC) or MVP Development</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step2</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Data Acquisition & Preparation</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step3</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Model Development & Integration</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step4</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Model Deployment & Feedback</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step5</div>
          </div>
          <div className="organization-iot-tech-talent-banner6-step">
            <p>Hire Tech Talent</p>
            <div className="organization-iot-tech-talent-banner6-diamond">Step6</div>
          </div>
        </div>
        </div>
          <div ref={techtalentSectionRef} className="organization-iot-tech-talent-banner7">
            <h1 className="organization-iot-tech-talent-banner7-h1">Take the Next Step</h1>
            <p className="organization-iot-tech-talent-banner7-p">Whether you're looking to hire expert tech talent or need tailored
            data solutions, we're here to help!</p>
            <div className="organization-iot-tech-talent-banner7-contact">
              <h1>Contact Us</h1>
              <p>Schedule a meeting to discuss your needs</p>
            </div>
            <div className="organization-iot-tech-talent-banner7-btn">
              <div className="organization-iot-tech-talent-banner7-btn1">
              <Link to="/organizations/new-post-project"><button>Post Your Project</button></Link>
              <p>Click here to share your requirements</p>
              </div>
              <div className="organization-iot-tech-talent-banner7-btn1">
                <button>Hire Tech Talent</button>
                {/* <p>Click Here to find the perfect fit for your team</p> */}
                <p>Contact Sales</p>
                <p className="organizations-page-banner8-p">
                  <a 
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@huebits.in" 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      target="_blank"
                      rel="noopener noreferrer" >
                      sales@huebits.in
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="organization-iot-tech-talent-banner8">
             <p className="organization-iot-tech-talent-banner8-p"><FontAwesomeIcon icon={faHandshake} /> Partners</p>
             <p className="embedded-system-container5-contact">Write to us for collaboration
             : <span>partnerships@huebits.in</span></p>
            <div className="organization-iot-tech-talent-banner8-1">
            <div className="organization-iot-tech-talent-banner8-cards">
            <div>
            <img src={Awses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={Databrickses} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            <div>
            <img src={Azures} alt="" className="organization-iot-tech-talent-banner8-cards-image"/>
            </div>
            </div>
            </div>
          </div>
          <Reveal>
         <div className="organizations-page-banner10">
          <div className="organizations-page-banner10-card">
            <ul className="organizations-page-banner10-ul">
            <li>
            <h1 className="organizations-page-banner10-h1">Business Partnership</h1>
            <p className="organizations-page-banner10-p">Partner with Huebits to Fast-track</p>
            <p className="organizations-page-banner10-p">Talent Engagement and industry Solutions</p>
            </li>
            </ul>
            <div className="organizations-page-banner10-images">
            <img className="organizations-page-banner10-image" src={Footerimage} alt=""/>
            </div>
          </div>
        </div>
        </Reveal>
          <div className="homepage-footers-images">
          <Footer />
          <footer className="homepage-footers">
          <hr className='homepage-footer-hr'/>
          <div className="footer-social">
          <div className="homepage-footer-copyright">
            <p>© Huebits[2025].All Rights Reserved.</p>
          </div>
          <div className='footer-social-icons'>
              <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
              <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
              <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
              <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
              <Link to="https://www.facebook.com/profile.php?id=61571753805069" target="_blank" rel="noopener noreferrer"><FaFacebook className="social-icon" /></Link>
              <Link onClick={scrollToFooterSection}>
              <FaArrowUp className="homepage-social-icon-link" />
              </Link>
          </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
    )
}


export default InternetofThings;