import React, { useState } from "react";
import { Button, TextField, Grid, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';  // Import CloseIcon

const Apply = ({ setActiveLink }) => {
  const [data, setData] = useState({
    institution: "",
    study: "",
    year: "",
    gpa: "",
    programmingskills: "",
    toolstechnologies: "",
  });

  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleNext = () => {
    let validationErrors = {};

    if (!data.programmingskills.trim()) {
      validationErrors.programmingskills = "Programming Languages are required";
    }

    if (!data.toolstechnologies.trim()) {
      validationErrors.toolstechnologies = "Tools and Technologies are required";
    }

    if (!data.institution.trim()) {
      validationErrors.institution = "Institution is required";
    }

    if (!data.study.trim()) {
      validationErrors.study = "Field of Study is required";
    }

    if (!data.year.trim()) {
      validationErrors.year = "Year of Study is required";
    }

    if (!data.gpa.trim()) {
      validationErrors.gpa = "GPA is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log("User Entered Data:", data);
    localStorage.setItem("technicalSkills", JSON.stringify(data));

    // Move to next section (Areas of Interest)
    setActiveLink("areas");
  };

  const handleClose = () => {
    // This function will handle the close action, such as navigating to another page or closing the modal
    setActiveLink("apply");
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 1, marginTop:"-20px"}}>
      <Box sx={{ maxWidth: "100%", padding: 2 }}>
        {/* Close Button */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 2,
            right: 8,
            color: "gray",
            '&:hover': { backgroundColor: "transparent" },
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" sx={{ marginBottom: 2, textAlign: "center" }}>
          Academic Background
        </Typography>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Organization / Institution"
            name="institution"
            value={data.institution}
            onChange={handleOnChange}
            error={!!errors.institution}
            helperText={errors.institution}
            required
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Field of Study"
            name="study"
            value={data.study}
            onChange={handleOnChange}
            error={!!errors.study}
            helperText={errors.study}
            required
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Year of Study"
            name="year"
            value={data.year}
            onChange={handleOnChange}
            error={!!errors.year}
            helperText={errors.year}
            required
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="GPA"
            name="gpa"
            value={data.gpa}
            onChange={handleOnChange}
            error={!!errors.gpa}
            helperText={errors.gpa}
            required
            sx={{ marginBottom: 2 }}
          />
        </Grid>

        <Typography variant="h5" sx={{ marginBottom: 2, textAlign: "center" }}>
          Technical Skills
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Programming Languages"
              name="programmingskills"
              value={data.programmingskills}
              onChange={handleOnChange}
              error={!!errors.programmingskills}
              helperText={errors.programmingskills}
              required
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Tools and Technologies"
              name="toolstechnologies"
              value={data.toolstechnologies}
              onChange={handleOnChange}
              error={!!errors.toolstechnologies}
              helperText={errors.toolstechnologies}
              required
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#043885",
                "&:hover": {
                  backgroundColor: "#052659",
                },
                padding: "10px 20px",
              }}
              onClick={handleNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Apply;
