import React, { useState } from "react";
import { Link } from "react-router-dom";
import BusinessIntelligence from "../../../../assets/learnersDashboard/dataScience/businessIntelligence.png";
import MachineLearning from "../../../../assets/learnersDashboard/dataScience/machineLearningAI.png";
import DataEngineering from "../../../../assets/learnersDashboard/dataScience/dataEngineering.png";
import GenerativeAI from "../../../../assets/learnersDashboard/dataScience/generativeAI.png";
import CertifiedDataScientist from "../../../../assets/learnersDashboard/dataScience/certifiedDataScientist.png";
import Gateda from "../../../../assets/learnersDashboard/dataScience/gateDa.png";

const IearningPath = () => {
  const [clickedPaths, setClickedPaths] = useState(() => {
    const savedPaths = localStorage.getItem("clickedPaths");
    return savedPaths ? JSON.parse(savedPaths) : {};
  });

  const handleStartClick = (path) => {
    const updatedPaths = { ...clickedPaths, [path]: true };
    setClickedPaths(updatedPaths);
    localStorage.setItem("clickedPaths", JSON.stringify(updatedPaths));
  };

  const renderButton = (path) => {
    const isClicked = clickedPaths[path];
    return (
      <button
        className="learning-path-button"
        onClick={() => handleStartClick(path)}
      >
        {isClicked ? "Resume" : "Start"}
      </button>
    );
  };

  return (
    <div>
      <div className="mylearningpaths">
        <div className="learning-path-container">
          <div className="learning-path-cards">
            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={BusinessIntelligence}
                alt="IoT Developer"
              />
              <h2 className="learning-path-title">Business Intelligence</h2>
              <Link to="./business-intelligence" style={{ textDecoration: "none" }}>
                {renderButton("business-intelligence")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={MachineLearning}
                alt="IoT Data Scientist"
              />
              <h2 className="learning-path-title">Machine Learning and AI</h2>
              <Link to="./machine-learning-ai" style={{ textDecoration: "none" }}>
                {renderButton("machine-learning-ai")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={DataEngineering}
                alt="Industrial IoT"
              />
              <h2 className="learning-path-title">Data Engineering</h2>
              <Link to="./data-engineering" style={{ textDecoration: "none" }}>
                {renderButton("data-engineering")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={GenerativeAI}
                alt="IoT Security"
              />
              <h2 className="learning-path-title">Generative AI</h2>
              <Link to="./generative-ai" style={{ textDecoration: "none" }}>
                {renderButton("generative-ai")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={CertifiedDataScientist}
                alt="IoT Hardware"
              />
              <h2 className="learning-path-title">Certified Data Scientist</h2>
              <Link to="./certified-data-scientist" style={{ textDecoration: "none" }}>
                {renderButton("certified-data-scientist")}
              </Link>
            </div>

            <div className="learning-path-card">
              <img
                className="learning-path-image"
                src={Gateda}
                alt="IoT Hardware"
              />
              <h2 className="learning-path-title">GATE DA (Data Science and Artificial Intelligence)</h2>
              <Link to="./data-science-artificial-intelligence" style={{ textDecoration: "none" }}>
                {renderButton("data-science-artificial-intelligence")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IearningPath;
