import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { initializeGA, trackPageView } from './analytics'; // Import GA functions
import Homepage1 from './mainpage/Homepage/homepage';
import Termsofuse from "./mainpage/Footer/termsofUse";
import PrivacyPolicy from "./mainpage/Footer/privacyPolicy";
import Careers from './mainpage/Abouts/Careers';
import Partnerships from './mainpage/Abouts/Partnerships';
import AboutUs from './mainpage/Abouts/Aboutus';
// import MarketingTeam from "./mainpage/Abouts/marketingTeam";
//Adminpages
import AdminSignup from './AdminDashboard/Adminsignup/Adminsignup';
import AdminLogin from './AdminDashboard/Adminlogin/Adminlogin';
import AdminForgotpassword from "./AdminDashboard/AdminForgotpassword/Forgot_Password";
import AdminDashboard from './AdminDashboard/AdminDashboard';
//Comanies
import Companies from './organizations/Organizations/organizations';
import InternetofThing from './organizations/LearningOrganizations/InternetofThings';
import DataScience from './organizations/LearningOrganizations/DataScience';
import EmbeddedSystem from './organizations/LearningOrganizations/EmbeddedSystems';
import Brilliant from './organizations/SignupOrganizations/signup';
import Transformation from './organizations/LoginOrganizations/Login';
import Forgotpasswordcompanies from './organizations/ForgotPasswordOrganizations/ForgotPassword';
import Organizationspopup from './organizations/PopupOrganizations/organizationspopup';
import Postproject1 from './organizations/OrganizationsProjects/post_project';
import Postproject2 from './organizations/OrganizationsProjects/select_industry';
import Postproject3 from './organizations/OrganizationsProjects/skills_required';
import Postproject4 from './organizations/OrganizationsProjects/select_languages';
import Postproject5 from './organizations/OrganizationsProjects/completion_time';
import Postproject6 from './organizations/OrganizationsProjects/submit_project';
import PostprojectsUploads from './organizations/OrganizationsProjects/postprojectsuploads';
import Compainesdashboard from './organizations/OrganizationsProjects/organizations_dashboard';
//Learners_pages
import Students from './Learners/Learnerspages/Learners/Learners';
import InternetofThings from './Learners/Learnerspages/LearningPathPages/IotPage';
import DataSciences from './Learners/Learnerspages/LearningPathPages/DataScience';
import EmbeddedSystems from './Learners/Learnerspages/LearningPathPages/EmbeddedSystems';
import Signup from './Learners/Learnersdashboard/Signup/signup';
import Sigin from './Learners/Learnersdashboard/Login/login';
import ForgotPassword from './Learners/Learnersdashboard/ForgotPassword/forgotPassword';
//Learners_dashboard
import Dashboardhomepage from './Learners/Learnersdashboard/Learners_Sidebar/dashboardhomepage';
import Topbar from './Learners/Learnersdashboard/Learners_Sidebar/topbar';
import Userprofile from './Learners/Learnersdashboard/Learners_data/userprofile';
import Dashboard from './Learners/Learnersdashboard/dashboard';
import SidebarComponent from './Learners/Learnersdashboard/Learners_Sidebar/Sidebar';
import Learningpath from './Learners/Learnersdashboard/learningpath';
import Iotdeveloper from "./Learners/Learnersdashboard/learningpath/Iot-developer";
import Industrialiot from "./Learners/Learnersdashboard/learningpath/Industrial-iot-and-insutry";
import Iotsecurity from "./Learners/Learnersdashboard/learningpath/Iot-security-specialist";
import Iotdatascientist from "./Learners/Learnersdashboard/learningpath/Iot-data-scientist";
import Iothardware from "./Learners/Learnersdashboard/learningpath/Iot-hardware-engineer";
import Started from './Learners/Learnersdashboard/learningpath/Customizedpath';
import Courses from './Learners/Learnersdashboard/courses/courses';
import Projects from './Learners/Learnersdashboard/projects/projects';
// Learning Paths for Data science
import DataScienceSidebarComponent from "./Learners/Learnersdashboard/Data-science-Learning-Paths/Sidebar";
import DataScienceDashboard from './Learners/Learnersdashboard/Data-science-Learning-Paths/dashboard';
import DataScienceTopbar from './Learners/Learnersdashboard/Data-science-Learning-Paths/topbar';
import DatascienceLearningPaths from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Learning-Paths";
import BusinessIntelligence from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Business-Intelligence";
import MachineLearningAI from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Machine-Learning";
import DataEngineering from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Data-Engineering";
import GenerativeAI from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Generative-AI";
import CertifiedDataScientist from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Certified-Data-Scientist";
import DataScienceArtificialIntelligence from "./Learners/Learnersdashboard/Data-science-Learning-Paths/LearningPaths/Data-Science-Artificial-Intelligence";


import Capstoneaimagic from './Learners/Learnersdashboard/capstoneaimagic/capstoneaimagic';
import LiveSessions from './Learners/Learnersdashboard/Live-Sessions/Live-Sessions';
import Work from './Learners/Learnersdashboard/Learners_data/Work';
import ProtectedRoutes from './helper/organizationstoken';
import ProtectedRoute from './helper/learnersToken';
import * as amplitude from '@amplitude/analytics-browser';
// Custom Amplitude config
// const config = {};

// export const initAmplitude = () => {
//   amplitude.init(AMPLITUDE_PROJECT_ID, undefined, config);
  amplitude.init('7d242daccb34990cf675f19b2bacaf9a', {"autocapture":true});


  const PageTracker = () => {
    const location = useLocation();
  
    useEffect(() => {
      trackPageView(location.pathname);
    }, [location]);
  
    return null;
  };

function App() {
    useEffect(() => {
    initializeGA();
  }, []);

    return (
        <>
            <CssBaseline />
            <PageTracker />
            <div className="app">
                <Routes>
                    {/* Homepages routes */}
                    <Route path="/" element={<Homepage1 />} />
                    <Route path="/terms-and-conditions" element={<Termsofuse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/partnerships" element={<Partnerships />} />
                    {/* <Route path="/marketing-team" element={<MarketingTeam />} /> */}
                    {/* Admindashboard */}
                    <Route path="/admin/signup" element={<AdminSignup />} />
                    <Route path="/admin/signin" element={<AdminLogin />} />
                    <Route path="/admin/forgot-password" element={<AdminForgotpassword/>} />
                    <Route path="/admin/dashboard" element={<AdminDashboard />} />
                    {/* Companies routes */}
                    <Route path="/organizations" element={<Companies />} />
                    <Route path="/organizations/internet-of-things" element={<InternetofThing/>} />
                    <Route path="/organizations/data-science" element={<DataScience/>} />
                    <Route path="/organizations/embedded-systems" element={<EmbeddedSystem/>} />
                    <Route path="/organizations/signup" element={<Brilliant />} />
                    <Route path="/organizations/signin" element={<Transformation />} />
                    <Route path="/organizations/forgot-password" element={<Forgotpasswordcompanies />} />
                    <Route element={<ProtectedRoutes />}>
                    <Route path="/organizations/new-post-project" element={<Organizationspopup />} />
                    <Route path="/organizations/post-project" element={<Postproject1 />} />
                    <Route path="/organizations/select-industry" element={<Postproject2 />} />
                    <Route path="/organizations/select-skills" element={<Postproject3 />} />
                    <Route path="/organizations/select-languages" element={<Postproject4 />} />
                    <Route path="/organizations/project-timeline" element={<Postproject5 />} />
                    <Route path="/organizations/upload-project" element={<Postproject6 />} />
                    <Route path="/organizations/project-confirmation" element={<PostprojectsUploads />} />
                    <Route path="/organizations/dashboard" element={<Compainesdashboard />} />
                    </Route>
                    {/* Learners Pages routes*/}
                    <Route path="/learners" element={<Students />} />
                    <Route path="/learners/internet-of-things" element={<InternetofThings />} />
                    <Route path="/learners/data-science" element={<DataSciences />} />
                    <Route path="/learners/embedded-systems" element={<EmbeddedSystems />} />
                    {/* Students Authentication routes */}
                    <Route path="/learners/signup" element={<Signup />} />
                    <Route path="/learners/signin" element={<Sigin />} />
                    <Route path="/learners/forgot-password" element={<ForgotPassword />} />
                     {/* Students  Dashboard routes */}
                     <Route path="/learners/dashboardpage" element={<Dashboardhomepage />} />
                     <Route element={<ProtectedRoute />}>
                     <Route path="/learners/dashboard/*" element={<DashboardLayout />} />
                     <Route path="/learners/data-science-dashboard/*" element={<DataScienceDashboardLayout />} />
                     </Route>
                </Routes>
            </div>
        </>
    );
}

function DashboardLayout() {
    return (
        <>
            <SidebarComponent />
            <main className="content">
                <Topbar />
                <Routes>
                    <Route element={<ProtectedRoute />}>
                    <Route index element={<Dashboard />} />
                    <Route path="internetofthings" element={<Learningpath />} />
                    <Route path="internetofthings/iot-developer-engineer" element={<Iotdeveloper />} />
                    <Route path="internetofthings/iot-data-scientist" element={<Iotdatascientist />} />
                    <Route path="internetofthings/industrial-iot-industry" element={<Industrialiot />} />
                    <Route path="internetofthings/iot-security-specialist" element={<Iotsecurity />} />
                    <Route path="internetofthings/iot-hardware-engineer" element={<Iothardware />} />
                    <Route path="internetofthings/started" element={<Started />} />
                    <Route path="courses" element={<Courses />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="capstoneaimagic" element={<Capstoneaimagic />} />
                    <Route path="mentorconnect" element={<LiveSessions />} />
                    <Route path="userprofile" element={<Userprofile />} />
                    <Route path="work" element={<Work />} />
                    </Route>
                </Routes>
            </main>
        </>
    );
}

function DataScienceDashboardLayout() {
    return (
        <>
            <DataScienceSidebarComponent />
            <main className="content">
                <DataScienceTopbar />
                <Routes>
                    <Route element={<ProtectedRoute />}>
                    <Route index element={<DataScienceDashboard />} />
                    {/*  Learning Paths for Data science */}
                    <Route path="data-science-learningpath" element={<DatascienceLearningPaths />} />
                    <Route path="data-science-learningpath/business-intelligence" element={<BusinessIntelligence />} />
                    <Route path="data-science-learningpath/machine-learning-ai" element={<MachineLearningAI />} />
                    <Route path="data-science-learningpath/data-engineering" element={<DataEngineering />} />
                    <Route path="data-science-learningpath/generative-ai" element={<GenerativeAI />} />
                    <Route path="data-science-learningpath/certified-data-scientist" element={<CertifiedDataScientist />} />
                    <Route path="data-science-learningpath/data-science-artificial-intelligence" element={<DataScienceArtificialIntelligence />} />
                    <Route path="courses" element={<Courses />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="capstoneaimagic" element={<Capstoneaimagic />} />
                    <Route path="mentorconnect" element={<LiveSessions />} />
                    <Route path="userprofile" element={<Userprofile />} />
                    <Route path="work" element={<Work />} />
                    </Route>
                </Routes>
            </main>
        </>
    );
}

export default App;
