import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import Internet from "../../assets/homepage/courses/internetofThings.png";
import Machine from "../../assets/homepage/courses/machineLearning.png";
import Generative from "../../assets/homepage/courses/generativeai.png";
import DataScience from "../../assets/homepage/courses/dataScienceEngineering.png";
import EmbeddedSystems from "../../assets/homepage/courses/embeddedSystems.png";
import FullStackDevelopment from "../../assets/homepage/courses/fullStackDevelopment.png";
import product1 from "../../assets/homepage/organization.png";

const courses = [
  { img: Internet, title: "Internet of Things", path: "/organizations/internet-of-things" },
  { img: Machine, title: "AI and Machine Learning", path: "/organizations/data-science" },
  { img: Generative, title: "Generative AI", path: "/organizations/data-science" },
  { img: DataScience, title: "Data Science Engineering", path: "/organizations/data-science" },
  { img: EmbeddedSystems, title: "Embedded Systems", path: "/organizations/embedded-systems" },
  { img: FullStackDevelopment, title: "Full Stack Development", path: "#" } //comming soon
];

const Organizations = () => {
  return (
    <div className="homepage-banner6-card">
      <div>
      <div className="homepage-banner6-card1">
    <div className="homepage-banner6-card1-1">
      <i className="fas fa-microchip homepage-banner6-icon"></i>
      <p className="huebits-homepages1-span">Cutting-Edge IoT and Data Solutions</p>
    </div>
    <div className="homepage-banner6-card1-1">
      <i className="fas fa-industry homepage-banner6-icon"></i>
      <p className="huebits-homepages1-span">Industry 4.0 <span>Solutions</span></p>
    </div>
     </div>
     <div className="homepage-banner6-card2">
    <div className="homepage-banner6-card1-1">
      <i className="fas fa-chart-line homepage-banner6-icon"></i>
      <p className="huebits-homepages1-span">Maintain Competitive Edge</p>
    </div>
    <div className="homepage-banner6-card1-1">
      <i className="fas fa-users homepage-banner6-icon"></i>
      <p className="huebits-homepages1-span">Elite Tech Talent
      </p>
    </div>
    </div>
      </div>
      <div className="homepage-banner6-card-image">
        <img className="homepage-banner6-card-img" src={product1} alt="" />
      </div>
    </div>
  );
};

const IndustryOrganizations = () => {
  const navigate = useNavigate(); // For navigation

  const handleCardClick = (path) => {
    console.log(`Navigating to ${path}`);
    navigate(path); // Redirects to course page
  };

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" gap={4} sx={{ padding: 3, marginTop: "25px" }}>
  {courses.map((course, index) => (
    <Card
      key={index}
      sx={{
        width: { xs: "100%", sm: "45%", md: "31%" },
        maxWidth: 350,
        boxShadow: 3,
        borderRadius: 2,
        padding: "6px",
        border: "1px solid rgb(255, 255, 4)",
        cursor: course.path !== "#" ? "pointer" : "default",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out",
        "&:hover": {
          boxShadow: course.path !== "#" ? 6 : 3,
          transform: course.path !== "#" ? "scale(1.03)" : "none",
          backgroundColor: course.path !== "#" ? "#f0f7ff" : "inherit"
        }
      }}
      onClick={() => course.path !== "#" && handleCardClick(course.path)}
    >
      <CardMedia component="img" height="180" image={course.img} alt={course.title} sx={{ borderRadius: "10px" }} />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {course.title}
        </Typography>
        <Box display="flex" alignItems="center" mt={2}>
          {course.path !== "#" ? (
            <Link
              to={course.path}
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                color: "#1976d2",
                fontWeight: "bold",
                fontSize: "16px",
                border: "1px solid #1976d2",
                borderRadius: "4px",
                padding: "6px 12px",
                transition: "0.3s ease",
              }}
            >
              Know More
              <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: "6px" }} />
            </Link>
          ) : (
            <Box
              sx={{
                backgroundColor: "#949191",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "16px",
                borderRadius: "4px",
                padding: "6px 12px",
                textAlign: "center",
              }}
            >
              Coming Soon
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  ))}
</Box>
  );
};

export { Organizations, IndustryOrganizations };
