import React from 'react'; // Import React hooks
import { Box, Grid, Paper, useMediaQuery } from "@mui/material";
import Coursesprogress from './coursesprogres';  // Import your components
import Projectsprogress from './ProjectsProgress';
import Learningpathprogress from './Learningpathprogress';
import Hoescoreprogress from './HOEScore';

const LearningPathProgressDisplay = ({ selectedOption, responseData }) => {
  const isMobile = useMediaQuery("(max-width:600px)"); // Mobile: ≤600px
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:1023px)"); // Tablet: 601px - 1023px

  return (
    <Box sx={{ padding: isMobile ? "10px" : isTablet ? "20px 30px" : "20px 60px", width: "100%" }}>
      <Grid container spacing={isMobile ? 2 : isTablet ? 3 : 4}>
        {/* Courses Progress */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
              margin: isMobile ? "10px" : isTablet ? "15px" : "0px 40px 0px 0px",
            }}
          >
            {selectedOption && <Coursesprogress totalCoursesProgress={responseData.totalCoursesProgress} />}
          </Paper>
        </Grid>
        {/* Projects Progress */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
              margin: isMobile ? "10px" : isTablet ? "15px" : "0px",
            }}
          >
            {selectedOption && <Projectsprogress totalProjectsProgress={responseData.totalProjectsProgress} />}
          </Paper>
        </Grid>
        {/* Learning Path Progress */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
              margin: isMobile ? "10px" : isTablet ? "15px" : "0px 40px 0px 0px",
            }}
          >
            {selectedOption && <Learningpathprogress learningPathProgress={responseData.learningPathProgress} />}
          </Paper>
        </Grid>
        {/* HOE Score */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: "#f5f7fa",
              margin: isMobile ? "10px" : isTablet ? "15px" : "0px",
            }}
          >
            {selectedOption && <Hoescoreprogress HOE={responseData.HOE} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LearningPathProgressDisplay;
