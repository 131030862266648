import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Typography, Box, Paper, Chip, LinearProgress, Container } from "@mui/material";
import { useLocation } from 'react-router-dom';
import OrganizationsHeader from "../PopupOrganizations/topbar";

const Postproject3 = () => {
  const [selectedSkills2Array, setSelectedSkills2Array] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(30);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const postDataSelectRef = useRef(null);
  const location = useLocation();
  const { skills,} = location.state;

  const handlePostDataChange = () => {
    const selectedSkill = postDataSelectRef.current.value.trim();
    if (selectedSkill) {
      if (selectedSkills2Array.length < 9) {
        if (!selectedSkills2Array.includes(selectedSkill)) {
          const newSkillsArray = [...selectedSkills2Array, selectedSkill];
          setSelectedSkills2Array(newSkillsArray);
          postDataSelectRef.current.value = ""; // Reset the dropdown
        }
      } else {
        toast.error("You can select a maximum of 9 skills.");
      }
    }
  };

  const handleSkillDelete = (skillToDelete) => {
    const updatedSkills = selectedSkills2Array.filter(skill => skill !== skillToDelete);
    setSelectedSkills2Array(updatedSkills);
  };

  const validateSkills = () => {
    if (selectedSkills2Array.length === 0) {
      toast.error("Please select at least one skill.");
      return false;
    }
    if (selectedSkills2Array.length > 9) {
      toast.error("You can select a maximum of 9 skills.");
      return false;
    }
    return true;
  };

  // Display selected skills in the console
  //console.log("Selected Skills:", selectedSkills2Array);
  localStorage.setItem('selectedSkills', JSON.stringify(selectedSkills2Array));

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateSkills()) {
      return;
    }

    setLoading(true);
    toast.loading("Submitting your data...");
    setCompletionPercentage(prevPercentage => prevPercentage + 20);
    setTimeout(() => {
      toast.success("Data submitted successfully!");
      navigate("/organizations/select-languages", { state: {selectedSkills2Array}});
    }, 1500);
  };

  return (
    <div className="students-flex">
      <OrganizationsHeader />
      <div className="signup-pages-flex">
        <Box
          sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%)",
          padding: "20px 10px",
          height: { xs: "100vh", sm: "100vh", md: "100vh" },
          }}
          >
          <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
          <Box sx={{ marginTop: -6 }}>
            <Container maxWidth="sm">
              <Paper elevation={2} sx={{ p: 1, borderRadius: 2, textAlign: "center", padding: "10px 20px 30px 20px" }}>
                <form onSubmit={handleSubmit}>
                  <Typography variant="h4" style={{ padding: "10px", color: "#052659" }}>
                    What skills are you looking for in particular?
                  </Typography>
                  <Typography variant="p" color="textSecondary" sx={{ marginBottom: 2 }}>
                    Let's ensure that your project post attracts the talent with the right skillset.
                  </Typography>
                  <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                  <select id="postData" ref={postDataSelectRef}
                    aria-label="Select skill"
                    style={{
                      width: '100%',
                      padding: '10px 15px',
                      fontSize: '16px',
                      fontFamily: 'Arial, sans-serif',
                      border: '2px solid #ddd',
                      borderRadius: '5px',
                      backgroundColor: '#fff',
                      marginTop: "10px",
                      color: '#333',
                      outline: 'none',
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      transition: 'border-color 0.3s ease, background-color 0.3s ease',
                    }}
                    onFocus={(e) => e.target.style.borderColor = '#5c6bc0'}
                    onBlur={(e) => e.target.style.borderColor = '#ddd'}
                    onChange={handlePostDataChange}>
                    <option value="">select option</option>
                    {skills.map((skill, index) => (
                      <option key={index} value={skill}>{skill}</option>
                    ))}
                  </select>
                  </Box>
                  <Box sx={{ marginY: 1 }}>
                    {selectedSkills2Array.map((skill, index) => (
                      <Chip key={index}
                        label={skill}
                        onDelete={() => handleSkillDelete(skill)}
                        color="primary"
                        sx={{ margin: 0.5,
                          backgroundColor: "#052659",
                          color: "#ffff"
                         }} 
                      />
                    ))}
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 3 }}>
                    <Button
                      component={Link}
                      to="/organizations/post-project"
                      variant="outlined"
                      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                      disabled={loading}
                      sx={{
                        margin: 0.5,
                        color: "#052659",
                        "& .MuiChip-deleteIcon": {
                          color: "#052659",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      sx={{
                        margin: 0.5,
                        backgroundColor: "#043885",
                        "&:hover": {
                          backgroundColor: "#052659",
                        },
                      }}
                    >
                      {loading ? "Submitting..." : "Next"}
                    </Button>
                  </Box>
                </form>
                <Box sx={{ marginTop: 4 }}>
                  <Typography variant="body2" gutterBottom>
                    Step 3 of 6
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={completionPercentage}
                    sx={{
                      borderRadius: 1,
                      height: 10,
                      backgroundColor: "#f5f5f5",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "green",
                      },
                    }} />
                  <Typography variant="body2" align="right"
                    sx={{ mt: 1, fontWeight: 600 }}>
                    {completionPercentage}%
                  </Typography>
                </Box>
              </Paper>
            </Container>
          </Box>
      </Box>
      </div>
    </div>
  );
};

export default Postproject3;
