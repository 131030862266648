import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, Box, Typography, Pagination, useMediaQuery } from "@mui/material";
import Course1 from "../../../assets/Courses/datasciencecourses/DataScienceFoundation.png";
import Course2 from "../../../assets/Courses/datasciencecourses/PythonforDataScience.png";
import Course3 from "../../../assets/Courses/datasciencecourses/StatisticsforDataScience.png";
import Course4 from "../../../assets/Courses/datasciencecourses/MathematicsforDataScience.png";
import Course5 from "../../../assets/Courses/datasciencecourses/SQLforDataScience.png";
import Course6 from "../../../assets/Courses/datasciencecourses/IntroductiontoBusinessIntelligence.png";
import Course7 from "../../../assets/Courses/datasciencecourses/DataVisualization.png";
import Course8 from "../../../assets/Courses/datasciencecourses/MasteringPowerBIDataAnalyticsandVisualizationwithHands-OnProjects.png";
import Course9 from "../../../assets/Courses/datasciencecourses/MasteringTableauDataVisualizationandBusinessIntelligence.png";
import Course10 from "../../../assets/Courses/datasciencecourses/MasteringGoogleLookerStudioDataVisualizationandBusinessIntelligence.png";
import Course11 from "../../../assets/Courses/datasciencecourses/MasteringQlikSenseDataVisualizationandAnalytics.png";
import Course12 from "../../../assets/Courses/datasciencecourses/machineLearningEssentials.png";
import Course13 from "../../../assets/Courses/datasciencecourses/AdvancedMachineLearning.png";
import Course14 from "../../../assets/Courses/datasciencecourses/MachineLearningModelDeploymentUsingFlask.png";
import Course15 from "../../../assets/Courses/datasciencecourses/TimeSeriesFoundation.png";
import Course16 from "../../../assets/Courses/datasciencecourses/DeepLearning.png";
import Course17 from "../../../assets/Courses/datasciencecourses/NaturalLanguageProcessing.png";
import Course18 from "../../../assets/Courses/datasciencecourses/ReinforcementLearning.png";
import Course19 from "../../../assets/Courses/datasciencecourses/MicrosoftCertifiedAzureAIFundamentals.png";
import Course20 from "../../../assets/Courses/datasciencecourses/DataEngineeringFundamentals.png";
import Course21 from "../../../assets/Courses/datasciencecourses/MicrosoftAzureDataEngineering.png";
import Course22 from "../../../assets/Courses/datasciencecourses/MasteringApacheKafkaHands-OnDistributedStreamingandDataPipelines.png";
import Course23 from "../../../assets/Courses/datasciencecourses/MasteringDatabricksEnd-to-EndDataEngineeringandAnalyticswithApacheSpark.png";
import Course24 from "../../../assets/Courses/datasciencecourses/MasteringSnowflakeHands-OnCloudDataWarehousingandAnalytics.png";
import Course25 from "../../../assets/Courses/datasciencecourses/AIFundamentals.png";
import Course26 from "../../../assets/Courses/datasciencecourses/CompleteGenerativeAICourse.png";
import Course27 from "../../../assets/Courses/datasciencecourses/GenerativeAdverarialNetworks.png";

//projects
import Project1 from "../../../assets/Courses/datascience-projects/104.png";
import Project2 from "../../../assets/Courses/datascience-projects/106.png";
import Project3 from "../../../assets/Courses/datascience-projects/108.png";
import Project4 from "../../../assets/Courses/datascience-projects/111.png";
import Project5 from "../../../assets/Courses/datascience-projects/113.png";
import Project6 from "../../../assets/Courses/datascience-projects/117.png";
import Project7 from "../../../assets/Courses/datascience-projects/119.png";
import Project8 from "../../../assets/Courses/datascience-projects/121.png";
import Project9 from "../../../assets/Courses/datascience-projects/123.png";
import Project10 from "../../../assets/Courses/datascience-projects/125.png";
import Project11 from "../../../assets/Courses/datascience-projects/128.png";
import Project12 from "../../../assets/Courses/datascience-projects/168.png";
import Project13 from "../../../assets/Courses/datascience-projects/130.png";
import Project14 from "../../../assets/Courses/datascience-projects/132.png";
import Project15 from "../../../assets/Courses/datascience-projects/134.png";
import Project16 from "../../../assets/Courses/datascience-projects/136.png";
import Project17 from "../../../assets/Courses/datascience-projects/138.png";
import Project18 from "../../../assets/Courses/datascience-projects/140.png";
import Project19 from "../../../assets/Courses/datascience-projects/169.png";
import Project20 from "../../../assets/Courses/datascience-projects/170.png";
import Project21 from "../../../assets/Courses/datascience-projects/142.png";
import Project22 from "../../../assets/Courses/datascience-projects/148.png";
import Project23 from "../../../assets/Courses/datascience-projects/150.png";
import Project24 from "../../../assets/Courses/datascience-projects/153.png";
import Project25 from "../../../assets/Courses/datascience-projects/155.png";
import Project26 from "../../../assets/Courses/datascience-projects/157.png";
import Project27 from "../../../assets/Courses/datascience-projects/159.png";
import Project28 from "../../../assets/Courses/datascience-projects/163.png";
import Project29 from "../../../assets/Courses/datascience-projects/166.png";

const courses = [
    { id: 1, img: Course1, title: "Data Science Foundation"},//19
    { id: 2, img: Course2, title: "Python for Data Science" },//23
    { id: 3, img: Course3, title: "Statistics for Data Science" },//27
    { id: 4, img: Course4, title: "Mathematics for Data Science" },//30
    { id: 5, img: Course5, title: "SQL for Data Science" },//33
    { id: 6, img: Course6, title: "Business Intelligence"},//37
    { id: 7, img: Course7, title: "Data Visualization" },//42
    { id: 8, img: Course8, title: "Mastering Power BI: Data Analytics and Visualization with Hands-On Projects" },//44
    { id: 9, img: Course9, title: "Mastering Tableau: Data Visualization and Business Intelligence" },//48
    { id: 10, img: Course10, title: "Mastering Google Looker Studio: Data Visualization and Business Intelligence" },//50
    { id: 11, img: Course11, title: "Mastering Qlik Sense: Data Visualization and Analytics"},//52
    { id: 12, img: Course12, title: "Machine Learning Essentials Foundations and Key Algorithms" },//55
    { id: 13, img: Course13, title: "Advanced Machine Learning" },//57
    { id: 14, img: Course14, title: "Machine Learning Model Deployment Using Flask" },//60
    { id: 15, img: Course15, title: "Time Series Foundation" },//66
    { id: 16, img: Course16, title: "Deep Learning"},//69
    { id: 17, img: Course17, title: "Natural Language Processing" },//72
    { id: 18, img: Course18, title: "Reinforcement Learning" },//75
    { id: 19, img: Course19, title: "Microsoft Certified Azure AI Fundamentals" },//77
    { id: 20, img: Course20, title: "Data Engineering Fundamentals" },//81
    { id: 21, img: Course21, title: "Microsoft Azure Data Engineering" },//84
    { id: 22, img: Course22, title: "Mastering Apache Kafka: Hands-On Distributed Streaming and Data Pipelines" },//87
    { id: 23, img: Course23, title: "Mastering Databricks: End-to-End Data Engineering and Analytics with Apache Spark" },//89
    { id: 24, img: Course24, title: "Mastering Snowflake: Hands-On Cloud Data Warehousing and Analytics" },//91
    { id: 25, img: Course25, title: "Generative AI Fundamentals" },//95
    { id: 26, img: Course26, title: "Complete Generative AI Course using Langchain and Huggingface" },//98
    { id: 27, img: Course27, title: "Generative Adversarial Networks" },//101
    // { id: 28, img: Course28, title: "AI Fundamentals" },
    // { id: 29, img: Course29, title: "Generative AI Applications and Case Studies" },
    // { id: 30, img: Course30, title: "Prompt Engineering" },
    // { id: 31, img: Course31, title: "Fine Tuning LLM’s" },
    // { id: 32, img: Course32, title: "Probability & Statistics for Data Science" },
    // { id: 33, img: Course33, title: "Engineering Mathematics" },
];

const projects = [
    { id: 1, img: Project1, title: "PySpark ETL Project for Real-Time Data Processing" },//104
    { id: 2, img: Project2, title: "Analyse Yelp Dataset with Spark & Parquet Format on Azure Databricks" },//106
    { id: 3, img: Project3, title: "Movielens Dataset Analysis on Azure" },//108
    { id: 4, img: Project4, title: "Talend Real-Time Project for ETL Process Automation" },//111
    { id: 5, img: Project5, title: "Hadoop Project-Analysis of Yelp Dataset using Hadoop Hive" },//113
    { id: 6, img: Project6, title: "Azure Data Factory and Databricks End-to-End Project" },//117
    { id: 7, img: Project7, title: "Snowflake Azure Project to build real-time Twitter feed dashboard" },//119
    { id: 8, img: Project8, title: "Databricks Real-Time Streaming with Event Hubs and Snowflake" },//121
    { id: 9, img: Project9, title: "Azure Stream Analytics for Real-Time Cab Service Monitoring" },//123
    { id: 10, img: Project10, title: "Build a Data Pipeline with Azure Synapse and Spark Pool" },//125
    { id: 11, img: Project11, title: "Stock Price Prediction Project using LSTM and RNN" },//128
    { id: 12, img: Project12, title: "Many-to-One LSTM for Sentiment Analysis and Text Generation" },//168
    { id: 13, img: Project13, title: "Image Classification Model using Transfer Learning in PyTorch" },//130
    { id: 14, img: Project14, title: "Build a Text Generator Model using Amazon SageMaker" },//132
    { id: 15, img: Project15, title: "PyTorch Project to Build a LSTM Text Classification Model" },//134
    { id: 16, img: Project16, title: "Text Classification with Transformers-RoBERTa and XLNet Model" },//136
    { id: 17, img: Project17, title: "Build Multi Class Text Classification Models with RNN and LSTM" },//138
    { id: 18, img: Project18, title: "Build a Langchain Streamlit Chatbot for EDA using LLMs" },//140
    { id: 19, img: Project19, title: "AI Video Summarization Project using Mixtral, Whisper, and AWS" },//169
    { id: 20, img: Project20, title: "Build a Multi ClassText Classification Model using Naive Bayes" },//170
    { id: 21, img: Project21, title: "Build and Deploy Text-2-SQL LLM Using OpenAI and AWS" },//142
    { id: 22, img: Project22, title: "BigMart Sales Prediction ML Project in Python" },//148
    { id: 23, img: Project23, title: "AWS MLOps Project to Deploy Multiple Linear Regression Model" },//150
    { id: 24, img: Project24, title: "Credit Card Default Prediction using Machine learning techniques" },//153
    { id: 25, img: Project25, title: "Build an Image Classifier for Plant Species Identification" },//155
    { id: 26, img: Project26, title: "PyTorch Project to Build a GAN Model on MNIST Dataset" },//157
    { id: 27, img: Project27, title: "Build a Multi Class Image Classification Model Python using CNN" },//159
    { id: 28, img: Project28, title: "Build an optimal End-to-End MLOps Pipeline and Deploy on GCP" },//163
    { id: 29, img: Project29, title: "Predict Churn for a Telecom company using Logistic Regression" },//166
];

const DataScienceCourses = () => {
    const [currentPage, setCurrentPage] = useState(1);
      const [itemsPerPage, setItemsPerPage] = useState(8);
    
      const isPhone = useMediaQuery("(max-width: 480px)");
      //const isTablet = useMediaQuery("(max-width: 768px)");
    
      useEffect(() => {
        const updateItemsPerPage = () => {
          if (window.innerWidth <= 480) {
            setItemsPerPage(2);
          } else if (window.innerWidth <= 768) {
            setItemsPerPage(4);
          } else {
            setItemsPerPage(8);
          }
        };
    
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
      }, []);
    
      const totalPages = Math.ceil(courses.length / itemsPerPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = courses.slice(startIndex, startIndex + itemsPerPage);
    
      return (
        <Box>
          {/* Course Grid */}
          <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop="4px">
            {currentItems.map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id} display="flex" justifyContent="center">
                <Card sx={{ width: 280, height: 260, borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia component="img" height="170px" image={course.img} alt={course.title} />
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="body" fontSize={14} textAlign="start">
                      {course.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ArrowBack />
            </IconButton> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              siblingCount={isPhone ? 0 : 1} // 0 on phones, 1 on tablets/desktops
              boundaryCount={isPhone ? 1 : 2} // Show first/last pages
              size={isPhone ? "small" : "medium"} // Smaller pagination on phones
            />
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ArrowForward />
            </IconButton> */}
          </Box>
        </Box>
      );
    };

//projects
const DataScienceProjects = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    
    const isPhone = useMediaQuery("(max-width: 480px)");
    //const isTablet = useMediaQuery("(max-width: 768px)");
    
      useEffect(() => {
        const updateItemsPerPage = () => {
          if (window.innerWidth <= 480) {
            setItemsPerPage(2);
          } else if (window.innerWidth <= 768) {
            setItemsPerPage(4);
          } else {
            setItemsPerPage(8);
          }
        };
    
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
      }, []);
    
      const totalPages = Math.ceil(projects.length / itemsPerPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const currentItems = projects.slice(startIndex, startIndex + itemsPerPage);
    
      return (
        <Box>
          {/* Course Grid */}
          <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop="4px">
            {currentItems.map((course) => (
              <Grid item xs={12} sm={6} md={3} key={course.id} display="flex" justifyContent="center">
                <Card sx={{ width: 280, height: 260, borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia component="img" height="170px" image={course.img} alt={course.title} />
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="h6" fontSize={14} textAlign="start">
                      {course.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Pagination Controls */}
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              <ArrowBack />
            </IconButton> */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
              siblingCount={isPhone ? 0 : 1} // 0 on phones, 1 on tablets/desktops
              boundaryCount={isPhone ? 1 : 2} // Show first/last pages
              size={isPhone ? "small" : "medium"} // Smaller pagination on phones
            />
            {/* <IconButton onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              <ArrowForward />
            </IconButton> */}
          </Box>
        </Box>
      );
    };

export {DataScienceCourses, DataScienceProjects};