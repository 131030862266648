import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import Learners from "../../assets/homepage/learners.png"

const Reveal = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
          transition={{ duration: 0.5 }}
          className={className}
        >
      <div className="homepagehubits-courses-learners-banner2">
        <div>
          <div className="homepagehubits-courses-learners-banner2-card1">
            <div className='homepagehubits-courses-learners-banner2-cards1'>
            {inView && (
             <h1 className="homepagehubits-courses-learners-banner2-cards1-h1"><CountUp end={75} duration={10} />+</h1>
            )} 
             <p className='homepagehubits-courses-learners-banner2-cards1-p1'> Courses and Projects</p>
            </div>
            <div className='homepagehubits-courses-learners-banner2-cards1'>
            {inView && (
              <h1 className="homepagehubits-courses-learners-banner2-cards1-h1"><CountUp end={20} duration={10} />+</h1>
            )}
              <p className='homepagehubits-courses-learners-banner2-cards1-p1'> Collaborations</p>
            </div>
            </div>
            <div className="homepagehubits-courses-learners-banner2-cards2">
            <div className='homepagehubits-courses-learners-banner2-cards1'>
            {inView && (
              <h1 className="homepagehubits-courses-learners-banner2-cards1-h1"><CountUp end={100} duration={10} />%</h1>
            )}
              <p className='homepagehubits-courses-learners-banner2-cards1-p1'> Guaranteed Internships, Jobs</p>
            </div>
            <div className="homepagehubits-courses-learners-banner2-cards1">
            {inView && (
              <h1 className="homepagehubits-courses-learners-banner2-cards1-h1"><CountUp end={100} duration={10} />%</h1>
            )}
              <p className='homepagehubits-courses-learners-banner2-cards1-p1'> Exposure To The Real Industry Experience</p>
          </div>
        </div>
        </div>
        <img src={Learners} alt='' className='homepagehubits-courses-learners-banner2-cards-image'/>
    </div>
    {children}
  </motion.div>
  );
};

export default Reveal;
