import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio, Typography, CircularProgress, Paper, Box } from "@mui/material";
import OrganizationsHeader from "./topbar";

const Organizationspopup = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedSkill, setSelectedSkill] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setErrorMessage(""); // Clear error message on selection
        if (event.target.value !== "Rapid POC & Product Development" && event.target.value !== "Expert Research Consultancy") {
            setSelectedSkill(""); // Reset skill if another option is chosen
        }
    };

    const handleSkillChange = (event) => {
        setSelectedSkill(event.target.value);
        setErrorMessage(""); // Clear error message on selection
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (!selectedOption) {
            setErrorMessage("Please select an option before proceeding.");
            return;
        }
    
        if ((selectedOption === "Rapid POC & Product Development" || selectedOption === "Expert Research Consultancy") && !selectedSkill) {
            setErrorMessage("Please select a skill.");
            return;
        }
    
        setErrorMessage(""); // Clear error message when input is valid
        const formData = {
            Development: selectedOption,
            skill: selectedSkill,
        };
        
        localStorage.setItem("formData", JSON.stringify(formData));

        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            navigate("/organizations/post-project");
        }, 500);
    };
    
    return (
        <div className="students-flex">
            <OrganizationsHeader />
            <div className="organizations-popup-background-image">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "86vh", 
                        padding: '20px'
                    }} >
                    <Paper elevation={3} style={{ maxWidth: "500px", padding: "20px", borderRadius: "12px", backgroundColor: "#ffffff" }}>
                    <DialogTitle>
                    <Typography
                        component="div"
                        variant="h4"
                        align="center"
                        gutterBottom
                        style={{ fontWeight: "600", color: "#052659" }}
                    >
                        Welcome!
                    </Typography>
                       </DialogTitle>
                        <DialogContent>
                            <Typography
                                variant="body1"
                                align="center"
                                marginBottom={2}
                                style={{ marginBottom: "20px", color: "#555" }}
                            >
                                What would you like to do next?
                            </Typography>
                            {errorMessage && (
                                <Typography color="error" align="center" style={{ marginBottom: "10px" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <form id="postDataForm" onSubmit={handleSubmit}>
                                <RadioGroup value={selectedOption} onChange={handleOptionChange} name="projectsOption">
                                    <Box>
                                        <FormControlLabel
                                            value="Rapid POC & Product Development"
                                            control={<Radio />}
                                            label="Rapid POC & Product Development"
                                            style={{ marginBottom: "10px" }}
                                        />
                                        {selectedOption === "Rapid POC & Product Development" && (
                                            <select value={selectedSkill} onChange={handleSkillChange} style={{ marginTop: "10px", marginBottom: "10px", width: "100%", padding: "8px" }}>
                                                <option value="">Select</option>
                                                <option value="Internet of Things">Internet of Things</option>
                                                <option value="Embedded Systems">Embedded Systems</option>
                                                <option value="Data Science">Data Science</option>
                                                <option value="Web Application Development">Web Application Development</option>
                                            </select>
                                        )}
                                    </Box>
                                    <FormControlLabel value="Expert Research Consultancy" control={<Radio />} label="Expert Research Consultancy" />
                                    {selectedOption === "Expert Research Consultancy" && (
                                        <select value={selectedSkill} onChange={handleSkillChange} style={{ marginTop: "10px", marginBottom: "10px", width: "100%", padding: "8px" }}>
                                            <option value="">Select</option>
                                            <option value="Data Science">Data Science</option>
                                            <option value="Digital Marketing">Digital Marketing</option>
                                            <option value="Content Development">Content Development</option>
                                            <option value="Business Intelligence">Business Intelligence</option>
                                        </select>
                                    )}
                                </RadioGroup>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={loading}
                                fullWidth
                                style={{
                                    height: "45px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderRadius: "8px",
                                    backgroundColor: "#052659",
                                }}
                            >
                                {loading ? (
                                    <>
                                        <CircularProgress size={20} color="#ffff" /> Submitting...
                                    </>
                                ) : (
                                    "Post A Project"
                                )}
                            </Button>
                        </DialogActions>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default Organizationspopup;
